<template>
  <div
    class="currency-component-container"
    :style="style"
  >
    {{ timeString }}
  </div>
</template>

<script>
import Minin from './mixin';
export default {
  name: 'DateCom',
  mixins: [Minin],
  computed: {
    year() {
      return this.date.getFullYear();
    },
    month() {
      return this.date.getMonth() + 1;
    },
    day() {
      return this.date.getDate();
    },
    timeString() {
      return this.formatTimeMethod();
    },
    style() {
      return {
        fontSize: this.fontSize,
        textAlign: this.textAlign || '',
        lineHeight: this.lineHeight || '',
        fontWeight: this.bold,
        fontStyle: this.italic,
        fontFamily: this.fontFamily
      };
    }
  },
  data() {
    return {
      date: new Date() // 当前date对象
    };
  },
  methods: {
    // 格式时间方法
    formatTimeMethod() {
      let y = this.year;
      let m = this.month;
      let d = this.day;
      if (this.formatChinese) {
        y = this.formatChineseMethod(y);
        m = this.formatChineseMethod(m, true);
        d = this.formatChineseMethod(d, true);
      }
      if (!this.formatChinese && this.zeroFilling) {
        m = this.zeroFillingMethod(m);
        d = this.zeroFillingMethod(d);
      }
      if (this.timeFormat == 'custom') {
        return this.timeFormatString.replace('${YYYY}', y).replace('${MM}', m).replace('${DD}', d);
      } else {
        return this.timeFormat.replace('YYYY', y).replace('MM', m).replace('DD', d);
      }
    },
    // 补零
    zeroFillingMethod(n) {
      if (n < 10) {
        return `0${n}`;
      }
      return n;
    },
    /**
     * @description 格式化中文 适用预日期转化
     * @param name { Number } 需要转换的数字
     * @param complement { Boolean } 是否添加进制单位
     * @return { string } 转化结果
     */
    formatChineseMethod(num, complement = false) {
      const t = ['', '十', '百', '千', '万'];
      const ns = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九', '十'];
      const numStringArr = (num + '').split('');
      const res = numStringArr.map((el, index) => {
        let s = ns[Number(el)];
        if (complement) {
          s += t[Number(numStringArr.length - 1 - index)];
          if (el + '' === '0') {
            return '';
          }
        }
        return s;
      });
      return res.join('');
    }
  }
};
</script>

<style lang="less" scoped>
.currency-component-container {
  width: 100%;
  height: 100%;
}
</style>
