<!--
  * @Author: your name
  * @Date: 2021-12-02 15:32:01
 * @LastEditTime: 2023-08-02 13:46:50
 * @LastEditors: 朱廷果 1028509503@qq.com
  * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
  * @FilePath: \nanhu-micro\src\views\components\bindModel\index.vue
 -->
 <template>
  <div class="lat-choose-cont" v-loading="loading">
			<div id="map2dCont" class="relative flex1" style="position:absolute; top: 0;left: 0;width: 100%;height: 100%;"></div>
      <div class="type-content" :style="style">
        <div v-for="(item,index) in typeData" :key="index" @click="onTypeCheck(item)">
          <div class="type-item" :class="typecheck.includes(item.id)?'is-active':''">
            <i class="iconfont" :class="item.icon" style="margin-right: 4px;"></i>
            <span>{{item.name}}({{ item.value }})</span>
          </div>
        </div>
      </div>
      <Splitpanes class="default-theme" @resize="onSplitpane">
        <Pane :size="20" :min-size="15" :max-size="25">
          <div class="left-item">
        <leftItem
          ref="leftItem"
          @onHide="onHide"
          @getChecked="getChecked"
          :showCheckBox="true"
          :showCadre="false"
          :checkAll="true"
        ></leftItem>
			</div>
        </Pane>
        <Pane>
          <div class="project-search">
        <div class="mr32" style="display:flex;align-items: center;">
          <span class="search-title">
            行业类型
          </span>
          <el-select v-model="value1" placeholder="请选择">
            <el-option
              v-for="item in options1"
              :key="item.code"
              :label="item.value"
              :value="item.code">
            </el-option>
          </el-select>
        </div>
        <div class="mr32" style="display:flex;align-items: center;">
          <span class="search-title" >
            项目等级
          </span>
          <el-select v-model="value2" placeholder="请选择">
            <el-option
              v-for="item in options2"
              :key="item.code"
              :label="item.value"
              :value="item.code">
            </el-option>
          </el-select>
        </div>
        <div class="mr32" style="display:flex;align-items: center;">
          <span class="search-title">
            建设状态
          </span>
          
          <el-select v-model="value3" placeholder="请选择">
            <el-option
              v-for="item in options3"
              :key="item.code"
              :label="item.value"
              :value="item.code">
            </el-option>
          </el-select>
        </div>
        <div class="mr32" style="display:flex;align-items: center;">
          <span class="search-title">
            重要程度
          </span>
          
          <el-select v-model="value4" placeholder="请选择">
            <el-option
              v-for="item in options4"
              :key="item.code"
              :label="item.value"
              :value="item.code">
            </el-option>
          </el-select>
        </div>
				<el-button type="primary" plain style="margin-left: 16px;" @click="reData">重置</el-button>
				<el-button type="primary" plain @click="onTypeCheck">查询</el-button>
      </div>
        </Pane>
      </Splitpanes>
      
      <NumCont 
        ref="numCont"
        v-for="(item,index) in numData"
        :key="index"
        :zoom="zoom"
        :item="item"
        :id="item.id"
        :num="item?.children?.length"
        :isSearch="isSearch"
        style="position: absolute"
        :style="{ top: item.top, left: item.left }"
        @getInfo="getInfo"
        @locationProject="setZoom"
      >
      </NumCont>
      <div class="pro-info" v-if="showProInfo">
        <div class="info-header">
          <span>
            {{ proInfo.name }}
          </span>
         <i class="iconfont iconclose" @click="showProInfo = false"></i>
        </div>
        <div class="info-content">
          <div v-for="(item,index) in proInfo.children" :key="index" class="info-item">
            <div class="icon-div">
              <i class="iconfont iconzhiliang"></i>
            </div>
            <div class="info-center">
              <div style="display: flex;align-items: center;">
                <span class="submitter">{{item.submitter}}</span>
                <span style="display: flex;height: 18px;padding: 0px 4px;align-items: center;margin-left: 8px;" :style="getStatusStyle(item.jg_flow_status)"
                >{{getStatusName(item.jg_flow_status)}}</span>
              </div>
              <div class="desc">问题描述 {{item.inspect_describe}}</div>
            </div>
            <div class="w90"> {{item.created_at.slice(0,10)}}</div>
          </div>
        </div>
      </div>
		</div>
</template>

<script>
import NumCont from '@/custom-component/proInfo/components/numCont.vue';
import { dataInterface } from '@/apis/data/index';
import vPinyin from '@/custom-component/proInfo/components/vue-py.js'
import leftItem from '@/custom-component/proInfo/components/leftItem';
import { Splitpanes, Pane } from "splitpanes";
import "splitpanes/dist/splitpanes.css";
let viewerGis;
export default {
  name: "Map2d",
  props: {
    appId: {
			type: String,
			default: ''
		},
		appSecret: {
			type: String,
			default: ''
		}
  },
  components:{
    NumCont,
    leftItem,
    Splitpanes,
    Pane,
  },
  created(){
    this.getOptions()

  },
  data() {
    return {
      isSearch:false,
      typeData:[],
      typecheck:[],
      showItem:true,
      zoom:4, // 当前层级
      projectTypeData: [], //项目类别
      projectList: [], //项目列表
      numData: [], //数量数据
      normData: [], //项目转换后的项目信息标准数据
      treedata:[],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      comData:[],
      comDataId:[],
      options1:[],
      options2:[],
      options3:[],
      options4:[],
      value1: '',
      value2: '',
      value3: '',
      value4: '',
      search: [],
      proInfo: [],
      showProInfo:false,
      leftWidth:0,
      loading:false
    };
  },
  computed: {
    style(){
      return `left:${this.leftWidth+ 8}px`
    } 
  },
  watch: {
  },
  methods: {
    onSplitpane(){
      let div = document.querySelector('.left-item')
      this.leftWidth = div.offsetWidth
      console.log(this.leftWidth)
    },
    getStatusName(num){
      if(num===0){
        return '已发起'
      }else if(num === 5){
        return '已完成'
      }else{
        return '处理中'
      }
    },
    getStatusStyle(num){
      if(num===0){
        return 'color:#337EFF;background:#F2F7FF'
      }else if(num === 5){
        return 'background:#EDFAF2;color:#00A650'
      }else{
        return 'background:#FEF6DF;color:#EFC11A'
      }
    },
    getInfo(item){
      dataInterface({
          object_uuid:'object6268b4c9c083f',
          view_uuid:'view64b793fd77e9d',
          __method_name__: "dataList",
          archi_type_value:item.data.archi_type_value,
          transcode:0
        }).then(res=>{
          this.proInfo.name= item.data.name
          this.proInfo.children = res.data.data.data
          this.showProInfo = true
        })
    },
    // 头上筛选数据

    
    getOptions(){
    // 建设状态
      const buildUuid = 'dict6176887db19df'
      dataInterface({
        __method_name__: 'globalDictionaryData',
        dict_uuid: buildUuid,
        transcode: 0
      }).then((re) => {
        console.log(re)
        if (re && re.data.code === 200) {
          this.options3 = re.data.data[buildUuid]
        }
      })

      // 重要程度
      const imUuid = 'dict6170c467009be'
      dataInterface({
        __method_name__: 'globalDictionaryData',
        dict_uuid: imUuid,
        transcode: 0
      }).then((re) => {
        if (re && re.data.code === 200) {
          this.options4  = re.data.data[imUuid]
        }
      })

      // 行业类型
      const proTypeUuid = 'dict6170c458dc5de'
      dataInterface({
        __method_name__: 'globalDictionaryData',
        dict_uuid: proTypeUuid,
        transcode: 0
      }).then((re) => {
        if (re && re.data.code === 200) {
          this.options1 = re.data.data[proTypeUuid]
        }
      })

      // 项目等级
      const levelUuid = 'dict620e2c90da6a6'
      dataInterface({
        __method_name__: 'globalDictionaryData',
        dict_uuid: levelUuid,
        transcode: 0
      }).then((re) => {
        if (re && re.data.code === 200) {
          this.options2 = re.data.data[levelUuid]
        }
      })
      this.getStatusOp()
    },
    // 安全事故等需要刷新数据 单独请求
    getStatusOp(){
      let param = {}
      if(this.comDataId.length){
        param.object_id = this.comDataId
      }
      dataInterface(param, 'api/graph/149').then((res) => {
        
        if(res.data.code===200){
          this.typeData = res.data.data
        }
      })
    },
    onSeach(){
      if(this.value1){
        this.search.push({
          code: "project_nature",
          ruleType: "eq",
          value: this.value1
        })
      }
      if(this.value2){
        this.search.push({
          code: "project_level",
          ruleType: "eq",
          value: this.value2
        })
      }
      if(this.value3){
        this.search.push({
          code: "build_status_x",
          ruleType: "eq",
          value: this.value3
        })
      }
      if(this.value4){
        this.search.push({
          code: "importance",
          ruleType: "eq",
          value: this.value4
        })
      }
      
      this.normData=[]
      this.getData()
    },
    onTypeCheck(item){
      this.search=[]
      if(this.typecheck.includes(item.id)){
        this.typecheck.splice(this.typecheck.findIndex(it => it === item.id), 1);
      }else{
        if(item.id){
          this.typecheck.push(item.id)
        }
       
      }
      if(this.typecheck.length){
        this.isSearch = true;
      }else{
        this.isSearch = false;
      }
      this.onSeach()
    },
    reData(){
      this.search=[]
      this.typecheck=[]
      this.value1= ''
      this.value2= ''
      this.value3= ''
      this.value4= ''
      this.getData()
    },
    onHide(value){
      this.showItem = false
      let line = document.querySelector('.splitpanes__splitter')
      if(value){
        this.leftWidth = 0
        line.style.display = 'none'
      }else{
        this.onSplitpane()
        let div = document.querySelector('.left-item')
        this.leftWidth = div.offsetWidth
        line.style.display = 'block'
      }
      
    },
    getChecked(value){
      this.comDataId=[]
      this.comData = value
      this.comData.forEach(item=>{
        this.comDataId.push(item.id)
      })
      
      this.normData=[]
      this.getData()
      this.getStatusOp()
    },
		async initGIS(){
      this.loading = true
			viewerGis = new global.BIMCC_GIS_Base.Viewer("map2dCont", {
				host: "models.bimcc.net",
				port: "",
				isHttps: true,
				appId: this.appId || process.env.VUE_APP_MODEL_APPID,
				appSecret: this.appSecret || process.env.VUE_APP_MODEL_APPSECRET,
			});
			await viewerGis.init();
      viewerGis.Scene.setBackgroundColor('#ffffff')
			window.viewerGis = viewerGis;
			//切换成2d地图
			viewerGis.Scene.setImageryLayer(global.BIMCC_GIS_Base.ImageryType.MAPBOX, {styleId: 'clk6ob6xn00jo01pd055b35at'});
			// viewerGis.MouseController.mouseStyleByColumbusView();
      viewerGis.Scene.setSceneSetting({
          mode: global.BIMCC_GIS_Base.SceneMode.SCENE2D,
          mapProjection: global.BIMCC_GIS_Base.MapProjection.WEBMERCATOR,
          maxZoomDistance: 9392000
      })
			// 开启监听相机变化
      // viewerGis.MouseController.mouseStyleByColumbusView();
			viewerGis.MouseCustomEvent.registerCameraChange()
			// 监听相机视角变化事件
			viewerGis.on('cameraChange',()=>{
				this.formatData()
			})
      this.getData();
		},

    setZoom(data,height){
      viewerGis.Scene.setCameraView({
        lng:data.lng??data.children[0].lng,
        lat: data.lat??data.children[0].lat,
        height: height,
        heading: 0,
        pitch: -90,
        roll: 0
    });
    },
  /**
   * @description: 格式化数据,主要是经纬度转换屏幕坐标
   * @return {*}
   */		
		formatData(){
      this.numData = []
			for(let i = 0; i < this.normData.length; i++){
				const screenCoordinate = viewerGis.Coordinate.getCanvasByWgs(this.normData[i].lng,this.normData[i].lat,this.normData[i].height);
				if(screenCoordinate){
          let item = this.normData[i]
					this.normData[i].left = screenCoordinate.x + ''
					this.normData[i].top = screenCoordinate.y + ''
          this.altitudeToZoom(viewerGis.Scene.getCameraView().height)
          let index = -1;
          let alreadyExists = this.numData.some((newItem, j) => {
            let oldlat = Number(item.left.slice(0, -2));
            let newlat = Number(newItem.left.slice(0, -2));
            let oldlon = Number(item.top.slice(0, -2));
            let newlon = Number(newItem.top.slice(0, -2));
            if (
              130 > Math.abs(oldlat - newlat) &&
              130 > Math.abs(oldlon - newlon) &&
              this.zoom<8
            ) {
              index = j;
              return true;
            }
          });
          if (!alreadyExists) {
            let obj = {
              name: item.name,
              latitude: item.latitude,
              longitude: item.longitude,
              lat: item.latitude,
              lng: item.longitude,
              object_id: item.object_id,
              data: item.data,
              short_name: item.short_name,
              build_status_x:item.build_status_x,
              warn_type_info:item.warn_type_info
            };
            this.numData.push({
              object_id: item.object_id,
              latitude: item.latitude,
              longitude: item.longitude,
              lat: item.latitude,
              lng: item.longitude,
              children: [obj],
              left: screenCoordinate.x - 44 + "px",
              top: screenCoordinate.y - 44 + "px",
              data: item.data,
              warn_type_info:item.warn_type_info
            });
          } else {
            let obj = {
              name: item.name,
              latitude: item.latitude,
              longitude: item.longitude,
              lat: item.latitude,
              lng: item.longitude,
              object_id: item.object_id,
              short_name: item.short_name,
              build_status_x:item.build_status_x,
              warn_type_info:item.warn_type_info
            };
            if(!this.numData[index].children.filter(v=>v.object_id === obj.object_id).length){
              this.numData[index].children.push(obj);
            }
          }
				}
			}
     
      let arr = []
      if(this.zoom >= 6 && this.zoom < 8){
        this.numData.forEach(item=>{
          let newArr = [{build_status_x:1},{build_status_x:2},{build_status_x:3},{build_status_x:4}]
          item.children.forEach(it=>{
            let obj = {
              name: it.name,
              latitude: it.latitude,
              longitude: it.longitude,
              lat: it.latitude,
              lng: it.longitude,
              object_id: it.object_id,
              short_name: it.short_name,
              build_status_x:it.build_status_x,
              warn_type_info:it.warn_type_info
            }
            newArr.forEach((data,index)=>{
              if(data.build_status_x === +it.build_status_x){
                if(data.children && data.children.length){
                  data.children.push(obj)
                }else{
                  const screenCoordinate = viewerGis.Coordinate.getCanvasByWgs(it.longitude,it.latitude,0);
                  newArr[index].children = []
                  newArr[index].left = screenCoordinate.x - 44 + "px",
                  newArr[index].top = screenCoordinate.y - 44 + "px",
                  newArr[index].children.push(obj)
                }
              }
            })
          })
          arr =arr.concat(newArr)
        })
        
        this.numData = arr
      }
      
		},

    /**
     *  高度转换为对应层级
     * @param height
     */
    altitudeToZoom(height){
      const A = 40487.57;
      const B = 0.00007096758;
      const C = 91610.74;
      const D = -40467.74;
      this.zoom = Math.round(D + (A - D) / (1 + Math.pow(Number(height) / C, B))) + 1;
    },
     /**
      * @desc 获取地图信息数据
      */
    async getData() {
      let params = {}
      if(this.comDataId.length){
        params.object_id = this.comDataId
      }
      if(this.search.length){
        params.search = this.search
      }
      if(this.typecheck.length){
        params.warn_type = this.typecheck 
      }
      dataInterface(params,'api/graph/151').then((res) => {
        this.loading = false
          if (res.data && res.data.code === 200) {
            const projectList =  res.data.data
            //请求项目总类别
            const uuid = "dict6170c458dc5de"
            dataInterface({
                __method_name__: "globalDictionaryData",
                dict_uuid: uuid,
                transcode: 0,
            }).then((re) => {
              const typeData = re.data.data[uuid] ||[];
              if(typeData.length && projectList.length ){
                typeData.forEach((item) => {
                  const findData = projectList.filter(v=> v.industry_type?.includes(item.code))
                  const obj = {
                    value: item.value,
                    num:findData.length || 0,
                    code: item.code,
                  };
                this.projectTypeData.push(obj);
                })
              } 
            })
            this.getAllProjects(res.data.data);
            this.formatData()
            
            this.$nextTick(()=>{
              this.numData.forEach((item,index)=>{
                console.log(this.$refs.numCont,'this.$refs.numCont')
                this.$refs.numCont[index].updateInfo()
              })
            })

          }
      });


    },
    
    getAllProjects(arr, parent_id) {
      for (let i = 0; i < arr.length; i++) {
        let it = arr[i];
        if (it.map != '' && (+it.build_status_x !== -1 && +it.build_status_x !==0) ) {
          
          let ads = JSON.parse(it.map);
          // let ads = it.info[0];
          if (!it.parent_id) {
            this.projectList.push({
              name: it.name, 
              short_name: it.short_name,
              object_id: it.object_id,
              pin: vPinyin
                  .chineseToPinYin(it.name)
                  .substring(0, 1),
              location: ads.lnglat != null ? ads.lnglat : null,
              address:
                  ads.build_address != null
                      ? ads.build_address
                      : null,
              model_num: it.model_num,
              noChild: true,
              info: it.info,
              build_status_x:it.build_status_x,
              warn_type_info:it.warn_type_info
            });
          }

          //将项目按经纬相近的组合数据
          if (ads != '') {
            let obj = {};
            if (parent_id) {
              obj = {
                  name: it.name,
                  latitude: ads.latitude,
                  longitude: ads.longitude,
                  lng: ads.longitude,
                  lat: ads.latitude,
                  top: 0,
                  left: 0,
                  object_id: it.object_id,
                  parent_id: parent_id,
                  data: it,
                  short_name: it.short_name,
                  build_status_x: it.build_status_x,
                  warn_type_info:it.warn_type_info
              };
            } else {
              obj = {
                name: it.name,
                latitude: ads.latitude,
                longitude: ads.longitude,
                lng: ads.longitude,
                lat: ads.latitude,
                top: 0,
                left: 0,
                object_id: it.object_id,
                data: it,
                short_name: it.short_name,
                build_status_x: it.build_status_x,
                warn_type_info:it.warn_type_info
              };
            }
              this.normData.push(obj);
            }
          if (it.children && it.children.length > 0) {
            this.getAllProjects(it.children, it.object_id);
          }
        }
      }
      
    },
  },
	mounted() {
		this.initGIS()
    window.addEventListener('click', () => {
      this.showProInfo = false
    })
    this.leftWidth = document.querySelector('.lat-choose-cont').offsetWidth*0.2
    setTimeout(()=>{
      this.onSplitpane()
    },500)
    
  },
}
</script>
<style lang="less" scoped>
:deep(.el-button){
   color: #fff !important;
 
}
.lat-choose-cont {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  .flex1 {
    flex: 1;
    height: 100%;
  }
  .relative {
    position: relative;
  }
}
.type-content{
  position: absolute;
  top: 95px;
  left: 256px;
  border-radius: 4px;
  background: #FFF;
  padding: 8px 16px;
  height: 46px;
  box-sizing: border-box;
  display: flex;
  transform: 0.3s;
  z-index: 19;
  .type-item{
    display: flex;
    padding: 6px 16px;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    border: 1px solid #C1C7CD;
    margin-right:16px;
    color: #71787F;
  }
  .is-active{
    color: #337EFF;
    border-color: #E7F0FE;
    background: #E7F0FE;
  }
}
.project-search{
  height: 64px;
  padding: 16px 20px;
  margin-left: 8px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: #fff;
  position: relative;
  z-index: 99;
  // position: absolute;
  box-sizing: border-box;
  // left: 240px;
  // top: 0;
  // z-index:19;
  .search-title{
    margin-right: 12px;
  }
  .mr32{
    margin-right: 32px;
  }
}
.left-item{
  // position: absolute;
  // top: 0;
  height: 100%;
}
.pro-info{
  position: fixed;
  right: 50px;
  top: 160px;
  display: flex;
  width: 480px;
  height: 315px;
  padding: 24px 8px 12px 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  flex-shrink: 0;
  border-radius: 8px;
  background: linear-gradient(180deg, #E9EFFF 0%, #FFF 39.06%, #FFF 100%);
  box-shadow: 0px 0px 8px 0px rgba(5, 16, 43, 0.20);
  .info-header{
    color:  #161C1F;
    font-family: Source Han Sans CN;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    padding: 0px 8px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    margin-bottom: 6px;
  }
  .info-content{
    display: flex;
    flex: 1;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    flex-shrink: 0;
    align-self: stretch;
    overflow: auto;
    .info-item{
      height: 90px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between; 
      width: 100%;
      .icon-div{
        display: flex;
        padding: 4px;
        align-items: flex-start;
        gap: 10px;
        border-radius: 12px;
        background:  #D1E3FF;
        i{
          color:#337EFF
        }
      }
      .info-center{
        flex:1;
        text-align: left;
        margin-left: 12px;
      }
      .submitter{
        color: var(--sys-text-text-primary, #161C1F);
        font-family: Source Han Sans CN;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
        letter-spacing: 0.5px;
      }
      .desc{
        margin-top: 4px;
        color: var(--sys-text-text-helper, #71787F);
        font-family: Source Han Sans CN;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
        letter-spacing: 0.4px;
      }
      .w90{
        width: 90px;
      }
    }

  }
}
</style>
