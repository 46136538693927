export default {
  categoryFuncList: [
    {
      category: '取值函数',
      functions: [
        {
          ruleType: 'FUNCTION',
          displayName: 'calcField',
          funcName: 'calcField',
          backFuncName: 'calcField',
          category: '取值函数',
          wiki: 'calcField（字段）：获取某个表单字段值。示例：calcField(\'object63a1799e4c154.field63a94acbc8fd4\')',
          desc: '获取某个表单字段值',
          defaultFuncList: [
            {
              ruleType: 'FUNCTION',
              displayName: 'calcField',
              funcName: 'calcField',
              backFuncName: 'calcField'
            },
            {
              ruleType: 'OPERATION',
              displayName: '(',
              funcName: '('
            },
            {
              ruleType: 'OPERATION',
              displayName: ')',
              funcName: ')'
            }
          ]
        },
        {
          ruleType: 'FUNCTION',
          displayName: 'calcFieldIF',
          funcName: 'calcFieldIF',
          backFuncName: 'calcFieldIF',
          category: '取值函数',
          wiki: '后端：calcFieldIF（取值字段, 字段1, 判断符, 值,[字段2, 判断符, 值],...）：按条件字段取值。前端：calcFieldIF（数据表格.字段1,条件,数据表格.字段2）：返回字段1满足条件时对应的字段2的值',
          desc: '后端：按条件字段取值；前端：字段1满足条件时对应的字段2的值',
          defaultFuncList: [
            {
              ruleType: 'FUNCTION',
              displayName: 'calcFieldIF',
              funcName: 'calcFieldIF',
              backFuncName: 'calcFieldIF'
            },
            {
              ruleType: 'OPERATION',
              displayName: '(',
              funcName: '('
            },
            {
              ruleType: 'OPERATION',
              displayName: ',',
              funcName: ','
            },
            {
              ruleType: 'OPERATION',
              displayName: ')',
              funcName: ')'
            }
          ]
        },
        {
          ruleType: 'FUNCTION',
          displayName: 'calcFilter',
          funcName: 'calcFilter',
          backFuncName: 'calcFilter',
          category: '取值取值',
          wiki: 'calcFilter（数据, 条件, 字段）：获取满足筛选条件字段集合，条件需用calcAND或calcOR函数包裹。示例：calcFilter(calcView(\'object639ad44c34c39.view639adf8973bc8\'),calcAND(\'object639ad44c34c39.field639ae281cd399\',\'==\',\'万元满\'),\'object639ad44c34c39.field639af234511e6\')',
          desc: '获取满足筛选条件字段集合',
          defaultFuncList: [
            {
              ruleType: 'FUNCTION',
              displayName: 'calcFilter',
              funcName: 'calcFilter',
              backFuncName: 'calcFilter'
            },
            {
              ruleType: 'OPERATION',
              displayName: '(',
              funcName: '('
            },
            {
              ruleType: 'OPERATION',
              displayName: ',',
              funcName: ','
            },
            {
              ruleType: 'OPERATION',
              displayName: ')',
              funcName: ')'
            }
          ]
        },
        {
          ruleType: 'FUNCTION',
          displayName: 'calcParams',
          funcName: 'calcParams',
          backFuncName: 'calcParams',
          category: '取值函数',
          wiki: 'calcParams（字段）：表单动态参数取值。',
          desc: '表单动态参数取值',
          defaultFuncList: [
            {
              ruleType: 'FUNCTION',
              displayName: 'calcParams',
              funcName: 'calcParams',
              backFuncName: 'calcParams'
            },
            {
              ruleType: 'OPERATION',
              displayName: '(',
              funcName: '('
            },
            {
              ruleType: 'OPERATION',
              displayName: ',',
              funcName: ','
            },
            {
              ruleType: 'OPERATION',
              displayName: ')',
              funcName: ')'
            }
          ]
        },
      ]
    },
    /* {
      category: '常用代码段',
      functions: [
        {
          ruleType: 'CODE',
          displayName: 'if-elseif-else',
          code: 'if(){\n'
            + '  // todo\n'
            + '  \n'
            + '} else if(){\n'
            + '  // todo\n'
            + '  \n'
            + '} else {\n'
            + '  // todo\n'
            + '  \n'
            + '}',
          funcName: 'if-elseif-else',
          backFuncName: 'if-elseif-else',
          category: 'code',
          wiki: '连续逻辑判断伪代码',
          desc: '根据条件执行对应事件',
          defaultFuncList: [
          ]
        },
        {
          ruleType: 'CODE',
          displayName: 'switch-case',
          code: 'switch(){\n'
            + '  case \'\':\n'
            + '    // todo\n'
            + '    break;\n'
            + '  case \'\':\n'
            + '    // todo\n'
            + '    break;\n'
            + '  default:\n'
            + '    // todo\n'
            + '}',
          funcName: 'switch-case',
          backFuncName: 'switch-case',
          category: 'code',
          wiki: 'switch 语句用于基于不同的条件来执行不同的动作',
          desc: 'switch case语句',
          defaultFuncList: [
          ]
        },
        {
          ruleType: 'CODE',
          displayName: '自定义函数',
          code: 'function selfFunc(){\n'
            + '\n'
            + '}',
          funcName: '自定义函数',
          backFuncName: '自定义函数',
          category: 'code',
          wiki: '自定义函数，请使用js函数语法定义',
          desc: 'javascript自定义函数',
          defaultFuncList: [
          ]
        }
      ]
    }, */
    {
      category: '逻辑函数',
      functions: [
        {
          ruleType: 'FUNCTION',
          displayName: 'calcIF',
          funcName: 'calcIF',
          backFuncName: 'calcIF',
          category: '逻辑函数',
          wiki: 'calcIF(条件,A,B) ：如果满足条件，默认返回TRUE，否则返回FALSE，A/B可替换为其他输入值或事件，支持嵌套多层函数。（不支持表格内字段的逻辑处理）',
          desc: '根据条件执行对应事件',
          defaultFuncList: [
            {
              ruleType: 'FUNCTION',
              displayName: 'calcIF',
              funcName: 'calcIF',
              backFuncName: 'calcIF'
            },
            {
              ruleType: 'OPERATION',
              displayName: '(',
              funcName: '('
            },
            {
              ruleType: 'OPERATION',
              displayName: ',',
              funcName: ','
            },
            {
              ruleType: 'STATIC',
              displayName: 'TRUE',
              funcName: 'TRUE',
              value: 'true',
              paramType: 'bool'
            },
            {
              ruleType: 'OPERATION',
              displayName: ',',
              funcName: ','
            },
            {
              ruleType: 'STATIC',
              displayName: 'FALSE',
              funcName: 'FALSE',
              value: 'false',
              paramType: 'bool'
            },
            {
              ruleType: 'OPERATION',
              displayName: ')',
              funcName: ')'
            }
          ]
        },
        {
          ruleType: 'FUNCTION',
          displayName: 'calcIFS',
          funcName: 'calcIFS',
          backFuncName: 'ifs',
          category: 'calcIFS',
          wiki: 'calcIFS(判断条件1，真值1，判断条件2（可选），真值2（可选），···) ：检查是否满足一个或多个条件，且返回符合第一个 TRUE 条件的值',
          desc: '检查是否满足一个或多个条件，且返回符合第一个 TRUE 条件的值',
          defaultFuncList: [
            {
              ruleType: 'FUNCTION',
              displayName: 'calcIFS',
              funcName: 'calcIFS',
              backFuncName: 'calcIFS'
            },
            {
              ruleType: 'OPERATION',
              displayName: '(',
              funcName: '('
            },
            {
              ruleType: 'OPERATION',
              displayName: ',',
              funcName: ','
            },
            {
              ruleType: 'STATIC',
              displayName: 'TRUE',
              funcName: 'TRUE',
              value: 'true',
              paramType: 'bool'
            },
            {
              ruleType: 'OPERATION',
              displayName: ',',
              funcName: ','
            },
            {
              ruleType: 'STATIC',
              displayName: 'FALSE',
              funcName: 'FALSE',
              value: 'false',
              paramType: 'bool'
            },
            {
              ruleType: 'OPERATION',
              displayName: ')',
              funcName: ')'
            }
          ]
        },
        {
          ruleType: 'FUNCTION',
          displayName: 'calcAND',
          funcName: 'calcAND',
          backFuncName: 'calcAND',
          category: '逻辑函数',
          wiki: 'calcAND(字段1, 判断符, 取值1, [字段2, 判断符, 取值2], ...)：多个用AND连接的条件，当所有条件均满足时，表达式返回TRUE，否则返回FALSE，支持连接多个条件。',
          desc: '若干个条件同时满足，逻辑才成立',
          defaultFuncList: [
            {
              ruleType: 'FUNCTION',
              displayName: 'calcAND',
              funcName: 'calcAND',
              backFuncName: 'calcAND'
            },
            {
              ruleType: 'OPERATION',
              displayName: '(',
              funcName: '('
            },
            {
              ruleType: 'OPERATION',
              displayName: ',',
              funcName: ','
            },
            {
              ruleType: 'OPERATION',
              displayName: ')',
              funcName: ')'
            }
          ]
        },
        {
          ruleType: 'FUNCTION',
          displayName: 'calcOR',
          funcName: 'calcOR',
          backFuncName: 'calcOR',
          category: '逻辑函数',
          wiki: 'calcOR(字段1, 判断符, 取值1, [字段2, 判断符, 取值2] ...)：多个用OR连接的条件，只要有一个条件满足时，表达式返回TRUE，否则返回FALSE，支持连接多个条件。',
          desc: '若干个条件只要有一个满足，逻辑即成立',
          defaultFuncList: [
            {
              ruleType: 'FUNCTION',
              displayName: 'calcOR',
              funcName: 'calcOR',
              backFuncName: 'calcOR'
            },
            {
              ruleType: 'OPERATION',
              displayName: '(',
              funcName: '('
            },
            {
              ruleType: 'OPERATION',
              displayName: ',',
              funcName: ','
            },
            {
              ruleType: 'OPERATION',
              displayName: ')',
              funcName: ')'
            }
          ]
        },
        {
          ruleType: 'FUNCTION',
          displayName: 'calcNOT',
          funcName: 'calcNOT',
          category: '逻辑函数',
          wiki: 'calcNOT(条件1, ...)：对条件的逻辑值取反，例：NOT(2>1)的返回就是FALSE。（不支持表格内字段的逻辑处理）',
          desc: '对条件的逻辑值取反',
          defaultFuncList: [
            {
              ruleType: 'EXPRESS_FUNCTION',
              displayName: 'calcNOT',
              funcName: 'calcNOT'
            },
            {
              ruleType: 'OPERATION',
              displayName: '(',
              funcName: '('
            },
            {
              ruleType: 'OPERATION',
              displayName: ')',
              funcName: ')'
            }
          ]
        }
        /* {
          ruleType: 'FUNCTION',
          displayName: 'ISCONTAINS',
          funcName: 'calcCONTSINS',
          category: '逻辑函数',
          wiki: 'ISCONTAINS(字段1，字段2) ：如果字段1包含字段2，表达式返回TRUE，否则返回FALSE。（不支持表格内字段的逻辑处理）',
          desc: '判断前一个字段是否包含后一个字段',
          defaultFuncList: [
            {
              ruleType: 'FUNCTION',
              displayName: 'ISCONTAINS',
              funcName: 'calcCONTSINS'
            },
            {
              ruleType: 'OPERATION',
              displayName: '(',
              funcName: '('
            },
            {
              ruleType: 'OPERATION',
              displayName: ',',
              funcName: ','
            },
            {
              ruleType: 'OPERATION',
              displayName: ')',
              funcName: ')'
            }
          ]
        }
        {
          "ruleType": "FUNCTION",
          "displayName": "ISEMPTY",
          "funcName": "calcEMPTY",
          "category": "逻辑函数",
          "wiki": "ISEMPTY(字段) ：如果是空值，返回TRUE，否则返回FALSE。（不支持表格内字段的逻辑处理）",
          "desc": "判断字段是否为空",
          "defaultFuncList": [
            {
              "ruleType": "FUNCTION",
              "displayName": "ISEMPTY",
              "funcName": "calcEMPTY"
            },
            {
              "ruleType": "OPERATION",
              "displayName": "(",
              "funcName": "("
            },
            {
              "ruleType": "OPERATION",
              "displayName": ")",
              "funcName": ")"
            }
          ]
        },
        {
          "ruleType": "FUNCTION",
          "displayName": "EQUAL",
          "funcName": "calcEAQUAL",
          "category": "逻辑函数",
          "wiki": " EQUAL(字段1，字段2)：如果字段1和字段2相等，返回TRUE，否则返回FALSE。（不支持表格内字段的逻辑处理）",
          "desc": "判断两个字符串是否相等",
          "defaultFuncList": [
            {
              "ruleType": "FUNCTION",
              "displayName": "EQUAL",
              "funcName": "calcEAQUAL"
            },
            {
              "ruleType": "OPERATION",
              "displayName": "(",
              "funcName": "("
            },
            {
              "ruleType": "OPERATION",
              "displayName": ",",
              "funcName": ","
            },
            {
              "ruleType": "OPERATION",
              "displayName": ")",
              "funcName": ")"
            }
          ]
        } */
      ]
    },
    {
      category: '高级函数',
      functions: [
        {
          ruleType: 'FUNCTION',
          displayName: 'calcMULTIPLY',
          funcName: 'calcMULTIPLY',
          category: '数学函数',
          wiki: 'calcMULTIPLY(字段1, 字段2, …) ：仅支持对数字、金额类型字段的求乘积。',
          desc: '多字段求乘积',
          defaultFuncList: [
            {
              ruleType: 'FUNCTION',
              displayName: 'calcMULTIPLY',
              funcName: 'calcMULTIPLY'
            },
            {
              ruleType: 'OPERATION',
              displayName: '(',
              funcName: '('
            },
            {
              ruleType: 'SEPARATOR',
              displayName: ',',
              funcName: ','
            },
            {
              ruleType: 'OPERATION',
              displayName: ')',
              funcName: ')'
            }
          ]
        },
        {
          ruleType: 'FUNCTION',
          displayName: 'calcSUM',
          funcName: 'calcSUM',
          category: '数学函数',
          wiki: 'SUM(字段1, 字段2, …) ：仅支持对数字、金额类型字段的求和。',
          desc: '多字段求和',
          defaultFuncList: [
            {
              ruleType: 'FUNCTION',
              displayName: 'calcSUM',
              funcName: 'calcSUM'
            },
            {
              ruleType: 'OPERATION',
              displayName: '(',
              funcName: '('
            },
            {
              ruleType: 'SEPARATOR',
              displayName: ',',
              funcName: ','
            },
            {
              ruleType: 'OPERATION',
              displayName: ')',
              funcName: ')'
            }
          ]
        },
        {
          ruleType: 'FUNCTION',
          displayName: 'calcSUMIF',
          funcName: 'calcSUMIF',
          category: '数学函数',
          wiki: 'calcSUMIF([array1],”===text”, [array2(number)])：统计表格中符合条件的数值，并求和。',
          desc: '统计表格中符合条件的数值，并求和',
          defaultFuncList: [
            {
              ruleType: 'FUNCTION',
              displayName: 'calcSUMIF',
              funcName: 'calcSUMIF'
            },
            {
              ruleType: 'OPERATION',
              displayName: '(',
              funcName: '('
            },
            {
              ruleType: 'SEPARATOR',
              displayName: ',',
              funcName: ','
            },
            {
              ruleType: 'OPERATION',
              displayName: ')',
              funcName: ')'
            }
          ]
        },
        {
          ruleType: 'FUNCTION',
          displayName: 'calcCONCAT',
          funcName: 'calcCONCAT',
          category: '文本函数',
          wiki: 'calcCONCAT(text1,[text2], …) ：字符串连接函数，连接函数中的所有字段。',
          desc: '字符串连接函数，连接函数中的所有字段',
          defaultFuncList: [
            {
              ruleType: 'FUNCTION',
              displayName: 'calcCONCAT',
              funcName: 'calcCONCAT'
            },
            {
              ruleType: 'OPERATION',
              displayName: '(',
              funcName: '('
            },
            {
              ruleType: 'SEPARATOR',
              displayName: ',',
              funcName: ','
            },
            {
              ruleType: 'OPERATION',
              displayName: ')',
              funcName: ')'
            }
          ]
        },
        {
          ruleType: 'FUNCTION',
          displayName: 'calcMID',
          funcName: 'calcMID',
          category: '文本函数',
          wiki: 'calcMID(text, startnum, numchars) ：返回字符串中从指定位置开始的特定数目的字符，该数目由用户指定。text:字段或内容\n'
            + 'startnum:指定的位置\n'
            + 'numchars:指定的字符数量\n'
            + '均为必填项，否则函数无法运算。',
          desc: '返回字符串中从指定位置开始的特定数目的字符，该数目由用户指定',
          defaultFuncList: [
            {
              ruleType: 'FUNCTION',
              displayName: 'calcMID',
              funcName: 'calcMID'
            },
            {
              ruleType: 'OPERATION',
              displayName: '(',
              funcName: '('
            },
            {
              ruleType: 'SEPARATOR',
              displayName: ',',
              funcName: ','
            },
            {
              ruleType: 'SEPARATOR',
              displayName: ',',
              funcName: ','
            },
            {
              ruleType: 'OPERATION',
              displayName: ')',
              funcName: ')'
            }
          ]
        },
        {
          ruleType: 'FUNCTION',
          displayName: 'calcAVG',
          funcName: 'calcAVG',
          category: '数学函数',
          wiki: 'calcAVG(字段1, 字段2, …) ：仅支持返回数字、金额类型字段的平均值。',
          desc: '多字段求平均值',
          defaultFuncList: [
            {
              ruleType: 'FUNCTION',
              displayName: 'calcAVG',
              funcName: 'calcAVG'
            },
            {
              ruleType: 'OPERATION',
              displayName: '(',
              funcName: '('
            },
            {
              ruleType: 'SEPARATOR',
              displayName: ',',
              funcName: ','
            },
            {
              ruleType: 'OPERATION',
              displayName: ')',
              funcName: ')'
            }
          ]
        },
        {
          ruleType: 'FUNCTION',
          displayName: 'calcMAX',
          funcName: 'calcMAX',
          category: '数学函数',
          wiki: 'calcMAX(字段1, 字段2, …) ：仅支持返回数字、金额类型字段的最大值。',
          desc: '多字段取最大值',
          defaultFuncList: [
            {
              ruleType: 'FUNCTION',
              displayName: 'calcMAX',
              funcName: 'calcMAX'
            },
            {
              ruleType: 'OPERATION',
              displayName: '(',
              funcName: '('
            },
            {
              ruleType: 'SEPARATOR',
              displayName: ',',
              funcName: ','
            },
            {
              ruleType: 'OPERATION',
              displayName: ')',
              funcName: ')'
            }
          ]
        },
        {
          ruleType: 'FUNCTION',
          displayName: 'calcMIN',
          funcName: 'calcMIN',
          category: '数学函数',
          wiki: 'calcMIN(字段1, 字段2, …) ：仅支持返回数字、金额类型字段的最小值。',
          desc: '多字段取最小值',
          defaultFuncList: [
            {
              ruleType: 'FUNCTION',
              displayName: 'calcMIN',
              funcName: 'calcMIN'
            },
            {
              ruleType: 'OPERATION',
              displayName: '(',
              funcName: '('
            },
            {
              ruleType: 'SEPARATOR',
              displayName: ',',
              funcName: ','
            },
            {
              ruleType: 'OPERATION',
              displayName: ')',
              funcName: ')'
            }
          ]
        },
        /* {
          ruleType: 'FUNCTION',
          displayName: 'COUNT',
          funcName: 'calcCOUNT',
          category: '数学函数',
          wiki: 'COUNT(字段)：用于统计表格内字段非空数据条数。',
          desc: '统计表格字段数据条数',
          defaultFuncList: [
            {
              ruleType: 'FUNCTION',
              displayName: 'COUNT',
              funcName: 'calcCOUNT'
            },
            {
              ruleType: 'OPERATION',
              displayName: '(',
              funcName: '('
            },
            {
              ruleType: 'OPERATION',
              displayName: ')',
              funcName: ')'
            }
          ]
        }, */
        {
          ruleType: 'FUNCTION',
          displayName: 'calcCOUNTIF',
          funcName: 'calcCOUNTIF',
          category: '数学函数',
          wiki: 'COUNTIF([value1,value2,…],  criteria) ：COUNTIF([1,2,3,4], “>2”)，结果为2。统计满足条件的参数个数。',
          desc: '统计满足条件的参数个数',
          defaultFuncList: [
            {
              ruleType: 'FUNCTION',
              displayName: 'calcCOUNTIF',
              funcName: 'calcCOUNTIF'
            },
            {
              ruleType: 'OPERATION',
              displayName: '(',
              funcName: '('
            },
            {
              ruleType: 'SEPARATOR',
              displayName: ',',
              funcName: ','
            },
            {
              ruleType: 'OPERATION',
              displayName: ')',
              funcName: ')'
            }
          ]
        }
      ]
    }
  ],
  expressBarList: [
    {
      ruleType: 'OPERATION',
      displayName: '+',
      funcName: '+'
    },
    {
      ruleType: 'OPERATION',
      displayName: '-',
      funcName: '-'
    },
    {
      ruleType: 'OPERATION',
      displayName: '*',
      funcName: '*'
    },
    {
      ruleType: 'OPERATION',
      displayName: '/',
      funcName: '/'
    },
    {
      ruleType: 'OPERATION',
      displayName: '&&',
      funcName: '&&'
    },
    {
      ruleType: 'OPERATION',
      displayName: '||',
      funcName: '||'
    },
    {
      ruleType: 'OPERATION',
      displayName: '===',
      funcName: '==='
    },
    {
      ruleType: 'OPERATION',
      displayName: '==',
      funcName: '=='
    },
    {
      ruleType: 'OPERATION',
      displayName: '>',
      funcName: '>'
    },
    {
      ruleType: 'OPERATION',
      displayName: '>=',
      funcName: '>='
    },
    {
      ruleType: 'OPERATION',
      displayName: '<',
      funcName: '<'
    },
    {
      ruleType: 'OPERATION',
      displayName: '<=',
      funcName: '<='
    },
    {
      ruleType: 'OPERATION',
      displayName: '=',
      funcName: '=='
    },
    {
      ruleType: 'OPERATION',
      displayName: '(',
      funcName: '('
    },
    {
      ruleType: 'OPERATION',
      displayName: ')',
      funcName: ')'
    },
    {
      ruleType: 'SEPARATOR',
      displayName: ',',
      funcName: ','
    },
    {
      ruleType: 'STATIC',
      displayName: 'TRUE',
      value: 'true',
      paramType: 'bool'
    },
    {
      ruleType: 'STATIC',
      displayName: 'FALSE',
      value: 'false',
      paramType: 'bool'
    }
  ],
  advancedRuleList: [
    {
      ruleType: 'FUNCTION',
      displayName: 'IF',
      funcName: 'calcIF',
      backFuncName: 'if'
    },
    {
      ruleType: 'OPERATION',
      displayName: '(',
      funcName: '('
    },
    {
      ruleType: 'FUNCTION',
      displayName: 'SUM',
      funcName: 'calcSUM'
    },
    {
      ruleType: 'OPERATION',
      displayName: '(',
      funcName: '('
    },
    {
      ruleType: 'COMPONENT_VALUE',
      displayName: 'L',
      value: '2f18780d-3454-4bec-8759-8e9d708b1e99'
    },
    {
      ruleType: 'SEPARATOR',
      displayName: ',',
      funcName: ','
    },
    {
      ruleType: 'COMPONENT_VALUE',
      displayName: 'E',
      value: 'c06f6292-346f-4fe6-b4d5-fed63324200b'
    },
    {
      ruleType: 'SEPARATOR',
      displayName: ',',
      funcName: ','
    },
    {
      ruleType: 'COMPONENT_VALUE',
      displayName: 'C',
      value: '89556302-0139-441f-b74c-b03dce63f406'
    },
    {
      ruleType: 'OPERATION',
      displayName: ')',
      funcName: ')'
    },
    {
      ruleType: 'OPERATION',
      displayName: '<',
      funcName: '<'
    },
    {
      ruleType: 'STATIC',
      displayName: '输入',
      value: '70',
      paramType: 'number'
    },
    {
      ruleType: 'OPERATION',
      displayName: ',',
      funcName: ','
    },
    {
      ruleType: 'STATIC',
      displayName: '输入',
      value: '较小风险',
      paramType: 'string'
    },
    {
      ruleType: 'OPERATION',
      displayName: ',',
      funcName: ','
    },
    {
      ruleType: 'FUNCTION',
      displayName: 'IF',
      funcName: 'calcIF',
      backFuncName: 'if'
    },
    {
      ruleType: 'OPERATION',
      displayName: '(',
      funcName: '('
    },
    {
      ruleType: 'FUNCTION',
      displayName: 'SUM',
      funcName: 'calcSUM'
    },
    {
      ruleType: 'OPERATION',
      displayName: '(',
      funcName: '('
    },
    {
      ruleType: 'COMPONENT_VALUE',
      displayName: 'L',
      value: '2f18780d-3454-4bec-8759-8e9d708b1e99'
    },
    {
      ruleType: 'SEPARATOR',
      displayName: ',',
      funcName: ','
    },
    {
      ruleType: 'COMPONENT_VALUE',
      displayName: 'E',
      value: 'c06f6292-346f-4fe6-b4d5-fed63324200b'
    },
    {
      ruleType: 'SEPARATOR',
      displayName: ',',
      funcName: ','
    },
    {
      ruleType: 'COMPONENT_VALUE',
      displayName: 'C',
      value: '89556302-0139-441f-b74c-b03dce63f406'
    },
    {
      ruleType: 'OPERATION',
      displayName: ')',
      funcName: ')'
    },
    {
      ruleType: 'OPERATION',
      displayName: '<',
      funcName: '<'
    },
    {
      ruleType: 'STATIC',
      displayName: '输入',
      value: '160',
      paramType: 'number'
    },
    {
      ruleType: 'OPERATION',
      displayName: ',',
      funcName: ','
    },
    {
      ruleType: 'STATIC',
      displayName: '输入',
      value: '一般风险',
      paramType: 'string'
    },
    {
      ruleType: 'OPERATION',
      displayName: ',',
      funcName: ','
    },
    {
      ruleType: 'FUNCTION',
      displayName: 'IF',
      funcName: 'calcIF',
      backFuncName: 'if'
    },
    {
      ruleType: 'OPERATION',
      displayName: '(',
      funcName: '('
    },
    {
      ruleType: 'FUNCTION',
      displayName: 'SUM',
      funcName: 'calcSUM'
    },
    {
      ruleType: 'OPERATION',
      displayName: '(',
      funcName: '('
    },
    {
      ruleType: 'COMPONENT_VALUE',
      displayName: 'L',
      value: '2f18780d-3454-4bec-8759-8e9d708b1e99'
    },
    {
      ruleType: 'SEPARATOR',
      displayName: ',',
      funcName: ','
    },
    {
      ruleType: 'COMPONENT_VALUE',
      displayName: 'E',
      value: 'c06f6292-346f-4fe6-b4d5-fed63324200b'
    },
    {
      ruleType: 'SEPARATOR',
      displayName: ',',
      funcName: ','
    },
    {
      ruleType: 'COMPONENT_VALUE',
      displayName: 'C',
      value: '89556302-0139-441f-b74c-b03dce63f406'
    },
    {
      ruleType: 'OPERATION',
      displayName: ')',
      funcName: ')'
    },
    {
      ruleType: 'OPERATION',
      displayName: '<',
      funcName: '<'
    },
    {
      ruleType: 'STATIC',
      displayName: '输入',
      value: '320',
      paramType: 'number'
    },
    {
      ruleType: 'OPERATION',
      displayName: ',',
      funcName: ','
    },
    {
      ruleType: 'STATIC',
      displayName: '输入',
      value: '较大风险',
      paramType: 'string'
    },
    {
      ruleType: 'OPERATION',
      displayName: ',',
      funcName: ','
    },
    {
      ruleType: 'FUNCTION',
      displayName: 'IF',
      funcName: 'calcIF',
      backFuncName: 'if'
    },
    {
      ruleType: 'OPERATION',
      displayName: '(',
      funcName: '('
    },
    {
      ruleType: 'FUNCTION',
      displayName: 'SUM',
      funcName: 'calcSUM'
    },
    {
      ruleType: 'OPERATION',
      displayName: '(',
      funcName: '('
    },
    {
      ruleType: 'COMPONENT_VALUE',
      displayName: 'L',
      value: '2f18780d-3454-4bec-8759-8e9d708b1e99'
    },
    {
      ruleType: 'SEPARATOR',
      displayName: ',',
      funcName: ','
    },
    {
      ruleType: 'COMPONENT_VALUE',
      displayName: 'E',
      value: 'c06f6292-346f-4fe6-b4d5-fed63324200b'
    },
    {
      ruleType: 'SEPARATOR',
      displayName: ',',
      funcName: ','
    },
    {
      ruleType: 'COMPONENT_VALUE',
      displayName: 'C',
      value: '89556302-0139-441f-b74c-b03dce63f406'
    },
    {
      ruleType: 'OPERATION',
      displayName: ')',
      funcName: ')'
    },
    {
      ruleType: 'OPERATION',
      displayName: '>=',
      funcName: '>='
    },
    {
      ruleType: 'STATIC',
      displayName: '输入',
      value: '320',
      paramType: 'number'
    },
    {
      ruleType: 'OPERATION',
      displayName: ',',
      funcName: ','
    },
    {
      ruleType: 'STATIC',
      displayName: '输入',
      value: '重大风险',
      paramType: 'string'
    },
    {
      ruleType: 'OPERATION',
      displayName: ')',
      funcName: ')'
    },
    {
      ruleType: 'OPERATION',
      displayName: ')',
      funcName: ')'
    },
    {
      ruleType: 'OPERATION',
      displayName: ')',
      funcName: ')'
    },
    {
      ruleType: 'OPERATION',
      displayName: ')',
      funcName: ')'
    }
  ]
}
