<!--
 * @Author: 吴绍鹏 542278473@qq.com
 * @Date: 2023-08-07 10:38:50
 * @LastEditors: 吴绍鹏 542278473@qq.com
 * @LastEditTime: 2023-08-17 18:16:20
 * @FilePath: \dataview-next\src\custom-component\simpleChart\SimpleChart.vue
 * @Description: 简单图表
-->
<template>
  <div class="simple-chart-wrap" :class="layout" v-loading="loading">
    <div class="message-wrap">
        <p class="label">{{ title }}</p>
        <p class="number">{{ sourceData.statistics }}</p>
    </div>
    <div v-if="sourceData.data" v-chart="{sourceData, config: element.chartConfig }" class="chart-wrap" ref="chartWrap">
    </div>
		<el-empty class="empty-wrap" v-else description="暂无内容">
		</el-empty>
		<div class="lenged-wrap" v-if="Array.isArray(sourceData.data) && sourceData.data.length && element.chartConfig.type === 'polarBar'">
			<div class="lenged-item" v-for="(item, index) in sourceData.data" :key="index">
				<div class="icon" :style="getIconStyle(element.chartConfig, index)">
				</div>
				<p class="label">{{ item.name }}: {{item.value }}</p>
			</div>
		</div>
  </div>
</template>

<script>
import { Empty} from 'element-ui'
import * as ECharts from 'echarts'
import CustomComponentMixin from '@/custom-component/mixins/CustomComponentMixin.js'
import eventBus from '@/plugins/eventBus';
import { dataInterface } from '@/apis/data/index';
import { mapState } from 'vuex';
import { removeEventBus, getComponentById, initParams } from '@/utils/tools';
import simpleChartFormat from './simpleChartFormat.js'
export default {
  inject: ['EDITOR_pageUUID'],
  mixins: [CustomComponentMixin],
	components: {
		'el-empty': Empty
	},
  props: {
		element: {
			type: Object,
			required: true,
			default: () => {}
		},
    // 是否在组合内
    isGroup: {
			type: Boolean,
		},
    // 组合内组件列表
		groupComponents: {
			type: Array,
			default: () => []
		},
    componentList: {
      default: null
    }
	},
  data() {
    return {
      loading: false,
      interfaceFinished: false,
      sourceData: {} // 图表的数据
    }
  },
  computed: {
    ...mapState(['componentData', 'globalInterval', 'subsidiaryComponentData']),
    // 数据仓库配置
		database() {
			return this.element && this.element.database;
		},
    // 请求定时器，若存在全局则以全局为准，若无全局则取当前组件自定义
    interfaceInterval() {
      if (this.globalInterval && this.globalInterval.useInterval) {
        return this.globalInterval;
      }
      return this.element?.interfaceInterval;
    },
		// 布局
    layout() {
      return this.element?.chartConfig?.layout || 'upper-and-lower-structures';
    },
		// 标题
    title() {
      return this.sourceData.title || this.element?.chartConfig?.title || '-';
    }
  },
  created() {
    if (this.database && !this.hasComponentParam()) {
			const { search = [], param = {}, canPost } = initParams(this.element?.database?.paramsConfig || [], this.isGroup, this.subComponentData, this.groupComponents);
			if (!canPost) return;
			this.getData(this.database, search, param);
		}
		this.metadata = this.element.metadata || [];
		// 定时器
		this.setInterval();
  },
	directives: {
		// 指令化是为了简化对dom 的操作 并集中管理图表相关内容
		chart: {
			bind(el) {
				// 大小监听
				el.resizeObserver = new ResizeObserver((entries) => {
					for (const entry of entries) {
						if(el.chartInstance) {
							el.chartInstance.resize(entry.contentRect.width, entry.contentRect.height);
						}
					}
				})
				el.resizeObserver.observe(el)
			},
			inserted(el, binding) {
				// 初始化
				el.chartInstance = ECharts.init(el);
				const option = simpleChartFormat(binding.value.sourceData, binding.value.config);
				el.chartInstance.setOption(option);
			},
			update(el, binding) {
				// 更新
				const option = simpleChartFormat(binding.value.sourceData, binding.value.config);
				el.chartInstance.clear();
				el.chartInstance.setOption(option);
			},
			unbind(el) {
				// 卸载
				if(el.resizeObserver) {
					el.resizeObserver.disconnect();
				}
				if(el.chartInstance) {
					el.chartInstance.dispose();
				}
			}
		}
	},
  mounted() {
    // 配置关联参数的容器才需要监听
		const databaseTrigger = {
			[this.element.id]: (data) => {
				if (data.parentId && data.parentId !== this.element.id) return false;
				// 配置时候触发请求
				if (data.componentId === this.element.id && data.isInit) {
					const { search = [], param = {}, canPost } = initParams(
						this.element?.database?.paramsConfig || [],
						this.isGroup,
						this.subComponentData,
						this.groupComponents
					);
					// console.log("配置时候触发请求-------", canPost, search, param);
					if (!canPost) return;
					this.getData(this.element.database, search, param);
					return;
				}
				// 点击操作时候不更新数据
				if (data.noUpdate) return;
				const { paramsConfig } = this.element.database;
				if (!paramsConfig || !paramsConfig.length) return;
				// 以下步骤是为了避免有多个来源的search需要进行differ 避免检索结果错误情况
				let { search = [], param = {}, canPost } = initParams(
					this.element?.database?.paramsConfig || [],
					this.isGroup,
					this.subComponentData,
					this.groupComponents
				);
				// console.log("以下步骤是为了避免有多个来源的search需要进行differ-------", canPost, search, param);
				if (!canPost) return;
				let isTarget = false;
				paramsConfig.forEach((ele) => {
					if (ele.componentId === data.componentId) {
						isTarget = true;
					}
				});
				if (!isTarget && !data.isUpdate) return;
				this.param = param;
				this.getData(this.element.database, search, param);
			}
		}
		eventBus.$on('databaseTrigger', databaseTrigger[this.element.id]);
  },
  beforeDestroy() {
    if (!this.EDITOR_pageUUID) {
      removeEventBus(['databaseTrigger'], this.element.id);
    }
		this.initTimer(false)
		// 清除定时器
		if (this.intervalObj) {
			clearInterval(this.intervalObj);
		}
  },
  methods: {
		getIconStyle(config, index) {
			const { useGradient, colors, gradientColorsOne, gradientColorsTow, gradientColorsThree } = config.style;
			if(useGradient) {
				let gradientColors = null
				switch(index) {
					case 0: {
						gradientColors = gradientColorsOne;
						break;
					}
					case 1: {
						gradientColors = gradientColorsTow;
						break;
					}
					case 2: {
						gradientColors = gradientColorsThree;
						break;
					}
				}
				if(Array.isArray(gradientColors) && gradientColors.length) {
					return `background-image: linear-gradient(${gradientColors.join(',')})`
				} else {
					if(Array.isArray(colors) && colors.length) {
						return `background: ${colors[index % colors.length]}`
					}
				}
			} else {
				if(Array.isArray(colors) && colors.length) {
					return `background: ${colors[index % colors.length]}`
				}
			}
		},
    /**
     * @description: 获取数据
     * @param {*} database
     * @param {*} search
     * @param {*} params
     * @return {*}
     */    
    getData(database, search = [], params = {}) {
			this.search = Array.isArray(search) ? search : [];
			this.tableData = [];
			if (!this.validDatabase(database)) {
				this.interfaceFinished = true;
				return;
			}
			// 外部参数
			// 注入的参数
			let outParams = {};
			if (this.element.database.userOutParams) {
				outParams = sessionStorage.getItem(
					`dialogRelationParams_${this.EDITOR_pageUUID || this.$route.query.pageUUID}`
				);
				outParams = outParams ? JSON.parse(outParams) : {};
			}
			this.loading = true;
			const paramsObj = {
				...params,
				...outParams,
				search
			};
			// 配置
			// let __method_name__ = 'dataList';
			const mapping = database.mapping;
			let configObj = null;
			let url = '/api/mapi';
			if (mapping === 'interface') {
				configObj = {
					...paramsObj
				}
				if (database?.interfaceConfig?.url?.indexOf('http') === -1) {
					url = `/api${database?.interfaceConfig?.url}`;
				} else {
					url = database?.interfaceConfig?.url
				}
			} else if (mapping === 'object') {
				// configObj = {
				// 	__method_name__,
				// 	object_uuid: database.objectData.uuid,
				// 	view_uuid: database.viewData.uuid,
				// 	...paramsObj
				// };
				this.$message('暂不支持数仓！');
				return;
			} else if (mapping === 'relation') {
				// __method_name__ = 'relationList';
				// configObj = {
				// 	__method_name__: 'relationList',
				// 	object_uuid: database.objectData.uuid,
				// 	relationship_uuid: database.relationData.uuid,
				// 	...paramsObj
				// };
				this.$message('暂不支持关联关系');
				return;
			}
			dataInterface(configObj, url).then((res) => {
				if (res && res.status === 200) {
					let data = [];
					if (mapping === 'interface') {
						// 列表数据
						data = res.data.data
          }
          // 字段列表
					if (!this.metadata || !this.metadata.length) {
						let metadata = {};
						if (mapping === 'interface') {
							// 接口
							if (Array.isArray(data)) {
								if (Object.prototype.toString.call(data?.[0]) === '[object Object]') {
									for (let key in data?.[0]) {
										metadata[key] = key;
									}
								} 
							}
						} else {
							metadata = res.data.metadata;
						}
						this.metadata = metadata;
					}
					// 仅此是必要
          this.sourceData = data
					// 完全数据 暂时保存
					this.fullData = res.data.data;
					// 保存容器数据
					this.$store.commit('modifyComponent', {
						component: {
							...this.element,
							containerData: this.sourceData,
							fullData: this.fullData,
							metadata: this.metadata,
							database: {
								...this.element.database,
								fieldList: this.getFieldList(this.metadata)
							}
						},
						containerId: null,
						isModify: true,
						pageUUID: this.EDITOR_pageUUID
					});
				}
			}).catch((err) => {
				console.log(err);
			}).finally(() => {
				this.interfaceFinished = true;
				this.loading = false;
			});
		},
    /**
		 * @desc: 验证
		 * @param {Object} database 数据仓库的绑定
		 */
		validDatabase(database) {
			if (!database || typeof database !== 'object') return false;
			if (database.mapping === 'interface' && database.interfaceConfig) return true;
			if (!database.objectData) return false;
			if (!database.viewData && !database.relationData) return false;
			return true;
		},
    /**
		 * @desc: 判断是否存在依赖其他组件的取值
		 */
		hasComponentParam() {
			if (!this.database.paramsConfig || !this.database.paramsConfig.length) {
				return false;
			}
			for (let i = 0; i < this.database.paramsConfig.length; i++) {
				const { componentId = '', key = '', sourceType = '' } = this.database.paramsConfig[i];
				if ((key !== 'search' || !componentId.includes('CommonForm')) && sourceType !== 'url') {
					// componentId.includes('CommonTableContainer')兼容跨页请求的动态判定
					// 补充不同架构问题
					if (!componentId.includes('CommonTableContainer') && this.paramsSameArchi(componentId)) {
						return true;
					}
				}
			}
			return false;
		},
		/**
		 * @desc: 判断依赖的参数是否在当前架构下启用(@凌志华树形图架构限制)
		 * @param {String} componentId
		 * @return {Boolean}
		 */
		paramsSameArchi(componentId) {
			let comp = getComponentById(this.subComponentData, componentId);
			if (!comp && this.isGroup && this.groupComponents.length) {
				comp = getComponentById(this.groupComponents, componentId);
			}
			if (!comp) return false;
			const targetArchi = this.$GetTargetArchi('archiType');
			if (comp && comp.archiLimit && comp.archiLimit.length && comp.archiLimit.includes(targetArchi)) {
				return true;
			}
			return false;
		},
		/**
		 * @desc: 获取字段列表
		 * @param {Object} obj metadata对象
		 */
		getFieldList(obj) {
			const arr = [];
			if (obj && typeof obj === 'object') {
				for (let key in obj) {
					arr.push({
						name: obj[key],
						uuid: key
					});
				}
			}
			return arr;
		},
    /* 循环定时器请求数据 */
		doInterface() {
			if (!this.interfaceFinished) return;
      this.interfaceFinished = false;
			if (this.database && !this.hasComponentParam()) {
				const { search = [], param = {}, canPost } = initParams(this.element?.database?.paramsConfig || [], this.isGroup, this.subComponentData, this.groupComponents);
				if (!canPost) return;
				this.getData(this.database, search, param);
			}
			this.metadata = this.element.metadata || [];
		},
    /**
     * @description: 设置定时请求
     */
    setInterval() {
      // 清除定时器
      if (this.intervalObj) {
        clearInterval(this.intervalObj);
      }
      if (!this.interfaceInterval) return false;
      const {
        useInterval = false,
        type = 'timeout',
        timeout = 3000,
        times = -1,
        unit = 'day',
        weekDay = '',
        month = '',
        day = '',
        hms = '06:00'
      } = this.interfaceInterval;
      if (!useInterval) return false;
      this.intervalObj = null;
      this.intervalTimes = 0;
      // 定时循环
      if (type === 'timeout') {
        if (timeout <= 0) return;
        this.intervalObj = setInterval(() => {
					if (times !== -1) {
						this.intervalTimes++;
					}
          this.doInterface();
          if (times !== -1 && this.intervalTimes >= times) {
            clearInterval(this.intervalObj);
          }
        }, timeout * 1000);
        return;
      }
      // 周期_时分秒
      if (['second', 'minutes', 'hour'].includes(unit)) {
        let count = 1;
        if (unit === 'minutes') {
          count = 60;
        } else if (unit === 'hour') {
          count = 60 * 60;
        }
        this.intervalObj = setInterval(() => {
          if (times !== -1) {
						this.intervalTimes++;
					}
          this.doInterface();
          if (times !== -1 && this.intervalTimes >= times) {
            clearInterval(this.intervalObj);
          }
        }, count * 1000);
      } else {
        // 每天 每分钟循环一次
        this.intervalObj = setInterval(() => {
          const date = new Date();
          const currentMonth = date.getMonth() + 1;
          const currentDay = date.getDate();
          const currentWeekDay = date.getDay();
          const hours = date.getHours();
          const minutes = date.getMinutes();
          this.intervalTimes++;
          if (unit === 'day' && hms === `${hours}:${minutes}`) {
            // 日
            this.doInterface();
          } else if (unit === 'week') {
            // 周
            if (currentWeekDay === weekDay && hms === `${hours}:${minutes}`) {
              this.doInterface();
            }
          } else if (unit === 'month') {
            // 月
            if (day === currentDay && hms === `${hours}:${minutes}`) {
              this.doInterface();
            }
          } else if (unit === 'year') {
            // 年
            if (month === currentMonth && day === currentDay && hms === `${hours}:${minutes}`) {
              this.doInterface();
            }
          }
          if (times !== -1 && this.intervalTimes >= times) {
            clearInterval(this.intervalObj);
          }
        }, 59 * 1000);
      }
    },
    initTimer(flag) {
			const timerHandle = () => {
				this.times += 1
				if (this.times >= (this.statusConfig.duration || 3) * 60) {
					this.times = 0;
					// 如果没有暂停
					if(!this.suspend) {
						if(this.fullPageScrolling) {
							this.activePage += Number(this.statusConfig.rowNumber)
						} else {
							this.activePage += 1
						}
					}
				}
				requestAnimationFrame(timerHandle)
			}
			if (flag) {
				this.times = 0
				this.timer = requestAnimationFrame(timerHandle)
			} else if (this.timer) {
				cancelAnimationFrame(this.timer)
			}
		}
  }
}
</script>
<style scoped lang="less">
// 布局有 隐藏的问题
.simple-chart-wrap{
	position: relative;
  padding: 16px 20px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  text-align: left;
  .message-wrap{
    box-sizing: border-box;
		flex-shrink: 0;
    .label{
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
      letter-spacing: 0.5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .number{
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 36px */
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .chart-wrap{
    flex-grow: 1;
  }
	.empty-wrap{
		flex-grow: 1;
	}
}
.upper-and-lower-structures {
  display: flex;
  flex-direction: column;
  .chart-wrap{
    width: 100%;
		height: 10px;
  }
}
.left-and-right-structures{
  display: flex;
  flex-direction: row !important;
  .message-wrap{
    width: 50%;
    height: 100%;
  }
  .chart-wrap{
    height: 100%;
		width: 10px;
  }
}
.lenged-wrap{
	position: absolute;
	bottom: 26px;
	left: 20px;
	z-index: 1;
	max-width: 100%;
	overflow: hidden;
	display: flex;
	.lenged-item{
		display: flex;
		align-items: center;
		.icon{
			width: 10px;
			height: 10px;
			border-radius: 1px;
			background: pink;
		}
		.label{
			margin-left: 4px;
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: 150%; /* 18px */
			letter-spacing: 0.4px;
			color: #707786;
		}
		& + .lenged-item{
			margin-left: 24px;
		}
	}
}
</style>
