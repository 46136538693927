export default class VectorEvents {
    constructor() {}

    static getEvents(viewer, comp) {
        return {
            'ViewerGIS.createKmlList': async (eid, list, cb) => {
                if (eid !== comp.element.id) return;

                let kmlRes = [];
                for (let {id, name, url} of list){
                    let kml = await viewer.Vector.createKml(id, url);
                    kmlRes.push(Object.assign({
                        id: id,
                        name: name
                    }, kml));
                }

                cb(kmlRes);
            },
            'ViewerGIS.showKml': (eid, kmlId, isShow) => {
                if (eid !== comp.element.id) return;

                viewer.Vector.showKml(kmlId, isShow);
            },
            'ViewerGIS.showKmlItem': (eid, kmlId, itemIds, isShow) => {
                if (eid !== comp.element.id) return;

                viewer.Vector.showKmlItem(kmlId, itemIds, isShow);
            },
            'ViewerGIS.locationKml': (eid, kmlId) => {
                if (eid !== comp.element.id) return;

                viewer.Vector.locationKml(kmlId);
            },
            'ViewerGIS.locationKmlItem': (eid, kmlId, itemIds) => {
                if (eid !== comp.element.id) return;

                viewer.Vector.locationKmlItem(kmlId, itemIds);
            },
        };
    }
}
