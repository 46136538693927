<!--
 * @Author: wsp 542278473@qq.com
 * @Date: 2022-11-30 14:17:40
 * @LastEditors: 吴绍鹏 542278473@qq.com
 * @LastEditTime: 2023-01-17 09:30:24
 * @FilePath: \dataview-next\src\custom-component\login\components\ResetPassword.vue
 * @Description: 忘记密码重置密码弹窗
-->
<template>
  <div class="reset-password-wrap" v-loading="loading">
    <h4 class="title">忘记密码</h4>
    <div class="form-wrap">
      <transition name="alternate" mode="out-in">
        <!-- 获取验证码表单 -->
        <section v-if="formType === 'code'" key="code" class="code-form">
          <el-form
            ref="codeForm"
            :model="codeFormData"
            :rules="codeFormRules"
            :hide-required-asterisk="true"
            :show-message="false"
            size="lager"
          >
            <el-form-item prop="phone" :style="`margin-bottom: ${styleConfig.formItemMargin}px`">
              <el-input :class="`${styleConfig.inputStyle}-input`" v-model="codeFormData.phone" class="phone-type" placeholder="您注册时使用的手机号或邮箱">
                <span slot="prefix" class="phone-type-span">+86</span>
              </el-input>
            </el-form-item>
            <el-form-item prop="code" :style="`margin-bottom: ${styleConfig.formItemMargin}px`">
              <el-input :class="`${styleConfig.inputStyle}-input`" v-model="codeFormData.code" class="code-input" placeholder="请输入验证码">
                <span slot="suffix">
                  <el-button
                    :style="{ color: styleConfig.activeColor }"
                    type="text" :disabled="isGetingCode"
                    :loading="getCodeLoading"
                    @click="getCode"
                    @keydown.enter="handleSubmit"
                  >
                    <span v-if="!isGetingCode">
                      获取验证码
                    </span>
                    <span v-else>
                      {{countdown}}秒后重试
                    </span>
                  </el-button>
                </span>
              </el-input>
            </el-form-item>
          </el-form>
          <el-button :style="{ background: styleConfig.activeColor, color: styleConfig.btnColor }" style="width:100%" size="lager" @click="handleSubmit">下一步</el-button>
          <p class="message-info" :class="`${errorMessageType}-message`">
            <template v-if="showErrorMessage">
              <i :class="errorMessageType === 'error' ? 'el-icon-warning' : 'el-icon-success'"></i>
              <span class="message">{{ errorMessage }}</span>
            </template>
            <template v-else>
              <span>&nbsp;</span>
            </template>
          </p>
        </section>
        <!-- 重置密码表单 -->
        <section v-else-if="formType === 'reset'" key="reset" class="reset-form">
          <el-form
            ref="resetForm"
            :model="resetFormData"
            :rules="resetFormRules"
            :hide-required-asterisk="true"
            :show-message="false"
            size="lager"
          >
            <el-form-item prop="password" :style="`margin-bottom: ${styleConfig.formItemMargin}px`">
              <el-input :class="`${styleConfig.inputStyle}-input`" v-model="resetFormData.password" placeholder="新密码" show-password></el-input>
            </el-form-item>
            <el-form-item prop="password_confirmation" :style="`margin-bottom: ${styleConfig.formItemMargin}px`">
              <el-input :class="`${styleConfig.inputStyle}-input`" v-model="resetFormData.password_confirmation" placeholder="重复密码" show-password @keydown.enter="handleSubmit"></el-input>
            </el-form-item>
          </el-form>
          <el-button :style="{ background: styleConfig.activeColor, color: styleConfig.btnColor }" style="width:100%" size="lager" @click="handleSubmit" :loading="loading">重置密码</el-button>
          <p class="back-btn">
            <el-button type="text" :style="{ color: styleConfig.activeColor }" @click="formType = 'code'">返回上一步</el-button>
          </p>
          <p class="message-info" style="padding-top: 0" :class="`${errorMessageType}-message`">
            <template v-if="showErrorMessage">
              <i :class="errorMessageType === 'error' ? 'el-icon-warning' : 'el-icon-success'"></i>
              <span class="message">{{ errorMessage }}</span>
            </template>
            <template v-else>
              <span>&nbsp;</span>
            </template>
          </p>
        </section>
      </transition>
    </div>
    <footer class="footer">
      <el-button :style="{ color: styleConfig.activeColor }" type="text" @click="changeModel">返回登陆</el-button>
    </footer>
  </div>
</template>
<script>
import { dataInterface } from '@/apis/data/index';
export default {
  props: {
    styleConfig: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data () {
    // 验证手机号
    const checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(''));
      }
      const phoneReg = /^(((13[0-9]{1})|(14[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[0-9]{1})|(19[0-9]{1})|(18[0-9]{1}))+\d{8})$/
      if(!phoneReg.test(value)) {
        return callback(new Error(''));
      } else {
        callback()
      }
    }
    const that = this
    return {
      formType: 'code',
      codeFormData: {
        phone: '',
        code: ''
      },
      codeFormRules: {
        phone: [
          { required: true, message: '', trigger: 'blur' },
          { validator: checkPhone, trigger: 'change' }
        ],
        code: [{ required: true, message: '', trigger: 'blur' }]
      },
      resetFormData: {
        password: '',
        password_confirmation: ''
      },
      resetFormRules: {
        password: [
          { required: true, message: '', trigger: 'blur' },
          { validator: (rule, value, callback) => {
            if (!value) {
              return callback(new Error(''));
            }
            // 验证暂时无法统一，故先舍弃正则
            // const reg = /^(?![a-zA-Z]+$)(?![0-9]+$)[A-Za-z0-9]{8,18}$/  !reg.test(value)
            if(value.length < 8) {
              that.errorMessage = '密码至少8位，由数字、字母、特殊字符组成。'
              that.errorMessageType = 'error'
              that.changeShowErrorMessage()
              return callback(new Error(''));
            } else {
              callback()
            }
          }, trigger: 'change' }
        ],
        password_confirmation: [
          { required: true, message: '', trigger: 'blur' },
          { validator: (rule, value, callback) => {
            if (!value) {
              return callback(new Error(''));
            }
            if(value !== that.resetFormData.password) {
              that.errorMessage = '密码不一致'
              that.errorMessageType = 'error'
              that.changeShowErrorMessage()
              return callback(new Error(''));
            } else {
              callback()
            }
          }, trigger: 'change' }
        ]
      },
      showErrorMessage: false,
      errorMessage: '',
      errorMessageType: '',
      countdown: 0,
      isGetingCode: false,
      getCodeLoading: false,
      loading: false
    }
  },
  methods: {
    handleSubmit() {
      if(this.loading) { return }
      this.$refs[`${this.formType}Form`].validate((valid) => {
        if(valid) {
          // 验证成功
          switch(this.formType) {
            case 'code': {
              this.doValidCode()
              break
            }
            case 'reset': {
              this.doResetPassword()
              break
            }
          }
        } else {
          this.errorMessage = '请完整填写表单'
          this.errorMessageType = 'error'
          this.changeShowErrorMessage()
        }
      })
    },
    doValidCode() {
      // this.errorMessage = '短信验证码错误或已失效，请重新获取'
      // this.errorMessageType = 'error'
      // this.changeShowErrorMessage()
      // 现在接口不支持验证验证码。故直接跳过
      this.formType = 'reset'
    },
    /**
     * @description: 重置密码请求
     */    
    doResetPassword() {
      // 发起请求
      const data = Object.assign({}, this.codeFormData, this.resetFormData)
      this.loading = true
      dataInterface(data, 'api/v4/user/reset/pwd').then(res => {
        if(res && res.status === 200) {
          if(res.data.code === 200) {
            this.$message.success(res.data.msg)
            this.changeModel()
          } else {
            this.errorMessage = res.data.msg || '重置密码失败'
            this.errorMessageType = 'error'
            this.changeShowErrorMessage()
          }
        }
      }).catch(() => {
        this.errorMessage = '重置密码失败'
        this.errorMessageType = 'error'
        this.changeShowErrorMessage()
      }).finally(() => {
        this.loading = false
      })
    },
    /**
     * @description: 获取验证码
     */    
    getCode() {
      if(!this.getCodeLoading && !this.isGetingCode) {
        this.$refs[`codeForm`].validateField('phone', (valid) => {
          if (!valid) {
            const phoneReg = /^(((13[0-9]{1})|(14[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[0-9]{1})|(19[0-9]{1})|(18[0-9]{1}))+\d{8})$/
            if(!phoneReg.test(this.codeFormData.phone)) {
              this.errorMessage = '请输入正确的手机号'
              this.errorMessageType = 'error'
              this.changeShowErrorMessage()
              return
            }
            // 发个请求
            this.getCodeLoading = true
            dataInterface({phone: this.codeFormData.phone}, 'api/v4/user/reset/sendcode').then((res) => {
              if (res && res.status === 200) {
                this.errorMessage = '短信验证码已发送，可能会有延后，请耐心等待'
                this.errorMessageType = 'success'
                this.changeShowErrorMessage()
                this.countdown = 60
                this.isGetingCode = true
                this.intervalTimer = setInterval(() => {
                  this.countdown -= 1
                  if(this.countdown <= 0) {
                    clearInterval(this.intervalTimer)
                    this.isGetingCode = false
                  }
                }, 1000);
              } else {
                this.errorMessage = res.data.msg || '发送验证码失败'
                this.errorMessageType = 'error'
                this.changeShowErrorMessage()
              }
            }).finally(() => {
              this.getCodeLoading = false
            })
          } else {
            this.errorMessage = '手机信息错误'
            this.errorMessageType = 'error'
            this.changeShowErrorMessage()
          }
        })
      }
    },
    /**
     * @description: 改变错误信息的提示框，3秒
     */    
    changeShowErrorMessage() {
      if(this.showErrorMessage && this.timer) {
        clearTimeout(this.timer)
      }
      this.showErrorMessage = true
      this.timer = setTimeout(() => {
        this.showErrorMessage = false
        this.timer = null
      }, 3000)
    },
    changeModel() {
      this.$emit('changeModel', 'input')
    }
  }
}
</script>
<style scoped lang="less">
  .reset-password-wrap{
    width: 100%;
    height: 100%;
    padding: 0 24px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    .title{
      margin-top: 48px;
      margin-bottom: 30px;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
    }
    .form-wrap{
      flex-grow: 1;
      height: 10px;
      display: flex;
      align-items: flex-end;
      .code-form{
        width: 100%;
        :deep(.phone-type) {
          .el-input__inner{
            padding: 0 15px 0 45px;
          }
          .phone-type-span{
            color: unset;
          }
        }
        :deep(.code-input) {
          .el-input__inner{
            padding: 0 80px 0 15px;
          }
        }
      }
      .reset-form{
        width: 100%;
        .back-btn{
          text-align: center;
          margin-top: 4px;
        }
      }
      .message-info{
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        padding: 8px 0;
        box-sizing: border-box;
        .message{
          padding-left: 8px;
        }
      }
      .error-message{
        color: #E2A045;
      }
      .success-message{
        color: #70C241;
      }
    }
    .footer{
      text-align: center;
      margin: 8px 0;
    }
  }
</style>