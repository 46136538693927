/*
 * @Description: 列表页面模板配置
 * @Author: luocheng
 * @Date: 2021-12-03 14:09:06
 * @LastEditors: luocheng
 * @LastEditTime: 2021-12-07 17:03:47
 */
export const templateConfig = {
  object_uuid: "",  // table object_uuid
  view_uuid: "", // table view_uuid
  form_uuid: "", // table all view_uuid
  // 以上参数，如果内部的操作函数没配置，将使用这些作为默认值
  fieldList: [], // 字段列表 存储可用的数据仓库存储字段
  columns: [
    // 顺序调整问题
    // table columns  字段code，显示名称，宽度，显示组件(text-status-image)，文字颜色  计算表达式
    // { code: 'id', name: 'id', width: '100', type: 'text', color: 'black', formula: "${token} + '-' + ${device}" },
  ],

  needColumnActions: false, // 是否需要操作列
  // 操作列按钮描述
  columnActions: [],

  needSearch: false, // 是否需要搜索功能

  // 搜索条配置项
  searchConfig:
  {
    needResetButton: true, // 需要重置按钮？
    needSearchButton: true, // 需要查询按钮？ 为false 的时候将实时给出搜索值 防抖200ms
    components: [
      // 名称   组件   提示语   字段名  查询方式  是否加粗
      // {
      //   name: "token",
      //   component: "input",
      //   placeholder: "请输入token",
      //   code: "token",
      //   ruleType: "like",
      // },
      // {
      //   name: "设备",
      //   component: "input",
      //   placeholder: "请输入设备",
      //   code: "device",
      //   ruleType: "like",
      // },
    ],
    otherButtons: [
      // 附加按钮 直接摆在搜索类后面
      // 按钮名称  按钮类型  按钮图标  执行的行为  该行为成功是否刷新table？
      // {
      //   name: "新增",
      //   type: "primary",
      //   icon: "",
      //   function: "showForm",
      //   payload: {
      //     object_uuid: "", // 这里面就必须填了，可以先默认起
      //     form_uuid: "", // 这里面就必须填了
      //     data_id: "",
      //   },
      // },
    ],
    rightButtons: [
      // 右侧按钮
      // 按钮名称  按钮类型  按钮图标  执行的行为  该行为成功是否刷新table？
      // {
      //   name: "导入",
      //   type: "warning",
      //   icon: "",
      //   function: "showMessage",
      //   payload: {
      //     title: '提示语',
      //     message: '随便写了个内容'
      //   },
      // },
    ],
  }
}

