import { Graph,Shape } from '@antv/x6';
import '@antv/x6-vue-shape'
import TaskNode from "@/views/workOrder/task-flow/components/TaskNode.vue";

// 连接桩
export const ports = {
	groups: {
		top: {
			position: 'top',
			attrs: {
				circle: {
					r: 4,
					magnet: true,
					stroke: '#5F95FF',
					strokeWidth: 1,
					fill: '#fff',
					style: {
						visibility: 'hidden',
					},
				},
			},
		},
		right: {
			position: 'right',
			attrs: {
				circle: {
					r: 4,
					magnet: true,
					stroke: '#5F95FF',
					strokeWidth: 1,
					fill: '#fff',
					style: {
						visibility: 'hidden',
					},
				},
			},
		},
		bottom: {
			position: 'bottom',
			attrs: {
				circle: {
					r: 4,
					magnet: true,
					stroke: '#5F95FF',
					strokeWidth: 1,
					fill: '#fff',
					style: {
						visibility: 'hidden',
					},
				},
			},
		},
		left: {
			position: 'left',
			attrs: {
				circle: {
					r: 4,
					magnet: true,
					stroke: '#5F95FF',
					strokeWidth: 1,
					fill: '#fff',
					style: {
						visibility: 'hidden',
					},
				},
			},
		},
	},
	items: [
		{
			group: 'top',
		},
		{
			group: 'right',
		},
		{
			group: 'bottom',
		},
		{
			group: 'left',
		},
	],
}

const registerNodes = () => {
  Graph.registerNode('start', {
      inherit: 'circle',
      attrs: {
        body: {
          strokeWidth: 2,
          stroke: '#7E8595',
          fill: '#FFF',
        },
        label: {
          text: '开始',
          fontSize: 16,
          fill: '#7E8595',
        },
      },
      ports: { ...ports },
    }, true)
  Graph.registerNode('end', {
      inherit: 'circle',
      attrs: {
        body: {
          strokeWidth: 4,
          stroke: '#7E8595',
          fill: '#FFF',
        },
        label: {
          text: '结束',
          fontSize: 16,
          fill: '#7E8595',
        },
      },
      ports: { ...ports },
    }, true)
  Graph.registerNode('gateway', {
      inherit: 'polygon',
      attrs: {
        body: {
          refPoints: '0,10 10,0 20,10 10,20',
          strokeWidth: 2,
          stroke: '#7E8595',
          fill: '#EFF4FF',
        },
        label: {
          text: '+',
          fontSize: 50,
          fill: '#7E8595',
        },
      },
      ports: { ...ports },
    }, true)
  Graph.registerNode("task", {
    inherit: "vue-shape",
    component: {
      template: `<task-node />`,
      components: {
        'task-node': TaskNode,
      },
    },
    ports: { ...ports },
  },true);
}


export const graphInitial = (container)=>{
  registerNodes();
	const graph = new Graph({
		container: container,
		autoResize: true,
		mousewheel: {
			enabled: true,
			zoomAtMousePosition: true,
			minScale: 0.5,
			maxScale: 3,
		},
		panning: {
			enabled: true,
		},
		connecting: {
			router: {
				name: 'manhattan',
				args: {
					padding: 10,
				},
			},
			connector: {
				name: 'rounded',
				args: {
					radius: 10,
				},
			},
			anchor: 'center',
			connectionPoint: 'anchor',
			allowBlank: false,
			snap: {
				radius: 20,
			},
			createEdge() {
				return new Shape.Edge({
					attrs: {
						line: {
							stroke: '#A2B1C3',
							strokeWidth: 2,
							targetMarker: {
								name: 'block',
								width: 12,
								height: 8,
							},
						},
					},
					data: {},
					zIndex: 0,
				})
			},
			validateConnection({ targetMagnet }) {
				return !!targetMagnet
			},
		},
		highlighting: {
			magnetAdsorbed: {
				name: 'stroke',
				args: {
					attrs: {
						fill: '#5F95FF',
						stroke: '#5F95FF',
					},
				},
			},
		},
		resizing: true,
		rotating: true,
		selecting: {
			enabled: true,
			rubberband: false,
			showNodeSelectionBox: true,
			showEdgeSelectionBox: true,
		},
		snapline: {
			enabled: true,
			className: 'snapline',
			clean: false,
		},
		keyboard: {
			enabled: true,
			global: true,
		},
		history: true,
		clipboard: true,
		translating: true,
	});

	// 显示隐藏连接桩
	const showPorts = (ports,show) => {
		for (let i = 0, len = ports.length; i < len; i = i + 1) {
			ports[i].style.visibility = show ? 'visible' : 'hidden'
		}
	}
	graph.on('node:mouseenter', () => {
		if(graph.isRubberbandEnabled()){
			const ports = container.querySelectorAll('.x6-port-body')
			showPorts(ports, true)
		}
	})
	graph.on('node:mouseleave', () => {
		const ports = container.querySelectorAll('.x6-port-body')
		showPorts(ports, false)
	})

	//delete
	graph.bindKey('backspace', () => {
		const cells = graph.getSelectedCells()
		if (cells.length) {
			graph.removeCells(cells)
		}
	})

	graph.bindKey('delete', () => {
		const cells = graph.getSelectedCells()
		if (cells.length) {
			graph.removeCells(cells)
		}
	})

	graph.bindKey('ctrl+c', () => {
		const cells = graph.getSelectedCells()
		if (cells.length) {
			graph.copy(cells)
		}
		return false
	})

	graph.bindKey('ctrl+v', () => {
		if (!graph.isClipboardEmpty()) {
			const cells = graph.paste({ offset: 32 })
			graph.cleanSelection()
			graph.select(cells)
		}
		return false
	})

	graph.bindKey('ctrl+z', () => {
		if(graph.canUndo()){
			graph.undo()
		}
		return false
	})

	graph.bindKey('up', () => {
		if(graph.isRubberbandEnabled()){
			const cells = graph.getSelectedCells()
			for (let cell of cells){
				if(cell.shape !== 'edge'){
					cell.translate(0,-5)
				}
			}
		}
	})
	graph.bindKey('down', () => {
		if(graph.isRubberbandEnabled()){
			const cells = graph.getSelectedCells()
			for (let cell of cells){
				if(cell.shape !== 'edge'){
					cell.translate(0,5)
				}
			}
		}
	})
	graph.bindKey('left', () => {
		if(graph.isRubberbandEnabled()){
			const cells = graph.getSelectedCells()
			for (let cell of cells){
				if(cell.shape !== 'edge'){
					cell.translate(-5)
				}
			}
		}
	})
	graph.bindKey('right', () => {
		if(graph.isRubberbandEnabled()){
			const cells = graph.getSelectedCells()
			for (let cell of cells){
				if(cell.shape !== 'edge'){
					cell.translate(5)
				}
			}
		}
	})

	/*graph.on('node:added', ({ node }) => {
		const { x, y } = node.position()
		console.log(x,y)
	})*/

	return graph
}

export const recoverInitial = (container)=>{
	const graph = new Graph({
		container: container,
		mousewheel: {
			enabled: true,
			zoomAtMousePosition: true,
			modifiers: 'ctrl',
			minScale: 0.5,
			maxScale: 3,
		},
		connecting: {
      router: {
        name: 'manhattan',
        args: {
          padding: 10,
        },
      },
      connector: {
        name: 'rounded',
        args: {
          radius: 10,
        },
      },
			anchor: 'center',
			connectionPoint: 'anchor',
			allowBlank: false,
			snap: {
				radius: 20,
			},
			createEdge() {
				return new Shape.Edge({
					attrs: {
						line: {
							stroke: '#A2B1C3',
							strokeWidth: 2,
							targetMarker: {
								name: 'block',
								width: 12,
								height: 8,
							},
						},
					},
					data: {},
					zIndex: 0,
				})
			},
			validateConnection({ targetMagnet }) {
				return !!targetMagnet
			},
		},
		keyboard: true,
	});

	return graph
}
