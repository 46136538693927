const requireComponent = require.context('./', false, /\w+\.vue$/);

const components = {};

// eslint-disable-next-line array-callback-return
requireComponent.keys().map((fileName) => {
  const comp = requireComponent(fileName).default;
  components[comp.name] = comp;
});

export default components;
