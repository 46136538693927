<!--
    @name: index
    @description：index
    @author: ZengWei
    @date: 2022-05-13 09:19
-->
<template>
  <div class="task-flow-container" v-loading="loading">
    <div class="action-bar" v-if="!disabled">
      <el-button-group>
        <el-button plain size="small">
          新手引导
        </el-button>
        <el-button plain size="small">
          自适应
        </el-button>
        <el-button v-if="!disabled" plain size="small" @click="saveTaskFlow">
          保存
        </el-button>
      </el-button-group>
    </div>
    <div class="graph-container" v-if="!disabled">
      <div
        v-for="item in nodeList"
        :key="item.text"
        class="node-item"
        @mousedown="drag($event,item)"
      >
        <div class="node-item-icon" :class="item.class"></div>
        <span class="node-label">{{ item.text }}</span>
      </div>
    </div>

    <task-props
      v-if="propOpen && nodeType"
      :key="key"
      :disabled="disabled"
      :current-item="currentItem"
      :currentNodeId="currentNodeId"
      :node-type="nodeType"
      :form-node="formNode"
      @on-close="close"
      @on-change="updateProps"
    ></task-props>

    <div class="task-config-wrapper" v-if="configOpen && !disabled">
      <p class="conf-title">
        任务引擎
        <i class="iconfont iconc-close" @click="closeConfig"></i>
      </p>
      <div class="content">
        <task-config
          ref="taskConfig"
          :disabled="disabled"
          :config-prop="configData"
          @on-change="configChange"
        ></task-config>
      </div>
    </div>
    <div ref="container" class="task-block"></div>
  </div>
</template>

<script>

import {Addon} from "@antv/x6";
import {graphInitial} from "@/views/workOrder/task-flow/components/TaskInitializer";
import {ButtonGroup, Button} from "element-ui";
import TaskProps from "@/views/workOrder/task-flow/components/TaskProps.vue";
import TaskConfig from "@/views/workOrder/task-flow/components/TaskConfig.vue";
import {dataInterface} from "@/apis/data";
import {preTaskNode} from "@/plugins/util";
import eventBus from "@/plugins/eventBus";
import {uniqid} from "@/utils/util";

export default {
  name: "index",
  components: {
    'el-button-group':ButtonGroup,
    'el-button':Button,
    TaskProps,
    TaskConfig
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    actionBar: {
      type: Boolean,
      default: false
    },
    workTodo: {
      type: Boolean,
      default: true
    },
    taskId:{
      type: [Number,String],
      default: 0
    },
  },
  data() {
    return {
      graph: null,
      dnd: null,
      activeTab: 'worker',
      taskEngineId: this.taskId,
      propOpen: true,
      configOpen: true,
      configData: {
        name: '',
        desc: '',
        interval_open: 0,
        interval:{}
      },
      nodeList:[
        {text: '开始', type: 'start', class: 'node-start',},
        {text: '任务', type: 'task', class: 'node-task',},
        {text: '网关', type: 'gateway', class: 'node-gateway',},
        {text: '结束', type: 'end', class: 'node-end',},
      ],
      selectedNode: null,
      currentItem: null,
      currentNodeId: '',
      nodeType: null,
      formNode: [],
      loading: false,

      modalTitle: '',
      dialogVisible: false,
      formDesignData: null,
      formDisabled: false,
      displayData: {},
      editFields: {},
      needFlow: false,
      moduleUuid: '',
      formMode: 'add',
      key: uniqid()
    }
  },
  created() {
  },
  mounted() {
    this.initCanvas()
    eventBus.$on('task-graph-recover',(taskEngineId)=>{
      this.taskEngineId = taskEngineId
      this.configOpen = false
      this.getTaskEngine()
    })
    eventBus.$on('open-attach',(attach)=>{
      console.log(attach, "任务附件")
    })
  },
  methods: {
    open(){
      this.configOpen = true
    },
    close(){
      this.propOpen = false
    },
    closeConfig(){
      this.configOpen = false
    },
    configChange(data){
      const {name,desc,interval_open,interval} = data
      this.configData = {name,desc,interval_open,interval}
    },
    getTaskEngine(){
      this.loading = true
      const param = {
        __method_name__: 'dataInfo',
        object_uuid: 'object628619c716579',
        data_id: this.taskEngineId,
        transcode: 0
      }
      dataInterface(param).then(res=>{
        if(res.data.code === 200){
          const {name,desc,interval_open,interval,json} = res.data.data
          this.configData = {name,desc,interval_open,interval}
          if(this.graph){
            this.graph.clearCells({})
            this.graph.fromJSON(json)
            this.graph.centerContent()
          }
          this.loading = false
        }
      })
    },
    validTaskEngine(graphData){
      let startNode = false,endNode = false, startNodeId = ''
      let source = [],target = [], firstNode = []

      for (let cell of graphData){
        if(cell.shape === 'start'){
          startNode = true
          startNodeId = cell.id
        }
        if(cell.shape === 'end') endNode = true
        if(cell.shape === 'edge'){
          source = source.concat(cell.source.cell)
          target = source.concat(cell.target.cell)
        }
      }

      const firstEdge = graphData.filter(item=>item.shape === 'edge' && item.source.cell === startNodeId)
      let firstNodeId = []
      if(firstEdge instanceof Array){
        firstNodeId = Array.from(firstEdge,item => item.target.cell)
        firstNode = graphData.filter(item=>item.shape === 'task' && firstNodeId.includes(item.id))
      }
      return {startNode,endNode,source,target,firstNode}
    },
    saveTaskFlow(){
      if(this.graph){
        const graphData = this.graph.toJSON()
        const nodeData = this.formatData(graphData.cells)
        for (let node of nodeData.node_data.nodeList){
          if(node.user_type === 1 && node.nodeType === 'task' && node.target_users.length === 0){
            this.$message.error('任务：[ '+node.name+' ] 责任人不能为空，请选择责任人')
            return
          } else if(node.user_type === 5 && node.nodeType === 'task' && node.user_depart.length === 0){
            this.$message.error('任务：[ '+node.name+' ] 部门不能为空，请选择部门')
            return
          } else if(node.user_type === 6 && node.nodeType === 'task' && node.user_role.length === 0){
            this.$message.error('任务：[ '+node.name+' ] 岗位不能为空，请选择岗位')
            return
          }
          if(node.nodeType === 'task' && (node.func_form === '' || node.func_form === null)){
            this.$message.error('任务：[ '+node.name+' ] 任务表单不能为空，请选择配置任务表单')
            return
          }
        }
        const valid = this.validTaskEngine(graphData.cells)
        if(typeof this.$parent.triggerValidate === 'function'){
          try {
            this.$parent.triggerValidate()
          } catch (e) {
            this.$message.error('数据校验失败，请核对必填字段！')
            return
          }
        }
        if(!this.configData.name){
          this.activeTab = 'config'
          this.$refs.taskConfig.validForm()
          this.$message.error('任务引擎名称缺失，请检查后保存！')
          return
        }
        if(!valid.startNode || !valid.endNode){
          this.$message.error('开始或结束节点缺失，请检查后保存！')
          return
        }
        for (let item of graphData.cells){
          if(
            item.shape === 'task' &&
            (!valid.source.includes(item.id) || !valid.target.includes(item.id))
          ){
            this.$message.error('任务连线未连接完整，请检查后保存！')
            return
          }
        }
        // 验证开启定时任务时，紧跟开始节点的步骤只能是指定人员
        if(this.configData.interval_open){
          if(valid.firstNode.length>0){
            for (let first of valid.firstNode){
              if(first.data.user_type !== 1 && first.data.target_users.length === 0){
                this.$message.error('任务引擎开启定时任务，任务：[ '+first.data.name+' ] 必须包含责任人！')
                return
              }
            }
          }
        }
        const param = {
          __method_name__: 'createData',
          object_uuid: 'object628619c716579',
          transcode: 0
        }
        if(this.taskEngineId){
          param.__method_name__ = 'updateData'
          param.data_id = this.taskEngineId
        }
        const saveData = {
          ...this.configData,
          json: graphData,
          ...nodeData,
          ...param
        }
        this.$emit('on-save',saveData)
      }
    },
    formatData(graphData){
      const nodeList = [], lineList = []
      let start_node='', end_node=''
      for (let item of graphData){
        if(['edge','gateway'].includes(item.shape)){
          const newData = {
            nodeId: item.id,
            nodeType: item.shape,
            name: item.data?.name || item.shape,
            run_logic: item.data?.run_logic || [],
          }
          if(item.shape === 'edge'){
            newData.from = item.source.cell
            newData.to = item.target.cell
            lineList.push(newData)
          } else {
            nodeList.push(newData)
          }
        } else {
          if(item.shape === 'start') start_node = item.id
          if(item.shape === 'end') end_node = item.id
          if(item.shape === 'task') delete item.component
          const newData = {
            nodeId: item.id,
            nodeType: item.shape,
            name: item.data?.name || item.shape,
            user_type: item.data?.user_type || 7,
            target_users: item.data?.target_users || [],
            user_from: item.data?.user_from || 1,
            user_depart: item.data?.user_depart || [],
            user_role: item.data?.user_role || [],

            send_type: item.data?.send_type || 7,
            send_users: item.data?.send_users || [],
            send_from: item.data?.send_from || 1,
            send_role: item.data?.send_role || [],

            assign: item.data?.assign || 1,
            form_type: item.data?.form_type || 1,
            object_uuid: item.data?.object_uuid || '',
            func_form: item.data?.func_form || '',
            run_flow: item.data?.run_flow || 1,
            run_logic: item.data?.run_logic || [],
          }
          nodeList.push(newData)
        }
      }
      const node_data = {nodeList,lineList}
      return {node_data,start_node,end_node}
    },
    updateProps(data){
      if(this.selectedNode){
        if(this.nodeType === 'edge'){
          if(data.name){
            const label = {
              markup: [
                {tagName: 'rect', selector: 'body',},
                {tagName: 'text', selector: 'label',},
              ],
              attrs: {
                text: {
                  text: data.name,
                },
                label: {
                  fill: '#000',
                  fontSize: 14,
                  textAnchor: 'middle',
                  textVerticalAnchor: 'middle',
                  pointerEvents: 'none',
                },
                body: {
                  ref: 'label',
                  fill: '#ffd591',
                  stroke: '#ffa940',
                  strokeWidth: 2,
                  rx: 4,
                  ry: 4,
                  refWidth: '140%',
                  refHeight: '140%',
                  refX: '-20%',
                  refY: '-20%',
                },
              },
              zIndex:10
            }
            this.selectedNode.setLabels([label])
          } else {
            this.selectedNode.setLabels([])
          }
        }
        this.selectedNode.updateData(data)
      }
    },
    initCanvas(){
      const self = this
      this.$nextTick(()=>{
        const container = this.$refs.container
        const minmap = this.$refs.minMap
        const graph = graphInitial(container,minmap)
        graph.enableRubberband()
        graph.on('node:selected', ({node})=>{
          self.key= uniqid()
          self.propOpen = true
          self.configOpen = false
          self.selectedNode = node
          self.currentItem = node.getData()
          self.currentNodeId = node.id
          self.nodeType = node.shape
        })
        graph.on('node:click', ({node})=>{
          if(self.disabled){
            self.key= uniqid()
            self.propOpen = true
            self.configOpen = false
            self.selectedNode = node
            self.currentItem = node.getData()
            self.currentNodeId = node.id
            self.nodeType = node.shape
          }
        })
        graph.on('edge:click', ({edge})=>{
          self.key= uniqid()
          self.propOpen = true
          self.configOpen = false
          self.selectedNode = edge
          self.currentItem = edge.getData()
          self.currentNodeId = edge.id
          self.nodeType = edge.shape
        })

        graph.on('blank:click', ()=>{
          self.propOpen = false
          self.configOpen = true
        })

        // 拖拽实例化
        this.dnd = new Addon.Dnd({
          target: graph,
          scaled: false,
          animation: true,
        })
        this.graph = graph

        this.graph.options.panning = {
          enabled: true,
          modifiers: 'ctrl',
        }
        if(this.disabled){
          this.graph.options.resizing.enabled = false
          this.graph.options.rotating.enabled = false
          this.graph.options.interacting = false
          this.graph.options.panning = {
            enabled: true,
          }
          this.graph.disableSelection()
        }

        if(this.taskEngineId){
          this.getTaskEngine()
        }
      })
    },
    drag(e,nodeItem){
      let nodeData;
      switch (nodeItem.type){
        case 'start':
          nodeData = {
            shape: "start", width: 70, height: 70,
            data:{}
          }
          break
        case 'task':
          nodeData = {
            shape: "task", width: 220, height: 160,
            data: {
              name: '任务标题',
              content: []
            }
          }
          break
        case 'gateway':
          nodeData = {
            shape: "gateway", width: 86, height: 86,
            data:{}
          }
          break
        case 'end':
          nodeData = {
            shape: "end", width: 70, height: 70,
            data:{}
          }
          break
      }
      const node = this.graph.createNode(nodeData)
      this.dnd.start(node, e)
    },
    // 获取前置任务配置Data
    getPreTask(nodeId,type){
      const graphData = this.graph.toJSON()
      const preNodeId = preTaskNode(graphData.cells,nodeId,type,[nodeId])
      const preNode = graphData.cells.filter(item => preNodeId.includes(item.id) && item.shape === 'task')
      const nodeData = Array.from(preNode,item=>item.data)
      return nodeData
    },
  },
  destroyed() {
    if(this.graph){
      this.graph.dispose()
      this.graph = null
    }
  }
}
</script>
<style lang="less">
  .snapline{
    .x6-widget-snapline-horizontal{
      border-bottom:1px dashed #F57F17 !important;
    }
    .x6-widget-snapline-vertical{
      border-right:1px dashed #F57F17 !important;
    }
  }
</style>
<style lang="less" scoped>
:deep(.el-tabs) {
  height: 100%;
  box-shadow: none;
  .el-tabs__content{
    padding: 0;
    height: calc(100% - 40px);
    .el-tab-pane{
      height: 100%;
    }
  }
}

.scrollbar-bar{
  overflow-y: auto;
  padding: 0 15px;
  height: calc(100% - 60px);
}

.iconc-close{
  position: absolute;
  font-size: 22px;
  top: 5px;
  right: 8px;
  z-index: 10;
  cursor: pointer;
}

.task-config-wrapper{
  width: 350px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  background: #ffffff;

  .conf-title{
    padding: 10px;
    border-bottom: 1px solid #eaebed;
    .iconc-close{
      float: right;
    }
  }
  .content {
    height: calc(100% - 105px);
  }
}

.task-flow-container{
  position: relative;
  width: 100%;
  height: 100%;

  .action-bar{
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 2;
  }
  .graph-container{
    position: absolute;
    top: 100px;
    left: 20px;
    z-index: 101;
    width: 50px;
    padding: 20px 10px;
    text-align: center;
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 0 10px 1px rgb(228, 224, 219);

    .node-item {
      margin-bottom: 20px;
    }

    .node-item-icon {
      display: block;
      width: 30px;
      height: 30px;
      margin: auto;
      background-size: cover;
    }

    .node-label {
      font-size: 12px;
      line-height: 30px;
      user-select: none;
    }

    .node-start {
      background: url('./background/start.png') no-repeat;
      background-size: cover;
    }

    .node-task {
      background: url('./background/task.png') no-repeat;
      background-size: cover;
    }

    .node-gateway {
      background: url('./background/gateway.png') no-repeat;
      background-size: cover;
    }

    .node-end {
      background: url('./background/end.png') no-repeat;
      background-size: cover;
    }
  }

  .task-block{
    width: 100%;
    height: 100%;
    background-color: #F7F9FF;
  }

}
</style>
