/*
 * @Description: 其他
 * @Author: luocheng
 * @Date: 2021-10-15 14:53:50
 * @LastEditors: luocheng
 * @LastEditTime: 2022-10-08 11:37:13
 */
// 其他
const other = {
	type: 'other',
	title: '其他组件',
	show: true,
	order: 10,
	componentList: [
		{
      component: 'CommonMagic',
      type: 'form',
      name: '魔法盒子',
      icon: 'iconmoxingchakan',
      isLock: false, // 是否锁定
      needCommonStyle: true,
      propValue: '',
      span: 24,
      statusConfig: {
				displayShow: true,
        isShow: true, // 是否显示
      },
      style: {
        minHeight: 0,
        height: '100%',
        width: '',
        rotate: 0,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0
      },
			magicConfig: {
				defaultHost: '',
				link: ''
			},
      actionConfig: []
    },
		{
			component: 'CommonRandomText',
			type: 'text',
			name: '随机数模拟',
			icon: 'iconyeqian',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			propValue: '50',
			span: 24,
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
				defaultVal: 50, // 默认值
				showUnit: true,
				useRandom: true,
				unit: '%', // 单位
				unitSize: 14,
				randomTime: 300,
				min: 0,
				max: 100,
				floatLength: 0
			},
			style: {
				height: 32,
				width: 120,
				rotate: 0,
				color: 'rgba(232, 209, 34, 1)',
				fontSize: 30,
				fontWeight: 500,
				lineHeight: 32,
			}
		}
	]
};

export default other;