<!--
 * @Author: wsp 542278473@qq.com
 * @Date: 2022-11-30 14:17:40
 * @LastEditors: 吴绍鹏 542278473@qq.com
 * @LastEditTime: 2023-07-04 12:00:41
 * @FilePath: \dataview-next\src\custom-component\login\CommonLoginNew.vue
 * @Description: 新登录组件
-->
<template>
  <div class="common-login-new">
    <div class="common-login-wrap" :style="sourceStyle">
      <transition
        name="alternate"
        mode="out-in"
      >
        <LoginByInput
          v-if="type === 'input'"
          :methodConfig="methodConfig"
          :styleConfig="styleConfig"
          @changeModel="handleChangeModel"
        />
        <LoginByCode
          v-else-if="type === 'code'"
          :methodConfig="methodConfig"
          @changeModel="handleChangeModel"
        />
        <ResetPassword
          v-else-if="type === 'reset'"
          :methodConfig="methodConfig"
          :styleConfig="styleConfig"
          @changeModel="handleChangeModel"
        />
        <BindPassword
          v-else-if="type === 'bindPassword'"
          :methodConfig="methodConfig"
          @changeModel="handleChangeModel"
        />
        <BindPhone
          v-else-if="type === 'bindPhone'"
          :methodConfig="methodConfig"
          @changeModel="handleChangeModel"
        />
      </transition>
    </div>
  </div>
</template>
<script>
import LoginByInput from './components/LoginByInput.vue';
import LoginByCode from './components/LoginByCode.vue';
import ResetPassword from './components/ResetPassword.vue';
import BindPassword from './components/BindPassword.vue';
import BindPhone from './components/BindPhone.vue';
import { mapState } from 'vuex';
export default {
  name: 'CommonLoginNew',
  components: {
    LoginByInput,
    LoginByCode,
    ResetPassword,
    BindPassword,
    BindPhone
  },
  props: {
    // 绑定的值
    element: {
      type: Object,
      default: () => {},
      required: true
    },
    // 是否在组合内
    isGroup: {
      type: Boolean
    },
    // 组合内组件列表
    groupComponents: {
      type: Array,
      default: () => []
    },
    scaleHeight: {
      type: Number,
      default: 1
    },
    scaleWidth: {
      type: Number,
      default: 1
    },
    ratio: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      type: 'input'
    };
  },
  computed: {
    ...mapState(['componentData', '_PageCustomStatus', '_APPCustomStatus']),
    sourceStyle() {
      return {
        width: this.element.style.width + 'px',
        height: this.element.style.height + 'px',
        transform: `scaleX(${this.scaleWidth}) scaleY(${this.scaleHeight})`,
        '--login-input-color' : this.styleConfig.inputColor,
        '--login-placeholder-color': this.styleConfig.placeholderColor
      }
    },
    statusConfig() {
      return this.element.statusConfig || {};
    },
    /**
     * @description: 配置允许的登录方式的对象
     * @return {Object} 配置允许的登录方式的对象
     */
    methodConfig() {
      const usePasswordLogin = !!this.statusConfig.usePasswordLogin;
      const useCodeLogin = !!this.statusConfig.useCodeLogin;
      const useResetPassword = !!this.statusConfig.useResetPassword;
      const userQRCodelogin = !!this.statusConfig.userQRCodelogin;
      if (!usePasswordLogin && !useCodeLogin && userQRCodelogin) {
        this.handleChangeModel('code');
      } else if (usePasswordLogin || useCodeLogin) {
        this.handleChangeModel('input');
      } else {
        this.handleChangeModel('');
      }
      return {
        usePasswordLogin,
        useCodeLogin,
        useResetPassword,
        userQRCodelogin
      };
    },
    /**
     * @description: 配置登录表单的对象
     * @return {Object} 配置允许的登录方式的对象
     */  
    styleConfig() {
      return {
        formItemMargin: this.statusConfig?.formItemMargin || 22, // 表单项间距
        tabsMargin: this.statusConfig?.tabsMargin || 8, // 登录选择间距
        activeColor: this.statusConfig?.activeColor || '#409eff', // 活动颜色
        btnColor: this.statusConfig?.btnColor || '#fff', //按钮颜色 
        tabsFontSize: this.statusConfig?.tabsFontSize || '#fff', 
        tabsFontWeight: this.statusConfig?.tabsFontWeight || 'normal', 
        inputStyle: this.statusConfig?.inputStyle || 'border', // 输入框风格
        inputColor: this.statusConfig?.inputColor || '#606266', // 输入框颜色
        placeholderColor: this.statusConfig?.placeholderColor || '#C0C4CC'
      }
    }
  },
  methods: {
    /**
     * @description: 改变登录弹窗
     * @param {String} type
     */
    handleChangeModel(type) {
      this.type = type;
    }
  }
};
</script>
<style lang="less" scoped>
.common-login-new{
  width: 100%;
  height: 100%;
  .common-login-wrap{
    transform-origin: top left;
    width: 100%;
    height: 100%;
    .alternate-enter-active, .alternate-leave-active {
      transition: all .3s ease;
    }
    .alternate-enter, .alternate-leave-to /* .fade-leave-active below version 2.1.8 */ {
      opacity: 0;
    }
    :deep(.border-input) {
      .el-input__inner{
        background: transparent;
        border: 1px solid #E6E9F0;
      }
    }
    :deep(.underline-input) {
      .el-input__inner{
        background: transparent;
        border-width: 0 0 2px 0;
        border-bottom-color: #737373;
      }
    }
    :deep(.fill-input) {
      .el-input__inner{
        background: #ECECEC;
        border: none;
      }
    }
    :deep(.el-loading-mask){
      background: rgba(255, 255, 255, .2);
      backdrop-filter: blur(9px);
    }
    :deep(.el-button--default) {
      border: none !important;
    }
    :deep(.el-input) {
      input[type="text"] {
        color: var(--login-input-color);
        &:-webkit-autofill {
          -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
          background-color: transparent !important; //设置input框记住密码背景颜色
          background-image: none;
          transition: background-color 50000s ease-in-out 0s;
          -webkit-text-fill-color: var(--login-input-color) !important; //设置浏览器默认密码字体颜色
        }
        &::placeholder{
          color: var(--login-placeholder-color);
        }
      }
      input[type="password"] {
        color: var(--login-input-color);
        &:-webkit-autofill {
          -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
          background-color: transparent !important; //设置input框记住密码背景颜色
          background-image: none;
          transition: background-color 50000s ease-in-out 0s;
          -webkit-text-fill-color: var(--login-input-color) !important; //设置浏览器默认密码字体颜色
        }
        &::placeholder{
          color: var(--login-placeholder-color);
        }
      }
    }
  }
}
</style>
