<!--
    @name: topology
    @description：topology
    @author: ZengWei
    @date: 2022-06-13 15:40
-->
<template>
  <div class="task-flow-container">
    <div class="action-bar">
      <el-button-group >
<!--        <el-button plain size="small">
          新手引导
        </el-button>-->
        <el-button plain size="small" @click="fitCenter">
          自适应
        </el-button>
        <el-button plain size="small" v-if="topoGraphId && editMode" @click="saveTaskFlow">
          保存
        </el-button>
        <el-button plain size="small" v-if="topoGraphId" @click="openLockGraph">
          {{ editMode ? '预览' : '编辑'}}
        </el-button>
      </el-button-group>
    </div>
    <div class="graph-container" v-show="editMode">
      <div
        v-for="item in nodeList"
        :key="item.text"
        class="node-item"
        @mousedown="drag($event,item)"
      >
        <div class="node-item-icon" :class="item.class"></div>
        <span class="node-label">{{ item.text }}</span>
      </div>
    </div>

    <topo-props
      v-if="propOpen && nodeType"
      :key="key"
      :current-item="currentItem"
      :currentNodeId="currentNodeId"
      :node-type="nodeType"
      :form-node="formNode"
      :edit-mode="!editMode"
      @on-close="close"
      @on-change="updateProps"
    ></topo-props>

<!--    <div class="task-config-wrapper" v-if="configOpen">
      <template v-if="workTodo">
        <p class="conf-title">
          拓扑图
          <i class="iconfont iconc-close" @click="closeConfig"></i>
        </p>
        <div class="content">
        </div>
      </template>
    </div>-->

    <div ref="container" class="task-block" @contextmenu="onContextMenu($event)"></div>

    <div class="context-menu" v-if="contextmenu.left && graph" :style="contextmenu">
      <ContextMenu :canvas="graph" :props.sync="selectedNode" @cancel="cancelMenu"></ContextMenu>
    </div>

  </div>
</template>

<script>
import {Addon} from "@antv/x6";
import {graphInitial, ports} from "@/views/workOrder/task-flow/components/TaskInitializer";
import {ButtonGroup, Button} from "element-ui";
import {dataInterface} from "@/apis/data";
import TopoProps from "@/custom-component/topography/components/TopoProps";
import ContextMenu from "@/custom-component/topography/components/ContextMenu";
import eventBus from "@/plugins/eventBus";
import {uniqid} from "@/utils/util";

export default {
  name: "Topology",
  components: {
    'el-button-group':ButtonGroup,
    'el-button':Button,
    TopoProps,
    ContextMenu
  },
  props: {
    actionBar: {
      type: Boolean,
      default: false
    },
    workTodo: {
      type: Boolean,
      default: true
    },
    topoId:{
      type: [Number,String],
      default: 0
    },
  },
  data() {
    return {
      graph: null,
      contextmenu: {
        left: null,
        top: null,
        bottom: null
      },
      dnd: null,
      activeTab: 'worker',
      topologyId: this.topoId,
      propOpen: true,
      configOpen: true,
      configData: {
        name: '',
        desc: '',
        interval_open: 0,
        interval:{}
      },
      nodeList:[
        {text: '虚线框', type: 'dashed', class: 'node-dashed',},
        {text: '文本', type: 'text', class: 'node-text',},
        {text: '矩形', type: 'rect', class: 'node-rect',},
        {text: '直线', type: 'line', class: 'node-line',},
        {text: '任务', type: 'task', class: 'node-task',},
      ],
      selectedNode: null,
      currentItem: null,
      currentNodeId: '',
      nodeType: null,
      formNode: [],
      topoGraphId: 0,
      topoGraphData: null,
      editMode: false,
      key: uniqid()
    }
  },
  created() {
    // const self = this
    window.addEventListener('message',(e)=>{
      if(e.data.event === 'open-task-form'){
        console.log(e.data.task, "任务表单")
      } else if(e.data.event === 'open-attach') {
        console.log(e.data.attach, "任务附件")
      }
    })
  },
  mounted() {
    this.initCanvas()
    eventBus.$on('active-graph',(topoGraphId)=>{
      this.topoGraphId = topoGraphId
      this.getTopoDetail()
    })
    eventBus.$on('filter-graph',(query)=>{
      this.filterGraphData(query)
    })
  },
  methods: {
    openLockGraph(){
      if(this.graph){
        if(this.editMode){
          this.graph.options.interacting = false
          this.graph.options.resizing.enabled = false
          this.graph.options.rotating.enabled = false
          this.graph.options.panning = {
            enabled: true,
          }
          this.graph.disableRubberband()
        } else {
          this.graph.options.interacting = true
          this.graph.options.resizing.enabled = true
          this.graph.options.rotating.enabled = true
          this.graph.options.panning = {
            enabled: true,
            modifiers: 'ctrl',
          }
          this.graph.enableRubberband()
        }
        this.editMode = !this.editMode
      }
    },
    lockGraph(){
      this.editMode = false
      this.graph.options.resizing.enabled = false
      this.graph.options.rotating.enabled = false
      this.graph.options.interacting = false
      this.graph.options.panning = {
        enabled: true,
      }
      this.graph.disableRubberband()
      // this.graph.disableSelection()
    },
    fitCenter(){
      if(this.graph){
        this.graph.zoomToFit()
      }
    },
    open(){
      this.configOpen = true
    },
    close(){
      this.propOpen = false
    },
    closeConfig(){
      this.configOpen = false
    },
    configChange(data){
      const {name,desc,interval_open,interval} = data
      this.configData = {name,desc,interval_open,interval}
    },
    filterGraphData(query){
      const copyData = this.topoGraphData
      const name = query.name
      let filterData
      if(name === '未完成'){
        filterData = copyData.cells.filter(item=>{
          const status = item?.data?.status || 1
          return item.shape === 'task' && status !== 2
        })
        filterData = {cells: filterData}
      } else if(name === '已完成'){
        filterData = copyData.cells.filter(item=>{
          const status = item?.data?.status || 1
          return item.shape === 'task' && status === 2
        })
        filterData = {cells: filterData}
      } else {
        filterData = this.topoGraphData
      }
      if(this.graph){
        this.graph.fromJSON(filterData)
        this.graph.centerContent()
      }
    },
    getTopoDetail(){
      const param = {
        __method_name__: 'dataInfo',
        object_uuid: 'object62a83e285860b',
        data_id: this.topoGraphId,
        transcode: 0
      }
      dataInterface(param).then(res=>{
        if(res.data.code === 200){
          const {json} = res.data.data
          this.topoGraphData = json
          if(this.graph){
            this.graph.fromJSON(json)
            this.graph.centerContent()
            this.lockGraph()
          }
        }
      })
    },
    saveTaskFlow(){
      if(this.graph){
        const graphData = this.graph.toJSON()
        for (let cell of graphData.cells){
          if(cell.shape === 'task'){
            delete cell.component
          }
        }
        const param = {
          __method_name__: 'createData',
          object_uuid: 'object62a83e285860b',
          transcode: 0
        }
        if(this.topoGraphId){
          param.__method_name__ = 'updateData'
          param.data_id = this.topoGraphId
        }
        const saveData = {
          json: graphData,
          ...param
        }
        dataInterface(saveData).then(res=>{
          if(res.data.code === 200){
            this.$message.success('保存拓扑图成功')
          } else {
            this.$message.success('保存拓扑图失败')
          }
        })
      }
    },
    updateProps(data){
      if(this.selectedNode){
        if(this.nodeType === 'edge'){
          if(data.name){
            const label = {
              markup: [
                {tagName: 'rect', selector: 'body',},
                {tagName: 'text', selector: 'label',},
              ],
              attrs: {
                text: {
                  text: data.name,
                },
                label: {
                  fill: '#000',
                  fontSize: 14,
                  textAnchor: 'middle',
                  textVerticalAnchor: 'middle',
                  pointerEvents: 'none',
                },
                body: {
                  ref: 'label',
                  fill: '#ffd591',
                  stroke: '#ffa940',
                  strokeWidth: 2,
                  rx: 4,
                  ry: 4,
                  refWidth: '140%',
                  refHeight: '140%',
                  refX: '-20%',
                  refY: '-20%',
                },
              },
              zIndex:10
            }
            this.selectedNode.setLabels([label])
          } else {
            this.selectedNode.setLabels([])
          }
        } else if(this.nodeType === 'text-block'){
          let fontSize = parseInt(data.fontSize)
          const label = {
            style:{fontSize: fontSize,color:data.fontColor},
            text: data.name,
          }
          this.selectedNode.setAttrs({label: label})
        } else if(this.nodeType === 'rect'){
          let fontSize = parseInt(data.fontSize)
          const label = {
            style:{fontSize: fontSize,color:data.fontColor},
            text: data.name,
          }
          this.selectedNode.setAttrs({label: label})
        }
        this.selectedNode.updateData(data)
      }
    },
    initCanvas(){
      const self = this
      this.$nextTick(()=>{
        const container = this.$refs.container
        const minmap = this.$refs.minMap
        const graph = graphInitial(container,minmap)

        graph.on('node:selected', ({node})=>{
          this.key = uniqid()
          if(['start','end'].includes(node.shape)){
            self.propOpen = false
            self.configOpen = true
          } else {
            self.propOpen = true
            self.configOpen = false
            self.selectedNode = node
            self.currentItem = node.getData()
            self.currentNodeId = node.id
            self.nodeType = node.shape
          }
        })
        graph.on('node:click', ({node})=>{
          if(!self.editMode){
            this.key = uniqid()
            if(['start','end'].includes(node.shape)){
              self.propOpen = false
              self.configOpen = true
            } else {
              self.propOpen = true
              self.configOpen = false
              self.selectedNode = node
              self.currentItem = node.getData()
              self.currentNodeId = node.id
              self.nodeType = node.shape
            }
          }
        })

        graph.on('edge:click', ({edge})=>{
          this.key = uniqid()
          self.propOpen = true
          self.configOpen = false
          self.selectedNode = edge
          self.currentItem = edge.getData()
          self.currentNodeId = edge.id
          self.nodeType = edge.shape
        })

        graph.on('blank:click', ()=>{
          self.propOpen = false
          self.configOpen = true
          self.contextmenu.left = null;
        })

        // 拖拽实例化
        this.dnd = new Addon.Dnd({
          target: graph,
          scaled: false,
          animation: true,
        })
        this.graph = graph

        if(this.topologyId){
          this.getTopoDetail()
        }
      })
    },
    drag(e,nodeItem){
      let nodeData;
      switch (nodeItem.type){
        case 'dashed':
          nodeData = {
            width: 280,
            height: 150,
            attrs: {
              body: {
                stroke: '#cccccc',
                strokeDasharray: '5 3',
                fill: '#F7F9FF',
                rx: 10,
                ry: 10,
              },
            },
          }
          break
        case 'text':
          nodeData = {
            width: 120,
            height: 30,
            shape: 'text-block',
            text: `文本文字`,
            attrs: {
              body: {
                stroke: 'none',
                fill: 'none',
                rx: 4,
                ry: 4,
              },
            },
          }
          break
        case 'rect':
          nodeData = {
            width: 160,
            height: 80,
            label: '矩形文字',
            attrs: {
              body: {
                rx: 10,
                ry: 10,
              },
            },
            ports: { ...ports },
          }
          break
        case 'line':
          nodeData = {
            shape: 'polyline',
            width: 150,
            height: 10,
            attrs: {
              body: {
                stroke: '#000000',
                refPoints: '0,0  100,0',
                refY: 5,
              },
            },
          }
          break
        case 'task':
          nodeData = {
            shape: "task", width: 220, height: 160,
            data: {
              name: '任务标题',
              content: []
            }
          }
          break
      }
      const node = this.graph.createNode(nodeData)
      this.dnd.start(node, e)
    },
    onContextMenu(event) {
      event.preventDefault();
      event.stopPropagation();

      if (event.clientY + 360 < document.body.clientHeight) {
        this.contextmenu = {
          left: event.clientX + 'px',
          top: event.clientY + 'px'
        };
      } else {
        this.contextmenu = {
          left: event.clientX + 'px',
          bottom: document.body.clientHeight - event.clientY + 'px'
        };
      }
    },
    cancelMenu() {
      this.contextmenu.left = null;
    },
  },
  destroyed() {
    if(this.graph){
      this.graph.dispose()
      this.graph = null
    }
  }
}
</script>
<style lang="less">
.snapline{
  .x6-widget-snapline-horizontal{
    border-bottom:1px dashed #F57F17 !important;
  }
  .x6-widget-snapline-vertical{
    border-right:1px dashed #F57F17 !important;
  }
}
</style>
<style lang="less" scoped>
.context-menu {
  position: fixed;
  z-index: 100;
}
:deep(.el-tabs) {
  height: 100%;
  box-shadow: none;
  .el-tabs__content{
    padding: 0;
    height: calc(100% - 40px);
    .el-tab-pane{
      height: 100%;
    }
  }
}

.scrollbar-bar{
  overflow-y: auto;
  padding: 0 15px;
  height: calc(100% - 60px);
}

.iconc-close{
  position: absolute;
  font-size: 22px;
  top: 5px;
  right: 8px;
  z-index: 10;
  cursor: pointer;
}

.task-config-wrapper{
  width: 350px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  background: #ffffff;

  .conf-title{
    padding: 10px;
    border-bottom: 1px solid #eaebed;
    .iconc-close{
      float: right;
    }
  }
  .content {
    height: calc(100% - 105px);
  }
}

.task-flow-container{
  position: relative;
  width: 100%;
  height: calc(100% - 5px);
  border: 1px solid #eaebed;

  .action-bar{
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 2;
  }
  .graph-container{
    position: absolute;
    top: 100px;
    left: 20px;
    z-index: 101;
    width: 50px;
    padding: 20px 10px;
    text-align: center;
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 0 10px 1px rgb(228, 224, 219);

    .node-item {
      margin-bottom: 20px;
    }

    .node-item-icon {
      display: block;
      width: 30px;
      height: 30px;
      margin: auto;
      background-size: cover;
    }

    .node-label {
      font-size: 12px;
      line-height: 30px;
      user-select: none;
    }

    .node-dashed {
      background: url('./images/dash.png') no-repeat;
      background-size: cover;
    }
    .node-text {
      background: url('./images/text.png') no-repeat;
      background-size: cover;
    }
    .node-rect {
      background: url('./images/angle.png') no-repeat;
      background-size: cover;
    }
    .node-line {
      background: url('./images/line.png') no-repeat;
      background-size: cover;
    }

    .node-task {
      background: url('./images/task.png') no-repeat;
      background-size: cover;
    }
  }

  .task-block{
    width: 100%;
    height: 100%;
    background-color: #F7F9FF;
  }

}
</style>

