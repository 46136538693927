<template>
  <div class="wrapPanel" v-loading="isLoading">
    <div class="content-wrap" v-show="!isEdit">
      <div class="btnsUl horizontal-flex">
        <template v-for="item in tabBtns">
          <el-tooltip :key="item.id" :content="item.name" placement="bottom">
            <a :class="['iconfont', item.icon]" @click="onClickBtn(item)"></a>
          </el-tooltip>
        </template>

        <el-select
          v-model="viewFilterIndex"
          :popper-append-to-body="false"
          class="filter-wrap"
          placeholder="请选择"
          size="small"
          @change="onViewChange"
        >
          <el-option
            v-for="(item, index) in viewFilter"
            :key="index"
            :value="index"
            :label="item.name"
          ></el-option>
        </el-select>
      </div>

      <ViewPoint
        ref="viewPoint"
        :element.sync="element"
        :bindViewerId="bindViewerId"
        class="viewPoint-wrap"
      ></ViewPoint>
    </div>

    <Editor
      v-show="isEdit"
      class="editor-wrap"
      :infoList="viewerEditor"
      :cancelCallback="onCancelObj"
      :confirmCallback="onConfirmObj"
    ></Editor>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { Tooltip } from 'element-ui';
import ViewPoint from '../subitem/ViewPoint';
import Editor from '../subitem/Editor';

export default {
  name: 'ViewerGISScene',
  components: {
    'el-tooltip': Tooltip,
    ViewPoint,
    Editor
  },
  props: {
    element: {
      type: Object,
      required: true,
      default: () => {
        return {};
      }
    },
    // 是否在组合内
    isGroup: {
      type: Boolean
    },
    // 组合内组件列表
    groupComponents: {
      type: Array,
      default: () => []
    },
    bindViewerId: {
      type: String
    }
  },
  data() {
    return {
      isEdit: false,
      isLoading: false,
      tabBtns: [
        { id: 'addGroup', name: '添加分组', icon: 'iconxinzengfenlei' },
        { id: 'addObj', name: '添加视点', icon: 'iconbim_huidaojiaodian' }
      ],
      viewFilter: [
        { id: 'all', name: '所有数据' },
        { id: 'user_id', name: '由我创建' },
        { id: 'archi_id', name: '项目中的所有视图' }
      ],
      viewFilterIndex: 0,
      viewerEditor: []
    };
  },
  watch: {
    bindViewerId: {
      handler(newV) {
        this.$refs.viewPoint.getViewPointData(newV);
      }
    }
  },
  computed: {
    ...mapState(['curComponent'])
  },
  methods: {
    setIsLoading(isShow = false) {
      this.isLoading = isShow;
    },
    setIsEdit(isShow = false) {
      this.isEdit = isShow;
    },
    showViewerEditor() {
      let data = this.$refs.viewPoint.getEditorData();
      if (!data) return;

      this.viewerEditor = data;
      this.isEdit = true;
    },
    /**
     * @description: 表格头按钮点击事件
     * @param {String} item 按钮对象
     * @return Void
     */
    onClickBtn(item) {
      switch (item.id) {
        case 'addGroup': //添加组
          this.$refs.viewPoint.createViewPointGroup();
          break;
        case 'addObj': //添加对象
          this.showViewerEditor();
          break;
      }
    },
    /**
     * @description: 编辑页面取消按钮事件
     * @return Void
     */
    onCancelObj() {
      this.isEdit = false;
    },
    /**
     * @description: 编辑页面确定按钮事件
     * @param {String} list 编辑子项对象
     * @return Void
     */
    onConfirmObj(list) {
      let view = {};
      for (let item of list) {
        view[item.id] = item.value;
      }

      this.$refs.viewPoint.createViewPointObj(view);
    },
    /**
     * @description: 视图切换事件
     * @return Void
     */
    onViewChange() {
      const item = this.viewFilter[this.viewFilterIndex];

      this.$refs.viewPoint.setViewType(item.id);
    }
  }
};
</script>

<style lang="less" scoped>
.horizontal-flex {
  display: inline-flex;
  flex-direction: row;
  line-height: initial;
  vertical-align: middle;
}

.wrapPanel {
  float: left;
  padding: 0 !important;
  overflow-y: hidden;
  background-color: inherit;
  color: inherit;

  .content-wrap {
    width: 100%;
    height: 100%;
    background-color: inherit;
    color: inherit;
  }

  .btnsUl {
    width: 100%;
    height: 40px;
    line-height: 40px;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }

  .btnsUl a {
    padding-left: 10px;
    width: 30px;
    line-height: 40px;
    font-size: 18px;
  }

  .btnsUl a:hover {
    color: #0076ff;
  }

  .filter-wrap {
    width: 150px;
    margin-left: 15px;

    :deep(.el-input--small .el-input__inner) {
      height: 25px;
      line-height: 25px;
    }
  }

  .viewPoint-wrap {
    width: 100%;
    height: calc(100% - 40px);
  }

  .editor-wrap {
    width: 100%;
    height: 100%;
  }
}
</style>
