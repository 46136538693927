<!--
 * @Description: 统计表格-1
 * @Author: luocheng
 * @Date: 2022-04-07 16:25:29
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-01-12 10:17:12
-->
<template>
  <div class="common-stat-table-one">
    <div class="search-header">
      <RadioGroup v-model="type" @change="handleRadioChange">
        <template v-for="(item, index) in radioOptions" >
          <RadioButton :key="index" :label="item.value">{{ item.label }}</RadioButton>
        </template>
      </RadioGroup>
      &nbsp;&nbsp;
      <DatePicker
        v-model="time.start"
        @change="handleTimeStartChange"
        type="year"
        placeholder="起始时间"
        value-format="yyyy">
      </DatePicker>
      -
      <DatePicker
        v-model="time.end"
        @change="handleTimeEndChange"
        type="year"
        placeholder="截止时间"
        value-format="yyyy">
      </DatePicker>
    </div>
    <article class="stat-content">
      <el-table
        v-loading="tableLoading"
        :data="tableData"
        border
        show-summary
        :summary-method="summaryMethod"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" v-if="statusConfig.showSelect" width="55" :fixed="fixedColumn ? 'left' : false"/>
        <el-table-column prop="year" label="年"  :fixed="fixedColumn ? 'left' : false"/>
        <el-table-column prop="name" width="160" label="工程名称"   :fixed="fixedColumn ? 'left' : false"/>
        <el-table-column prop="01" label="1月份" />
        <el-table-column prop="02" label="2月份" />
        <el-table-column prop="03" label="3月份" />
        <el-table-column prop="04" label="4月份" />
        <el-table-column prop="05" label="5月份" />
        <el-table-column prop="06" label="6月份" />
        <el-table-column prop="07" label="7月份" />
        <el-table-column prop="08" label="8月份" />
        <el-table-column prop="09" label="9月份" />
        <el-table-column prop="10" label="10月份" />
        <el-table-column prop="11" label="11月份" />
        <el-table-column prop="12" label="12月份" />
        <el-table-column prop="summary" label="列合计" :fixed="fixedColumn ? 'right' : false" />
      </el-table>
    </article>
  </div>
</template>

<script>
import { RadioGroup, RadioButton, DatePicker  } from 'element-ui';
import { dataInterface } from '@/apis/data';
import eventBus from '@/plugins/eventBus';
import { initParams } from '@/utils/tools';
import { mapState } from 'vuex';
import databaseTriggerMixin from '@/custom-component/databaseTriggerMixin';

export default {
  name: 'CommonStatTableOne',
  components: {
    RadioGroup,
    DatePicker,
    RadioButton
  },
  mixins: [databaseTriggerMixin],
  props: {
    // 组件对象
    element: {
      type: Object,
      required: true,
      default: () => {}
    },
		// 是否为分组
		isGroup: {
			type: Boolean,
		},
		// 当前分组的组件数据
		groupComponents: {
			type: Array,
			default: () => []
		},
    componentList: {
      default: null
    }
  },
  computed: {
    ...mapState(['componentData']),
    // 组件配置
    statusConfig() {
      return this.element.statusConfig || {}
    },
    // 固定头尾列
    fixedColumn() {
      return this.statusConfig?.fixedColumn;
    },
    // 数据仓库
    database() {
      return this.element?.database
    }
  },
  watch: {
    // 监听配置对象来触发单选框组和检索条件
    'element.statusConfig': {
      handler(n) {
        if (n) {
          // 如果存在实际值和计划值
          if (n.planKey && n.actualKey) {
            this.radioOptions = [
              { label: '计划', value: n.planKey },
              { label: '实际', value: n.actualKey }
            ]
            // 默认为计划
            this.type = n.planKey
          }
          // 如果存在object_uuid和view_uuid获取数据
          if (n.object_uuid && n.view_uuid) {
            this.getData()
          }
        }
      },
      immediate: true,
      deep: true
    }
  },
  data() {
    return {
      type: '',
      time: {
        start: '',
        end: ''
      },
      tableData: [],
      // 源数据，切换时提供数据
      dataSource: [],
      radioOptions: [],
      tableLoading: false,
      archiType: ''
    }
  },
  created() {
    this.archiType = this.$GetTargetArchi('archiType');
    this.getData();
  },
  mounted () {
    // 监听请求
		// 配置关联参数的容器才需要监听
    const databaseTrigger = {
      [this.$elementId]: (data) => {
        if (data.parentId && data.parentId !== this.$elementId) return false;
        // 配置时候触发请求
        if (data.componentId === this.$elementId && data.isInit) {
          this.getData();
          return;
        }
        // 点击操作时候不更新数据
        if (data.noUpdate) return;
        const { paramsConfig } = this.element.database;
        if (!paramsConfig || !paramsConfig.length) return;
        let isTarget = false;
        paramsConfig.forEach((ele) => {
          if (ele.componentId === data.componentId) {
            isTarget = true;
          }
        });
        if (!isTarget && !data.isUpdate) return;
        this.getData();
      }
    };
		eventBus.$on('databaseTrigger', databaseTrigger[this.$elementId]);
  },
  methods: {
    // 事件change事件
    handleTimeStartChange(v) {
      if (isNaN(v) && this.time.end) {
        return
      }
      if (!this.time.start && !this.time.end) {
        this.getData()
        return
      }
      if (v && this.time.end) {
        if (Number(v) > Number(this.time.end)) {
          const temp = this.time.end
          this.time.end = this.time.start
          this.time.start = temp
        }
        this.getData()
      }
    },
    handleTimeEndChange(v) {
      if (isNaN(v) && this.time.start) {
        return
      }
      if (!this.time.start && !this.time.end) {
        this.getData()
        return
      }
      if (v && this.time.start) {
        if (Number(v) < Number(this.time.start)) {
          const temp = this.time.start
          this.time.start = this.time.end
          this.time.end = temp
        }
        this.getData()
      }
    },
    // 获取数据
    getData() {
      let params = {
        __method_name__: 'dataList',
        transcode: 0,
        object_uuid: this.database?.objectData?.uuid,
        view_uuid: this.database?.viewData?.uuid,
        search: []
      }
      // 如果存在时间检索条件
      if (this.time && this.time.start && this.time.end) {
        params.search.push({
          code: 'year',
          ruleType: 'gte',
          value: this.time.start  + '-01-01 00:00:00'
        })
        params.search.push({
          code: 'year',
          ruleType: 'lte',
          value: this.time.end  + '-12-31 23:59:59'
        })
      }
      const { search = [], param = {}, canPost } = initParams(this.element?.database?.paramsConfig || [], this.isGroup, this.componentList || this.componentData, this.groupComponents);
      if (!canPost) return;
      params = {
        ...params,
        ...param,
        search: params.search.concat(search)
      }
      this.tableLoading = true
      dataInterface(params).then(res => {
        if (res.data.code === 200) {
          this.dataSource = res.data.data
          this.initTableData(res.data.data)
        } else {
          this.tableData = []
          this.dataSource = []
        }
      }).finally(() => {
        this.tableLoading = false
      })
    },
    // 单选按钮change事件
    handleRadioChange() {
      // 如果源数据的长度不等于0
      if (this.dataSource.length) {
        this.tableLoading = true
        this.initTableData(this.dataSource)
        this.$nextTick().then(() => {
          this.tableLoading = false
        })
      }
    },
    // 生成表格数据
    initTableData(data) {
      // 使用哈希存储信息，便于查找
      const years = {}
      data.map(el => {
        // 以年和项目名称作为键值存放数据
        if(years[el.new_year]) {
          if (years[el.new_year][el.project_name]) {
            years[el.new_year][el.project_name][el.new_month] = el[this.type] ? el[this.type]: 0
          } else {
            years[el.new_year][el.project_name] = {
            year: el.new_year,
            name: el.project_name,
            '01': 0,
            '02': 0,
            '03': 0,
            '04': 0,
            '05': 0,
            '06': 0,
            '07': 0,
            '08': 0,
            '09': 0,
            '10': 0,
            '11': 0,
            '12': 0
          }
          years[el.new_year][el.project_name][el.new_month] = el[this.type] ? el[this.type]: 0
          }
        } else {
          years[el.new_year] = {
            year: el.new_year
          }
          years[el.new_year][el.project_name] = {
            year: el.new_year,
            name: el.project_name,
            '01': 0,
            '02': 0,
            '03': 0,
            '04': 0,
            '05': 0,
            '06': 0,
            '07': 0,
            '08': 0,
            '09': 0,
            '10': 0,
            '11': 0,
            '12': 0
          }
          years[el.new_year][el.project_name][el.new_month] = el[this.type] ? el[this.type]: 0
        }
      })
      // 转为数组
      const list = Object.values(years)
      // 将年份列表排序
      list.sort((a, b) => b.year - a.year)
      const res = []
      // 添加小计
      list.map(el => {
        for (const key in el) {
          if (Object.hasOwnProperty.call(el, key) && key !== 'year') {
            res.push(el[key])
          }
        }
      })
      this.setTableData(res)
    },
    // 需要对原数据进行处理才可以显示行合计与计算列合并
    setTableData (data) {
      let preKey = ''
      data.map(el => {
        // 行合计
        const keys = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
        el.summary = 0
        keys.map(ele => {
          el.summary += isNaN(el[ele]) ? 0 : Number(el[ele])
        })
        // 获取列合并
        if (preKey !== el.year) {
          // 如果不是同一年
          preKey = el.year
          // 合并列为相同的年
          el.colsapn = data.filter(ele => ele.year === el.year).length
        } else {
          // 已合并，合并列为0
          el.colsapn = 0
        }
      })
      this.tableData = data
    },
    // 计算列合并
    // 由于列表小计，计算后得到的数据为脏数据，暂时关闭，
    summaryMethod(param) {
      const { columns, data } = param
        const sums = []
        columns.forEach((column, index) => {
          // 第一行是多选框
          if (index === 0) {
            sums[index] = '合计'
            return
          }
          // 第二行是年度
          if (index === (+this.statusConfig.showSelect)) {
            sums[index] = '-'
            return
          }
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
          } else {
            sums[index] = '-'
          }
        })
        return sums
    },
    // 合并列 已不需要合并
    spanMethod({ row, columnIndex }) {
      if (columnIndex === (+this.statusConfig.showSelect)) {
        if (row.colsapn) {
          return [row.colsapn, 1]
        } else {
          return [0, 0]
        }
      }
    },
    /**
		 * @desc: 选择表格
		 * @param {Array} selectList
		 */
		handleSelectionChange(selectList) {
			this.element.resolveData = selectList || [];
		},
  }
}
</script>

<style lang="less" scoped>
@headerHeight: 45px;
@border: rgb(221, 222, 221);
.common-stat-table-one{
  .search-header{
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 5px;
    box-sizing: border-box;
    .el-input{
      width: 220px;
    }
  }
  .stat-content{
    height: calc(100% - 50px);
    padding: 0 5px;
    box-sizing: border-box;
  }
  .ellipsis{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>