<!--
 * @Description: 时间轴
 * @componentDesc: 可以设置X,Y轴，以及左侧右侧以及均匀分布item
 * @props: 
-->

<template>
    <div id="axisCont" class="gantt-milepost">
        <div class="flex-between">
            <p class="second-title">
                {{ listConfig?.title || "" }}
            </p>
            <div v-if="caseList.length && !listConfig.isBaseBg" style="display: flex">
                <p
                    v-for="(item, index) in caseList"
                    :key="index"
                    style="display: flex;align-items: center;margin-right: 10px;"
                >
                    <span class="small-cicle" :style="{ backgroundColor: item.color }"/> 
										<span style="font-size: 14px; color: #a9a9a9">{{item?.label || ''}}</span>
                        
                    
                </p>
            </div>
        </div>
        <div class="no-scroll-bar w-full relative" :class="getClass">
            <div
                v-if="blockData.length != 0"
                class="h-full inner-cont"
                :class="[is_moving ? 'pointer-none' : '', listConfig.axisDirection === 'x' ? 'flex-row': 'flex-column',]"
                @mousedown="mousedown($event)"
                :style="{ transform: `translate(${totalDistance}px, 0px)` }"
            >
                <div
                    v-for="(item, index) in blockData"
                    :key="index"
                    :data="item.id"
                    class="inner-item"
                    :style="getContStyle(index)"
                >
                    <div :style="getBlockStyle(item)" class="block" :class="[listConfig.centerDot? 'middle-dot':'',listConfig.isDashed?'dashed-block':'']">
                        <div v-for="(val, key, i) in item" :style="getOutStyle(val,index)" :key="i">
                            <!-- 图片 -->
                            <el-image
                                :style="{
                                    width: val.imgConfig.width + 'px',
                                    height: val.imgConfig.height + 'px',
                                }"
                                v-if="+val.type === 2 && getImageState(val.value)"
                                fit="cover"
                                :preview-src-list="srcList(val.value)"
                                :src="val.value[0].path"
                            />
                            <!-- 状态 -->
                            <p v-if="+val.type === 3" :style="{textAlign:listConfig.textAlign?listConfig.textAlign : 'left'}"  class="font-style">
                                <span v-show="key && !key.includes('null')">{{ key }}：</span>{{val.statusOptions.filter((v) => +v.value === +val.value)[0]?.label || '-'}}
                            </p>
                            <!-- 文字 -->
                            <div
                                v-if="+val.type === 1"
                                class="long-text w-full"
                            >
                                <span v-show="key && !key.includes('null')">{{ key }}：</span> {{ !val.value ? "-" : val.value }}
                            </div>
                        </div>
                        <span :style="getDotBg(item)" class="dot"></span>
                        <!-- //三角 -->
                        <div v-if="listConfig.centerDot && listConfig?.axisDirection === 'x'" class="triangle-box">
                            <div :style="{'--background':getTriangleBg(item)}" class="triangle"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { dataInterface } from "@/apis/data";
import eventBus from "@/plugins/eventBus";
import { initParams } from "@/utils/tools";
import { Image } from "element-ui";

export default {
    name: "AxisList",
    props: {
        element: {
            type: Object,
            required: true,
            default: () => {},
        },
        // 是否为预览
        isPreview: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    components: {
        "el-image": Image,
    },
    data() {
        return {
            blockData: [],
            is_moving: false,
            distance: 0, // 拖动距离
            sb_bkx: 0,
            totalDistance: 0,
            loading: false,
            showFieldList: [],
            caseList: [],
        };
    },
    mounted() {
        if (this.element.fieldConfig?.length) {
            this.element.fieldConfig.forEach((item) => {
                if (item.show) {
                    if (item.type === 3 && item.statusOptions?.length) {
                        item.statusOptions.forEach((val) => {
                            let it = val;
                            this.$set(it, "uuid", item.uuid);
                            if(!this.element.listConfig.isBaseBg){
                                this.caseList.push(it);
                            }
                        });
                    }
                    this.showFieldList.push(item);
                }
            });
        }
        eventBus.$on("databaseTrigger", (config) => {
            if (config.componentId.includes("AxisList")) {
                this.getData();
            }
        });
        this.getData();
    },
    computed: {
        // 组件属性
        statusConfig() {
            return this.element?.statusConfig;
        },
        //参数配置
        listConfig() {
            return this.element?.listConfig || null;
        },
        getClass() {
            let className = "";
            if (this.listConfig?.axisDirection) {
                className = className +` axisDirection-${this.listConfig.axisDirection}`;
            }
            if (this.listConfig?.itemPosition) {
                className = className + ` position-${this.listConfig.itemPosition}`;
            }
            return className;
        },
        itemWidth() {
            return this.element?.listConfig?.itemWidth || 150;
        },
        itemHeight() {
            return this.element?.listConfig?.itemHeight || 150;
        },
        itemBg() {
            return this.element?.listConfig?.itemBg || "#fff";
        },
        topFields() {
            return this.element?.listConfig?.topFields || [];
        },
        getBlockStyle() {
            return function (param) {
                let data = Object.values(param)
                let style = {
                    width: this.itemWidth + "px",
                    height: this.itemHeight + "px",
                    backgroundColor:this.element.listConfig.isBaseBg?this.itemBg : '#fff',
                    color:this.element.listConfig.itemCol?this.element.listConfig.itemCol:'#000000',
                };
                if (data.length) {
                  for (let i=0; i<data.length; i++) {
                    let item = data[i]
                    if (+item.type === 3 &&  item.statusOptions?.length) {    
                      let filterData = item.statusOptions.filter((v) => +v.value === +item.value);
                      if (filterData.length && !this.element.listConfig.isBaseBg) {
                          style.backgroundColor = filterData[0].color;
                          break;
                      }
                    }
                  }
                }
                return style;
            };
        },
        getContStyle() {
            return function (i) {
                let style = {};
                if (this.listConfig?.axisDirection === "y") {
                    style.transform = `translate(${(0, this.distance)}px)`;
                    if (this.listConfig.itemPosition === "between") {
                        if (i % 2 === 0) {
                            style.marginLeft = (Number(this.itemWidth) + 46) * 0.5 + "px";
                        } else {
                            style.marginLeft = -(Number(this.itemWidth) + 46) * 0.5 + "px";
                        }
                    }
                } else {
                    style.transform = `translate(${this.distance}px, 0px)`;
                    if (this.listConfig.itemPosition === "between") {
                        if (i % 2 === 0) {
                            style.marginTop = Number(this.itemHeight) + 46 + "px";
                        } else {
                            style.marginTop = 0;
                        }
                    }
                }
                return style;
            };
        },
        getDotBg() {
          return function (item) {
            if (!this.element.listConfig.isBaseBg) {
              for (let key in item) {
                if (+item[key].type === 3 && item[key].statusOptions?.length) {
                  let filterData = item[key].statusOptions.filter((v) => +v.value === +item[key].value);
                  if (filterData.length) {
                      return { backgroundColor: filterData[0].color };
                  }
                }
              }
            } else {
                if (this.element.listConfig.itemBg.includes('rgba')) {
                    let arr = this.element.listConfig.itemBg.split(',')
                    return { backgroundColor: `${arr[0]},${arr[1]},${arr[2]},1)` }
                } else {
                    return { backgroundColor: this.element.listConfig.itemBg };
                }
            }
            return "";
          };
        },
        getTriangleBg(){
          return function (item) {
            if (!this.element.listConfig.isBaseBg) {
              for (let key in item) {
                if (+item[key].type === 3 && item[key].statusOptions?.length) {
                    let filterData = item[key].statusOptions.filter((v) => +v.value === +item[key].value);
                    if (filterData.length) {
                        return filterData[0].color
                    }
                }
              }
            } else {
              return this.element.listConfig.itemBg 
            }
            return "";
          };
        },
        getImageState() {
            return function (data) {
                if (Array.isArray(data) && data.length && data[0].path) {
                    return true;
                }
                return false;
            };
        },
        srcList() {
            return function (data) {
                let arr = [];
                data.forEach((item) => {
                    if (item.url) {
                        arr.push(item.url);
                    }
                });
                return arr;
            };
        },
        getOutStyle(){
          return function(val,j){
            let obj ={textAlign:this.listConfig?.textAlign ? this.listConfig?.textAlign :'left',}
            if(this.topFields.length && this.listConfig?.axisDirection === "x"){
              this.topFields.forEach((item,i)=>{
                if(item === val.uuid){
                  obj.position = 'absolute';
                  if(this.listConfig.itemPosition === "between"){
                    obj.width = (Number(this.itemWidth) + 40) + 'px';
                    obj.left = '-20px';
                    if(j % 2 === 0){
                      obj.top = `${(i+1)*25  + 10}px`;
                    }else{
                      obj.bottom = `${(i+1)*25 + 10}px`;
                    }
                  } else if(this.listConfig.itemPosition === "left"){
                    obj.width = this.itemWidth + 'px';
                    obj.top = `${(i+1)*25  + 10}px`;
                  } else {
                    obj.width = this.itemWidth + 'px';
                    obj.bottom = `${(i+1)*25 + 10}px`;
                  }
                  
                  return obj
                }
              })
            }
            return obj
          }
        }
    },
    methods: {
        /**
         * @desc: 获取数据
         */
        getData() {
            let database = this.element.database;
            let { canPost } = initParams();
            if (!canPost) {
                return;
            }
            if (
                database &&
                database.requestType &&
                database.objectData?.uuid &&
                database.viewData?.uuid
            ) {
                const reqData = {
                    object_uuid: database.objectData.uuid,
                    view_uuid: database.viewData.uuid,
                    __method_name__: database.requestType,
                    size: 1000,
                };
                this.loading = true;
                dataInterface(reqData)
                    .then((res) => {
                        if (res.data.code === 200) {
                            if (
                                res.data.data &&
                                Array.isArray(res.data.data) &&
                                res.data.data.length
                            ) {
                                this.init(res.data.data);
                            } else if (
                                res.data.data?.data &&
                                Array.isArray(res.data.data.data) &&
                                res.data.data.data.length
                            ) {
                                this.init(res.data.data.data);
                            }
                            if (!this.isPreview) {
                                this.$store.commit("modifyComponent", {
                                    component: {
                                        ...this.element,
                                        metadata: res.data.metadata,
                                    },
                                    containerId: null,
                                    isModify: true,
                                });
                            }
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        init(data) {
            this.blockData = [];
            if (data?.length) {
                for (let i = 0; i < data?.length; i++) {
                    let obj = {};
                    if (this.showFieldList?.length) {
                        for (let j = 0; j < this.showFieldList.length; j++) {
                            this.$set(obj, this.showFieldList[j].label, {
                                value: data[i][this.showFieldList[j].uuid],
                                type: this.showFieldList[j].type,
                                statusOptions:this.showFieldList[j].statusOptions,
                                imgConfig: this.showFieldList[j].imgConfig,
                                uuid:this.showFieldList[j].uuid
                            });
                        }
                    }
                    this.blockData.push(obj);
                }
            }
        },
        mousedown(event) {
            if (this.listConfig?.axisDirection === "y") {
                return;
            }
            var startx = event.x;
            this.sb_bkx = startx - event.target.offsetLeft;
            this.is_moving = true;
            document.onmousemove = (ev) => {
                var endx = ev.x - this.sb_bkx;
                if (this.is_moving) {
                    if (
                        this.totalDistance + endx > 0 ||
                        Math.abs(this.totalDistance + endx) >
                            this.blockData.length * (this.itemWidth + 10)
                    ) {
                        // 只能拖动指定长度
                        this.totalDistance = 0;
                        this.distance = 0;
                    } else {
                        this.distance = endx;
                    }
                }
            };
            // eslint-disable-next-line
            document.onmouseup = (ev) => {
                const mo =
                    Math.floor(
                        Math.abs(this.distance) / (this.itemWidth + 10)
                    ) + 1;
                if (mo < this.blockData.length) {
                    this.totalDistance = this.totalDistance + this.distance;
                    this.distance = 0;
                }
                this.is_moving = false;
            };
        },
    },
    beforeDestroy() {
        eventBus.$off("databaseTrigger");
    },
};
</script>

<style lang="less" scoped>
.gantt-milepost {
		user-select: none;
    height: 155px;
    padding: 16px;
    padding-bottom: 0;
    box-sizing: border-box;
    .no-scroll-bar::-webkit-scrollbar {
        display: none; /* Chrome Safari */
    }
    .begin-line {
        width: 4px;
        height: 102px;
        position: absolute;
        background-color: #409eff;
        left: 0;
        z-index: 100;
    }
    .inner-cont {
        width: auto;
        display:flex!important;
        word-break: keep-all;
        text-overflow: ellipsis;
        white-space: nowrap;
        transform: translate(0px, 0px);
        height: 100%;
        display: inline-block;
        vertical-align: top;
    }
    .block {
        position: relative;
        padding: 5px;
        height: auto;
        cursor: pointer;
        border-radius: 2px;
    }
    .block-before {
        position: absolute;
        content: "";
        width: 100%;
        height: 4px;
        opacity: 1;
        background: #2ebf76;
        top: 0;
        left: 0;
    }

    .small-cicle {
        display: inline-block;
        width: 12px;
        height: 12px;
        opacity: 1;
        border-radius: 50%;
        margin-right: 5px;
    }

    .second-title {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
    }

    .flex-between {
        display: flex;
        justify-content: space-between;
        height: 24px;
    }
    .pointer-none {
        pointer-events: none;
    }
    .font-style {
        line-height: 19px;
        padding: 0 5px;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .long-text {
        max-height: 60px;
        font-size: 14px;
        line-height: 19px;
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        white-space: pre-wrap;
        word-break: normal;
        word-wrap: break-word;
    }
    .dot{
        z-index: 10;
    }
    .triangle-box {
      position:absolute;
      .triangle{
        position: relative;
        width: 18px;
        height: 18px;
        overflow: hidden;
        transform: rotate(45deg);
        &:before{
          content: '';
          display: block;
          width: 24px;
          height: 27px;
          transform: rotate(45deg) translateX(25%);
          background: linear-gradient(to left,var(--background), #fff);
        }
      }
        
    }
}
</style>
<style lang="less" scoped>
//x轴的显示样式
.axisDirection-x {
    overflow-x: auto;
    overflow-y: hidden;
    padding:40px 0;
    display: flex;
    flex-direction: row;
    .inner-item {
        margin: 25px 0px 25px 10px;
    }
    //虚线
    .dashed-block::after{
        background: linear-gradient(to left,transparent 0%,transparent 50%,#ccc 50%,#ccc 100%)!important;
        background-size: 22px 5px!important;
        background-repeat: repeat-x!important;
    }
}

// y轴显示样式
.axisDirection-y {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    .dashed-block::after{
        background: linear-gradient(to top,transparent 0%,transparent 50%,#ccc 50%,#ccc 100%)!important;
        background-size: 5px 24px!important;
        background-repeat: repeat-y!important;
    }
    .inner-cont {
        width: 100%;
        .inner-item {
            width: 100%;
            padding: 10px 0;
            .block {
                margin: auto;
            }
            .inline {
                width: 4px;
                height: calc(100% + 20px);
                top: -10px;
                left: -15px;
            }

            // 单右侧样式
        }
    }
}
.axisDirection-y.position-right {
    //单左侧的样式
    .block::after {
        content: "";
        width: 4px;
        height: calc(100% + 4px);
        background: rgba(0, 0, 0, 0.2);
        position: absolute;
        top: 14px;
        left: -18px;
    }
    .dot {
        display: inline-block;
        width: 12px;
        height: 12px;
        opacity: 1;
        border-radius: 50%;
        background: rgba(0, 0, 0, 1);
        position: absolute;
        top: 0;
        left: -22px;
    }
    .middle-dot::after {
        height: calc(100% + 14px)!important;
        top: 0!important;
    }
    .middle-dot .dot {
        top: 50%!important;
        margin-top:-6px!important;
    }
}
.axisDirection-y.position-left {
    //单左侧的样式
    .block::after {
        content: "";
        width: 4px;
        height: calc(100% + 4px);
        background: rgba(0, 0, 0, 0.2);
        position: absolute;
        top: 14px;
        right: -18px;
    }
    .dot {
        display: inline-block;
        width: 12px;
        height: 12px;
        opacity: 1;
        border-radius: 50%;
        background: rgb(0, 0, 0);
        position: absolute;
        top: 0;
        right: -22px;
    }
    .middle-dot::after {
        height: calc(100% + 14px)!important;
        top: 0!important;
    }
    .middle-dot .dot {
        top: 50%!important;
        margin-top:-6px!important;
    }
}
.axisDirection-y.position-between {
    .inner-item:nth-child(2n) {
        position: relative;
        .block::after {
            content: "";
            width: 4px;
            height: calc(100% + 4px);
            background: rgba(0, 0, 0, 0.2);
            position: absolute;
            top: 14px;
            right: -22px;
        }
        .dot {
            display: inline-block;
            width: 12px;
            height: 12px;
            opacity: 1;
            border-radius: 50%;
            background: rgba(0, 0, 0, 1);
            position: absolute;
            top: 0;
            right: -26px;
        }
        .middle-dot::after {
            height: calc(100% + 20px)!important;
            top: 0!important;
        }
        .middle-dot .dot {
            top: 50%!important;
            margin-top:-6px!important;
        }
    }
    .inner-item:nth-child(2n + 1) {
        position: relative;
        .block::after {
            content: "";
            width: 4px;
            height: calc(100% + 4px);
            background: rgba(0, 0, 0, 0.2);
            position: absolute;
            top: 14px;
            left: -18px;
        }
        .dot {
            display: inline-block;
            width: 12px;
            height: 12px;
            opacity: 1;
            border-radius: 50%;
            background: rgba(0, 0, 0, 1);
            position: absolute;
            top: 0px;
            left: -22px;
        }
        .middle-dot::after {
            height: calc(100% + 20px)!important;
            top: 0!important;
        }
        .middle-dot .dot {
            top: 50%!important;
            margin-top:-6px!important;
        }
    }
}

.axisDirection-x.position-right {
    // 上侧显示的样式
    .block::after {
        content: "";
        height: 4px;
        width: calc(100% - 6px);
        background: rgba(0, 0, 0, 0.2);
        position: absolute;
        bottom: -18px;
        left: 14px;
    }
    .dot {
        display: inline-block;
        width: 12px;
        height: 12px;
        opacity: 1;
        border-radius: 50%;
        background: rgba(0, 0, 0, 1);
        position: absolute;
        bottom: -22px;
        left: 0;
    }
    .middle-dot .triangle-box {
        bottom: -11px;
        left: 50%!important;
        margin-left:-9px!important;
        .triangle{
          transform: rotate(45deg);
        }
    }
    .middle-dot::after {
        width: calc(100% + 7px)!important;
        bottom: -20px!important;
        left: 0!important;
    }
    .middle-dot .dot {
        left: 50%!important;
        margin-left:-6px!important;
    }
}
.axisDirection-x.position-left {
    //下册显示的样式
    .block::after {
        content: "";
        height: 4px;
        width: calc(100% - 6px);
        background: rgba(0, 0, 0, 0.2);
        position: absolute;
        top: -18px;
        left: 14px;
    }
    .dot {
        display: inline-block;
        width: 12px;
        height: 12px;
        opacity: 1;
        border-radius: 50%;
        background: rgba(0, 0, 0, 1);
        position: absolute;
        top: -22px;
        left: 0;
    }
    .middle-dot .triangle-box {
        top: -11px;
        left: 50%!important;
        margin-left:-9px!important;
        .triangle{
          transform: rotate(-135deg);
        }
    }
    .middle-dot::after {
        width: calc(100% + 7px)!important;
        bottom: -20px!important;
        left: 0!important;
    }
    .middle-dot .dot {
        left: 50%!important;
        margin-left:-6px!important;
    }
}
.axisDirection-x.position-between {
    .inner-item:nth-child(2n) {
        margin-top: 0;
        position: relative;
        .block::after {
            content: "";
            height: 4px;
            width: calc(100% - 6px);
            background: rgba(0, 0, 0, 0.2);
            position: absolute;
            bottom: -20px;
            left: 14px;
        }
        .dot {
            display: inline-block;
            width: 12px;
            height: 12px;
            opacity: 1;
            border-radius: 50%;
            background: rgba(0, 0, 0, 1);
            position: absolute;
            left: 0;
            bottom: -24px;
        }
        .middle-dot .triangle-box {
            bottom: -12px;
            left: 50%!important;
            margin-left:-9px!important;
            .triangle{
              transform: rotate(45deg);
            }
        }
        .middle-dot::after {
            width: calc(100% + 10px)!important;
            bottom: -20px!important;
            left: 0!important;
        }
        .middle-dot .dot {
            left: 50%!important;
            margin-left:-6px!important;
        }
    }
    .inner-item:nth-child(2n + 1) {
        // margin-top:106px ;
        position: relative;
        .block::after {
            content: "";
            height: 4px;
            width: calc(100% - 6px);
            background: rgba(0, 0, 0, 0.2);
            position: absolute;
            top: -20px;
            left: 14px;
        }
        .dot {
            display: inline-block;
            width: 12px;
            height: 12px;
            opacity: 1;
            border-radius: 50%;
            background: rgb(0, 0, 0);
            position: absolute;
            left: 0;
            top: -24px;
        }
        .middle-dot .triangle-box {
            top: -12px;
            left: 50%!important;
            margin-left:-9px!important;
            .triangle{
              transform: rotate(-135deg);
            }
        }
        .middle-dot::after {
            width: calc(100% + 10px)!important;
            bottom: -20px!important;
            left: 0!important;
        }
        .middle-dot .dot {
            left: 50%!important;
            margin-left:-6px!important;
        }
    }
}
</style>>
