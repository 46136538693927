var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ul',{staticClass:"vertical-flex moduleUl"},_vm._l((_vm.moduleList),function(item,index){return _c('li',{key:index,class:[
        'vertical-flex moduleLi',
        { moduleLi_active: _vm.activeModule === item.id }
      ],on:{"click":function($event){return _vm.onSelectModule(item)}}},[_c('a',{class:['iconfont', item.icon]}),_c('span',[_vm._v(_vm._s(item.name))])])}),0),_c('div',{staticClass:"modulePanel"},[_c('ul',{staticClass:"tabUl"},_vm._l((_vm.tabList),function(item,index){return _c('li',{key:index,class:[
          'vertical-flex tabLi',
          { tabLi_active: _vm.activeTab === item.id }
        ]},[_c('a',{class:['iconfont', item.icon]}),_c('span',[_vm._v(_vm._s(item.name))])])}),0),_c('ViewerGISModel',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeModule === 'model' && _vm.activeTab === 'modelTree'),expression:"activeModule === 'model' && activeTab === 'modelTree'"}],ref:"modelList",staticClass:"moduleWrap",attrs:{"element":_vm.element,"bindViewerId":_vm.bindViewerId},on:{"update:element":function($event){_vm.element=$event}}}),_c('ViewerGISScene',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeModule === 'scene' && _vm.activeTab === 'viewPoint'),expression:"activeModule === 'scene' && activeTab === 'viewPoint'"}],ref:"sceneList",staticClass:"moduleWrap",attrs:{"element":_vm.element,"bindViewerId":_vm.bindViewerId},on:{"update:element":function($event){_vm.element=$event}}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }