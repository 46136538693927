<!--
    @name: ques-radio
    @description：ques-radio
    @author: ZengWei
    @date: 2022-04-01 11:17
-->
<template>
  <div class="widget-signature">
    <div class="q-title-wrap" v-if="!element.config.__ques__.showTitle">
      <div class="seq-num">
        <span v-if="element.config.__config__.required" class="required">*</span>
      </div>
      <div class="q-title">
        {{ element.config.__config__.label }}
      </div>
      <div class="q-title" v-html="element.config.__ques__.quesDesc"></div>
    </div>
    <div class="q-option-list">
      <el-popover placement="right" width="100">
        <el-button plain slot="reference" @click="generateQrcode">
          <i class="iconfont iconfenbaoshenhe"></i>&nbsp;&nbsp;手写签名
        </el-button>
        <div style="text-align: center">
          <img v-if="qrCode" :src="qrCode" alt width="100" height="100" />
          <p>请打开手机扫一扫</p>
        </div>
      </el-popover>

      <div>
        <el-image
          v-for="(item,index) in element.value"
          :key="index"
          style="width: 100px; height: 100px;margin: 15px"
          :src="item"
          fit="contain"
          :preview-src-list="[item]">
        </el-image>
      </div>
    </div>
  </div>
</template>

<script>
import {Popover} from "element-ui";
import quesWidgetMinix from "@/custom-component/form/quesParser/scripts/quesWidgetMinix";
import {formRequest} from "@/apis/home";

export default {
  name: "ques-signature",
  components: {
    'el-popover': Popover
  },
  mixins:[quesWidgetMinix],
  props: ['element','number'],
  computed:{},
  data() {
    return {
      qrCode: '',
      qrToken: '',
      timer: null,
    }
  },
  methods: {
    generateQrcode() {
      if(!this.qrCode){
        clearInterval(this.timer);
        const url = '/api/generate/signature'
        formRequest('post', url ,{})
          .then(res=>{
            if (res.data.code === 200) {
              this.qrCode = res.data.data.qrcode;
              this.qrToken = res.data.data.token;
              let that = this;
              this.timer = setInterval(() => {
                that.getAppSignature();
              }, 1000);
            }
          })
      }
    },
    getAppSignature() {
      const data = {qrToken: this.qrToken}
      const url= '/api/generate/signature'
      formRequest('get', url, data)
        .then((res) => {
          if (res.data.code === 200) {
            this.signImg = res.data.data.signature;
            if (this.signImg !== null) {
              this.element.value.push(this.signImg);
              clearInterval(this.timer);
            }
          }
        });
    },
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/assets/css/questionItem';
</style>
