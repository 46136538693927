<!--
 * @Author: xjq damarkday@163.com
 * @Date: 2022-06-28 17:44:08
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2022-12-22 11:24:19
 * @Description: 
-->
<template>
	<div class="wrap" @click="jumpTo" v-if="statusConfig">
		<div :class="`wrapper ${statusConfig.type === `default` ? `flex-col` : `flex-row`}`">
			<div>
				<!-- icon -->
				<div class="mini-icon-container">
					<img :src="statusConfig.miniIconUrl" :alt="statusConfig.title" class="img" />
				</div>

				<!-- text -->
				<div class="title">
					<span class="label">{{ statusConfig.title || '--' }}</span>
				</div>
				<div class="description">
					<span class="label">{{ statusConfig.description }}</span>
				</div>
			</div>

			<!-- icon -->
			<div class="large-icon-container">
				<img :src="statusConfig.largeIconUrl" :alt="statusConfig.title"
					:class="statusConfig.type === `default` ? `col` : `row`" />
			</div>
		</div>

	</div>
</template>

<script>
import cardMixin from './cardMixin';

export default {
	name: 'LargeCard',
	props: {
		element: {
			type: Object,
			required: true.valueOf,
			default: () => {}
		}
	},
	mixins: [cardMixin],
	computed: {
		statusConfig() {
			return this.element?.statusConfig || {};
		}
	}
};
</script>

<style lang="less" scoped>
.wrap {
	background: #ffffff;
	border-radius: 11px;
	box-sizing: border-box;
	cursor: pointer;

	.flex-row {
		flex-direction: row;
		justify-content: space-between
	}

	.flex-col {
		flex-direction: column
	}

	.wrapper {
		display: flex;
		padding: 16px 26px;

		.mini-icon-container {
			display: flex;

			.img {
				height: 70px;
				width: 62px
			}
		}


		.title {
			justify-content: flex-start;
			display: flex;
			margin: 5px 0;

			.label {
				font-style: normal;
				font-weight: 700;
				font-size: 24px;
				line-height: 36px;
				text-align: left;
				letter-spacing: 0.05em;
				color: #272829;
			}
		}

		.description {
			width: 125px;
			height: 18px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;

			.label {
				font-weight: 400;
				font-size: 12px;
				line-height: 18px;
				letter-spacing: 0.05em;
				color: #888888;
			}
		}

		.large-icon-container {
			overflow: hidden;

			.col {
				width: 204px;
				height: 203px;
			}

			.row {
				width: 201px;
				height: 157px;
			}
		}
	}
}
</style>