<!--
    @name: KnowDialog
    @description：KnowDialog
    @author: ZengWei
    @date: 2022-04-28 15:14
-->
<template>
  <div class="global-dialog global-dialog-two">
    <el-dialog
      :title="title"
      custom-class="global-dialog-overscroll"
      :visible.sync="showDialog"
      :before-close="onFormSubmit"
      width="60%">

      <template v-if="detailData">
        <KnowDetail
          v-if="tag === 'repository_new'"
          :data="detailData"
        ></KnowDetail>
        <FileFlowDetail
          v-if="tag === 'repository_file_inspect'"
          :data="detailData"
        ></FileFlowDetail>
        <KnowPageDetail
          v-if="tag === 'repository_page'"
          :data="detailData"
        ></KnowPageDetail>
      </template>

      <div v-if="loading" v-loading="true" style="height:200px"></div>
    </el-dialog>
  </div>
</template>

<script>
import FileFlowDetail from "@/components/global/knowledge/FileFlowDetail";
import KnowDetail from "@/components/global/knowledge/KnowDetail";
import KnowPageDetail from "@/components/global/knowledge/KnowPageDetail";
import {dataInterface} from "@/apis/data";

export default {
  name: "KnowDialog",
  components: {KnowDetail,FileFlowDetail,KnowPageDetail},
  props: {
    disabled: {
      type: Boolean,
      default: true,
    },
    data_id:{
      type:Number,
      default:0
    },
    title:{
      type:String,
      default:'提示'
    },
    tag:{
      type:String,
      default:''
    }
  },
  data() {
    return {
      showDialog: false,
      loading: true,
      detailData: null,
    }
  },
  created() {
    this.initDetailData()
  },
  methods: {
    initDetailData(){
      this.loading = true;
      this.showDialog = true;
      let reqData = {}
      if(this.tag === 'repository_new'){
        reqData = {
          __method_name__: 'dataInfo',
          object_uuid: 'object61e7d567ba3b8',
          view_uuid: 'view61e7dce6d7ea6',
          transcode: 0,
          data_id: this.data_id
        }
      } else if(this.tag === 'repository_file_inspect') {
        reqData = {
          __method_name__: 'dataInfo',
          object_uuid: 'object624d062737368',
          transcode: 0,
          data_id: this.data_id
        }
      } else if(this.tag === 'repository_page') {
        reqData = {
          __method_name__: 'dataInfo',
          object_uuid: 'object61e7d684aa425',
          transcode: 0,
          data_id: this.data_id
        }
      }
      dataInterface(reqData).then(res => {
        if(res.data.code === 200){
          this.detailData = res.data.data
          this.loading = false;
        }
      })
    },
    onFormSubmit() {
      this.showDialog = false;
      this.loading = false;
      this.$emit('close')
    },
  },
}
</script>

<style scoped>

</style>
