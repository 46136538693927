/*
 * @Author: Shiltin 18580045074@163.com
 * @Date: 2022-12-23 14:53:01
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2022-12-23 17:59:55
 * @FilePath: \console\src\views\gantt\js\theme.js
 * @Description: 主题色
 */

export default {
  COLOR: '#fff',
  HEAD_COLOR: '#999',
  BORDER_COLOR: '#efefef',
  BACKGROUND_COLOR: 'transparent',
  SCROLL_FOREGROUND_COLOR: '#B3B3B3',
  SCROLL_BACKGROUND_COLOR: '#d8d8d8',
  SCROLL_CORNER_COLOR: '#666',
  ARROW_COLOR: '#666',
  ARROW_ACTIVE_COLOR: '#f00',
	HOVER_BACKGROUND_COLOR: '#f6f6f6'
}
