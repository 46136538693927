<!--
 * @Description: 组合组件 0.0.1
 * @Author: luocheng
 * @Date: 2021-08-11 11:51:35
 * @LastEditors: luocheng
 * @LastEditTime: 2022-09-08 15:31:45
-->
<template>
	<div class="group">
		<!-- <div class="group-content"> -->
		<template v-for="item in propValue" >
				<!-- :style="item.groupStyle" -->
			<component
				class="component"
				:is="item.component"
				:propValue="item.propValue"
				:key="item.id"
				:id="'component' + item.id"
				:element="item"
				:isGroup="true"
				:style="getComStyle(item.style)"
				:groupComponents="propValue"
				:scaleHeight="+scaleHeight"
				:scaleWidth="+scaleWidth"
			/>
		</template>
		<!-- </div> -->
	</div>
</template>

<script>
import { getStyle } from '@/utils/style';
// 注册组件
const componentsList = require.context('@/custom-component', true, /\.+vue$/);
const components = {};
componentsList.keys().forEach((fileName) => {
	let name = fileName.replace(/.vue/, '');
	name = name.replace(/\.\//, '');
	name = name.split('/')[1];
	components[name] = componentsList(fileName).default;
});

export default {
	name: 'CommonGroup',
	props: {
		// 组合包含的组件列表
		propValue: {
			type: Array,
			required: true,
			default: () => []
		},
		// 组合本身
		element: {
			type: Object,
			required: true,
			default: () => {}
		},
		scaleHeight: {
      type: Number,
			default: 1
    },
    scaleWidth: {
      type: Number,
			default: 1
    },
		ratio: {
			type: Number,
			default: 1
		}
	},
	components,
	created() {
		this.formatChildrenStyle();
	},
	methods: {
    /**
     * @desc: 格式化组内子节点样式
     */
		formatChildrenStyle() {
			// const parentStyle = this.element.style;
			this.propValue.forEach(component => {
				// component.groupStyle 的 top left 是相对于 group 组件的位置
				// 如果已存在 component.groupStyle，说明已经计算过一次了。不需要再次计算
				let style = component.groupStyle ? {...component.groupStyle} : {};
				if (!Object.keys(component.groupStyle).length) {
				// 	component.groupStyle = getStyle(style);
				// 	component.groupStyle.left = this.toPercent(((style.left - parentStyle.left) / parentStyle.width));
				// 	component.groupStyle.top = this.toPercent(((style.top - parentStyle.top) / parentStyle.height));
				// 	component.groupStyle.width = this.toPercent((style.width / parentStyle.width));
				// 	component.groupStyle.height = this.toPercent((style.height / parentStyle.height));
				// 	component.groupStyle.fontSize = this.toPercent((style.width / parentStyle.width));
				}
				component.groupStyle.transform = `${style.transform} scale(${this.scaleWidth}, ${this.scaleHeight})`;
			});
		},
    /**
     * @desc: 将小数转为百分比
     * @param {Number} val 需要转换的小数
     * @return {String} 百分比
     */
    toPercent(val) {
      if (isNaN(val)) return '0%';
      return `${val * 100}%`;
    },
		/**
		 * @desc: 根据图层的样式对象获取可渲染的组件样式
		 * @param {Object/null} styleObj
		 * @return {Object} 壳渲染组件
		 */
		getComStyle(styleObj) {
			if (!styleObj || !styleObj.backgroundColor) return {};
			let excludesArr = ['top', 'left', 'rotate', 'height', 'width', 'fontSize'];
      let styleResult = getStyle(styleObj, excludesArr)
      excludesArr.forEach(ele => {
        let val = styleObj[ele] || 0;
        if (ele !== 'rotate') {
          if (ele === 'height') {
						if (!isNaN(+val)) {
							val = `${(val * this.scaleHeight * this.ratio).toFixed(2)}px`
						}
          } else if (ele === 'width') {
						if (!isNaN(+val)) {
							val = `${(val * this.scaleWidth * this.ratio).toFixed(2)}px`;
						}
          } else if (ele === 'fontSize') {
						val = `${val}px`;
					} else {
						if (!isNaN(+val)) {
							val = `${(val * this.scaleWidth * this.ratio).toFixed(2)}px`;
						}
					}
        } else {
					val = 'rotate(' + styleObj[ele] + 'deg)';
        }
        styleResult = {
          ...styleResult,
          [ele]: val
        }
      });
			return {
				...styleResult,
				top: 0,
				left: 0
			};
		},
	}
};
</script>

<style lang="less" scoped>
.group {
	height: 100%;
	width: 100%;
		position: relative;
		overflow: hidden;
	// .group-content {
	// 	position: relative;
	// 	width: 100%;
	// 	height: 100%;
	// 	overflow: hidden;
		.component {
			position: absolute;
		}
	// }
}
</style>
