<!--
 * @Description: 全屏按钮
 * @Author: luocheng
 * @Date: 2022-04-29 10:27:27
 * @LastEditors: '张星' you@example.com
 * @LastEditTime: 2023-04-28 10:42:51
-->
<template>
  <div class="full-screen-container" :style="styleContent">
    <i :class="['iconfont', isFullScreen ? 'icontuichuquanping' : 'iconquanping5']" @click="handleScreenChange" :style="style"></i>
  </div>
</template>
<script>
/**
 * @description 全屏按钮
 */
import Minin from './mixin'
export default {
  name: 'FullScreenCom',
  mixins: [Minin],
  data () {
    return {
      isFullScreen: false
    }
  },
  computed: {
    style() {
      return {
        fontSize: this.fontSize,
        textAlign: this.textAlign || '',
        lineHeight: this.lineHeight
      }
    },
    // 样式缺失？
    styleContent() {
      return {};
    }
  },
  methods: {
    // 全屏事件
    handleScreenChange() {
      let main = document.body;
      if (this.isFullScreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen()
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        }
      } else {
        if (main.requestFullscreen) {
          main.requestFullscreen()
        } else if (main.mozRequestFullScreen) {
          main.mozRequestFullScreen()
        } else if (main.webkitRequestFullScreen) {
          main.webkitRequestFullScreen()
        } else if (main.msRequestFullscreen) {
          main.msRequestFullscreen()
        }
      }
      this.isFullScreen = !this.isFullScreen;
    }
  }
}
</script>
<style lang="less" scoped>
  .full-screen-container{
    width: 100%;
    height: 100%;
  }
</style>