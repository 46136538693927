export default class PlotUtils {
    static getFillTypeByType(type){
        if(type === 'polyline' || type === 'curve') {
            return PlotUtils.getPolylineFillType();
        }else if(type === 'circle' || type === 'wall'){
            return PlotUtils.getAnimationPolygonFillType();
        }else{
            return PlotUtils.getPolygonFillType();
        }
    }

    static getPolylineFillType(){
        return [
            {id: 'solidLine', name: '实线'},
            {id: 'dashLine', name: '虚线'},
            {id: 'glowLine', name: '泛光线'},
            {id: 'arrowLine', name: '箭头线'},
            {id: 'flowLine', name: '流动线'},
            {id: 'animationLine', name: '动画线'},
        ]
    }

    static getPolygonFillType(){
        return [
            {id: 'color', name: '纯色'},
            {id: 'grid', name: '网格'},
            {id: 'stripe', name: '条纹'},
            {id: 'checkerboard', name: '棋盘'},
        ]
    }

    static getAnimationPolygonFillType(){
        return [
            {id: 'color', name: '纯色'},
            {id: 'grid', name: '网格'},
            {id: 'stripe', name: '条纹'},
            {id: 'checkerboard', name: '棋盘'},
            {id: 'animation', name: '动画'},
        ]
    }

    static getAnimationDirection(){
        return [
            {id: 'flowToBottom', name: '流动由上到下'},
            {id: 'flowToTop', name: '流动由下到上'},
            {id: 'flowToClockWise', name: '流动顺时针'},
            {id: 'flowToAntiClockWise', name: '流动逆时针'},
            {id: 'arrowToClockWise', name: '箭头顺时针'},
            {id: 'arrowToAntiClockWise', name: '箭头逆时针'}
        ]
    }
}
