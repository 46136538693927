<!--
 * @Author: 冉桂精 156189868@qq.com
 * @Date: 2022-10-18 14:31:34
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2022-12-22 16:25:30
 * @FilePath: /dataview-viewer-test/src/custom-component/currency/Hyperlinks.vue
 * @Description: 
-->
<template>
  <div
    class="currency-component-container"
    :style="style"
  >
    <a
      :href="hrefValue"
      :target="target"
      :style="style"
      >{{ element.propValue ? element.propValue : '-' }}</a
    >
  </div>
</template>

<script>
import Minin from './mixin';
export default {
  name: 'Hyperlinks',
  mixins: [Minin],
  computed: {
    style() {
      return {
        fontSize: this.fontSize,
        textAlign: this.textAlign || '',
        lineHeight: this.lineHeight || '',
        fontWeight: this.bold,
        fontStyle: this.italic,
        fontFamily: this.fontFamily
      };
    },
    // 超链接href
    hrefValue() {
      if (!this.statusConfig.hrefValue && window.location.href.indexOf('modify-page') > -1) {
        return 'javascript:void(0)';
      }
      return this.statusConfig.hrefValue;
    },
    // 打开目标
    target() {
      return this.statusConfig.openWay || '_blank';
    }
  }
};
</script>

<style lang="less" scoped>
.currency-component-container {
  width: 100%;
  height: 100%;
}
</style>
