/*
 * @Author: Shiltin 18580045074@163.com
 * @Date: 2022-12-23 14:53:01
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2022-12-23 18:00:09
 * @FilePath: \console\src\views\gantt\js\common.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export class Rect {
  constructor (x, y, w, h) {
    this.x = x
    this.y = y
    this.w = w
    this.h = h
    this.x1 = x + w
    this.y1 = y + h
  }

  testIn (x, y) {
    return x >= this.x && x <= this.x1 &&
            y >= this.y && y <= this.y1
  }
}
