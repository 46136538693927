// 工单相关的store
/* eslint-disable */
export default {
  state: {
	  work_space:{},
  },
  mutations: {
		setWorkSpace(state, data){
			localStorage.setItem('work_space',JSON.stringify(data))
			state.work_space = data
		},
  },
  getters: {
	  getWorkSpace(state){
		  const work_space = localStorage.getItem('work_space')
		  state.work_space = JSON.parse(work_space)
		  return state.work_space
	  },
  },
}
