<!--
 * @Author: 朱廷果 1028509503@qq.com
 * @Date: 2023-07-17 14:14:46
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2023-08-01 15:14:45
 * @FilePath: \console\src\views\systemTool\components\leftItem.vue
 * @Description: 建工项目总览的左侧树组件 
-->
<template>
  <div style="height: 100%; z-index: 19; position: relative;" v-loading="loading">
    <div v-if="showSelect" class="mini-left">
      <div class="mini-content" @click="selectClick">
        <div style="display: flex;align-items: center;" >
          <img
            alt=""
            class="node-icon"
            :src="getIcon(treeValue[0])"
          >
          <span style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;max-width: 136px;"
          >{{ treeValue.length?treeValue[0].name:'请选择公司' }}{{ treeValue.length>0?`(${treeValue.length})`:'' }}</span>
        </div>
        <i class="iconfont iconpage-arrow2-16 select-icon" ></i> 
      </div>
    </div>
    <div class="left-default" v-show="showTree" :style="{height:miniTree?'25%':'100%'}">
      <div class="left-header" >
        <span>企业组织</span>
        <div class="icon" @click="onHide">
          <i class="iconfont icona-zhidingpinned" v-if="showHeader" :class="miniTree?'iconquanping5':'icontuichuquanping'"/>
        </div>
      </div>
      <el-input v-model="filterText" placeholder="搜索" prefix-icon="el-icon-search" />
      <el-tree
        ref="subitemTree"
        :data="treeData"
        :expand-on-click-node="false"
        :filter-node-method="filterNode"
        node-key="id"
        :default-expanded-keys="expandedData"
        :indent="24"
        highlight-current
        :props="treeProps"
        :show-checkbox="showCheckBox"
        @check-change="getChecked"
        @node-click="nodeClick"
      >
        <template #default="{ node, data }">
          <span class="custom-tree-node">
            <!-- 分部分项图标 -->
            <img
              v-if="isSubitem && data.type_id !== 'classification'"
              alt=""
              class="node-icon"
              :src="getIcon(data)"
            >
            <div
              class="label"
            >{{ node.label }}</div>
          </span>
        </template>
      </el-tree>
    </div>
  </div>
</template>

<script>
import { Tree} from 'element-ui'
import { dataInterface } from '@/apis/data/index';
export default {
  components: {
    'el-tree': Tree,
  },
  name:'LeftItem',
  props: {
    // 只有公司
    onlyCom: {
      type: Boolean,
      default: false
    },
    topTitle: {
      type: String,
      default: '企业组织'
    },
    // 是否收缩
    showHeader: {
      type: Boolean,
      default: true
    },
    // 选中的数据
    value: {
      type: [Array, Number, String],
      default: () => []
    },
    // 树配置
    treeProps: {
      type: Object,
      default: () => {
        return {
          children: 'children',
          label: 'name'
        }
      }
    },
    // 树类型 default 默认 作为选项或菜单  select  配置时候  eyes 眼睛特殊样式 leafOnly 仅选择叶子节点
    type: {
      type: String,
      default: 'default'
    },
    // 是否用于项目总览
    isProject: {
      type: Boolean,
      default: false
    },
    // 是否默认选中一个
    defaultSelect: {
      type: Boolean,
      default: false
    },
    // 是否可以筛选
    withFilter: {
      type: Boolean,
      default: false
    },
    // 不可选规则
    disabledRule: {
      type: [String, Boolean],
      default: false
    },
    // 是否为分部分项树
    isSubitem: {
      type: Boolean,
      default: true
    },
    // 默认展开第一层
    defaultExpand: {
      type: Boolean,
      default: true
    },
    // 标题
    treeTitle: {
      type: String,
      default: ''
    },
    defaultExpandKeys: {
      type: Array,
      default: () => {
        return []
      }
    },
    showCheckBox: {
      type: Boolean,
      default: false
    },
    // 是否在树里增加本部数据字段
    showCadre: {
      type: Boolean,
      default: true
    },
    isShowSelect: {
      type: Boolean,
      default: false
    },
    isMiniTree: {
      type: Boolean,
      default: false
    },
    isShowTree: {
      type: Boolean,
      default: true
    },
    treeType: {
      type: String,
      default: 'project'
    },
    showMini: {
      type: Boolean,
      default: true
    },
    checkAll: {
      type: Boolean,
      default: false
    }
  },
  emits: ['getChecked', 'add', 'nodeClick', 'onHide'],
  data () {
    return {
      loading: false,
      filterText: '',
      // project_type  1 集团 2 公司 3项目 4本部
      TYPES: [
        {
          code: 1,
          icon: 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/09a2828d85e35b55781cb4303da114b7.png'
        },
        {
          code: 2,
          icon: 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/75b6708b353c4e62aef3a57ec1b68a4d.png'
        },
        {
          code: 3,
          icon: 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/e6b7564fe9cc7f8cd4bd3f0820d9d1f7.png'
        },
        {
          code: 4,
          icon: 'https://openim-1309784708.cos.ap-shanghai.myqcloud.com/e6b7564fe9cc7f8cd4bd3f0820d9d1f7.png'
        }
      ],
      throttle: '', // 节流 树形选择
      treeData: null,
      treeValue: [],
      showSelect: false,
      miniTree: false,
      showTree: true,
      currentItem: null,
      isAddChild: false,
      timer: '', // check选中节流
      constomId: 1000,
      checkId: [],
      echoTreeData: [],
      expandedData: []
    }
  },
  watch: {
    // 过滤
    filterText (val) {
      this.$refs.subitemTree.filter(val)
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
        /**
     * @desc 节点被展开的数据
     * @param {*} e
     */
     NodeExpand (e) {
      this.expandedData.push(e.id)
    },
    nodeCollapse (e) {
      this.expandedData = this.expandedData.filter(item => item !== e.id)
    },
    /**
     * 单选节点被点击
     */
    nodeClick(e){
      if (this.showCheckBox) {
        this.$refs.subitemTree.setCheckedKeys([e.id])
        return
      }
      this.$emit('nodeClick',e)
    },
    selectClick(){
      this.miniTree = !this.miniTree
      if(this.miniTree){
        this.showTree = true;
      }else{
        this.showTree = false;
      }
    },
    onHide(){
      if(this.showSelect){
        this.showSelect = false;
        this.showTree = true
        this.miniTree = false;
      }else{
        this.showSelect = true;
        this.showTree = false
      }
      this.$emit('onHide', this.showSelect)
    },
    // 增加本部数据
    addCadre(data){
      data.forEach((item)=>{
        if(item.children && item.children.length){
          this.addCadre(item.children)
        }else{
          item.children =[]
        }
        item.children.unshift({project_type:4,id:item.id+'1',source_archi_type:item.source_archi_type,name:this.getName(item.project_type)})
      })
    },
    getName(item){
      if(+item === 1){
        return '本部集团数据'
      }else if(+item===2){
        return '本部公司数据'
      }else if(+item ===3){
        return '本部项目数据'
      }
    },
    /**
     *  获取树形数据
     */
     getData () {
      this.loading = true
      const param = {}
      if (this.treeType === 'company') {
        param.is_only_company = '1'
      }
      dataInterface(param,'api/graph/153').then((res) => {
        if (res.data.code === 200) {
          this.treeData = res.data.data
          if (this.showCadre) {
            this.addCadre(this.treeData)
          }
          if (this.defaultExpand) {
            this.treeData.forEach(item => {
              this.expandedData.push(item.id)
            })
          }
          if (this.checkAll) {
            this.treeData.forEach(item => {
              this.checkId.push(item.id)
            })
            console.log(this.checkId)
            this.$refs.subitemTree.setCheckedKeys(this.checkId)
            this.treeValue = this.$refs.subitemTree.getCheckedNodes()
            this.getChecked()
          }
        }
        this.loading = false
      })
    },
    /**
     * @desc: 节点过滤
     * @param {String} 关键字
     * @return {Object} 行数据
     */
    filterNode (value, data) {
      if (!value) return true
      return data[this.treeProps.label].toString().indexOf(value) > -1
    },
    /**
     * @desc 获取当前节点的id
     */
    getChecked () {
      this.$nextTick(() => {
        // 多选时候一直出发 节流
        if (this.timer) return
        this.timer = true
        setTimeout(() => {
          this.timer = false
        }, 500)
        this.treeValue = this.$refs.subitemTree.getCheckedNodes()
        this.$emit('getChecked', this.treeValue)
      })
    },
    /**
     * @desc: 图标ICON
     */
    getIcon(data){
      if (!data || !data.project_type) return
      return this.TYPES.find(ele => +ele.code === +data.project_type).icon
    },
  }
}
</script>
<style lang="less" scoped>
::-webkit-scrollbar{
  width: 4px !important;
}
:deep(.el-tree){
  height: calc(100% - 100px) ;
  overflow: auto;
  margin-top: 16px;
  .is-indeterminate,.is-checked{
    .el-checkbox__inner::after{
      border-color: #fff !important;
    }
    .el-checkbox__inner{
      background-color: #2977FF !important;
    }
  }

  .is-current{
    .el-tree-node__children{
      .el-tree-node__content{
        background-color:#fff !important;
        color: transparent;
      }
      .is-checked{
        .el-checkbox__inner::after{
          border-color: #fff !important;
        }
        .el-checkbox__inner{
          background-color: #2977FF !important;
        }
      }

      .label{
        color: #181C20;
      }
    }
    .el-checkbox__inner::after{
      border-color: #2977FF !important;
    }
    .el-checkbox__inner{
      background-color: #fff !important;
    }
    .label{
      color: #fff;
    }
    .el-tree-node__content{
      background-color: #2977FF;
      color: #fff;
    }
  }
  .el-tree-node__content{
    margin: 2px 0;
    .el-tree-node__expand-icon{
      margin: 2px;
    }
    .el-checkbox{
      padding: 0 5px;
      margin: 0 4px;
      height: 24px;
      display: inline-flex;
    align-items: center;
    }
  }
}
  .custom-tree-node {
    // height: 32px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    box-sizing: border-box;
    // padding-left: 0;
    padding: 5px 8px 5px 0;
    overflow: hidden;
    .node-icon {
      height: 16px;
      width: 16px;
      margin-right: 8px;
    }

    .label {
      height: 100%;
      line-height: 34px;
      flex: 1;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: left;
      font-family: Arial;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: 0.5px;
      color: #181C20;
    }
    .iconxinzeng3{
      color: #fff;
    }
  }
  .left-default{
    // width: 240px;
    height: 100%;
    padding: 8px 8px 4px 8px;
    background-color: white;
    border-radius: 8px 8px 0px 0px;
    box-sizing: border-box;
    z-index: 99;
    // transition: width .3s;
    .left-header{
      display: flex;
      height: 32px;
      padding-left: 12px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      margin-bottom: 4px;
        .icon{
          display: flex;
          width: 24px;
          height: 24px;
          padding: 5px;
          justify-content: center;
          align-items: center;
          border-radius: 6px;
          background-color: #F5F9FF;
          box-sizing: border-box;
          // transition: 0.3s;
        }
    }

  }

  .mini-left{
    display: flex;
    // width: 240px;
    height: 64px;
    padding: 12px 16px 12px 20px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    flex-shrink: 0;
    border-radius: 0px 0px 0px 8px;
    background: #fff;
    box-sizing: border-box;
    .mini-content{
      display: flex;
      padding: 16px 12px;
      box-sizing: border-box;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      flex: 1 0 0;
      align-self: stretch;
      border-radius: 6px;
      background: linear-gradient(135deg, rgba(189, 210, 252, 0.50) 0%, rgba(219, 199, 244, 0.50) 100%);
      .node-icon {
        height: 20px;
        width: 20px;
        margin-right: 8px;
      }

    }
  }

</style>
