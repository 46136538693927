<!--
    @name: ques-radio
    @description：ques-radio
    @author: ZengWei
    @date: 2022-04-01 11:17
-->
<template>
  <div class="widget-signature">
    <div class="q-title-wrap" v-if="!element.config.__ques__.showTitle">
      <div class="seq-num">
        <span v-if="element.config.__config__.required" class="required">*</span>
      </div>
      <div class="q-title">
        {{ element.config.__config__.label }}
      </div>
      <div class="q-title" v-html="element.config.__ques__.quesDesc"></div>
    </div>
    <div class="q-option-list">
      <el-button type="primary" icon="el-icon-menu"  @click="openOuterForm">
        打开{{element.config.__config__.label}}
      </el-button>
    </div>

    <el-dialog
      top="5vh"
      :title="outerFormTitle"
      :custom-class="'common-dialog'"
      :visible.sync="outerFormVisible"
      width="80%"
      append-to-body
    >
      <article class="dialog-main">
        <PcParser
          ref="outerFormParser"
          v-if="outerFormData && outerFormVisible"
          :hide-btn="true"
          :form-data="outerFormData"
          :form-mode="outerFormMode"
          :need-flow="outerNeedFlow"
          :module-uuid="outerFormUuid"
          :display-data="outerFormDisplay"
          :disabled="outerDisabled"
          @submit="outerFormSubmit"
        ></PcParser>
      </article>
      <div slot="footer">
        <el-button type="default" @click="outerFormVisible = false" style="margin-right: 10px;">取消</el-button>
        <el-button v-if="!outerDisabled" type="primary" @click="confirmSubmit()">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import PcParser from "@/custom-component/form/newParser/PcParser";
import quesWidgetMinix from "@/custom-component/form/quesParser/scripts/quesWidgetMinix";
import {formRequest} from "@/apis/home";

export default {
  name: "ques-outerForm",
  components: {
    PcParser
  },
  mixins:[quesWidgetMinix],
  props: ['element','number'],
  computed:{},
  data() {
    return {
      outerFormData:null,
      outerNeedFlow: false,
      outerFormUuid: '',
      outerObjectUuid: '',
      outerFormMode: 'add',
      outerFormDisplay: {},
      outerFormType: 1,
      outerFormShowType: 1,
      outerFormVisible:false,
      outerFormTitle: '外部表单',
      outerDisabled:false
    }
  },
  created() {
    this.getOuterFormDesign()
  },
  methods: {
    async getOuterFormDesign(){
      const formDesignId = this.element.config.__config__.formDesignId
      const url = '/api/metadata/transformuuid/'+ formDesignId
      const response = await formRequest('get', url, {});
      const resData = response.data.data;
      this.element.config.__config__.designData = resData
    },
    // 打开外部表单
    openOuterForm() {
      const element = this.element
      this.outerFormData = element.config.__config__.designData
      this.outerNeedFlow = !!element.config.__config__.designData?.need_flow
      this.outerFormMode = element.value ? 'detail': 'add'
      this.outerDisabled = element.value ? true: false
      this.outerFormTitle = element.config.__config__.label
      this.outerFormShowType = element.config.__config__.showType
      this.outerFormType = element.config.__config__.formType
      this.outerFormUuid = element.config.__config__.formDesignId
      this.outerObjectUuid = element.config.__config__.objectUuid

      if(parseInt(element.value)){
        //数据详情回显
        const url='api/mapi',method='POST';
        let ruuids = [];
        for (let i = 0; i < this.outerFormData.fields.length; i++) {
          let item = this.outerFormData.fields[i];
          if (item.__config__.tagIcon == 'form') {
            ruuids.push({ relationship_uuid: item.__vModel__ });
          }
        }
        const param = {
          object_uuid: this.outerObjectUuid,
          view_uuid: '',
          data_id: element.value,
          ruuids,
          __method_name__: 'dataInfo'
        }
        formRequest(method,url,param).then(res => {
          let data = res.data.data;
          this.outerFormDisplay = data;
          this.outerFormVisible = true
        });
      } else {
        this.outerFormVisible = true
      }
    },
    confirmSubmit(){
      this.$refs.outerFormParser.handleSubmit()
    },
    async outerFormSubmit(formSubmitData){
      if(!formSubmitData){
        formSubmitData = this.$refs.outerFormParser.getFormData()
      }
      if(this.outerObjectUuid){
        const url = '/api/mapi'
        const data = {
          __method_name__: 'createData',
          object_uuid: this.outerObjectUuid,
        }
        Object.assign(data,formSubmitData);
        let response = await formRequest('post', url, data);
        if(response.data.code === 200){
          const id = response.data.data.id
          this.element.value = id
          this.outerFormVisible = false;
          this.$message.success(response.data.msg)
        } else {
          this.$message.error(response.data.msg)
        }
      }
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/css/questionItem';

:deep(.common-dialog)  {
  .el-dialog__body{
    padding: 0;
  }
}

.dialog-main{
  padding: 0 15px;
  height: calc(100vh - 200px);
  overflow-y: auto;
}
</style>
