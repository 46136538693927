<template>
  <div class="decoration-container"  style="position: relative" :ref="ref">
    <div :style="`width:${w - 16}px;height:${h - 30}px;background: rgba(255, 255, 255,.1);
      left: 8px;top: 20px;position: absolute;
      z-index: -1;backdrop-filter: blur(${blur || 6 }px);border-radius: 19px;`">
    </div>
    <svg :width="w" :height="h" :id="defsKey" xmlns="http://www.w3.org/2000/svg" 
      xmlns:xlink="http://www.w3.org/1999/xlink">
      <g :filter="`url(#filter0_b_18_40${defsKey})`" style="z-index: 1">
        <rect x="7" y="20" :width="w - 14" :height="h - 30" rx="20" :stroke="`url(#paint0_linear_18_40${defsKey})`" fill="none" stroke-width="2" shape-rendering="crispEdges"/>
        <rect x="7" y="20" :width="w - 14" :height="h - 30" rx="20" :fill="secondaryColor" fill-opacity="0.15" shape-rendering="crispEdges"/>
        <path :d="`M${halfWidth - (Number(titleWidth) / 2)} 20L${halfWidth + 10 - (Number(titleWidth) / 2)} 4H${halfWidth - 10 + (Number(titleWidth) / 2)} L${halfWidth + (Number(titleWidth) / 2)} 20L${halfWidth - 10 + (Number(titleWidth) / 2)} 36H${halfWidth + 10 - (Number(titleWidth) / 2)}L${halfWidth - (Number(titleWidth) / 2)} 20Z`" :fill="mainColor" :stroke="`url(#paint0_linear_0_585${defsKey})`"/>
        <path :d="`M${halfWidth + (Number(titleWidth) / 2)} 20L${w} 20`" :stroke="`url(#paint1_linear_0_585${defsKey})`">
          <animate
            attributeName="opacity"
            values="1;0.4;1"
            dur="2s"
            begin="0s"
            repeatCount="indefinite"
          />
        </path>
        <path :d="`M${halfWidth - (Number(titleWidth) / 2)} 20L5 20`" :stroke="`url(#paint2_linear_0_585${defsKey})`">
          <animate
            attributeName="opacity"
            values="1;0.4;1"
            dur="2s"
            begin="0s"
            repeatCount="indefinite"
          />
        </path>
        <line :x1="halfWidth - (Number(titleWidth) / 2)" y1="42" :x2="halfWidth + (Number(titleWidth) / 2)" y2="42" :stroke="`url(#paint3_linear_0_585${defsKey})`" stroke-width="4">
          <animate
            attributeName="opacity"
            values="1;0.4;1"
            dur="2s"
            begin="0s"
            repeatCount="indefinite"
          />
        </line>
      </g>
      <text
        class="dv-border-box-11-title"
        :x="`${w / 2}`"
        y="22"
        :fill="textColor"
        :font-size="textSize"
        text-anchor="middle"
        dominant-baseline="middle"
      >
        {{ title }}
      </text>
      <defs>
        <linearGradient :id="`paint0_linear_0_585${defsKey}`" :x1="halfWidth" y1="1" :x2="halfWidth" y2="26" gradientUnits="userSpaceOnUse">
          <stop :stop-color="secondaryColor"/>
          <stop offset="1" :stop-color="secondaryColor"/>
        </linearGradient>
        <linearGradient :id="`paint1_linear_0_585${defsKey}`" :x1="halfWidth + 10 + (Number(titleWidth) / 2)" y1="2.81497e+14" :x2="w - 10" y2="2.81497e+14" gradientUnits="userSpaceOnUse">
          <stop :stop-color="secondaryColor"/>
          <stop offset="1" :stop-color="secondaryColor" stop-opacity="0"/>
        </linearGradient>
        <linearGradient :id="`paint2_linear_0_585${defsKey}`" :x1="halfWidth - 10 - (Number(titleWidth) / 2)" y1="2.81497e+14" x2="0" y2="2.81497e+14" gradientUnits="userSpaceOnUse">
          <stop :stop-color="secondaryColor"/>
          <stop offset="1" :stop-color="secondaryColor" stop-opacity="0"/>
        </linearGradient>
        <linearGradient :id="`paint3_linear_0_585${defsKey}`" :x1="halfWidth - 10 - (Number(titleWidth) / 2)" y1="32.2632" :x2="halfWidth + 10 + (Number(titleWidth) / 2)" y2="31" gradientUnits="userSpaceOnUse">
          <stop :stop-color="secondaryColor + '99'" stop-opacity="0"/>
          <stop offset="0.505208" :stop-color="secondaryColor"/>
          <stop offset="1" :stop-color="secondaryColor + '99'" stop-opacity="0"/>
        </linearGradient>
        <filter :id="`filter0_b_18_40${defsKey}`" x="-33" y="-33" :width="w + 40" :height="h + 40" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feGaussianBlur in="BackgroundImage" stdDeviation="7.5"/>
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_18_40"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_18_40" result="shape"/>
        </filter>
        <linearGradient :id="`paint0_linear_18_40${defsKey}`" :x1="w / 2" y1="7" :x2="w / 2" :y2="h + 10" gradientUnits="userSpaceOnUse">
          <stop :stop-color="secondaryColor" stop-opacity="0"/>
          <stop offset="1" :stop-color="secondaryColor"/>
        </linearGradient>
      </defs>
    </svg>
  </div>
</template>
<script>
import Resize  from '@jiaminghi/data-view/src/mixin/autoResize.js'
import Minin from './mixin'
export default {
  name: 'Decoration12',
  mixins: [Minin, Resize],
  data() {
    return {
      ref: 'borderBox-14',
      w: 741,
      h: 207,
      svgScale: [1, 1],
      defsKey: this.getKey()
    }
  },
  computed: {
    mainColor() {
      return this.statusConfig.mainColor || '#0031AE'
    },
    secondaryColor() {
      return this.statusConfig.secondaryColor || '#00C0FD'
    },
    halfWidth() {
      return this.width / 2
    },
    textColor() {
      return this.statusConfig.textColor || '#fff'
    },
    textSize() {
      return this.statusConfig.textSize || 16
    },
    blur() {
      return this.statusConfig.blur || 6
    }
  },
  methods: {
    getKey() {
      var d = new Date().getTime()
      if (window.performance && typeof window.performance.now === "function") {
          d += performance.now() //use high-precision timer if available
      }
      var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
          var r = (d + Math.random() * 16) % 16 | 0   // d是随机种子
          d = Math.floor(d / 16)
          return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16)
      })
      return uuid
    },
    afterAutoResizeMixinInit () {
      this.calcScale()
    },
    calcScale () {
      this.w = this.width
      this.h = this.height
    },
    onResize () {
      this.calcScale()
    },
  }
}
</script>
<style lang="less" scoped>
  .decoration-container{
    width: 100%;
    height: 100%;
  }
  .ground-glass-effect{
    position: absolute;
    top: 20px;
    left: 10px;
    width: calc(100% - 10px);
    height: calc(100% - 20px);
    background: rgba(255, 255, 255, .4);
    backdrop-filter: blur(6px);
    border-radius: 19px;
  }
</style>