/*
 * @Description: 请求
 * @Author: luocheng
 * @Date: 2021-09-01 16:09:22
 * @LastEditors: luocheng
 * @LastEditTime: 2022-05-17 15:09:32
 */

import HttpRequest from './axios'
// import config from './config'
// export const baseUrl = process.env.NODE_ENV === 'development' ? config.baseUrl.dev : config.baseUrl.pro
export const baseUrl = process.env.VUE_APP_BASE_URL;
// console.log(baseUrl,'baseUrl')
export const axios = new HttpRequest(baseUrl)