/*
 * @Author: Shiltin 18580045074@163.com
 * @Date: 2022-11-06 09:35:05
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2023-05-11 17:03:09
 * @FilePath: \dataview-next\src\custom-component\viewerGIS\Events\MouseCustomEvents.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import eventBus from '@/plugins/eventBus';

export default class MouseCustomEvents {
  constructor() {}

  static getEvents(viewer, comp) {
    viewer.on(viewer.EventType.cameraChange, () => {
      eventBus.$emit('ViewerGIS.onCameraChange');
    });

    return {
      'ViewerGIS.registerCameraChange': (eid) => {
        if (eid !== comp.element.id) return;

        viewer.MouseCustomEvent.registerCameraChange();
      },
      'ViewerGIS.logoutCameraChange': (eid) => {
        if (eid !== comp.element.id) return;

        viewer.MouseCustomEvent.logoutCameraChange();
      },
      'ViewerGIS.drawPointByMouseCustom': (eid, cb) => {
        if (eid !== comp.element.id) return;
				
        viewer.off(viewer.EventType.drawPointByMouseCustom);
        viewer.on(viewer.EventType.drawPointByMouseCustom, (xyz) => {
					const selectFeature = viewer.Model.getSelectFeature()
          cb && cb(xyz,selectFeature);
        });
        viewer.MouseCustomEvent.drawPoint();
      },
      'ViewerGIS.drawPolylineByMouseCustom': (eid, cb) => {
        if (eid !== comp.element.id) return;

        viewer.off(viewer.EventType.drawPolylineByMouseCustom);
        viewer.on(viewer.EventType.drawPolylineByMouseCustom, (res) => {
          cb && cb(res);
        });

        viewer.MouseCustomEvent.drawPolyline();
      },
      'ViewerGIS.drawCircleByMouseCustom': (eid, cb) => {
        if (eid !== comp.element.id) return;

        viewer.off(viewer.EventType.drawCircleByMouseCustom);
        viewer.on(viewer.EventType.drawCircleByMouseCustom, (res) => {
          cb && cb(res);
        });

        viewer.MouseCustomEvent.drawCircle();
      },
      'ViewerGIS.drawRectangleByMouseCustom': (eid, cb) => {
        if (eid !== comp.element.id) return;

        viewer.off(viewer.EventType.drawRectangleByMouseCustom);
        viewer.on(viewer.EventType.drawRectangleByMouseCustom, (res) => {
          cb && cb(res);
        });

        viewer.MouseCustomEvent.drawRectangle();
      },
      'ViewerGIS.drawPolygonByMouseCustom': (eid, cb) => {
        if (eid !== comp.element.id) return;

        viewer.off(viewer.EventType.drawPolygonByMouseCustom);
        viewer.on(viewer.EventType.drawPolygonByMouseCustom, (res) => {
          cb && cb(res);
        });

        viewer.MouseCustomEvent.drawPolygon();
      },
      'ViewerGIS.removeMouseCustomEvent': (eid) => {
        if (eid !== comp.element.id) return;

        viewer.off(viewer.EventType.drawPointByMouseCustom);
        viewer.off(viewer.EventType.drawPolylineByMouseCustom);
        viewer.off(viewer.EventType.drawCircleByMouseCustom);
        viewer.off(viewer.EventType.drawRectangleByMouseCustom);
        viewer.off(viewer.EventType.drawPolygonByMouseCustom);
        viewer.MouseCustomEvent.removeDrawing();
      }
    };
  }
}
