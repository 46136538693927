import { mapState } from 'vuex';
import { removeEventBus } from '@/utils/tools';

export default {
  props: {
    // 绑定的值
    element: {
      type: Object,
      default: () => { },
      required: true
    },
    // 是否在组合内
    isGroup: {
      type: Boolean,
    },
    // 组合内组件列表
    groupComponents: {
      type: Array,
      default: () => []
    }
  },
  inject: ['EDITOR_pageUUID'],
  computed: {
    ...mapState(['componentData', '_PageCustomStatus', '_APPCustomStatus']),
    statusConfig() {
      return this.element.statusConfig || {}
    },
    fontSize() {
      return this.statusConfig.fontSize || '16px'
    },
    textAlign() {
      return this.statusConfig.textAlign || 'center'
    },
    lineHeight() {
      return this.statusConfig.lineHeight || '32px'
    },
    isPreview() {
      return this.statusConfig.isPreview
    },
    src() {
      return this.statusConfig.src || ''
    },
  },
  beforeDestroy() {
    if (!this.EDITOR_pageUUID) {
      removeEventBus(['databaseTrigger'], this.element.id);
    }
  }
}