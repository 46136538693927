
<template>
  <div class="weather-conditions" v-if="weatherData">
    <section class="item" v-if="showWeather" :style="itemStyle">
      <i v-show="showIcon" class="iconfont weather-icon" :class="weatherClass"></i>
      <span class="weather-text" v-show="!showIcon">{{ weatherData.wea }}</span>
      <div class="desc">
        <p class="value">{{ weatherData.tem || "--" }}°C</p>
        <p class="label">天气</p>
      </div>
    </section>
    <section class="item" v-if="showAirpm" :style="itemStyle">
      <i class="iconfont weather-icon">
        <img src="@/assets/images/dataview/pm25.png" alt="" />
      </i>
      <div class="desc">
        <p class="value">{{ weatherData.pm25 || "--" }}</p>
        <p class="label">PM2.5</p>
      </div>
    </section>
  </div>
</template>

<script>
import { getWeather } from "@/apis/data";
import Minin from './mixin';
/**
 * @description 天气
 */
export default {
  name: "WeatherConditions",
  props: {
    scaleHeight: {
      type: Number,
      default: 1
    },
    scaleWidth: {
      type: Number,
      default: 1
    }
  },
  mixins: [Minin],
  data() {
    return {
      weatherData: null
    }
  },
  computed: {
    // 是否显示天气信息，温度阴雨等主要信息
    showWeather() {
      return this.statusConfig.showWeather;
    },
    // 是否显示PM2.5情况
    showAirpm() {
      return this.statusConfig.showAirpm;
    },
    weatherClass() {
      if (!this.weatherData) return ["iconfont", "weather-icon"];
      let weatherImg = this.weatherData.wea_img;
      // 多云用阴天图标
      if (this.weatherData.wea_img === "yun") {
        weatherImg = "yin";
      }
      return [`icontianqi-${weatherImg}`, `color-${weatherImg}`];
    },
    // 样式
    showIcon() {
      return this.statusConfig.showIcon;
    },
    // item样式
    itemStyle() {
      // let marginRight = this.statusConfig.weatherMargin ? this.statusConfig.weatherMargin : 0;
      // marginRight = isNaN(+marginRight) && marginRight.split('px')?.length ? marginRight.split('px')?.[0] : 30;
      return {
        marginTop: `${10 * this.scaleHeight }px`,
        height: `${34 * this.scaleHeight}px`,
        transform: `scale(${this.scaleWidth}, ${this.scaleHeight})`,
        marginLeft: -1 * 34 * (1 - this.scaleWidth) + 'px'
      };
    }
  },
  created() {
    this.getWeatherInfo();
  },
  methods: {
    // 获取天气情况
    getWeatherInfo() {
      getWeather().then(res => {
        if (res.data && res.data.city && res.data.data) {
          this.weatherData = Object.assign({}, res.data.data[0], res.data.aqi)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.weather-conditions {
  width: 100%;
  height: 100%;
  display: flex;
  .item {
    margin-top: 10px;
    height: 34px;
    display: flex;
    align-items: center;
    .weather-icon {
      height: 24px;
      width: 24px;
      font-size: 24px;
      text-align: center;
      line-height: 24px;
      margin-top: 5px;
      overflow: hidden;
      &.color-yin {
        color: #878c90;
      }
      &.color-qing {
        color: #ffb700;
      }
      &.color-yu {
        color: #00ccff;
      }
      &.color-yun {
        color: #878c90;
      }
      img {
        display: block;
        height: 24px;
        width: 24px;
        overflow: hidden;
        object-fit: cover;
      }
    }
    .weather-text {
      font-size: 18px;
    }
    .desc {
      margin-left: 6px;
      .value {
        min-width: 36px;
        max-width: 60px;
        line-height: 18px;
        height: 18px;
        opacity: 1;
        font-size: 18px;
        font-family: DINNextLTPro, DINNextLTPro-Medium;
        font-weight: 500;
        text-align: left;
      }
      .label {
        padding-top: 4px;
        width: 24px;
        height: 12px;
        opacity: 1;
        font-size: 12px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        line-height: 12px;
      }
    }
  }
}
</style>
