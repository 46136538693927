<!--
 * @Author: Shiltin 18580045074@163.com
 * @Date: 2022-01-21 09:25:47
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-07-25 18:10:08
 * @FilePath: /dataview-viewer-test/src/chart-components/components/common/ChartContainer.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
	<div ref="chartDOM" class="chart-dom"></div>
</template>

<script>
// import this.CHARTS from 'this.CHARTS';
import { addListener, removeListener } from 'resize-detector';

export default {
	props: {
		option: {
			type: Object,
			default: () => {}
		},
		// 自动刷新
		autoRefresh: {
			type: Boolean,
			required: false,
			default: false
		},
		// 自动刷新间隔
		during: {
			type: Number,
			required: false,
			default: 10
		}
	},
	data() {
		return {
			// 自动刷新的定时器
			interval: null,
			CHARTS: null
		};
	},
	watch: {
		option(val) {
			this.clear()
			this.echart.setOption(val);
		}
	},
	created() {
		// this.resize();
		this.CHARTS = require('echarts');
	},
	mounted() {
		this.$nextTick(() => {
			this.renderChart();
			addListener(this.$refs.chartDOM, this.resize); // 监听echart加载
			this.echart = this.CHARTS.init(this.$refs.chartDOM);
			if (this.autoRefresh) {
				if (this.interval) {
					clearTimeout(this.interval);
				}
				this.setRefresh();
			}
			// console.log(this.echart,'echart')
		});
	},
	methods: {
		resize() {
			this.echart.resize();
		},
		clear() {
			this.echart.clear();
		},
		renderChart() {
			this.echart = this.CHARTS.init(this.$refs.chartDOM);
			this.echart.setOption(this.option);
		},
		// 自动刷新
		setRefresh() {
			if (!this.autoRefresh) return;
			this.interval = setTimeout(() => {
				if (!this.echart || !this.echart.dispose) {
					clearTimeout(this.interval);
					this.setRefresh();
					return;
				}
				// 刷新
				this.echart.dispose(); // 释放图表实例
				setTimeout(() => {
					this.echart = this.CHARTS.init(this.$refs.chartDOM);
					this.echart.setOption(this.option);
				}, 0);
				this.setRefresh();
			}, this.during || 10000);
		}
	},
	beforeDestroy() {
		removeListener(this.$refs.chartDOM, this.resize);
		this.echart.dispose(); // 释放图表实例
		this.echart = null;
	}
};
</script>
<style lang="less" scope>
.chart-dom{
	height: 100%;
	width: 100%;
}
</style>