/*
 * @Description: 页面模板
 * @Author: luocheng
 * @Date: 2021-12-03 13:37:11
 * @LastEditors: luocheng
 * @LastEditTime: 2022-10-08 11:37:35
 */
import { templateConfig } from './templateConfig/list';

const pageTemplate = {
	type: 'pageTemplate',
	title: '页面模板',
	show: true,
	order: 12,
	componentList: [
		{
			component: 'ListTemplate',
			type: 'pageTemplate',
			name: '模板(未启用)',
			icon: 'iconyeqian',
			isLock: false, // 是否锁定
			needCommonStyle: false,
			propValue: '',
				span: 24,
				style: {
				height: '400',
				width: '',
				lineHeight: '',
				rotate: 0,
				marginTop: 0,
				marginBottom: 0,
				marginRight: 0,
				marginLeft: 0
			},
			statusConfig: {
				displayShow: true,
				isShow: true, // 是否显示
			},
			templateConfig // 模板配置
		},
	]
};
export default pageTemplate;