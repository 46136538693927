/* eslint-disable */

/**
 * 字符串代码执行
 * @param fn
 * @return {*}
 */
export let calcImpl = (fn) => {
  window.calcIFS = calcIFS
  window.calcAND = calcAND
  window.calcOR = calcOR
  window.calcNOT = calcNOT
  window.calcSUMIF = calcSUMIF
  window.calcCOUNTIF = calcCOUNTIF
  window.calcAVG = calcAVG
  window.calcCONCAT = calcCONCAT
  window.calcMID = calcMID
  window.calcMAX = calcMAX
  window.calcMIN = calcMIN
  window.calcFieldIF = calcFieldIF
  window.calcFilter = calcFilter

  window.calcIF = calcIF
  window.calcField = calcField
  window.calcSUM = calcSUM
  window.calcMULTIPLY = calcMULTIPLY

  // js计算代码段执行
  const Fn = Function
  try{
    return new Fn(`return ${fn}`)()
  } catch (e){
    // 不是函数时、直接当代码执行
    return new Fn(`${fn}`)()
  }
}

export const calcField = (formId,flag= false)=>{
  const formData = localStorage.getItem('TEMP_formData')
  const formIdSubmitData = localStorage.getItem('TEMP_formIdData')
  const formSubmitData = JSON.parse(formData)
  const formIdData = JSON.parse(formIdSubmitData)
  const keys1 = Object.keys(formSubmitData)
  const keys2 = Object.keys(formIdData)
  const fieldValue = formSubmitData[formId] || formIdData[formId] || 0

  let childForm = formId.split('.')
  if(childForm.length > 1 && formSubmitData[formId] === undefined) {
    let mainField = childForm[0]
    let childField = childForm[1]
    let childValue = formSubmitData[mainField] || []
    if(childValue.length>0){
      let fieldValue = Array.from(childValue,t=>t[childField])
      let number = 0
      if(flag) {
        number = fieldValue.reduce(function (prev, cur) {
          return parseFloat(prev) * parseFloat(cur);
        }, 0)
      } else {
        number = fieldValue.reduce(function (prev, cur) {
          return parseFloat(prev) + parseFloat(cur);
        }, 0)
      }
      return number
    }
  }

  let number = (keys1.includes(formId) || keys2.includes(formId))
    ? (parseFloat(fieldValue) ? parseFloat(fieldValue) : 0)
    : 0;
  return number;
}

/**
 * [子表、数据表格]取值
 * @param arg1 字段名
 * @param cond 条件
 * @param arg2 字段名
 */
export const calcFieldIF = (arg1, cond, arg2) => {
  if(!(arg1 instanceof Array) || !(arg2 instanceof Array)){
    throw new Error('参数错误')
  }
  let value = []
  for (let index of arg1){
    const boolVal = calcImpl(arg1[index] + cond)
    if(boolVal){
      value.push(arg2[index])
    }
  }
  return value
}

/**
 * [子表、数据表格]筛选条件取值
 * @param viewData 数据
 * @param cond 条件
 * @param arg2 字段名
 */
export const calcFilter = (viewData, cond, arg2) => {
  const arr = arg2.split('.')
  let argInd = arr[0]
  if(arr?.[1]){
    argInd = arr[1]
  }
  const data = viewData.filter(item => {
    return eval(cond)
  })
  return Array.from(data,item => item[argInd])
}

/**
* calcIF判断语句块
* @param arg1 条件
* @param arg2 为真执行
* @param arg3 为假执行
*/
export let calcIF = (arg1, arg2, arg3 = null) => {
  if (arg1) {
    return arg2
  } else {
    return arg3
  }
}

/**
 * calcIFS(判断条件1，真值1，判断条件2（可选），真值2（可选），···)
 * 检查是否满足一个或多个条件，且返回符合第一个 TRUE 条件的值
 * @param arg
 * @returns {*}
 * @constructor
 */
export const calcIFS = (...arg) => {
  if(arg.length % 2 !== 0){
    throw new Error('参数错误')
  }
  for (let i=0; i < arg.length; i += 2){
    const condition = calcImpl(arg[i])
    const value = arg[i+1]
    if(condition) return value
  }
}

/**
 * 条件与
 * @param arg
 * @returns {boolean}
 * @constructor
 */
export const calcAND = (...arg) => {
  let result = true
  for(let i = 0; i < arg.length; i++){
    let logical = calcImpl(arg[i])
    if(!logical){
      result = false
      break
    }
  }
  return result
}

/**
 * 条件或
 * @param arg
 * @returns {boolean|*}
 * @constructor
 */
export const calcOR = (...arg) => {
  let result = true
  for(let i = 0; i < arg.length; i++){
    let logical = calcImpl(arg[i])
    if(logical){
      return logical
    }
  }
  return result
}

/**
 * 取反
 * @param arg1
 * @returns {boolean}
 * @constructor
 */
export const calcNOT = arg1 => {
  let logical = calcImpl(arg1)
  return !logical
}


export let ISCONTAINS = (arg1, arg2) => {

}

/**
 * 求积（旧）
 * @param data
 * @returns {number}
 */
export let calcMULTIPLY = (...data) => {
  let number = 1;
  for (let i = data.length - 1; i >= 0; i--) {
    number *= data[i];
  }
  return number
}

/**
 * 求和
 * @param data
 * @returns {number}
 */
export let calcSUM = (...data) => {
  let number = 0;
  for (let i = data.length - 1; i >= 0; i--) {
    number += data[i];
  }
  return number
}

/**
 * 满足指定条件的求和
 * @param arr1
 * @param cond
 * @param arr2
 * @returns {number}
 * @constructor
 */
export const calcSUMIF = (arr1,cond,arr2) => {
  if(!(arr1 instanceof Array) || !(arr1 instanceof Array)){
    throw new Error('参数错误')
  }
  let value = 0
  for (let index of arr1){
    const boolVal = calcImpl(arr1[index] + cond)
    if(boolVal){
      value += parseInt(arr2[index])
    }
  }
  return value
}

/**
 * 字符串拼接
 * @param arg
 * @returns {string}
 * @constructor
 */
export const calcCONCAT = (...arg) => {
  let str = ''
  for (const ele of arg) {
    str += ele
  }
  return str
}

/**
 * 字符串解决
 * @param arg
 * @param start
 * @param len
 * @returns {string|string|*|string}
 * @constructor
 */
export const calcMID = (arg,start,len) => {
  return arg.substr(start,len)
}

/**
 * 求平均值
 * @param arg
 * @returns {number}
 * @constructor
 */
export const calcAVG = (...arg) => {
  let num = 0
  for (const ele of arg) {
    num += parseInt(ele)
  }
  return num/arg.length
}

/**
 * 求最大值
 * @param arg
 * @returns {number}
 * @constructor
 */
export const calcMAX = (...arg) => {
  return Math.max(...arg)
}

/**
 * 求最小值
 * @param arg
 * @returns {number}
 * @constructor
 */
export const calcMIN = (...arg) => {
  return Math.min(...arg)
}

/**
 * 满足条件的个数
 * @param arg
 * @returns {number}
 * @constructor
 */
export const calcCOUNTIF = (...arg) => {
  const criteria = arg[arg.length - 1]
  let countVal = 0
  for (let i = 0; i < arg.length - 1; i++) {
    const boolVal = calcImpl(arg[i] + criteria)
    if(boolVal){
      countVal ++
    }
  }
  return countVal
}
