/*
 * @Description: element
 * @Author: luocheng
 * @Date: 2021-09-13 11:55:24
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-06-30 15:16:01
 */
import Vue from 'vue';
import {
  Loading,
  Message,
  MessageBox,
  Button,
  Dialog,
  Form,
  FormItem,
  Input,
  Tree, Dropdown, DropdownMenu, DropdownItem,
  Row, Col, Drawer, Select, DatePicker, Option,
  RadioButton, RadioGroup, Table, TableColumn,
  Upload, Image, CarouselItem, Carousel, Switch,
  PageHeader,
} from 'element-ui';


Vue.use(Button);
Vue.use(Dialog);
Vue.use(Loading);
Vue.use(Loading.directive);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Drawer);
Vue.use(Select);
Vue.use(DatePicker);
Vue.use(Option);
Vue.use(Col);
Vue.use(Row);
Vue.use(DropdownItem);
Vue.use(DropdownMenu);
Vue.use(Dropdown);
Vue.use(Tree);
Vue.use(RadioButton);
Vue.use(RadioGroup);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Upload);
Vue.use(Image);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Switch);
Vue.use(PageHeader)


Vue.prototype.$loading = Loading.service;
Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;