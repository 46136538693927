<template>
    <div class="moduleWrap">
        <div class="load-wrap">
            <div class="title-wrap">
                <span class="title-wrap_content">标记</span>

                <el-dropdown trigger="click" hide-on-click class="title-wrap_btn" style="margin-right: 10px;" @command="onCreate">
                    <a class="iconfont iconbim_jia"></a>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="createMarker">新增标记</el-dropdown-item>
                        <el-dropdown-item command="createGroup">新增分组</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>

                <el-tooltip :content="isShowAll ? '全部隐藏' : '全部显示'" placement="bottom">
                    <a :class="['title-wrap_btn iconfont', isShowAll ? 'iconbimgis_yincang' : 'iconbimgis_xianshi']" @click="onShowAll"></a>
                </el-tooltip>

                <el-tooltip :content="isExpandAll ? '全部折叠' : '全部展开'" placement="bottom">
                    <a :class="['title-wrap_btn iconfont', isExpandAll ? 'iconshouqiquanbu' : 'iconzhankaiquanbu']" @click="onExpandAll"></a>
                </el-tooltip>
            </div>

            <el-tree ref="markerTree"
                     v-loading="isLoading"
                     :element-loading-background="loadingBackColor"
                     class="markerTree-wrap"
                     empty-text=""
                     :data="markerList"
                     :props="treeProps"
                     node-key="id"
                     :lazy="false"
                     :highlight-current="true"
                     :expand-on-click-node="false"
                     :check-on-click-node="false"
                     :check-strictly="false"
                     :show-checkbox="true"
                     @check=onShowMarker>
                <template #default="{ node, data }">
                    <div :class="['horizontal-flex tree-node']">
                        <a :class="['tree-node-icon iconfont', getTreeNodeIcon(data.type)]" @click.stop="onExpand(node)"></a>
                        <span class="tree-node-content" :title="data.name" @click.stop="onExpand(node)">{{ data.name}}</span>
                        <a class="tree-node-location iconfont icondingwei1" @click="onLocationMarker(data, node)"></a>
                        <el-dropdown trigger="click" hide-on-click class="tree-node-more" @command="onMoreMarker($event, data)">
                            <a class="iconfont icongengduomore"></a>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item v-if="data.type==='Child'" command="rename">重命名</el-dropdown-item>
                                <el-dropdown-item command="update">编辑</el-dropdown-item>
                                <el-dropdown-item command="delete">删除</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </template>
            </el-tree>

            <el-empty v-if="markerList.length === 0" description="什么都没有" class="empty-wrap"></el-empty>
        </div>

        <div :class="['createMarker-wrap', `createMarker-wrap-${subItemSpread}`]" v-show="isShowCreateMarker">
            <div class="title-wrap">
                <span class="title-wrap_content">选择标签样式</span>
                <a class="title-wrap_btn iconfont iconbimgis_guanbi" @click="onCloseCreateMarker"></a>
            </div>

            <el-collapse class="createMarkerTree-wrap" v-model="markerTemplateExpand">
                <el-collapse-item v-for="item in markerTemplateList" :title="item.name" :key="item.id" :name="item.id">
                    <div style="margin: 10px">
                        <el-row :gutter="10">
                            <el-col :span="6" v-for="obj in item.children" :key="obj.id">
                                <div class="vertical-flex" @click="onSelectMarkerTemplate(obj)">
                                    <img :src="obj.img" :class="['back-template', {'back-template_active': markerTemplateActive === obj.id}]">
                                    <div style="text-align: center;">{{obj.name}}</div>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </el-collapse-item>
            </el-collapse>

            <div class="horizontal-flex btnBottom-wrap">
                <div class="btnBottom-wrap_yes" @click="onApplyMarkerTemplate">确认</div>
            </div>
        </div>

        <div :class="['editMarker-wrap', `editMarker-wrap-${subItemSpread}`]" v-show="isShowEditMarker">
            <div class="title-wrap">
                <span class="title-wrap_content">编辑标签</span>
                <a class="title-wrap_btn iconfont iconbimgis_guanbi" @click="onCloseEditMarker"></a>
            </div>

            <div class="editMarkerList-wrap">
                <EditorList
                        ref="editor"
                        :element.sync="element"
                        :bindViewerId="bindViewerId"
                        :editList="editList"></EditorList>
            </div>

            <div class="horizontal-flex btnBottom-wrap">
                <div class="btnBottom-wrap_yes" @click="onSaveEditTemplate">保存</div>
                <div class="btnBottom-wrap_yes" @click="onApplyEditTemplate">应用</div>
                <div class="btnBottom-wrap_cancel" @click="onCloseEditMarker">取消</div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Empty, Tree, Dropdown, DropdownMenu, DropdownItem, Collapse, CollapseItem, Row, Col, Tooltip } from 'element-ui';
    import EditorList from "./components/EditorList";
    import MarkerUtils from "./components/MarkerUtils";

    const markerUtils = new MarkerUtils();
    export default {
        name: "MarkerSingle",
        components: {
            'el-empty': Empty,
            'el-tree': Tree,
            'el-dropdown': Dropdown,
            'el-dropdown-menu': DropdownMenu,
            'el-dropdown-item': DropdownItem,
            'el-collapse': Collapse,
            'el-collapse-item': CollapseItem,
            'el-row': Row,
            'el-col': Col,
            'el-tooltip': Tooltip,
            'EditorList': EditorList
        },
        data() {
            return {
                isLoading: false,
                loadingBackColor: 'rgba(0, 0, 0, 0.1)',
                isShowAll: false,
                isExpandAll: false,

                isShowCreateMarker: false,
                markerTemplateList: [],
                markerTemplateExpand: 'System',
                markerTemplateActive: 'icon',

                isShowEditMarker: false,
                editStatus: '',
                editObject: null,
                originEditList: [
                    {id: 'content', type: 'input', name: '描述（内容）', value: '标记点'},
                    {id: 'iconSetting', type: 'iconSelectorNumber', name: '图标设置', options: [], value: 'location', num: 36},
                    {id: 'fillColor', type: 'colorPicker', name: '填充颜色', value: '#FFFFFF'},
                    {id: 'markerWidth', type: 'input', name: '图标宽度', value: 180, tag: 'Number'},
                    {id: 'markerHeight', type: 'input', name: '图标高度', value: 75, tag: 'Number'},
                    {id: 'backgroundColor', type: 'colorPicker', name: '文本背景颜色', value: '#FFFFFF'},
                    {id: 'startColor', type: 'colorPicker', name: '渐变起始颜色', value: '#FFFFFF'},
                    {id: 'endColor', type: 'colorPicker', name: '渐变终止颜色', value: '#FFFFFF'},
                    {id: 'borderColor', type: 'colorPicker', name: '渐变边框颜色', value: '#FFFFFF'},
                    {id: 'isLeftTurn', type: 'radio', name: '是否左镜像', value: false},

                    {id: 'overflow', type: 'objSelector', name: '文本格式', options: [
                            {id: 'nowrap', name: '单行文本'},
                            {id: 'wrap', name: '多行文本'},
                        ], value: 'wrap'},
                    {id: 'width', type: 'input', name: '宽度设置', value: 150, tag: 'Number'},
                    {id: 'height', type: 'input', name: '宽度设置', value: 150, tag: 'Number'},
                    {id: 'color', type: 'colorPicker', name: '图标颜色', value: '#00a1ff'},
                    {id: 'fontSize', type: 'input', name: '字体大小', value: 12, tag: 'Number'},
                    {id: 'fontColor', type: 'colorPicker', name: '字体颜色', value: '#ffffff'},
                    {id: 'fontAlign', type: 'objSelector', name: '字体排列', options: [
                            {id: 'left', name: '靠左排列'},
                            {id: 'center', name: '居中排列'},
                            {id: 'right', name: '靠右排列'}
                        ], value: 'left'},
                    {id: 'isClampToGround', type: 'radioInfo', name: '是否贴地', value: false}
                ],
                editList: [],

                markerList: [],
                treeProps: {
                    label: 'name',
                    children: 'children',
                    isLeaf: 'isLeaf'
                }
            };
        },
        props: {
            element: {
                type: Object,
                required: true,
                default: () => {
                    return {};
                }
            },
            isGroup: {
                type: Boolean
            },
            groupComponents: {
                type: Array,
                default: () => []
            },
            bindViewerId: {
                type: String,
            },
            bindViewerType: {
                type: String,
                default: 'GIS'
            },
            subItemSpread: {
                type: String,
                default: 'right'
            }
        },
        mounted() {
            markerUtils.initViewerMarker().then(() => {
                this.markerTemplateList = markerUtils.getCollection(this.bindViewerType);
                this.originEditList[1].options = markerUtils.getIconArray();
            });
        },
        methods: {
            /**
             * @description: 获取树节点图标
             * @param {String} type 数据节点类型
             */
            getTreeNodeIcon(type){
                switch (type) {
                    case 'Group':
                        return 'icona-wenjianjiadakai tree-node-icon-file';
                    case 'Child':
                        return 'iconbimgis_biaoji';
                }
            },
            /**
             * @description: 获取编辑页面内容
             * @param {Object} opt 编辑页面数据对象
             * @param {Boolean} isNeedGroup 是否需要分组数据
             */
            getEditList(opt = {}, isNeedGroup = true){
                let res = [];

                if(isNeedGroup){
                    let groupOptions = [];
                    this.markerList.forEach(x => {
                        groupOptions.push({id: x.id, name: x.name});
                    });
                    res.push({id: 'pId', type: 'objSelector', name: '指定分组', options: groupOptions, value: groupOptions[0].id})
                }

                for (let item of this.originEditList){
                    if(opt[item.id] === undefined) continue;

                    let temp;
                    if(item.id === 'iconSetting'){
                        temp = Object.assign(item, {
                            value: opt[item.id].icon,
                            num: opt[item.id].size
                        });
                    }else{
                        temp = Object.assign(item, {
                            value: opt[item.id]
                        });
                    }

                    res.push(temp);
                }

                return res;
            },
            /**
             * @description: 获取编辑页面数据对象
             * @param {String} list 编辑页面内容
             */
            getEditOptions(list){
                let res = {};
                for (let item of list){
                    if(item.id === 'iconSetting'){
                        res[item.id] = {
                            icon: item.value,
                            size: Number(item.num)
                        };
                        continue;
                    }

                    if(item.tag === 'Number'){
                        res[item.id] = Number(item.value);
                    }else{
                        res[item.id] = item.value;
                    }
                }

                return res;
            },
            /**
             * @description: 获取标签样式对象
             * @param {String} data 标签对象样式数据
             */
            getMarkerOptions(data){
                if(data.imageType === 'canvas'){
                    let parent = markerUtils.getMarkerModule(data.markerType);
                    let canvas = markerUtils.getMarkerUrlByType(parent, data.markerType, data.options);
                    data.canvas = canvas;
                }

                return data;
            },
            /**
             * @description: 获取标记树的全部对象
             */
            getAllMarkerItem(){
                const el = this.$refs.markerTree;
                let allNodes = el.store._getAllNodes();
                let nodes = [];

                for (let i = 0; i < allNodes.length; i++) {
                    if(allNodes[i].level === 2) {
                        nodes.push(allNodes[i].data);
                    }
                }

                return nodes;
            },
            /**
             * @description: 获取标记树的指定对象
             * @param {String} key 标签对象id
             */
            getMarkerItem(key){
                const el = this.$refs.markerTree;
                let node = el.getNode(key);
                if (!node) return undefined;

                return node.data;
            },
            /**
             * @description: 获取标记树的已经勾选的对象
             */
            getMarkerCheckedNodes(){
                const el = this.$refs.markerTree;

                let nodes = el.getCheckedNodes();
                let keys = el.getCheckedKeys();

                return {
                    nodes,
                    keys
                };
            },

            /**
             * @description: 设置是否Loading
             * @param {Boolean} isShow 是否显示隐藏
             */
            setIsLoading(isShow = false) {
                this.isLoading = isShow;
            },
            /**
             * @description: 设置树节点数据对象是否勾选
             * @param {String} keys 数据对象id数据
             * @param {Boolean} isChecked 是否勾选
             */
            setTreeNodeCheck(keys, isChecked = true) {
                const el = this.$refs.markerTree;
                for (let item of keys) {
                    let node = el.getNode(item);
                    if (!node) continue;

                    el.setChecked(item, isChecked, true);
                }
            },
            /**
             * @description: 设置全部树节点数据对象是否勾选
             * @param {Boolean} isChecked 是否勾选
             */
            setAllNodeCheck(isChecked = true){
                const el = this.$refs.markerTree;

                let allNodes = el.store._getAllNodes();
                for (let i = 0; i < allNodes.length; i++) {
                    if(allNodes[i].level !== 1) continue;

                    el.setChecked(allNodes[i], isChecked, true);
                }
            },
            /**
             * @description: 显示编辑面板--创建标记时的临时对象
             * @param {String} data 数据对象
             */
            setEditorByActive(data){
                let {options} = data;
                this.editList = this.getEditList(options, true);
                this.isShowEditMarker = true;
                this.editStatus = 'active';
                this.editObject = data;
            },
            /**
             * @description: 显示编辑面板--修改标记时的对象
             * @param {String} data 数据对象
             */
            setEditorByUpdate(data){
                let {options} = data;
                this.editList = this.getEditList(options, false);
                this.isShowEditMarker = true;
                this.editStatus = 'update';
                this.editObject = data;
            },
            /**
             * @description: 清空编辑面板
             */
            setEditorByEmpty(){
                this.isShowEditMarker = false;
                this.editList = [];
                this.editStatus = '';
                this.editObject = null;
            },

            /**
             * @description: 添加树节点对象
             * @param {String} data 数据对象
             * @param {String} pId 数据对象父节点Id
             * @param {Boolean} isChecked 是否勾选
             */
            appendTreeData(data, pId = null, isChecked = false){
                const el = this.$refs.markerTree;
                let node = el.getNode(data.id);

                if (node) return false;
                el.append(data, pId);

                if(isChecked) {
                    this.$nextTick(() => {
                        this.setTreeNodeCheck([data.id]);
                    });
                }
                return true;
            },
            /**
             * @description: 更新树节点对象
             * @param {String} id 数据对象id
             * @param {String} name 修改键名称
             * @param {String} value 修改键值
             */
            updateTreeData(id, name, value){
                const el = this.$refs.markerTree;
                let node = el.getNode(id);
                if (!node) return false;

                node.data[name] = value;
                return true;
            },
            /**
             * @description: 删除树节点对象
             * @param {String} id 数据对象id
             */
            deleteTreeData(id){
                const el = this.$refs.markerTree;
                let node = el.getNode(id);
                if (!node) return false;

                el.remove(id);
                return true;
            },
            /**
             * @description: 清空树节点对象
             */
            emptyTreeData(){
                this.markerList = [];
            },

            /**
             * @description: 展开全部
             */
            onExpandAll(){
                this.isExpandAll = !this.isExpandAll;
                const el = this.$refs.markerTree;

                for (var i = 0; i < el.store._getAllNodes().length; i++) {
                    el.store._getAllNodes()[i].expanded = this.isExpandAll;
                }
            },
            /**
             * @description: 展开指定对象
             * @param {Object} node 指定数据对象
             */
            onExpand(node){
                node.expanded = !node.expanded;
            },
            /**
             * @description: 显示全部
             */
            onShowAll(){
                this.isShowAll = !this.isShowAll;
                this.$emit('showAll', this.isShowAll);
            },

            /**
             * @description: 创建下拉事件
             * @param {String} command 选择的创建命令
             */
            onCreate(command){
                switch (command) {
                    case 'createMarker':
                        if(this.markerList.length === 0){
                            this.$message.error('暂无分组，请先创建分组');
                            return;
                        }
                        this.markerTemplateActive = 'icon';
                        this.isShowCreateMarker = true;
                        break;
                    case 'createGroup':
                        this.$emit('createGroup');
                        break;
                }
            },
            /**
             * @description: 标签模板选择事件
             * @param {String} item 选择的标签模板对象
             */
            onSelectMarkerTemplate(item){
                this.markerTemplateActive = item.id;
            },
            /**
             * @description: 标签模板应用事件
             */
            onApplyMarkerTemplate(){
                this.onCloseCreateMarker();

                let parent = markerUtils.getMarkerModule(this.markerTemplateActive);
                let imageType = markerUtils.getImageType(this.markerTemplateActive);
                let options = markerUtils.getMarkerOptByType(parent, this.markerTemplateActive);

                let data = {
                    imageType: imageType,
                    markerType: this.markerTemplateActive,
                    options: options
                };
                if(imageType === 'canvas'){
                    let canvas = markerUtils.getMarkerUrlByType(parent, this.markerTemplateActive, options);
                    data.canvas = canvas;
                }

                this.$emit('createActiveMarker', data);
            },
            /**
             * @description: 标签模板关闭事件
             */
            onCloseCreateMarker(){
                this.isShowCreateMarker = false;
            },

            /**
             * @description: 编辑标签保存事件
             */
            onSaveEditTemplate(){
                let options = this.getEditOptions(this.editList);
                if(this.editObject.imageType === 'canvas'){
                    let parent = markerUtils.getMarkerModule(this.editObject.markerType);
                    let canvas = markerUtils.getMarkerUrlByType(parent, this.editObject.markerType, options);
                    this.editObject.canvas = canvas;
                }
                if(!this.editObject.options) this.editObject.options = {};
                this.editObject.options = Object.assign(this.editObject.options, options);

                if(this.editStatus === 'active'){
                    this.$emit('createMarker', this.editObject);
                }else if(this.editStatus === 'update'){
                    this.$emit('saveUpdateMarker', this.editObject);
                }
            },
            /**
             * @description: 编辑标签应用事件
             */
            onApplyEditTemplate(){
                let options = this.getEditOptions(this.editList);
                if(this.editObject.imageType === 'canvas'){
                    let parent = markerUtils.getMarkerModule(this.editObject.markerType);
                    let canvas = markerUtils.getMarkerUrlByType(parent, this.editObject.markerType, options);
                    this.editObject.canvas = canvas;
                }
                if(!this.editObject.options) this.editObject.options = {};
                this.editObject.options = Object.assign(this.editObject.options, options);

                if(this.editStatus === 'active'){
                    this.$emit('updateActiveMarker', this.editObject);
                }else if(this.editStatus === 'update'){
                    this.$emit('updateMarker', this.editObject);
                }
            },
            /**
             * @description: 编辑标签关闭事件
             */
            onCloseEditMarker(){
                if(this.editStatus === 'active'){
                    this.$emit('removeActiveMarker');
                }else if(this.editStatus === 'update'){
                    this.$emit('resetMarker');
                }
            },

            /**
             * @description: 标记列表显示隐藏事件
             * @param {Object} data 树节点对象
             */
            onShowMarker(data){
                const el = this.$refs.markerTree;
                const node = el.getNode(data.id);
                const isChecked = node.checked;

                this.$emit('showMarker', data, isChecked);
            },
            /**
             * @description: 标记列表对象定位事件
             * @param {Object} data 数据对象
             * @param {Object} node 节点对象
             */
            onLocationMarker(data, node){
                this.$emit('locationMarker', data, node.checked);
            },
            /**
             * @description: 标记列表更多事件
             * @param {string} command 命令类型
             * @param {Object} data 树节点对象
             */
            onMoreMarker(command, data){
                switch (command) {
                    case 'rename':
                        this.$emit('renameMarker', data);
                        break;
                    case 'update':
                        if(data.type === 'Group'){
                            this.$emit('updateGroup', data);
                        }else if(data.type === 'Child'){
                            this.$emit('preUpdateMarker', data);
                        }
                        break;
                    case 'delete':
                        if(data.type === 'Group'){
                            this.$emit('removeGroup', data);
                        }else if(data.type === 'Child'){
                            this.$emit('removeMarker', data);
                        }
                        break;
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    .moduleWrap {
        width: 100%;
        height: 100%;
    }

    .load-wrap {
        width: 100%;
        height: 100%;
    }

    .createMarker-wrap{
        z-index: 2000;
        position: absolute;
        top: 0;
        /*left: calc(100% + 10px);*/
        width: 400px;
        height: 400px;
        overflow: hidden;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: inherit;
        background-color: white;
        color: inherit;
    }

    .createMarker-wrap-left{
        right: calc(100% + 10px);
    }

    .createMarker-wrap-right{
        left: calc(100% + 10px);
    }

    .editMarker-wrap{
        z-index: 2000;
        position: absolute;
        top: 0;
        /*left: calc(100% + 10px);*/
        width: 300px;
        height: 100%;
        overflow: hidden;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: inherit;
        background-color: white;
        color: inherit;
    }

    .editMarker-wrap-left{
        right: calc(100% + 10px);
    }

    .editMarker-wrap-right{
        left: calc(100% + 10px);
    }

    .title-wrap {
        width: 100%;
        height: 38px;
        line-height: 38px;
        box-sizing: border-box;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .title-wrap_content {
        float: left;
        padding-left: 10px;
        font-size: 14px;
        color: inherit;
    }

    .title-wrap_btn {
        float: right;
        margin-top: 7px;
        width: 24px;
        height: 24px;
        line-height: 24px;
        font-size: 18px;
        color: inherit;
        border-radius: 4px;
    }

    .title-wrap_btn:hover{
        background-color: rgba(0, 118, 255, 0.1);
    }

    .btnBottom-wrap{
        width: 100%;
        height: 50px;
        line-height: 50px;

        .btnBottom-wrap_cancel {
            float: right;
            margin-right: 10px;
            margin-top: 10px;
            width: 60px;
            height: 32px;
            line-height: 32px;
            font-size: 14px;
            border: none;
            border-radius: 4px;
            background-color: #EFF1F5;
            color: black;
            font-weight: 500;
            text-align: center;
        }

        .btnBottom-wrap_cancel:hover {
            opacity: 0.7;
        }

        .btnBottom-wrap_yes{
            float: right;
            margin-right: 15px;
            margin-top: 10px;
            width: 60px;
            height: 32px;
            line-height: 32px;
            font-size: 14px;
            border: none;
            border-radius: 4px;
            background-color: #206EFC;
            color: white;
            font-weight: 500;
            text-align: center;
        }

        .btnBottom-wrap_yes:hover {
            opacity: 0.7;
        }
    }

    .markerTree-wrap{
        background-color: transparent !important;
        color: inherit !important;
        overflow-y: auto;
        width: 100%;
        height: calc(100% - 38px);

        :deep(.el-tree-node__content) {
            height: 32px !important;
            background-color: transparent !important;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            color: inherit;
        }

        :deep(.el-tree-node__content:hover) {
            background-color: rgba(131, 131, 131, 0.5) !important;
        }

        :deep(.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content) {
            background-color: rgba(131, 131, 131, 0.5) !important;
        }

        :deep(.el-tree-node__expand-icon){
            color: inherit;
            font-size: 14px;
        }

        :deep(.el-tree-node__content>label.el-checkbox) {
            position: absolute !important;
            right: 0;
            margin-right: 34px !important;
            line-height: 35px;
            color: inherit;
        }

        :deep(.el-checkbox__inner) {
            background-color: transparent !important;
            border: none;
            width: 24px;
            height: 24px;
            line-height: 24px;
            color: inherit;
        }

        :deep(.el-checkbox__inner::after) {
            font-family: "iconfont";
            content: "\e745";
            color: inherit;
            font-size: 18px;
            position: absolute;
            top: 0;
            left: 0;
            width: 24px;
            height: 24px;
            line-height: 24px;
            border: none;
            background-color: transparent !important;
            transform: none !important;
            transition: none !important;
        }

        :deep(.el-checkbox__input.is-checked .el-checkbox__inner::after) {
            font-family: "iconfont";
            content: "\e739";
            color: #0076FF;
            font-size: 18px;
            background-color: transparent !important;
            transform: none !important;
            /*transition: none !important;*/
        }

        :deep(.el-checkbox__input.is-indeterminate .el-checkbox__inner::before) {
            font-family: "iconfont";
            content: "\e745";
            color: inherit;
            font-size: 18px;
            position: absolute;
            top: 0;
            left: 0;
            width: 24px;
            height: 24px;
            line-height: 24px;
            border: none;
            background-color: transparent !important;
            transform: none !important;
            transition: none !important;
        }

        :deep(.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner) {
            background-color: transparent !important;
        }
    }

    .empty-wrap {
        position: absolute;
        top: 38px;
        width: 100%;
        background-color: transparent !important;
        color: inherit !important;
        overflow-y: auto;
        height: calc(100% - 38px);

        :deep(.el-empty__description p) {
            color: inherit !important;
        }
    }

    .createMarkerTree-wrap{
        overflow-y: auto;
        width: 100%;
        height: calc(100% - 90px);

        :deep(.el-collapse-item__header){
            padding-left: 10px;
        }
    }

    .editMarkerList-wrap{
        overflow-y: auto;
        width: 100%;
        height: calc(100% - 90px);
    }

    .tree-node {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        color: inherit;
        font-size: 14px;
        width: 88%;
        height: 35px;
        line-height: 35px;
        padding-right: 60px;
    }

    .tree-node-icon {
        margin-right: 3px;
        margin-left: -5px;
        width: 24px;
        height: 24px;
        line-height: 24px;
    }

    .tree-node-icon-file {
        color: #6DD3FD;
    }

    .tree-node-icon-obj {
        color: inherit;
    }

    .tree-node-content {
        text-align: left;
        width: -webkit-fill-available;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .tree-node-location{
        width: 24px;
        height: 24px;
        line-height: 24px;
        font-size: 18px;
        color: inherit;
    }

    .tree-node-location:hover{
        color: #1E90FF;
    }

    .tree-node-more{
        position: absolute;
        right: 10px;
        width: 24px;
        height: 24px;
        line-height: 24px;
        font-size: 18px !important;
        color: inherit;
    }

    .tree-node-more:hover{
        color: #1E90FF;
    }

    .back-template{
        width: -webkit-fill-available;
        height: 80px;
        background-size: cover;
        background-position: center;
        box-sizing: border-box;
        opacity: unset !important;
    }

    .back-template:hover{
        border: 1px solid #1E90FF;
    }

    .back-template_active{
        border: 1px solid #1E90FF !important;
    }
</style>
