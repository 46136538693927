/*
 * @Author: 冉桂精 156189868@qq.com
 * @Date: 2023-01-04 09:41:36
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-01-04 10:05:33
 * @FilePath: /dataview-next/src/custom-component/databaseTriggerMixin.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { removeEventBus } from '@/utils/tools';

export default {
  props: {
    element: {
      type: Object,
      isRequired: true,
      default: () => { }
    }
  },
  inject: ['EDITOR_pageUUID'],
  data() {
    return {
      $elementId: null,
    }
  },
  created() {
    this.$elementId = this.element.id;
  },
  beforeDestroy() {
    if (!this.EDITOR_pageUUID) {
      removeEventBus('databaseTrigger', this.$elementId);
    }
  }
}