<!--
 * @Author: your name
 * @Date: 2021-12-02 15:32:01
 * @LastEditTime: 2023-05-25 11:51:26
 * @LastEditors: Shiltin 18580045074@163.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \nanhu-micro\src\views\components\bindModel\index.vue
-->
<template>
	<div class="model-choose-cont left-list-hide">
		<div class="list relative">
			<el-page-header
				class="page-header"
				content="全景位置绑定"
				@back="back"
			/>
			<el-button
				class="absolute"
				size="mini"
				style="top: 8px; right: 80px"
				type="primary"
				@click="rebind"
			>
				重绑
			</el-button>
			<el-button
				class="absolute"
				size="mini"
				style="top: 8px; right: 10px"
				type="primary"
				@click="commit()"
			>
				确定
			</el-button>
		</div>
		<preview
			v-if="previewShow"
			ref="panoPreview"
			class="preview-cont"
			:model-data="data"
			@set-data="setData"
		/>
	</div>
</template>

<script>
import Preview from './preview.vue';
import { PageHeader, Button } from 'element-ui';
export default {
	name: 'BindGisIndex',
	components: {
		Preview,
		'el-button': Button,
		'el-page-header': PageHeader
	},
	props: {
		modelData: {
			type: Object,
			default: null
		},
	},
	data() {
		return {
			previewShow: true,
			selectInfo: null,
			data:{panoId:"de84892f-b8fe-4f38-b2b0-1ff7d69229af",position:{mx: 1074, my: 615, ath: "22.51", atv: "11.66",id: 10}}
		};
	},
	methods: {
		back() {
			this.$emit('back');
		},
		setData(data) {
			this.selectInfo = data;
		},
		rebind(){
			this.$refs.panoPreview.rebind()
		},	
		commit() {
			if (this.selectInfo !== null) {
				this.$emit('set-data', this.selectInfo);
			} else {
				this.$message.warning('请标记全景位置！');
			}
		}
	}
};
</script>
<style lang="less" scoped>
.model-choose-cont {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	background-color: #fff;
	overflow: hidden;
	.list {
		width: 300px;
		height: 100%;
		z-index: 120;
		border-right: 1px solid var(--el-border-color-base);
		.page-header {
			line-height: 45px;
			padding-left: 15px;
			border-bottom: 1px solid var(--el-border-color-base);
		}
		.list-cont {
			height: calc(100% - 45px);
		}
	}
	.preview-cont {
		height: 100%;
		flex: 1;
	}
	.other-btn {
		position: absolute;
		top: 10px;
		left: 310px;
		height: 20px;
		z-index: 100;
		i {
			font-size: 18px;
		}
	}
}
.left-list-hide {
	flex-direction: column !important;
	.list {
		width: 100%;
		height: 46px;
	}
	.other-btn {
		left: 15px !important;
		top: 50px !important;
	}
}
</style>
