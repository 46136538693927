<!--
 * @Description: 类型列表
 * @Author: luocheng
 * @Date: 2022-09-22 17:20:17
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-07-06 16:33:43
-->
<template>
	<div class="out-type" v-if="treeProps && nodeKey">
		<el-tree
      ref="treeContent"
      :data="treeData"
      :highlight-current="true"
      :current-node-key="currentNodeKey"
      :node-key="nodeKey"
      :props="treeProps"
      @node-click="onNodeClick">
    </el-tree>
	</div>
</template>

<script>
import { dataInterface } from '@/apis/data';
import { Tree } from 'element-ui';
import { mapState } from 'vuex';
import { initParams } from '@/utils/tools';

export default {
	name: 'OutType',
  components: {
    'el-tree': Tree
  },
	data() {
		return {
			treeData: [],
			treeProps: null,
      currentNodeKey: 0
		};
	},
	inject: ['element'],
  created() {
    this.getTreeData();
    this.treeProps = {
      children: this.operationConfig.childrenUUID,
      label: this.operationConfig.labelUUID
    };
    this.nodeKey = this.operationConfig?.idUUID;
  },
  computed: {
    ...mapState([
      'componentData'
    ]),
    // 文件操作配置
    operationConfig() {
      return this.element?.operationConfig || {};
    }
  },
	methods: {
    /**
     * @desc: 获取树形数据
     */
    getTreeData() {
      const {
				param = {},
				search = [],
				canPost
			} = initParams(
				this.operationConfig?.params || [],
				this.isGroup,
				this.componentList || this.componentData,
				this.groupComponents
			);
      if (!canPost) return;
      dataInterface({
        __method_name__: 'dataList',
        object_uuid: this.operationConfig.objectUUID,
        view_uuid: this.operationConfig.viewUUID,
        ...param,
        search
      }).then(res => {
        this.treeData = res.data?.data || [];
        // 默认选中第一个
        if (this.treeData.length) {
          this.currentNodeKey = this.treeData[0]?.[this.operationConfig.idUUID];
          this.onNodeClick(this.treeData[0]);
        }
      }).catch(err => {
        console.log(err, '99900---');
      });
    },
		/**
		 * @desc: 点击节点
		 */
		onNodeClick(data) {
			this.$refs.treeContent.setCurrentKey(data?.[this.operationConfig.idUUID]);
      this.$emit('getTypes', data);
    }
	}
};
</script>

<style lang="less" scoped>
.out-type {
	width: 240px;
  margin-right: 16px;
  height: 100%;
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid #f2f3f5;
  border-radius: 4px;
  .el-tree {
    height: 100%;
    width: 100%;
    overflow: hidden;
    overflow-y: auto;
  }
}
</style>
