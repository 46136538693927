<!--
 * @Author: wsp 542278473@qq.com
 * @Date: 2022-11-30 14:17:40
 * @LastEditors: wsp 542278473@qq.com
 * @LastEditTime: 2022-12-02 15:08:16
 * @FilePath: \dataview-next\src\custom-component\login\components\BindPhone.vue
 * @Description: 绑定手机，暂无接口，单纯的静态页面
-->
<template>
  <div class="bind-phone-wrap">
    <h4 class="title">绑定手机号</h4>
    <div class="form-wrap">
      <el-form
        class="setphone-form"
        ref="setphoneForm"
        :model="phoneFormData"
        :rules="phoneFormRules"
        :hide-required-asterisk="true"
        :show-message="false"
        size="lager"
      >
        <el-form-item>
          <el-input placeholder="请输入手机号">
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-input class="code-input" placeholder="请输入验证码">
            <span slot="suffix">
              <el-button type="text">获取验证码</el-button>
            </span>
          </el-input>
        </el-form-item>
      </el-form>
      <el-button style="width: 100%" size="lager" type="primary">完成</el-button>
    </div>
    <footer class="footer">
      <el-button type="text">点击跳过</el-button>
    </footer>
  </div>
</template>
<script>
export default {
  data () {
    return {
      phoneFormData: {},
      phoneFormRules: {}
    }
  }
}
</script>
<style scoped lang="less">
  .bind-phone-wrap{
    width: 100%;
    height: 100%;
    padding: 0 24px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    .title{
      margin-top: 48px;
      margin-bottom: 30px;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      color: #3C3C3C;
    }
    .form-wrap{
      flex-grow: 1;
      height: 10px;
      .setphone-form{
        width: 100%;
        :deep(.code-input) {
          .el-input__inner{
            padding: 0 90px 0 15px;
          }
        }
      }
    }
    .footer{
      text-align: right;
      margin: 8px 0;
    }
  }
</style>