<!--
 * @Description: 分页器
 * @Author: luocheng
 * @Date: 2021-09-10 17:18:41
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-08-04 09:31:34
-->
<template>
  <!-- 分页器 -->
  <!-- 验证有问题暂时放弃 -->
  <!-- :pager-count="+statusConfig['pager-count']" -->
  <el-pagination v-if="pager && typeof pager === 'object'"
    :small="statusConfig.small"
    :background="statusConfig.background"
    :layout="statusConfig.layout"
    :prev-text="statusConfig['prev-text']"
    :next-text="statusConfig['next-text']"
    :disabled="statusConfig.disabled"
    :hide-on-single-page="statusConfig['hide-on-single-page']"
    @size-change="onSize"
    @current-change="onPage"
    :current-page="+pager.current_page"
    :page-sizes="sizes"
    :page-size="+pager.per_page"
    :total="pager.total">
  </el-pagination>
</template>

<script>
import { Pagination } from 'element-ui';
import { getComponentById } from '@/utils/tools';
import { mapState } from 'vuex';
import eventBus from '@/plugins/eventBus';

export default {
  name: 'CommonPagination',
  components: {
    'el-pagination': Pagination
  },
  props: {
    element: {
      type: Object,
      default: () => {},
      required: true
    },
    fullData: {
			type: [Object, Array],
      required: false
    },
    // 是否在组合内
    isGroup: {
			type: Boolean,
		},
    // 组合内组件列表
		groupComponents: {
			type: Array,
			default: () => []
		},
    componentList: {
      default: null
    }
  },
  data() {
    return {
      // 每页条数备选
      size: 15,
      page: 1
    }
  },
  inject: ['EDITOR_pageUUID'],
  computed: {
    ...mapState([
      'componentData',
      'subsidiaryComponentData'
    ]),
    // 嵌入页面的参数获取
    subComponentData() {
      if (this.EDITOR_pageUUID) {
				return this.subsidiaryComponentData?.[this.EDITOR_pageUUID]?.componentData || this.componentList || this.componentData || [];
			}
			return this.componentList || this.componentData || [];
    },
    // 组件配置
    statusConfig() {
      return this.element ? this.element.statusConfig || {} : {};
    },
    pageSize() {
      return this.statusConfig.size || 15
    },
    sizes() {
      const base = this.pageSize
      if(base > 0) {
        return [1, 2, 4, 8].map(el => el * base)
      } else {
        return [15, 30, 50, 100]
      }
    },
    // 数据源配置
    database() {
      return this.element && this.element.database;
    },
    pager() {
      if (!this.database || !this.database.containerKey) return [];
      let container = getComponentById(this.subComponentData, this.database.containerKey);
      if (!container && this.isGroup && this.groupComponents.length) {
				container = getComponentById(this.groupComponents, this.database.containerKey);
			}
      if (!Array.isArray(container?.containerData)) return [];
      return container.fullData;
    }
  },
  created() {
    // 初始化size值
    if(this.statusConfig.size && this.statusConfig.size > 0) {
      this.size = isNaN(+this.statusConfig.size) ? 15 : +this.statusConfig.size;
    }
    this.reRender();
  },
  watch: {
    pager: {
      handler(newv) {
        if (newv) {
          this.page = this.pager.current_page || 1;
          // this.size = this.pager.per_page || 15;
        }
      },
      deep: true
    }
  },
  mounted() {
    setTimeout(() => {
      this.reRender();
    }, 100)
  },
  methods: {
    /**
     * @desc: 切换size
     * @param {Number} size 每页条数
     */
    onSize(size) {
      this.size = size;
      this.page = 1;
      this.reRender();
    },
    /**
     * @desc: 切换页面
     * @param {Number} page 页码
     */
    onPage(page) {
      this.page = page;
      this.reRender();
    },
    /**
     * @desc: 触发请求
     */
    reRender() {
      this.$store.commit('modifyComponent', {
        component: {
          ...this.element,
          resolveData: {
            page: this.page,
            size: this.size
          }
        },
        containerId: null,
        isModify: true,
        pageUUID: this.EDITOR_pageUUID
      });
      eventBus.$emit('databaseTrigger', {
        componentId: this.element.id,
        action: 'list',
        output: {
          page: this.page,
          size: this.size
        }
      });
    }
  }
}
</script>

<style lang="less" scoped>
.common-pagination{
  width: 100%;
}
</style>