<!--
    @name: EventProp
    @description：EventProp
    @author: ZengWei
    @date: 2022-08-27 11:27
-->
<template>
  <div></div>
</template>

<script>
export default {
  name: 'EventProp',
  components: {},
  props: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped></style>
