<template>
	<div class="pano-container">
		<div class="pano-header">
			<div
				:class="['pano-header-btn', isCompare ? 'isCompare' : 'noCompare']"
				@click="handleComparePano"
			>
				{{ isCompare ? "全景查看" : "全景对比" }}
				<i class="iconxiangyou-copy iconfont"></i>
			</div>
			<div class="dataview-header-select">
				<el-select
					class="w-full"
					v-model="path_left"
					@change="changeLeft"
					placeholder="请选择全景"
				>
					<el-option-group
						v-for="group in panoList"
						:key="group.id"
						:label="group.name"
					>
						<el-option
							v-for="item in group.panos"
							:key="item.id"
							:label="item.pano_name+'/'+item.pano_time"
							:value="item.pano_path"
						>
						</el-option>
					</el-option-group>
				</el-select>
			</div>
			<div
				v-if="isCompare"
				class="dataview-header-select pano-header-right-select"
			>
				<el-select
					class="w-full"
					v-model="path_right"
					@change="changeRight"
					placeholder="请选择全景"
				>
					<el-option
						v-for="item in rightPanoData"
						:key="item.id"
						:label="item.pano_name+'/'+item.pano_time"
						:value="item.pano_path"
					>
					</el-option>
				</el-select>
			</div>
		</div>
		<div class="pano-content">
			<iframe
				:src="isCompare ? comparePath : panoPath_left"
				frameborder="0"
				style="width: 100%; height: 100%"
			></iframe>
		</div>
	</div>
</template>

<script>
import { dataInterface } from '@/apis/data';
import { OptionGroup } from 'element-ui';
export default {
	name: 'PanoContent',
	data() {
		return {
			isCompare: false, // 是否对比
			panoList: [],
			rightPanoData: [],
			panoPath_left: '',
			path_left: '',
			path_right: ''
		};
	},
	components: {
		'el-option-group': OptionGroup
	},
	computed: {
		comparePath() {
			return `https://pano.bimcc.net/splitscreen?pano_1=${this.path_left}&pano_2=${this.path_right}`;
		}
	},
	methods: {
		/**
		 * @description: 全景数据
		 */
		async getPanoDataList() {
			let archObj = JSON.parse(localStorage.getItem('targetArchi'));
			this.panoList = [];
			this.rightPanoData = [];
			dataInterface({
				__method_name__: 'dataList',
				object_uuid: 'object61e1275be950e',
				view_uuid: 'view61e127c50f8c2',
				search: [
					{ code: 'archi_type', ruleType: 'eq', value: archObj.data_id }
				],
				transcode: 0
			}).then((res) => {
				if (res.data.code === 200 && res.data.data?.data) {
					let resultData = res.data.data.data;
					this.handlePanoData(resultData);
				}
			});
		},
		/**
		 * @description: 处理全景数据
		 * @param {Array} resultData
		 */
		handlePanoData(resultData = []) {
			if (
				Array.isArray(resultData) &&
				resultData.length > 0 &&
				Array.isArray(resultData[0].panos) &&
				resultData[0].panos[0]
			) {
				const { pano_path } = resultData[0].panos[0];
				if (pano_path.indexOf('https') > -1) {
					this.panoPath_left = pano_path;
				} else {
					this.panoPath_left = `https://pano.bimcc.net/pc/preView?id=${pano_path}`;
					this.path_left = `${pano_path}`;
				}
				this.rightPanoData = resultData[0].panos;
			}
			this.panoList = resultData;
		},
		/**
		 * @description: 左侧
		 * @param {String} data
		 */
		changeLeft(data) {
			this.panoPath_left = `https://pano.bimcc.net/pc/preView?id=${data}`;
			this.path_left = `${data}`;
			for (let i = 0, len = this.panoList.length; i < len; i++) {
				const el = this.panoList[i];
				if (el.panos && el.panos.length) {
					for (let j = 0, len_ = el.panos.length; j < len_; j++) {
						const res = el.panos[j];
						if (res.id === data.id) {
							this.rightPanoData = el.panos;
							break;
						}
					}
				}
			}
			// 已有右侧对比
			if (this.path_right) {
				let temp = this.rightPanoData.filter(
					(item) => item.pano_path !== this.path_left
				);
				if (Array.isArray(temp) && temp.length > 0) {
					const { pano_path } = temp[0];
					this.path_right = pano_path;
				} else {
					this.path_right = this.path_left;
				}
			}
		},
		/**
		 * @description: 右边全景
		 * @param {String} data
		 */
		changeRight(data) {
			this.path_right = `${data}`;
		},
		/**
		 * @description: 全景对比
		 */
		handleComparePano() {
			this.isCompare = !this.isCompare;
			if (!this.path_right) {
				let temp = this.rightPanoData.filter(
					(item) => item.pano_path !== this.path_left
				);
				if (Array.isArray(temp) && temp.length > 0) {
					const { pano_path } = temp[0];
					this.path_right = pano_path;
				} else {
					this.path_right = this.path_left;
				}
			}
		}
	},
	mounted() {
		this.getPanoDataList();
	}
};
</script>

<style lang="less">
.dataview-header-select .ivu-select-selection {
	background: no-repeat !important;
	border: none !important;
}
.dataview-header-select .ivu-select-selection .ivu-select-placeholder {
	color: #ffffff !important;
}
</style>
<style lang="less" scoped>
.pano-container {
	position: relative;
	width: 100%;
	height: 100%;
	.w-full {
		width: 100%;
	}
	.pano-header {
		position: absolute;
		width: 100%;
		height: 40px;
		top: 20px;
		padding: 0 20px;
		text-align: left;
		font-size:12px;
		.dataview-header-select {
			display: inline-block;
			width: 250px;
			height: 40px;
			line-height: 40px;
			color: #ffffff;
			background: url(//static.bimcc.com/images/saiDiPandect/quanjing_kuang.png)
				no-repeat center;
			background-size: 100% 100%;
			background-color: rgba(2, 122, 180, 0.2);
		}
		.pano-header-right-select {
			margin-left: calc(~'50% - 300px');
		}
		.pano-header-btn {
			display: inline-block;
			width: 90px;
			height: 40px;
			line-height: 40px;
			margin-right: 10px;
			vertical-align: bottom;
			text-align: center;
			color: #ffffff;
			background-color: rgba(2, 122, 180, 0.2) !important;
			cursor: pointer;
			font-size: 13px;
			i{ 
				font-size:15px;
			}
		}
		.isCompare {
			background: url(//static.bimcc.com/images/saiDiPandect/quanjing_btn_pre.png)
				no-repeat center;
			background-size: 100% 100%;
		}
		.noCompare {
			background: url(//static.bimcc.com/images/saiDiPandect/quanjing_btn.png)
				no-repeat center;
			background-size: 100% 100%;
		}
	}
	.pano-content {
		display: flex;
		width: 100%;
		height: 100%;
	}
	:deep(.el-input__inner) {
		background: transparent !important;
		border: none !important;
		color: #fff !important;
	}
}
</style>