<!--
 * @Author: 吴绍鹏 542278473@qq.com
 * @Date: 2023-04-06 17:32:23
 * @LastEditors: 吴绍鹏 542278473@qq.com
 * @LastEditTime: 2023-04-07 18:03:21
 * @FilePath: \dataview-next\src\custom-component\video\player\Rocker.vue
 * @Description: 视频监控控制
-->
<template>
  <div class="ptz-control-wrap">
    <section style="display:flex;align-items: center;">
      <div class="radius-block">
        <div class="roker-container">
          <div id="nipple_container" v-ptzDirectives="handlePTZMove" >
          </div>
        </div>
      </div>
      <div class="btn-arr">
        <span>
          <el-button class="action-button" type="text" @click="handleScaleChange('scaleup')">
            <i class="iconfont iconbim_jia" @click="handleScaleChange('scaleup')"/>
          </el-button>
        </span>
        <span>
          <el-button class="action-button" type="text" @click="handleScaleChange('scaledown')">
            <i class="iconfont iconbim_jian" @click="handleScaleChange('scaledown')"/>
          </el-button>
        </span>
        <span>
          <el-button :loading="collectLoading" class="action-button" type="text" @click="doCollect">
            <i v-if="!collectLoading" class="iconfont iconc-star"/>
          </el-button>
        </span>
      </div>
    </section>
    <section class="bottom-content">
      <div>
        <div v-show="!showList" class="list-title" @click="showListFun">
          <div class="list-title-i">
            <el-button class="action-button" type="text">
              <i class="iconfont iconfold_" />
            </el-button>
          </div>
        </div>
        <div v-show="showList" class="like-list-title">
          <span style="font-size: 12px;">收藏列表</span>
          <span>
            <el-button v-show="showEdit" class="action-button" type="text" @click="doCancle">
              取消
            </el-button>
            <el-button :loading="saveLoading" v-show="showEdit" class="action-button" type="text" @click="doSave">
              保存
            </el-button>
          </span>
          <span v-show="!showEdit">
            <el-button class="action-button" type="text" @click="showEdit=true">
              <i class="iconfont iconbianji2"/>
            </el-button>
            <el-button class="action-button" type="text" @click="showList=false">
              <i class="iconfont iconfold"/>
            </el-button>
          </span>
        </div>
      </div>
      <div v-show="showList" v-loading="listLoading" class="like-list-content">
        <div v-for="(item,index) in collectList" :key="index" class="img-content" @click="jumpPosition(item)">
          <img alt="" :src="item.image_url">
          <p v-show="!showEdit" class="p-font">
            {{ item.name }}
          </p>
          <div v-show="showEdit" class="edit-wrap" @click.stop="">
            <el-button type="danger" size="mini" @click.stop="deleteItem(item)" >
              <i class="iconfont icongis_shanchu"/> 删除
            </el-button>
            <el-input size="mini" v-model="item.name" placeholder="请输入..."></el-input>
          </div>
        </div>
        <el-empty
          v-if="!collectList.length"
          description="暂无数据"
        ></el-empty>
      </div>
    </section>
  </div>
</template>
<script>
import nipplejs from 'nipplejs';
import { Empty } from 'element-ui'
import { cptureImgApi, jumpPositionApi, monitorCollectionListApi, monitorCollectionAddApi, monitorCollectionEditApi, monitorCollectionDelApi } from '@/apis/data/monitor';
export default{
  components: {
    'el-empty': Empty
  },
  props: {
    // 监控的数据
    voideData: {
      type: Object,
      default() {
        return {}
      },
      require: true
    }
  },
  directives: {
    /**
     * 云台控制指令
     */
    ptzDirectives: {
      inserted: (el, binding) => {
        const options = {
          mode: 'static',
          size: 100,
          force: 0.2,
          pressure: 0.1,
          color: 'ddd',
          position: {
            left: '50%',
            top: '50%'
          },
          zone: el
        };
        const manager = nipplejs.create(options);
        manager.on('move', (evt, data) => {
          binding.value(evt, data);
        });
        manager.on('end', (evt, data) => {
          binding.value(evt, data);
        });
      }
    }
  },
  data() {
    return {
      collectList: [],
      listLoading: false,
      showList: false,
      showEdit: false,
      sourceList: [],
      saveLoading: false,
      collectLoading: false
    }
  },
  created() {
    this.getCollectList();
  },
  methods: {
    /**
     * @description: 展示收藏列表
     * @return {*}
     */    
    showListFun() {
      this.showList = true;
      this.getCollectList();
    },
    /**
     * @description: 云台移动
     * @param {*} ins
     * @param {*} data
     * @return {*}
     */    
    handlePTZMove(ins, data) {
      if(data && data.direction) {
        const { angle } = data.direction;
        if(angle) {
          this.$emit('handleAction', angle);
        }
      }
    },
    /**
     * @description: 缩放变化
     * @param {*} ins
     * @return {*}
     */    
    handleScaleChange(ins) {
      this.$emit('handleAction', ins);
    },
    /**
     * @description: 抓拍
     * @return {*}
     */    
    cptureImage() {
      return new Promise((resolve, reject) => {
        cptureImgApi(this.voideData.appId, this.voideData.device_serial, this.voideData.channel_id, {}).then((res) => {
          if(res.data && res.data.code === 200 && res.data.data) {
            resolve(res.data.data.url);
          } else {
            reject('');
          }
        }).catch(() => {
           reject('');
        })
      })
    },
    /**
     * @description: 收藏
     * @return {*}
     */    
    doCollect() {
      this.collectLoading = true;
      this.cptureImage().then(res => {
        if(res) {
          const len = this.collectList.length + 1;
          const data = {
            deviceSerial: this.voideData.device_serial,
            channelNo: this.voideData.channel_id,
            app_id: this.voideData.appId,
            image_url: res,
            monitor_id: this.voideData.monitor_id,
            name: `默认名称${len}`
          };
          monitorCollectionAddApi(data).then(res => {
            if (res.data && res.data.code === 200) {
              this.getCollectList();
            }
          }).finally(() => {
            this.collectLoading = false;
          })
        }
      }).catch(() => {
        this.collectLoading = false;
      })
    },
    /**
     * @description: 获取收藏列表
     * @return {*}
     */    
    getCollectList() {
      const params = { monitor_id: this.voideData.monitor_id, size: 1000 };
      this.listLoading = true;
      monitorCollectionListApi(params).then(res => {
        if(res.data && res.data.code === 200) {
          if(res.data.data && Array.isArray(res.data.data.data)) {
            this.collectList = res.data.data.data;
            this.sourceList = JSON.parse(JSON.stringify(res.data.data.data));
          }
        }
      }).finally(() => {
        this.listLoading = false;
      })
    },
    /**
     * @description: 取消编辑
     * @return {*}
     */    
    doCancle() {
      this.collectList.forEach((el, index) => {
        const sourceEl = this.sourceList[index];
        if(el.name !== sourceEl.name) {
          el.name = sourceEl.name;
        }
      })
      this.showEdit = false;
    },
    /**
     * @description: 保存
     * @return {*}
     */    
    doSave() {
      const needUpdateList = this.collectList.filter((el, index) => {
        const sourceEl = this.sourceList[index];
        return el.name !== sourceEl.name;
      })
      if(needUpdateList.length) {
        this.saveLoading = true;
        const primorseAll = needUpdateList.map(el => this.editCollectItem(el));
        Promise.all(primorseAll).finally(() => {
          this.getCollectList();
          this.saveLoading = false;
        })
      }
      this.showEdit = false;
    },
    /**
     * @description: 编辑小项
     * @param {*} item
     * @return {*}
     */    
    editCollectItem(item) {
      return monitorCollectionEditApi(item, item.id);
    },
    /**
     * @description: 删除小项
     * @param {*} item
     * @return {*}
     */    
    deleteItem(item) {
      monitorCollectionDelApi(item.id).then(res => {
        if(res.data && res.data.code === 200) {
          this.getCollectList();
        }
      })
    },
    /**
     * @description: 跳转位置
     * @param {*} item
     * @return {*}
     */    
    jumpPosition(item) {
      jumpPositionApi({ id: item.id }).then(() => {
        // console.log(res)
        // nothing to do
      })
    }
  }
}
</script>
<style lang="less">
  .ptz-control-wrap {
    position: absolute;
    top: 0px;
    right: 10px;
    width: 150px;
    height: calc(100% - 40px);
    .action-button{
      padding: 0;
      color: #000;
    }
    .radius-block {
      position: relative;
      width: 100px;
      height: 100px;
      margin: 10px auto;
      overflow: hidden;
      cursor: pointer;
      .roker-container {
        display: flex;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        #nipple_container {
          position: relative;
          width: 100%;
          height: 100%;
          cursor: pointer;
          .front {
            background: red;
            user-select: none;
          }
          .back {
            background-image: url("../images/yuntai.png");
            background-size: cover;
            background-position: center;
            opacity: unset !important;
          }
          .nipple{
            opacity: unset !important;
          }
        }
      }
    }
    .btn-arr{
      width: 26px;
      height: 86px;
      background: #FFFFFF;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      margin-left: 20px;
    }
    .bottom-content{
      height: calc(100% - 120px);
      .list-title{
        display: flex;
        justify-content: flex-end;
        width: 100%;
        .list-title-i{
          width: 26px;height: 24px;justify-content: center;cursor: pointer;
          background: #FFFFFF;border-radius: 4px;
          display: flex;
          align-items: center;
        }
      }
      .like-list-title{
        background: #fff;
        display: flex;
        align-items: center;
        border-radius: 4px;
        width: 126px;height: 26px;padding: 2px 10px;
        justify-content: space-between;
        position: relative;
        ::after{
          position: absolute;
          content: '';
          width:0;
          height:0;
          border-width:5px 5px 0;
          border-style:solid;
          border-color:#FFF transparent transparent;
          top: 30px;
          left: 26px;
        }
      }
      .like-list-content{
        width: 146px;
        height: calc(100% - 90px);
        overflow-y: auto;
        overflow-x: hidden;
        text-align: end;
        margin-top: 16px;
        .img-content{
          position: relative;
          cursor: pointer;
          width: 100%;
          height: 90px;
          border-radius: 4px;
          position: relative;
          img{
            width: 100%;
            height: 100%;
          }
          .p-font{
            background:rgba(0, 0, 0, .2);
            border-radius: 0px 0px 4px 4px;
            color: #fff;
            position: absolute;
            bottom: 0;
            width: calc(100% - 10px);
            left: 0;
            text-align: left;
            padding: 5px;
            font-size: 12px;
          }
          .edit-wrap{
            position: absolute;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, .2);
            top: 0;
            left: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 12px;
            box-sizing: border-box;
          }
          & + .img-content {
            margin-top: 5px;
          }
        }
      }
    }
  }
</style>