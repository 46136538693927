/*
 * @Author: Shiltin 18580045074@163.com
 * @Date: 2022-12-26 14:23:44
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2023-03-10 09:59:26
 * @FilePath: \console\src\views\gantt\js\countDate.js
 * @Description: 计算日期的函数
 */
export default {
  data () {
    return {
      startDate: '',
      endDate: ''
    }
  },
  methods: {
    /**
    * 年模式gantt标题
     * startYear: 起始年
     * start_mouth：起始月
     * end_year：结束年
     * end_mouth：结束月
     */
    onlyYearTitleDate (startYear, endYear) {
      const dates = []
      const yearDiff = endYear - startYear
      // 年间隔小于一年
      if (yearDiff !== 0) {
        for (let i = startYear; i < endYear + 1; i++) {
          const itemDays = this.getDaysBetween(`${startYear}-01-01`, `${startYear}-12-31`)
          dates.push(
            {
              title: `${i}年`,
              align: 'center',
              field: 'itemDate',
              sort: false,
              date: i,
              itemDays: itemDays,
              fullDate: `${i}-01-01`,
              children: []
            }
          )
        }
      } else {
        const itemDays = this.getDaysBetween(`${startYear}-01-01`, `${startYear}-12-31`)
        dates.push(
          {
            title: `${startYear}年`,
            align: 'center',
            field: 'itemDate',
            sort: false,
            date: startYear,
            itemDays: itemDays,
            fullDate: `${startYear}-01-01`,
            children: []
          }
        )
      }
      return dates
    },
    /**
     * 年-月模式gantt标题
     * startYear: 起始年
     * start_mouth：起始月
     * end_year：结束年
     * end_mouth：结束月
     */
    yearAndMonthsTitleDate (startYear, startMonth, endYear, endMonth) {
      // 日期数据盒子
      const dates = [
        {
          title: `${startYear}年`,
          align: 'center',
          field: 'itemDate',
          sort: false,
          date: startYear,
          children: []
        }
      ]
      // 处理年份
      const yearDiff = endYear - startYear
      // 年间隔小于一年
      if (yearDiff === 0) {
        const isLeap = this.isLeap(startYear) // 是否闰年
        const months = this.generationMonths(
          startYear,
          startMonth,
          endMonth + 1,
          isLeap,
          false
        ) // 处理月份
        dates[0].children = months
        return dates
      }
      // 处理开始月份
      const startIsLeap = this.isLeap(startYear)
      const startMonths = this.generationMonths(
        startYear,
        startMonth,
        13,
        startIsLeap,
        false
      )
      // 处理结束月份
      const endIsLeap = this.isLeap(endYear)
      const endMonths = this.generationMonths(
        endYear,
        1,
        endMonth + 1,
        endIsLeap,
        false
      )
      // 年间隔等于一年
      if (yearDiff === 1) {
        dates[0].children = startMonths
        dates.push({
          title: `${endYear}年`,
          align: 'center',
          field: 'itemDate',
          sort: false,
          date: endYear,
          children: endMonths
        })
        return dates
      }
      // 年间隔大于1年
      if (yearDiff > 1) {
        dates[0].children = startMonths
        for (let i = startYear; i < endYear; i++) {
          if (i !== startYear) {
            const isLeap = this.isLeap(i)
            const monthAndDay = this.generationMonths(i, 1, 13, isLeap, false)
            dates.push({
              title: `${i}年`,
              date: i,
              align: 'center',
              field: 'itemDate',
              sort: false,
              children: monthAndDay
            })
          }
        }

        dates.push({
          title: `${endYear}年`,
          date: endYear,
          align: 'center',
          field: 'itemDate',
          sort: false,
          children: endMonths
        })
        return dates
      }
    },
    /**
     * 年-周模式gantt标题
     * startYear: 起始年
     * start_mouth：起始月
     * end_year：结束年
     * end_mouth：结束月
     */
    yearAndWeekTitleDate (startYear, startMonth, endYear, endMonth) {
      // 处理年份
      const yearDiff = endYear - startYear
      let middleMonths = []
      // 只存在同年或前后年的情况
      if (yearDiff === 0) {
        // 年间隔为同一年
        const isLeap = this.isLeap(startYear) // 是否闰年
        const months = this.generationMonths(
          startYear,
          startMonth,
          endMonth + 1,
          isLeap,
          true,
          true
        ) // 处理月份
        return months
      } else {
        // 中间有完整的年份
        for (let i = startYear; i < endYear; i++) {
          if (i !== startYear) {
            const isLeap = this.isLeap(i) // 是否闰年
            const fullYear = this.generationMonths(
              i,
              1,
              13,
              isLeap,
              true,
              true
            )
            middleMonths = middleMonths.concat(fullYear)
          }
        }
      }
      // 处理开始月份
      const startIsLeap = this.isLeap(startYear)
      let startMonths = this.generationMonths(
        startYear,
        startMonth,
        13,
        startIsLeap,
        true,
        true
      )
      // 会出现周显示日期不全的情况比如2022-07-01
      if (startMonths?.length && startMonths[0].children?.length) {
        const days = this.getDaysBetween(this.startDate, startMonths[0].children[0].fullDate)
        if (days > 0) {
          const dateArr = this.startDate.split('-')
          if (days > 3) {
            const obj = {
              fullDate: this.startDate,
              itemDays: days + 1,
              title: dateArr[2] + '日',
              date: Number(dateArr[2]),
              field: 'itemDate',
              align: 'center'
            }
            startMonths[0].children.unshift(obj)
          } else {
            startMonths[0].children[0].fullDate = this.startDate
            startMonths[0].children[0].itemDays = startMonths[0].children[0].itemDays + days + 1
            startMonths[0].children[0].title = dateArr[2] + '日'
            startMonths[0].children[0].date = Number(dateArr[2])
          }
        }
      }
      // 处理结束月份
      const endIsLeap = this.isLeap(endYear)
      const endMonths = this.generationMonths(
        endYear,
        1,
        endMonth + 1,
        endIsLeap,
        true,
        true
      )
      startMonths = startMonths.concat(middleMonths)
      return startMonths.concat(endMonths)
    },
    /**
     * 月-日模式gantt标题
     * startYear: 起始年
     * start_mouth：起始月
     * end_year：结束年
     * end_mouth：结束月
     */
    monthsAndDayTitleDate (startYear, startMonth, endYear, endMonth) {
      // 处理年份
      const yearDiff = endYear - startYear
      let middleMonths = []
      // 只存在同年或前后年的情况
      if (yearDiff === 0) {
        // 年间隔为同一年
        const isLeap = this.isLeap(startYear) // 是否闰年
        const months = this.generationMonths(
          startYear,
          startMonth,
          endMonth + 1,
          isLeap
        ) // 处理月份
        return months
      } else {
        // 中间有完整的年份
        for (let i = startYear; i < endYear; i++) {
          if (i !== startYear) {
            const isLeap = this.isLeap(i) // 是否闰年
            const fullYear = this.generationMonths(
              i,
              1,
              13,
              isLeap
            )
            middleMonths = middleMonths.concat(fullYear)
          }
        }
      }
      // 处理开始月份
      const startIsLeap = this.isLeap(startYear)
      let startMonths = this.generationMonths(startYear, startMonth, 13, startIsLeap)
      // 处理结束月份
      const endIsLeap = this.isLeap(endYear)
      const endMonths = this.generationMonths(endYear, 1, endMonth + 1, endIsLeap)
      startMonths = startMonths.concat(middleMonths)
      return startMonths.concat(endMonths)
    },
    /**
     * 生成月份函数
     * year: Number 当前年份
     * startNum: Number 开始月分
     * end_num：Number 结束月份
     * isLeap: Boolean 是否闰年
     * insertDays: Boolean 是否需要插入 日
     * week: 是否以周的间隔
     */
    generationMonths (
      year,
      startNum = 1,
      endNum = 13,
      isLeap = false,
      insertDays = true,
      week = false
    ) {
      const months = []
      if (insertDays) {
        // 无需 日 的模式
        for (let i = startNum; i < endNum; i++) {
          // 需要 日 的模式
          const days = this.generationDays(year, i, isLeap, week)
          months.push({
            title: `${year}年${i}月`,
            align: 'center',
            sort: false,
            field: 'itemDate',
            date: i,
            fullDate: i < 10 ? `${year}-0${i}` : `${year}-${i}`,
            children: days
          })
        }
        return months
      }
      for (let i = startNum; i < endNum; i++) {
        const bigMonth = [1, 3, 5, 7, 8, 10, 12].includes(i)
        const smallMonth = [4, 6, 9, 11].includes(i)
        const itemDays = bigMonth ? 31 : smallMonth ? 30 : isLeap ? 29 : 28
        // 需要 日 的模式
        months.push({
          title: `${i}月`,
          align: 'center',
          sort: false,
          field: 'itemDate',
          date: i,
          itemDays: itemDays,
          fullDate: `${year}-${i < 10 ? '0' + i : i}-01`
        })
      }
      return months
    },
    /**
     * 生成日期函数
     * year: Number 当前年份
     * month: Number 当前月份
     * isLeap: Boolean 是否闰年
     * week: Boolean 是否间隔一周
     */
    generationDays (year, month, isLeap = false, week = false) {
      const bigMonth = [1, 3, 5, 7, 8, 10, 12].includes(month)
      const smallMonth = [4, 6, 9, 11].includes(month)
      const datesNum = bigMonth ? 32 : smallMonth ? 31 : isLeap ? 30 : 29
      const days = []
      if (week) {
        let _day = 1 // 从周日开始
        const startDayInweek = this.timeInWeek(`${year}-${month}-1`)
        if (startDayInweek !== 0) {
          _day = 8 - startDayInweek
        }
        for (let i = _day; i < datesNum; i += 7) {
          days.push({
            date: i,
            title: `${i}日`,
            align: 'center',
            field: 'itemDate',
            itemDays: 7,
            fullDate: `${year}-${month < 10 ? '0' + month : month}-${i < 10 ? '0' + i : i}`
          })
        }
      } else {
        for (let i = 1; i < datesNum; i++) {
          const fullDate = `${year}-${month < 10 ? '0' + month : month}-${i < 10 ? '0' + i : i}`
          const getDay = new Date(fullDate).getDay() // 周几
          days.push({
            date: i,
            title: `${i}日`,
            align: 'center',
            itemDays: 1,
            field: 'itemDate',
            fullDate: fullDate,
            weekend: !!(getDay === 6 || getDay === 0)
          })
        }
      }
      return days
    },
    /**
     * @description: 获取相差天数
     * @param {*} startDate
     * @param {*} enDate
     * @return {*}
     */
    getDaysBetween (startDate, enDate) {
      const sDate = Date.parse(startDate)
      const eDate = Date.parse(enDate)
      if (sDate > eDate) {
        return 0
      }
      // 这个判断可以根据需求来确定是否需要加上
      if (sDate === eDate) {
        return 1
      }
      const days = (eDate - sDate) / (1 * 24 * 60 * 60 * 1000)
      return days
    },
    /**
     * 查询时间是周几
     */
    timeInWeek (date) {
      var now = new Date(date)
      return now.getDay()
    },
    /**
     * 是否闰年函数
     * year: Number 当前年份
     */
    isLeap (year) {
      return year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0)
    }
  }
}
