<!--
 * @Description: 按钮
 * @Author: luocheng
 * @Date: 2021-08-23 11:42:01
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2023-01-03 16:38:19
-->
<template>
	<el-button
		class="common-button"
		@click.stop="onSyncPlanModel"
		:type="statusConfig.type"
		:size="statusConfig.size"
		:plain="statusConfig.plain"
		:round="statusConfig.round"
		:circle="statusConfig.circle"
		:loading="statusConfig.loading"
		:disabled="statusConfig.disabled || !syncConfig.modelField || !syncConfig.codeField"
		:icon="statusConfig.icon"
		:autofocus="statusConfig.autofocus"
		:class="{
			'just-icon': !textName
		}"
		>{{ textName }}</el-button
	>
</template>

<script>
import eventBus from '@/plugins/eventBus';
import { initParams } from '@/utils/tools';
import { formRequest } from '@/apis/home';
import { mapState } from 'vuex';
export default {
	name: 'SyncPlanModel',
	props: {
		// 组件对象
		element: {
			type: Object,
			required: true,
			default: () => {}
		}
	},
	computed: {
		...mapState(['componentData']),
		// 组件配置
		statusConfig() {
			return this.element.statusConfig || {};
		},
		// 配置
		syncConfig() {
			return this.element.syncConfig || {};
		},
		// 文本名称
		textName() {
			return this.element.syncConfig.textName || '';
		}
	},
	methods: {
		//同步进度计划模型
		onSyncPlanModel(data) {
			console.log(data, 5555);
			let obj = {
				object_uuid: this.element.database.objectData.uuid,
				view_uuid: this.element.database.viewData.uuid,
				code_filed: this.syncConfig.codeField,
				data_filed: this.syncConfig.modelField
			};
			formRequest('post', 'api/metadata/fourSchedule/newModelSync', obj)
				.then(res => {
					if (res.data.code === 200) {
						this.$message.success('同步计划模型成功！')
					}
				})
				.catch(err => {
					console.log(err, '-----');
				});
		}
	},
	mounted() {
		//监听行为
		eventBus.$on('doComponentBehavior', config => {
			const { component, list = [] } = config;
			let componentName = component.split('-')[0];
			if (!this.element.id.includes(componentName)) return false;
			list.forEach(ele => {
				const { behaviors, params } = ele;
				const { param = {}, canPost } = initParams(params, false, this.componentData, []);
				if (canPost) {
					// 调用行为方法
					behaviors.forEach(funName => {
						try {
							eval(this[funName])(param);
						} catch (err) {
							console.log(err);
						}
					});
				}
			});
		});
	},
	unmounted() {
		eventBus.$off('doComponentBehavior');
	}
};
</script>
<style lang="less">
.el-button {
	&.just-icon {
		border: none;
		span {
			margin-left: 0 !important;
		}
	}
}
</style>
