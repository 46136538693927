<!--
 * @Description: 盒子
 * @Author: luocheng
 * @Date: 2022-04-28 17:13:04
 * @LastEditors: '张星' you@example.com
 * @LastEditTime: 2023-05-22 16:20:52
-->
<!--
 * @Description: 组件盒子
 * @Author: luocheng
 * @Date: 2021-08-10 17:12:53
 * @LastEditors: luocheng
 * @LastEditTime: 2022-04-02 09:30:25
-->
<template>
	<div class="shape" :data-id="element.id" :style="'mixBlendMode: ' + mixBlendMode">
		<slot></slot>
	</div>
</template>

<script>

export default {
	name: 'Shape',
	props: {
		// 组件对象
		element: {
			type: Object,
			default: () => {},
			required: true
		},
		// 组件默认样式
		defaultStyle: {
			type: Object,
			default: () => {},
			required: true
		},
		// 组件在图层列表中下标
		index: {
			type: Number,
			required: true
		}
	},
	data() {
		return {
			mixBlendMode: ''
		};
	},
	mounted() {
		if (this.element?.style?.mixBlendMode && this.element?.style?.mixBlendMode !== 'normal') {
			this.mixBlendMode = this.element?.style?.mixBlendMode
		}
	}
};
</script>

<style lang="less" scoped>
.shape {
	position: absolute;
}
</style>
<style>
span, p, h1, h2, h3, h4, h5, h6, li, ol, div, header, section, article {
	cursor: pointer;
}
canvas{
	cursor:default;
}
</style>
