import axios from 'axios'
import { Message } from 'element-ui';

import {
  setToken,
  getToken
} from '@/utils/tools';
import ApiStore from './ApiStore';
// 测试
let metadatatoken = process.env.VUE_APP_METATOKEN
let contentType = 'application/json;charset=UTF-8'

class HttpRequest {
  constructor(baseUrl = 'https://dwh_api.bimcc.net') {
    this.baseUrl = baseUrl
    this.queue = {}
  }
  getInsideConfig() {
    let config = {
      baseURL: this.baseUrl,
      headers: {
        'bimcctoken': getToken(),
        'metadatatoken': metadatatoken,
        'Content-Type': contentType
      }
    }
    return config
  }
  destroy(url) {
    delete this.queue[url]
  }
  interceptors(instance, url) {
    // console.log(instance, url, '44444444444444', options)
    // 请求拦截
    // let method = ''
    if (!getToken()) {
      setToken('')
    }
    instance.interceptors.request.use(config => {
      // method = config.method
      this.queue[url] = true
      return config
    }, error => {
      return Promise.reject(error)
    })
    // 响应拦截
    instance.interceptors.response.use(res => {
      this.destroy(url)
      const {
        data,
        status
      } = res
      return {
        data,
        status
      }
    }, error => {
      this.destroy(url)
      return Promise.reject(error)
    })
    instance.interceptors.response.use((res) => {
      let { data } = res
      if (data.code !== 200) {
        if (data.code === 0) {
          window.bimccAxiosError = {
            ...data,
            origin: {
              status: 0
            }
          };
          Message({
            message: data.msg || '服务器内部错误',
            type: 'error'
          })
          return
        } else if (data.code === 401) {
          setToken('')
          sessionStorage.clear()
          localStorage.clear()
          console.log('跳转登录1')
          window.location.href = '/login'
          Message({
            message: '未登录或登录失效，请登录！',
            type: 'error'
          })
        } else if (data.code === 422) {
          if (data.msg) {
            Message({
              message: '数据校验失败！',
              type: 'error'
            })
          }
        } else if (data.code === 500) {
          if (data.msg === 'Invalid Token') {
            sessionStorage.clear()
            localStorage.clear()
            setToken('')
            console.log('跳转登录2')
            window.location.href = '/login'
            Message({
              message: '未登录或登录失效，请登录!',
              type: 'error'
            })
          } else {
            if (url.indexOf('api/v4/user/refreshToken') > -1) {
              // 验证token
              // return res;
            } else {
              Message({
                message: data.msg || '未登录或登录失效，请登录！',
                type: 'error'
              })
            }
          }
        } else {
           if (data.msg ) {
            Message({
              message: data.msg || '出错了，请联系管理员解决!',
              type: 'error'
            })
          }
        }
        return res
      } else {
        return res
      }
    }, (error) => {
      // console.log(error, 'error服务器内部错误');
      window.bimccAxiosError = error;
      Message({
        message: '服务器内部错误',
        type: 'error'
      })
      return Promise.reject(error)
    })
  }
  request(options) {
    const instance = axios.create({
      baseUrl: options.baseUrl,
      timeout: '600000',
      headers: {
        'Content-Type': contentType,
        'metadatatoken': metadatatoken
      },
    })
    options = Object.assign(this.getInsideConfig(), options)
    this.interceptors(instance, options.url, options)
    // 从缓存类中拿到请求的 AxiosPromise
    let request = ApiStore.handler(options, () => instance(options))
    return request
  }
}
export default HttpRequest
