/*
 * @Description: 系统信息
 * @Author: luocheng
 * @Date: 2022-07-13 14:25:58
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-02-03 15:31:53
 */
export default {
  state: {
    systemConfig: null
  },
  mutations: {
    // 配置系统配置
    setSystemConfig(state, config) {
      state.systemConfig = config;
      localStorage.setItem('systemConfig', JSON.stringify(config || {}));
    }
  }
}