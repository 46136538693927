<!--
    @name: FlowDesign
    @description：FlowDesign
    @author: ZengWei
    @date: 2021-10-22 09:06
-->
<template>
	<div class="workflow-container" v-loading="loading">
		<!-- 辅助工具栏 -->
		<Control class="vab-control" @trigger-event="triggerEvent"/>
		<!-- 节点面板 -->
		<NodePanel @ondrag="onDrag"/>
		<!-- 画布 -->
		<div id="left-view" ref="topology"></div>
		<!-- 属性面板 -->
		<el-drawer
			:visible.sync="drawerVisible"
			direction="rtl"
			size="400px"
			title="设置节点属性"
			append-to-body
		>
			<FlowProps
				v-if="drawerVisible"
				ref="propData"
				:flow-data="flowConfigProp"
				:obuuid="obuuid"
				@change="onUpdateProps"
				@flow-change="flowChange"
			/>
		</el-drawer>

		<el-dialog :visible.sync="dialogVisible" title="流程测试弹窗" width="50%">
			<FlowRecover :logs="[]"/>
			<FlowOperate :edit-data="editData"/>
		</el-dialog>
	</div>
</template>

<script>
	import NodePanel from './NodePanel'
	import Control from './Control'
	import FlowProps from './FlowProps'
	import FlowRecover from './FlowRecoverNew'
	import FlowOperate from './FlowOperate'
	import {Topology} from '@topology/core'
	import {register as registerFlow} from '@topology/flow-diagram'
	import {register as registerActivity} from '@topology/activity-diagram'
	import {flowEngine} from '@/apis/data/workflow'

	import {Drawer, Dialog} from 'element-ui'

	export default {
		name: 'Workflow',
		components: {
			NodePanel, Control, FlowProps, FlowRecover, FlowOperate,
			'el-drawer': Drawer,
			'el-dialog': Dialog,
		},
		props: {
			flowData: {
				type: Object,
				default: () => {
					return {}
				},
			},
			flowSave: {
				type: Boolean,
				default: true,
			},
			flowTool: {
				type: Boolean,
				default: true,
			},
			obuuid: {
				default: ''
			}
		},
		data() {
			return {
        loading: false,
        topoCanvas: null,
        canvasOptions: {
          hideInput: true,
          grid: false,
          bkColor: '#F7F9FF',
          hideRotateCP: true,
          disableScale: false,
          disableEmptyLine: true,
          rotateCursor: 'crosshair',
          scroll:false,
        },
				drawerVisible: false,
				dialogVisible: false,
				demoVisible: false,
				props: {
					node: null,
					line: null,
					nodes: null,
					multi: false,
					expand: false,
					locked: false
				},
				flowConfig: {},
				flowConfigProp: {},
				editData: {
					operateBtns: [
						{
							btn: '退回',
							status: -1,
						},
						{
							btn: '通过',
							status: 1,
						},
						{
							btn: '转交',
							status: 2,
						},
						{
							btn: '重新发起',
							status: 3,
						},
						{
							btn: '提交至退回人',
							status: 4,
						},
					],
				},
			}
		},
		methods: {
			storeDemo() {
				this.demoVisible = true
			},
			flowChange(flowConf) {
				this.flowConfig = flowConf
				const {name, description, is_outer, module, field_data, carry_listen, formUuid} = this.flowConfig
				this.flowConfigProp = {name, description, is_outer, module, field_data, carry_listen, formUuid}
			},
			triggerEvent(eventName) {
				this[eventName]()
			},
			onDrag(event, node) {
				event.dataTransfer.setData('Topology', JSON.stringify(node.data))
			},
			recoverDesign() {
				if (this.flowData.canvas_data?.pens && this.flowData.canvas_data != null) {
          this.topoCanvas.open(this.flowData.canvas_data)
          this.topoCanvas.centerView()
          this.topoCanvas.render()
				}
			},
			openConfig() {
				this.drawerVisible = true
				this.$nextTick(() => {
					this.$refs.propData.active = 'flowSetting'
				})
			},
			resetZoom: () => {
        this.topoCanvas.scaleTo(1)
        this.topoCanvas.centerView()
        this.topoCanvas.render()
			},
			openTour() {
				this.dialogVisible = true
			},
			saveFlow() {
        this.loading = true;
				let startNode = 0, endNode = 0
				let canvas_data = this.topoCanvas.data
				let allNode = canvas_data.pens
				let startNodeId = '', endNodeId = '',
					serialNode = [], duringNode = [], managerNode = []
				let lineList = [], nodeList = [], stepNode = []
				let nodeType = ['circle', 'activityFinal', 'rectangle']
				try {
					for (let item of allNode) {
						if (nodeType.indexOf(item.name) >= 0) {
							if (item.name === 'circle') {
								startNode += 1
								startNodeId = item.id
								if (item.data === '') {
									item.data = {
										nodeType: 'start',
										nodeId: startNodeId,
										name: item.text,
									}
								}
							}
							if (item.name === 'activityFinal') {
								endNode += 1
								endNodeId = item.id
								if (item.data === '') {
									item.data = {
										nodeType: 'end',
										nodeId: endNodeId,
										name: item.text,
									}
								}
							}
							if (
								item.name === 'rectangle' &&
								item.data.user_type === undefined
							) {
								this.loading = false;
								throw new Error(
									'步骤:【' + item.text + '】配置信息不完整，请核对后保存！'
								)
							}
							if (item.name === 'rectangle') stepNode.push(item)
							nodeList.push(item.data)
						} else if (item.name === 'polyline') {
							if (item.to.id === undefined) {
								this.loading = false;
								throw new Error('流程图连线不完整，请核对后保存！')
							}

							if (item.data === '') {
								let data = {
									nodeId: item.id,
									name: item.text,
									from: item.from.id,
									to: item.to.id,
									condition: [],
								}
								item.data = data
							}
							if (item.data.condition !== undefined) {
								if (item.data.condition.length > 0) {
									for (const itemEle of item.data.condition) {
										if (itemEle.field === '' || itemEle.value === '') {
											this.loading = false;
											throw new Error(
												'条件配置不完整，条件字段或字段值不能为空！'
											)
										}
									}
								}
							}
							lineList.push(item.data)
						}
					}
					if (startNode == 0 || endNode == 0) {
						this.loading = false;
						throw new Error('流程图缺少开始或结束节点，请核对后保存！')
					}
					if (startNode > 1 || endNode > 1) {
						this.loading = false;
						throw new Error('开始或结束节点有且只能有一个，请核对后保存！')
					}
					let fromNode = Array.from(lineList, (item) => item.from)
					let toNode = Array.from(lineList, (item) => item.to)
					if (
						toNode.indexOf(startNodeId) >= 0 ||
						fromNode.indexOf(endNodeId) >= 0
					) {
						this.loading = false;
						throw new Error(
							'开始节点不能有入节点或结束节点不能有出节点，请核对后保存！'
						)
					}
					stepNode.forEach((item) => {
						if (
							fromNode.indexOf(item.id) < 0 ||
							toNode.indexOf(item.id) < 0
						) {
							this.loading = false;
							throw new Error('流程图连线不完整，请核对后保存！')
						}
					})
					serialNode = nodeList.filter(
						(item) => item.user_type === 3 && item.user_who === 2
					)
					managerNode = nodeList.filter(
						(item) => item.user_type === 4 && item.user_who === 2
					)
					duringNode = nodeList.filter((item) => item.user_type === 7) //流程中指定人步骤的上一步不能是不定人员
					let serialNodeId = Array.from(serialNode, (item) => item.nodeId)
					let duringNodeId = Array.from(duringNode, (item) => item.nodeId)
					let managerNodeId = Array.from(managerNode, (item) => item.nodeId)

					// let firstLine = lineList.filter((item) => item.from === startNodeId)
					// let firstNode = Array.from(firstLine, (item) => item.to)
					for (const node of nodeList) {
						let target_user = node.target_user ? node.target_user : []
						if (node.user_type === 1 && target_user.length === 0) {
							this.loading = false;
							throw new Error('指定人员，审批人员不能为空！')
						}
						if (
							(node.user_type === 2 || node.user_type === 7) &&
							(node.user_from === 0 ||
								node.user_from === undefined ||
								node.user_from === '')
						) {
							this.loading = false;
							throw new Error('发起人自选或流程中指定，人员范围不能为空！')
						}
						if (
							(node.user_type === 3 || node.user_type === 4) &&
							(node.user_who === 0 ||
								node.user_who === undefined ||
								node.user_who === '')
						) {
							this.loading = false;
							throw new Error('主管连续多级主管，谁的主管不能为空！')
						}
						if (
							(node.user_type === 3 || node.user_type === 4) &&
							(node.user_from === 0 ||
								node.user_from === undefined ||
								node.user_from === '')
						) {
							this.loading = false;
							throw new Error('主管连续多级主管，主管层级不能为空！')
						}
						if (node.user_type === 5 && node.user_section.length === 0) {
							this.loading = false;
							throw new Error('部门不能为空！')
						}
						if (node.user_type === 6 && node.user_role.length === 0) {
							this.loading = false;
							throw new Error('角色不能为空！')
						}
					}

					for (const line of lineList) {
						if (serialNodeId.indexOf(line.to) !== -1) {
							let allSign = nodeList.filter(
								(item) => item.nodeId === line.from && item.sign_type === 2
							)
							if (allSign.length > 0) {
								this.loading = false;
								throw new Error(
									'审批人是上一步处理人的连续主管，上一步骤审批人不能是会签！'
								)
							}
							let emptyS = nodeList.filter(
								(item) => item.nodeId === line.from && item.user_type === 8
							)
							if (emptyS.length > 0) {
								this.loading = false;
								throw new Error(
									'审批人是上一步处理人的连续主管，上一步骤不能为空！'
								)
							}
						}
						if (managerNodeId.indexOf(line.to) !== -1) {
							let emptyM = nodeList.filter(
								(item) => item.nodeId === line.from && item.user_type === 8
							)
							if (emptyM.length > 0) {
								this.loading = false;
								throw new Error(
									'审批人是上一步处理人的主管，上一步骤不能为空！'
								)
							}
						}
						if (duringNodeId.indexOf(line.to) !== -1) {
							let uncertain = nodeList.filter(
								(item) =>
									item.nodeId === line.from &&
									[3, 4, 6, 8].indexOf(item.user_type) > -1
							)
							if (uncertain.length > 0) {
								this.loading = false;
								throw new Error(
									'审批人是流程中指定，上一步骤审批人不能是连续主管、主管、角色、空步骤等不定人员！'
								)
							}
						}
					}
				} catch (e) {
					this.loading = false;
					this.$message({
						showClose: true,
						message: e.message,
						type: 'error'
					});
          return false;
				}

				const flowData = {nodeList: nodeList, lineList: lineList}
        const allData = {
					data: canvas_data, node_data: flowData,
					start_node: startNodeId, end_node: endNodeId,
				}
				if (this.flowConfig) {
					allData.name = this.flowConfig.name
					allData.description = this.flowConfig.description
					allData.module = this.flowConfig.module
          allData.formUuid = this.flowConfig.formUuid
					allData.is_outer = this.flowConfig.is_outer
					allData.field_data = this.flowConfig.field_data
					allData.carry_listen = this.flowConfig.carry_listen
				}
				if (this.flowData) {
					flowEngine.designSave(this.flowData.id, allData).then((res) => {
						if (res.data.code === 200) {
							this.$message({
								message: res.data.msg,
								type: 'success'
							});
						} else {
							this.$message({
								message: res.data.msg,
								type: 'error'
							});
						}
            this.loading = false;
					})
				} else {
					this.$emit('save-flow', allData)
				}
			},
			onUpdateProps(node) {
        this.topoCanvas.updateProps(node)
        this.topoCanvas.render()
			},
			initStepData(node) {
				let data
				let nodeType = node.name === 'circle' ? 'start' : 'end'
				switch (node.name) {
					case 'circle':
					case 'activityFinal':
						data = {
							nodeType: nodeType,
							nodeId: node.id,
							name: node.text,
							send_type: 1,
							send_from: 1,
							send_who: 1,
							send_user: [],

							sendDepartList: [],
							sendRoleList: [],
						}
						break
					case 'rectangle':
						data = {
							nodeType: 'step',
							nodeId: node.id,
							name: node.text,
							user_type: 7,
							user_from: 1,
							user_who: 1,
							user_empty: 1,
							target_user: [],
							send_type: 1,
							send_from: 1,
							send_who: 1,
							send_user: [],
							turn_to: false,
							sign_type: 1,
							sign_rate: 1,
							edit_fields: [],
							btn_group: [],
							carry_listen: [],
							task_listen: [],
							color_status: 0,

							departList: [],
							roleList: [],
							sendDepartList: [],
							sendRoleList: [],
						}
						break
					case 'polyline':
						data = {
							nodeType: 'line',
							nodeId: node.id,
							name: node.text,
							from: node.from.id,
							to: node.to.id,
							condition_type: 1,
							condition: [],
							color_status: 0,
							publisher: [],
							condition_collect: [],
						}
						break
				}
				node.data = data
				this.onUpdateProps(node)
			},
			onMessage(event, data) {
				switch (event) {
					case 'dblclick':
						this.props = {
							node: data,
							line: null,
							multi: false,
							expand: this.props.expand,
							nodes: null,
							locked: data.locked,
						}
						this.drawerVisible = true
						this.$nextTick(() => {
							this.$refs.propData.updated(this.props)
						})
						break
					case 'node':
						break
					case 'addNode':
						this.props = {
							node: data,
							line: null,
							multi: false,
							expand: this.props.expand,
							nodes: null,
							locked: data.locked,
						}
						this.initStepData(data)
						break
					case 'line':
						this.props = {
							node: data,
							line: data,
							multi: false,
							nodes: null,
							locked: data.locked,
						}
						this.drawerVisible = true
						this.$nextTick(() => {
							this.$refs.propData.updated(this.props)
						})
						break
					case 'addLine':
						this.props = {
							node: data,
							line: data,
							multi: false,
							nodes: null,
							locked: data.locked,
						}
						this.initStepData(data)
						break
					case 'space':
						this.props = {
							node: null,
							line: null,
							multi: false,
							nodes: null,
							locked: false,
						}
						break
				}
			}
		},
		created() {
			registerFlow()
			registerActivity()
		},
		mounted() {
			const {name, description, is_outer, module, field_data, carry_listen,formUuid} = this.flowData
			this.flowConfig = {name, description, is_outer, module, field_data, carry_listen,formUuid}
			this.flowConfigProp = {name, description, is_outer, module, field_data, carry_listen,formUuid}

			this.$nextTick(() => {
				this.canvasOptions.on = this.onMessage
        this.topoCanvas = new Topology(this.$refs.topology, this.canvasOptions)
        this.topoCanvas.data.lineName = 'polyline'
				this.recoverDesign()
        this.topoCanvas.render()
			})
		}
	}
</script>

<style lang="less" scoped>
	//默认margin
	@baseMargin: 20px;
	.workflow-container {
		position: relative;
		width: 100%;
		height: 100%;

		.vab-control {
			position: absolute;
			top: @baseMargin;
			left: @baseMargin;
			z-index: 2;
		}

		#left-view {
			height: 100%;
			outline: none;
		}

		.time-plus {
			cursor: pointer;
		}

		.add-panel {
			position: absolute;
			z-index: 11;
		}
	}
</style>
