<!--
 * @Description: 基础标题
 * @Author: luocheng
 * @Date: 2022-04-01 11:09:01
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2022-12-23 16:17:06
-->
<template>
  <div class="common-title" :class="[ellipsisClass]">
    <component :is="titleLevel">
      <!-- 固定 -->
      <template v-if="element.valueOrign === 'fixed'">{{ element.propValue || nullText }}</template>
      <!-- 来源于其他组件 -->
      <template v-if="element.valueOrign === 'component'">
        {{ element.componentValueConfig | getValueFromComponent(that) }}
      </template>
      <!-- 数据仓库 -->
      <template v-if="element.valueOrign === 'database'">
        <template v-if="dataObj && bindUUID">
          <!-- 绑定值 -->
          <template v-if="databaseType === 'value'">
            {{ dataObj[bindUUID] || nullText }}
          </template>
          <!-- 数据字典(描述文字) -->
          <template v-else-if="databaseType === 'wordbook' && metaData">
            {{ metaData.name || nullText }}
          </template>
        </template>
      </template>
			<!-- 映射值 -->
			<template v-if="element.valueOrign === 'valueMap'">
				{{ _getValueFromMap() }}
			</template>
    </component>
  </div>
</template>

<script>
import { getComponentById } from '@/utils/tools';
import { mapState } from 'vuex';

export default {
	name: 'CommonTitle',
	props: {
		// 绑定的值
		element: {
			type: Object,
			default: () => {},
			required: true
		},
		fullData: {
			// type: Object
		},
		isGroup: {
			type: Boolean,
		},
		groupComponents: {
			type: Array,
			default: () => []
		},
    componentList: {
      default: null
    }
	},
	data() {
		return {
			that: this
		};
	},
	computed: {
		...mapState(['componentData']),
		// 数据仓库
		database() {
			return this.element.database;
		},
		// 字段对象
		dataObj() {
			if (!this.database || !this.database.containerKey) return [];
			let container = getComponentById(this.componentList || this.componentData, this.database.containerKey);
			if (!container && this.isGroup && this.groupComponents.length) {
				container = getComponentById(this.groupComponents, this.database.containerKey);
			}
			if (!container?.containerData && !this.fullData) return null;
			const result = (container && container.containerData) || this.fullData;
			return result;
		},
		// 绑定的uuid
		bindUUID() {
			if (!this.element?.database) return '';
			return this.element.database.bindUUID;
		},
		// 用于获取绑定的数据字典(描述文字)
		metaData() {
			if (!this.element?.database) return null;
			const { fieldList, bindUUID } = this.element.database;
			return fieldList.find((ele) => ele.uuid === bindUUID);
		},
		// 数据绑定类型
		databaseType() {
			if (!this.element?.database) return 'value';
			return this.element.database.databaseType || 'value';
		},
    // 组件配置
    statusConfig() {
      return this.element.statusConfig;
    },
    // 标题层级标签
    titleLevel() {
      return `h${this.statusConfig?.level || 3}`;
    },
		// 省略号类
		ellipsisClass() {
			const { ellipsis, clamp } = this.statusConfig;
			if (!ellipsis) return '';
			if (+clamp === 1) {
				return 'ellipsis-1';
			}
			return `ellipsis ellipsis-${clamp}`;
		},
		// 空值
		nullText() {
			return this.statusConfig?.nullText || '';
		}
	},
	filters: {
		// 来源于组件的值
		getValueFromComponent(config, _this) {
			if (!config || !config.component || !config.field || (!_this.componentData && !_this.componentList)) return '';
			let component = getComponentById(_this.componentList || _this.componentData, config.component);
			if (!component && _this.isGroup && _this.groupComponents.length) {
				component = getComponentById(_this.groupComponents, config.component);
			}
			if (!component) return '';
			if (config.field === 'propValue') {
				return component.propValue;
			}
			if (!component.customStatus) return '';
			return component.customStatus[config.field];
		}
	},
	updated() {
		this.toResolveData();
	},
	methods: {
		/**
		 * @desc: 暴露数据
		 */
		toResolveData() {
			const { valueOrign = 'fixed', componentValueConfig = {} } = this.element;
			if (valueOrign === 'fixed') {
				// 固定值
				this.element.resolveData = {
					value: this.element.propValue || ''
				};
			} else if (valueOrign === 'component') {
				// 其他组件
				this.element.resolveData = {
					value: this._getValueFromComponent(componentValueConfig)
				};
			} else if (valueOrign === 'database') {
				// 数据仓库
				this.element.resolveData = {
					value: this._getDatabaseData()
				};
			}
		},
		/**
		 * @desc: 获取数仓数据
		 */
		_getDatabaseData() {
			if (!this.dataObj || !this.bindUUID) return '';
			if (this.databaseType === 'value') {
				// 绑定值
				return this.dataObj[this.bindUUID] || this.nullText;
			}
			if (this.databaseType === 'wordbook' && this.metaData) {
				// 数据字典(描述文字)
				return this.metaData.name || '';
			}
			return '';
		},
		/**
		 * @desc: 获取组件来源绑定值
		 * @param {Object} config
		 */
		_getValueFromComponent(config) {
			if (!config || !config.component || !config.field || (!this.componentData && !this.componentList)) return '';
			let component = getComponentById(this.componentList || this.componentData, config.component);
			if(!component && this.isGroup && this.groupComponents.length) {
				component = getComponentById(this.groupComponents, config.component);
			}
			if (!component) return '';
			if (config.field === 'propValue') {
				return component.propValue;
			}
			if (!component.customStatus) return '';
			return component.customStatus[config.field];
		},
		/**
		 * @desc: 获取映射值
		 */
		_getValueFromMap() {
			const { bindField } = this.element?.loopMapConfig || {};
			if (!bindField) return this.nullText;
			const { renderData } = this.mapData || {};
			if (!renderData) return this.nullText;
			return renderData?.[bindField] || this.nullText;
		}
	}
};
</script>

<style lang="less" scoped>
.common-title{
  text-indent: 5px;
	&.ellipsis-1 {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
	&.ellipsis {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		text-overflow: ellipsis;
		overflow: hidden;
		word-break: break-all;
		&.ellipsis-2 {
			-webkit-line-clamp: 2;
		}
		&.ellipsis-3 {
			-webkit-line-clamp: 3;
		}
		&.ellipsis-4 {
			-webkit-line-clamp: 4;
		}
		&.ellipsis-5 {
			-webkit-line-clamp: 5;
		}
		&.ellipsis-6 {
			-webkit-line-clamp: 6;
		}
		&.ellipsis-7 {
			-webkit-line-clamp: 7;
		}
		&.ellipsis-8 {
			-webkit-line-clamp: 8;
		}
		&.ellipsis-9 {
			-webkit-line-clamp: 9;
		}
		&.ellipsis-10 {
			-webkit-line-clamp: 10;
		}
	}
}
</style>