<!--
 * @Author: your name
 * @Date: 2021-12-02 15:31:01
 * @LastEditTime: 2023-06-19 09:49:01
 * @LastEditors: Shiltin 18580045074@163.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \nanhu-micro\src\views\components\bindModel\modelList.vue
-->
<template>
  <div class="detail-title">
    <el-tree
      ref="treeRef"
			class="scroller"
      v-loading="loading"
      :data="treeData"
      node-key="id"
      :props="props"
      show-checkbox
      :default-expanded-keys="expandedKeys"
      :default-checked-keys="checkedKeys"
      style="padding: 0 5px;height:100%;overflow-y: auto;"
      @check="change"
    />
  </div>
</template>

<script>
import {dataInterface} from "@/apis/data/index";
import {Tree} from "element-ui";

export default {
  name: "Index",
  components:{
    'el-tree': Tree
  },
  props: {
    //回显数据
    modelData: {
			type: [Object,Array],
			default: null
    },
  },
  data() {
    return {
      treeData: [],
      loading: true,
      props: {
        label: "name",
        children: "gis_type",
      },
      loadedData: [],
      expandedKeys: [], //回显展开的数据
      checkedKeys: [], //回显选中的数据
    };
  },
  methods: {
    change(data, checked) {
      const keys = this.$refs.treeRef.getCheckedNodes(true, false);
      if (keys.length !== 0) {
        if (this.loadedData.length == 0) {
          //新增
          this.loadedData = keys;
          this.loopFn(keys, true);
        } else {
          let arr = this.diffent(this.loadedData, keys, "id");
          this.loadedData = keys;
          // checked.checkedKeys 选中的id
          if (
            arr.length != 0 &&
            checked &&
            checked.checkedKeys &&
            checked.checkedKeys.length != 0
          ) {
            if (
              checked.checkedKeys.filter((it) => it == arr[0].id)
                .length == 0
            ) {
              //减去
              this.loopFn(arr, false);
            } else {
              //新增
              this.loopFn(arr, true);
            }
          }
        }
      } else {
        let listChecked = [...this.loadedData];
        this.loadedData = [];
        listChecked.forEach((item) => {
            this.$emit("show-model", item.model_id, false);
        });
        this.$emit("clear-obk");
      }
    },
    loopFn(data, bol) {
      // bol 是否显示
      if (data && data.length != 0) {
        data.forEach((item) => {
          if (item.model_id ) {   
            this.$emit("show-model",item.model_id, bol);
          }
        });
      }
    },
    diffent(fArr, cArr, field) {
      let diffRes = [];
      let fDatas = [];
      let cDatas = [];
      for (let i in fArr) {
        let flg = false;
        for (let j in cArr) {
          if (cArr[j][field] === fArr[i][field]) {
            flg = true;
            break;
          }
        }
        if (!flg) {
          fDatas.push(fArr[i]);
        }
      }
      for (let i in cArr) {
        let flg = false;
        for (let j in fArr) {
          if (fArr[j][field] === cArr[i][field]) {
            flg = true;
            break;
          }
        }
        if (!flg) {
          cDatas.push(cArr[i]);
        }
      }
      diffRes.push(...cDatas.concat(fDatas));
      return diffRes;
    },

    getData() {
      let searchParam = [{
          code: 'archi_type',
          ruleType: 'eq',
          value: JSON.parse(localStorage.getItem('targetArchi')).data_id
      }]
      let obj ={
        // object_uuid: "object62624ddb0a688",
        // view_uuid: "view62624f04dabbb",
				object_uuid: 'object62624ddb0a688',
				view_uuid: 'view62b03f11b6e99',
        __method_name__: "dataList",
        transcode: 0,
				search:searchParam
      }
      dataInterface(obj).then((res) => {
        if (res.data.data?.length) {
          let arr = [];
          res.data.data.forEach((item) => {
            let it = item;
            if (it.gis_type?.length) {
              //回显数据
							this.checkFn(item, it.id);
              arr.push(it);
            }
          });
          this.treeData = arr;
          this.loading = false;
        } else {
          this.treeData = [];
          this.loading = false;
        }
      });
    },
    checkFn(item, parentId) {
      let models = item.gis_type;
      models.forEach((val) => {
				val.id = JSON.stringify(parentId) + JSON.stringify(val.id)
				if(Object.prototype.toString.call(this.modelData) === '[object Object]' && this.modelData?.models?.length){
					//兼容老格式
					if (this.modelData?.models && this.modelData.models.filter((it) => it.model_id == val.model_id).length != 0) {
						this.loadedData.push(val.model_id)
						this.$emit("show-model",val.model_id, true);
						this.expandedKeys.push(item.id);
						this.expandedKeys = Array.from(
							new Set(this.expandedKeys)
						);
						this.checkedKeys.push(val.id);
					}
				} else 	if(Object.prototype.toString.call(this.modelData) === '[object Array]'){
					//回显构件选中,新格式
					if (this.modelData.filter((it) => it.model_id == val.model_id).length != 0) {
						this.loadedData.push(val.model_id)
						this.$emit("show-model",val.model_id, true);
						this.expandedKeys.push(item.id);
						this.expandedKeys = Array.from(
							new Set(this.expandedKeys)
						);
						this.checkedKeys.push(val.id);
					}
				}
				
      });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

