<template>
  <div class="editorPanel">
    <div class="vertical-flex content-wrap">
      <div
        v-for="item in infoList"
        :key="item.id"
        class="vertical-flex contentItem-wrap"
      >
        <div class="contentItem-title">{{ item.name }}</div>
        <el-input
          v-if="item.type === 'input'"
          v-model="item.value"
          placeholder="请输入"
          size="small"
        ></el-input>
        <el-select
          v-else-if="item.type === 'selector'"
          v-model="item.value"
          placeholder="请选择"
          size="small"
          :popper-append-to-body="false"
        >
          <el-option
            v-for="obj in item.options"
            :key="obj.id"
            :value="obj.id"
            :label="obj.name"
          ></el-option>
        </el-select>
      </div>
    </div>

    <div class="horizontal-flex btns-wrap">
      <template v-for="item in btns">
        <el-button
          :key="item.id"
          type="primary"
          class="btns-item"
          @click="onClickBtns(item)"
          >{{ item.name }}</el-button
        >
      </template>
    </div>
  </div>
</template>

<script>
  import { Select, option, Input, Button } from 'element-ui';

export default {
  name: 'Editor',
  components: {
    'el-select': Select,
    'el-option': option,
    'el-input': Input,
    'el-button': Button
  },
  props: {
    infoList: {
      type: Array
    },
    cancelCallback: {
      type: Function
    },
    confirmCallback: {
      type: Function
    }
  },
  data() {
    return {
      tabList: [{ id: 'info', name: '属性', icon: 'iconbimgis_shuxing' }],
      activeTab: 'info',
      btns: [
        { id: 'cancel', name: '取消' },
        { id: 'confirm', name: '确定' }
      ]
    };
  },
  methods: {
    onClickBtns(item) {
      switch (item.id) {
        case 'cancel':
          this.cancelCallback && this.cancelCallback();
          break;
        case 'confirm':
          this.confirmCallback && this.confirmCallback(this.infoList);
          break;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.vertical-flex {
  display: inline-flex;
  flex-direction: column;
  line-height: initial;
  vertical-align: middle;
}

.horizontal-flex {
  display: inline-flex;
  flex-direction: row;
  line-height: initial;
  vertical-align: middle;
}

.editorPanel {
  float: left;
  padding: 0 !important;
  overflow-y: hidden;
  background-color: inherit;
  color: inherit;

  .content-wrap {
    width: 100%;
    height: calc(100% - 60px);
    overflow: auto;

    .contentItem-wrap {
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 10px;
    }

    .contentItem-title {
      width: 100%;
      height: 40px;
      line-height: 40px;
      color: inherit;
      text-align: left;
      font-size: 14px;
    }
  }

  .btns-wrap {
    flex-direction: row-reverse;
    width: 100%;
    height: 60px;

    .btns-item {
      margin-right: 10px;
      height: 40px;
    }
  }
}
</style>
