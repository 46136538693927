export default class IBehaviors {
  constructor(viewer, comp) {
    this.viewer = viewer;
    this.comp = comp;
  }

  registerBehaviors(list) {
    const comp = this.comp;

    for (let { label, func, value, desc } of list) {
      comp.element.behaviors.push({
        label,
        value,
        desc
      });

      comp.behaviorHandler[value] = func;
    }
  }

  parseParamArray(_array) {
    return _array.split(',').map((str) => {
      return parseInt(str);
    });
  }
}
