<!--
 * @Author: Shiltin 18580045074@163.com
 * @Date: 2022-05-26 14:49:07
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2022-12-23 16:02:53
 * @FilePath: \dataview-next\src\custom-component\projectsMap\components\signs.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="custom-sign1">
    <div class="top-cont">
      <div class="top-inner-cont">
        <p class="bottom-line">
          地点<span> {{ data.type ? data.type : '-' }}</span>
        </p>
        <p>
          项目数量<span class="num">{{ data.children && data.children.length ? data.children.length : 0 }}</span>
        </p>
      </div>
    </div>
    <span class="arrow-icon shake-icon"></span>
  </div>
</template>
<script>
export default {
  name: 'sign',
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  methods: {
    location_gis() {
      this.$emit('location', this.data);
    }
  }
};
</script>

<style lang="less" scoped>
@import '~./customSign.less';
</style>
