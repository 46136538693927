var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapPanel"},[_c('div',{staticClass:"title-wrap"},[_c('span',[_vm._v("模型树")]),_c('a',{class:[
        'iconfont iconjijia_liebiao2',
        { 'showUnload-btn_active': _vm.isShowUnload }
      ],on:{"click":_vm.onShowUnload}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowUnload),expression:"isShowUnload"}],staticClass:"unload-wrap"},[_c('el-tree',{ref:"unloadModelTree",staticClass:"unloadModelTree-wrap",attrs:{"empty-text":"暂无数据","data":_vm.unLoadModelList,"props":_vm.treeProps,"default-expand-all":true,"expand-on-click-node":false,"node-key":"id","highlight-current":"","check-on-click-node":false,"check-strictly":false,"show-checkbox":true},on:{"check":_vm.onCheckNode},scopedSlots:_vm._u([{key:"default",fn:function({ node, data }){return [_c('div',{class:['horizontal-flex tree-node-unload']},[_c('a',{class:['tree-node-icon iconfont ', _vm.getIconByType(data.type)]}),_c('div',{staticClass:"tree-node-content"},[_vm._v(" "+_vm._s(data.name + _vm.getModelLength(data))+" ")]),_c('el-popconfirm',{attrs:{"title":"是否卸载模型?","confirm-button-text":"是","cancel-button-text":"否"},on:{"confirm":function($event){return _vm.onDelete(data)}},scopedSlots:_vm._u([{key:"reference",fn:function(){return [_c('a',{directives:[{name:"show",rawName:"v-show",value:(data.isLoaded),expression:"data.isLoaded"}],staticClass:"iconfont iconbimgis_guanbi tree-node-delete"})]},proxy:true}],null,true)})],1)]}}])}),_c('div',{class:[
        'unload-btn',
        { 'unload-btn_active': _vm.checkModelList.length > 0 }
      ],on:{"click":_vm.onAppend}},[_vm._v(" 确定加载"+_vm._s(_vm.checkModelList.length)+"个模型 ")])],1),_c('el-tree',{ref:"modelTree",staticClass:"modelTree-wrap",attrs:{"empty-text":"暂无数据","data":_vm.modelList,"props":_vm.treeProps,"expand-on-click-node":false,"default-expanded-keys":_vm.modelExpanded,"node-key":"id","lazy":true,"load":_vm.onLoadModelNode,"highlight-current":"","check-on-click-node":false,"check-strictly":false,"show-checkbox":true},on:{"check":_vm.onCheckModelNode},scopedSlots:_vm._u([{key:"default",fn:function({ node, data }){return [_c('div',{class:['horizontal-flex tree-node']},[(data.type === 'file' || data.type === 'model')?_c('a',{class:[
            'tree-node-icon iconfont ',
            _vm.getIconByType(data.type, data.modelType)
          ]}):_vm._e(),_c('span',{staticClass:"tree-node-content",on:{"click":function($event){return _vm.onRadioModelNode(node, data)},"dblclick":function($event){return _vm.onDblClickModelNode(node, data)}}},[_vm._v(_vm._s(data.name))]),(data.type === 'model' && !data.isLoaded)?_c('a',{staticClass:"iconfont iconshuaxin tree-node-loading",staticStyle:{"margin-right":"5px"}}):_vm._e(),(data.type === 'model')?_c('a',{staticClass:"iconfont icongis_dingwei tree-node-location",on:{"click":function($event){return _vm.onLocationModel(data)}}}):_vm._e()])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }