<!--
 * @Author: 吴绍鹏 542278473@qq.com
 * @Date: 2022-12-05 09:11:47
 * @LastEditors: 吴绍鹏 542278473@qq.com
 * @LastEditTime: 2022-12-13 16:20:43
 * @FilePath: \dataview-next\src\custom-component\video\player\HlsPlayer.vue
 * @Description: HLS 播放器，依旧会采用指令来进行播放，这样可以简化dom的操作，进行分别设置是为了上层适配，提高可阅读性，不支持其他操作
-->
<template>
  <video class="hls-video-warp" autoplay :controls="config.showControl" playsInline v-hlsDirectives="option">
  </video>
</template>
<script>
/**
 * @desc HLS 播放器，依旧会采用指令来进行播放，这样可以简化dom的操作，进行分别设置是为了上层适配，提高可阅读性，不支持其他操作
 */
import HLS from '@/libs/HLS.js';
export default {
  props: {
    data: {
      type: Object,
      default () {
        return {};
      },
      require: true
    },
    config: {
      type: Object,
      default () {
        return {
          controls: true
        };
      }
    } 
  },
  data () {
    return {
      player: null
    }
  },
  computed: {
    // 这里处理整合所需的配置参数
    option() {
      return {
        src: this.data.monitor_path,
        player: this.player,
        setPlayer: this.setPlayer
      };
    }
  },
  directives: {
    hlsDirectives: {
      inserted: (el, binding) => {
        if(el.tagName.toLocaleLowerCase() !== 'video') {
          console.error('hls directives must ues video tag');
          return;
        }
        if(!binding.value || !binding.value.src) {
          console.error('hls directives src is undefine');
        }
        if (binding.oldValue) {
          const srcFlag = binding.oldValue.src === binding.value.src;
          // 如果值并没有变化则不进行处理，原因是因为当盒子被点击时compute会重新计算
          if (srcFlag) {
            return;
          }
        }
        if(HLS.isSupported()) {
          const hls = new HLS();
          hls.attachMedia(el);
          hls.on(HLS.Events.MEDIA_ATTACHED, function () {
            console.log('video and hls.js are now bound together !');
            hls.loadSource(binding.value.src);
            hls.on(HLS.Events.MANIFEST_PARSED, function (event, data) {
              console.log('manifest loaded, found ' + data.levels.length + ' quality level');
              setTimeout(() => {
                el.play();
              }, 10);
            })
            hls.on(HLS.ErrorTypes.NETWORK_ERROR, (err, data) => {
              console.error(err, data);
            })
            hls.on(HLS.ErrorTypes.MEDIA_ERROR, (err, data) => {
              console.error(err, data);
            })
            hls.on(HLS.ErrorTypes.OTHER_ERROR, (err, data) => {
              console.error(err, data);
            })
          })
          binding.value.setPlayer(hls);
        }
      },
      update: (el, binding) => {
        if(el.tagName.toLocaleLowerCase() !== 'video') {
          console.error('hls directives must ues video tag');
          return;
        }
        // 如果值并没有变化则不进行处理，原因是因为当盒子被点击时compute会重新计算
        if (binding.oldValue && binding.oldValue.src === binding.value.src) {
          return ;
        }
         // 如果存在之前的实例，停掉它
         if (binding.value.player && binding.value.player.stop) {
          binding.value.player.stop();
        }
        if (binding.value.player && binding.value.player.destroy) {
          binding.value.player.destroy();
        }
        if(HLS.isSupported()) {
          const hls = new HLS();
          hls.attachMedia(el);
          hls.on(HLS.Events.MEDIA_ATTACHED, function () {
            console.log('video and hls.js are now bound together !');
            hls.loadSource(binding.value.src);
            hls.on(HLS.Events.MANIFEST_PARSED, function (event, data) {
              console.log('manifest loaded, found ' + data.levels.length + ' quality level');
              setTimeout(() => {
                el.play();
              }, 10);
            })
            hls.on(HLS.ErrorTypes.NETWORK_ERROR, (err, data) => {
              console.error(err, data);
            })
            hls.on(HLS.ErrorTypes.MEDIA_ERROR, (err, data) => {
              console.error(err, data);
            })
            hls.on(HLS.ErrorTypes.OTHER_ERROR, (err, data) => {
              console.error(err, data);
            })
          })
          binding.value.setPlayer(hls);
        }
      },
      unbind: (el, binding) => {
        if (binding.value.player && binding.value.player.stop) {
          binding.value.player.stop();
        }
        if (binding.value.player && binding.value.player.destroy) {
          binding.value.player.destroy();
        }
      }
    }
  },
  methods: {
    /**
     * @desc 设置player,在指令内设置实例属性
     * @param {*} player 
     */
    setPlayer(player) {
      this.player = player;
    }
  }
}
</script>

<style lang="less" scoped>
  .hls-video-warp{
    width: 100%;
    height: 100%;
    object-fit: fill;

  }
</style>