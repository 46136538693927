<!--
 * @Author: ttheitao
 * @Date: 2021-10-20 17:49:14
 * @LastEditors: luocheng
 * @LastEditTime: 2022-01-18 09:20:36
 * @Description: file content
-->
<template>
	<!-- <el-dialog
		:style="{
      position: 'relative',
      zIndex: isHidden ? -100 : ''
    }"
		custom-class="spreadsheet-iframe-box"
		:title="modalTitle"
		v-if="showModal"
		:visible.sync="showModal"
		width="98%"
		top="2vh"
	> -->
		<iframe
			class="iframe-class"
			ref="excelIframe"
			:src="sssDomain"
			@load="handleIframe"
			:style="{
        position: isHidden ? 'absolute' : 'relative',
        zIndex: isHidden ? -100 : ''
      }"
		></iframe>
	<!-- </el-dialog> -->

</template>

<script>
import { getToken } from '@/utils/tools';

export default {
	name: 'spreadsheet',
	props: {
		// 文件标识 支持 id 与 uuid
		id: { type: [String, Number], default: '' },
		// 模态框标题
		modalTitle: { type: String, default: '标题' },
		// 当前excel是否为模版，设置为true则会在渲染完成后清空id
		isTemplate: { type: Boolean, default: false },
		// 是否显示工具栏
		showToolbar: { type: Boolean, default: false },
		/**
		 * 渲染模式，[show | form | bind]
		 * 渲染模式在 showToolbar 为 true 的情况下可以在内部切换
		 * show 与 form 模式区别在于show模式不显示表单控件
		 * showToolbar 为 false 时，不要使用 bind 模式
		 */
		renderMode: { type: String, default: 'form' },
		// 是保存编辑结果(会保存模版名称)
		isSave: { type: Boolean, default: true },
		/**
		 * 表单数据
		 * 值为 uuid:value 键值对，uuid为对象或关联关系uuid, value数据类型为对象或数组
		 * 数据键必须是uuid，为每条数据（包括子表）生成一个新的属性'__id__'，存放数据的id，如果无则为空
		 */
		formData: {
			type: Object,
			default: function () {
				return {};
			}
		},
		/**
		 * 预设值
		 */
		presetValues: {
			type: Object,
			default: function () {
				return {};
			}
		},
		/**
		 * 自动下载
		 * 渲染完成后自动下载[excel | image | pdf]
		 */
		autoDownLoad: { type: String, default: '' },
		/**
		 * 固化内容，渲染过程不再请求远程数据、不渲染传入的表单数据、预设值等
		 */
		fixed: { type: Boolean, default: false },
		// 是否隐藏UI层
		isHidden: {
			type: Boolean,
			default: false,
			required: false
		}
	},
	data() {
		return {
			showModal: true,
			sssDomain: 'https://sss.bimcc.com'
		};
	},
	watch: {
		showModal: function (val) {
			if (val == false) {
				this.close();
			}
		}
	},
	mounted() {
		window.addEventListener('message', this.iframePostMessage);
	},
	methods: {
		handleIframe() {
			this.$refs.excelIframe.contentWindow.postMessage(
				{
					baseUrl: process.env.VUE_APP_BASE_URL || 'https://dwh_api.bimcc.net/',
					metadataToken: '4fb0b638-2c43-4d7e-8acf-f1a7abdee58easdfasdfqwerqwer',
					bimcctoken: getToken(),
					id: this.id,
					isTemplate: this.isTemplate,
					renderMode: this.renderMode,
					showToolbar: this.showToolbar,
					isSave: this.isSave,
					formData: JSON.parse(JSON.stringify(this.formData)),
					presetValues: JSON.parse(JSON.stringify(this.presetValues)),
					autoDownLoad: this.autoDownLoad,
					fixed: this.fixed
				},
				'*'
			);
		},
		iframePostMessage(e) {
			if (e.data.event == 'spreadsheetSubmit') {
				// 数据提交事件
				this.$emit('submit', e.data.id, e.data.formData);
				this.showModal = false;
			}
			if (e.data.event === 'spreadsheetAdSucceed') {
				// 导出成功
				// console.log('导出');
				this.showModal = false;
				this.$emit('exportSuccess');
			}
			// if (e.data.event === 'spreadsheetAdSucceed') {
			// 	// console.log('加载完毕', e);
			// }
		},
		close() {
			// 模态框关闭事件
			this.$emit('close');
		}
	},
	beforeDestroy() {
		window.removeEventListener('message', this.iframePostMessage);
	}
};
</script>

<style lang="less" scoped>
.iframe-class {
	width: 100%;
	height: calc(100vh - 2vh - 104px);
}
</style>

<style>
.spreadsheet-iframe-box {
	margin-bottom: 0px;
}
.spreadsheet-iframe-box .el-dialog__body {
	padding: 10px 20px;
}
</style>
