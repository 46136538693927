/*
 * @Author: 冉桂精 156189868@qq.com
 * @Date: 2023-01-11 16:47:47
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-07-13 11:37:16
 * @FilePath: /dataview-viewer-test/src/utils/systemActions.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { dataInterface } from '@/apis/data/index';
import eventBus from '@/plugins/eventBus';
import { setPermissionObj } from '@/utils/tools';
import store from '@/store';
/**
 * @description: 切换组织架构
 * @param {String} id 带表明的组织架构ID
 */
export const onToggleArchi = (id) => {
  return new Promise((resolve, reject) => {
    // console.log(id, '------111111---');
    if (!id || typeof id !== 'string') {
      resolve(false);
      return false;
    }
    dataInterface({
      __method_name__: 'globalFunctionCall',
      typeName: 'LoginFuncClass',
      type: 'behavior',
      funcName: 'ToggleArchBehavior',
      payload: {
        __external_company_type: localStorage.getItem('userType'),
        archi_id: id,
        archi_table: id.indexOf('companys') > -1 ? 'org_companys' : 'org_projects'
      }
    })
      .then(res => {
        // console.log('切换结果----', res);
        if (!res || res.status !== 200) {
          resolve(false);
          return false;
        }
        // 通知系统层进行组织架构切换
        eventBus.$emit('EDITOR_systemAction', {
          key: 'toggleArchi',
          res
        });
        const targetArchi = {
          ...res.data.data,
          archiId: res.data.data.archi_id,
          archiType: id.indexOf('companys') > -1 ? 'company' : 'project',
          id: res.data.data.data_id,
          company_id: res.data.data.data.company_id || '',
          modelApproval: ['https://bim.xwjgpt.com/'].includes(process.env.VUE_APP_V3_HOST) ? true : false
        };
        // console.log('setTargetArchi---3333', targetArchi, process.env.VUE_APP_V3_HOST);
        store.commit('setTargetArchi', {targetArchi, a: 5});
        window.localStorage.setItem('currentProjectInfo', JSON.stringify(targetArchi));
        localStorage.setItem('userRoles', res.data.data.permissions || '');
        setPermissionObj(res.data.data.permissions || '');
        if (res?.data?.data?.userInfo) {
          const userInfo = res?.data?.data?.userInfo || {};
          let roleNames = [];
          if (Array.isArray(userInfo?.roles) && userInfo?.roles.length) {
            userInfo?.roles.forEach(ele => {
              if (ele?.name) {
                roleNames.push(ele.name);
              }
            });
          }
          localStorage.setItem('userInfo', JSON.stringify({
            ...userInfo,
            roleName: roleNames.join()
          }));
        }
        resolve(true);
      })
      .catch(err => {
        reject(false);
        console.log(err);
      });
  });
};