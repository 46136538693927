/*
 * @Author: your name
 * @Date: 2021-12-14 11:31:20
 * @LastEditTime: 2023-07-10 15:05:56
 * @LastEditors: 冉桂精 156189868@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /dataview-viewer-test/src/plugins/util.js
 */
export const baseUrl = () => {
	return '4fb0b638-2c43-4d7e-8acf-f1a7abdee58e';
};

export const formSrc = () => {
	if(process.env.NODE_ENV === 'production'){
		return '/base/designForm/#/design'
	} else {
		return '/localForm/#/design'
	}
};

export const uniqid = () => {
	var d = new Date().getTime();
	if (window.performance && typeof window.performance.now === "function") {
		d += performance.now();
	}
	var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		var r = (d + Math.random() * 16) % 16 | 0;    // d是随机种子
		d = Math.floor(d / 16);
		return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
	});
	return uuid;
};

/**
 * 任务引擎获取前置任务
 * @param graphData
 * @param nodeId
 */
export const preTaskNode = (graphData,nodeId,type,preNodeId) => {
	const edge = graphData.filter(item=>item.shape === 'edge')
	const startNode = graphData.filter(item=>item.shape === 'start')
	const startId = startNode[0]?.id || 0
	if(!startId) return preNodeId
	let preNode = []
	if(type === 'edge'){
		preNode = edge.filter(item=>item.id === nodeId)
	} else {
		preNode = edge.filter(item=>item.target.cell === nodeId)
	}
	const nodeIdArr = Array.from(preNode,item=>item.source.cell)
	if(!nodeIdArr.includes(startId)){
		for (let item of nodeIdArr){
			preNodeId.push(item)
			preTaskNode(graphData,item,type,preNodeId)
		}
	}
	return preNodeId
}

/**
 * 公式规则格式化树形数据
 * @param goalData
 * @returns {*|*[]}
 */
export function formatTreeData(goalData) {
  let treeData = [];
  function mapTree(item) {
    const { tagIcon } = item.__config__;
    const haveChildren = Array.isArray(item.__config__?.children) && item.__config__?.children.length > 0;
    const itemData = {
      id: uniqid(),
      formId: item.__config__.formId,
      type: tagIcon,
      field: item.__vModel__ || '',
      label: item.__config__.label,
      children: []
    };
    if (haveChildren) {
      itemData.children = item.__config__.children.map(i => mapTree(i));
    }
    if (tagIcon === 'tab') {
      const tabs = [];
      for (const tab of item.__tabs__) {
        const tabItem = {
          id: uniqid(),
          formId: '',
          type: 'tabItem',
          field: '',
          label: tab.label,
          children: []
        };
        if (tab?.children && tab.children.length > 0) {
          tabItem.children = tab.children.map(i => mapTree(i));
        }
        tabs.push(tabItem);
      }
      itemData.children = tabs;
    }
    if (tagIcon === 'table') {
      for (const rows of item.widget.rows) {
        for (const cols of rows.cols) {
          if (cols?.widgetList && cols.widgetList.length > 0) {
            itemData.children = itemData.children.concat(cols.widgetList.map(i => mapTree(i)));
          }
        }
      }
    }
    if (tagIcon === 'tableData') {
      const columns = [];
      for (const col of item.__config__.tableColumns) {
        const colItem = {
          id: uniqid(),
          formId: col?.element?.__config__?.formId || '',
          type: col?.element?.__config__?.tagIcon || 'text',
          field: col.prop,
          label: col.label,
          children: []
        };
        columns.push(colItem);
      }
      itemData.children = columns;
    }
    return itemData;
  }
  treeData = goalData.map(item => mapTree(item));
  return treeData;
}
