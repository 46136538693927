/*
 * @Author: 冉桂精 156189868@qq.com
 * @Date: 2023-06-20 14:43:11
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-06-21 11:48:05
 * @FilePath: /dataview-viewer-test/src/plugins/systemCompatibleMixin.js
 * @Description:
 */
import { dataInterface } from "@/apis/data";
import { getQueryValue, setToken } from "@/utils/tools";

export default {
  methods: {
    /**
     * @description: 转换外部token
     */
     transformOutToken() {
      return new Promise((resolve) => {
        const systemType = getQueryValue("systemType") || -1;
        const token = getQueryValue("token") || "";
        if (!token || systemType === -1) {
          resolve(null)
          return false
        }
        // 3.0系统
        if (+systemType === 1) {
          dataInterface({ token }, "/api/v4/user/oldPlatformLogin").then((res) => {
            const result = res?.data?.data;
            if (!result) {
              resolve(null);
              return false;
            }
            setToken(result?.token)
            resolve(result)
          }).catch((err) => {
            console.log(err);
            resolve("");
            return false;
          });
        } else {
          resolve(null)
        }
      })
    }
  },
};
