<template>
  <div class="decoration-container" :ref="ref">
    <svg :width="w" :height="h" :style="`position: relative; transform-origin: left top;transform:scale(${svgScale[0]},${svgScale[1]});`" viewBox="0 0 1920 106" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="-16.5" :width="w" :height="h" :fill="mainColor" stroke="none"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M608 0H968H1328H1362L1222 90.5L1208.5 90.5L1203.5 85.0808H968H732.5L727.5 90.5L714 90.5L574 0H608Z" fill="url(#paint0_linear_15_43)" fill-opacity="0.6"/>
      <path d="M1362 0L1362.27 0.419906L1363.69 -0.5H1362V0ZM1222 90.5V91H1222.15L1222.27 90.9199L1222 90.5ZM1208.5 90.5L1208.13 90.8391L1208.28 91H1208.5V90.5ZM1203.5 85.0808L1203.87 84.7418L1203.72 84.5808H1203.5V85.0808ZM732.5 85.0808V84.5808H732.281L732.133 84.7418L732.5 85.0808ZM727.5 90.5V91H727.719L727.867 90.8391L727.5 90.5ZM714 90.5L713.729 90.9199L713.852 91H714V90.5ZM574 0V-0.5H572.306L573.729 0.419906L574 0ZM968 -0.5H608V0.5H968V-0.5ZM968 0.5H1328V-0.5H968V0.5ZM1328 0.5H1362V-0.5H1328V0.5ZM1361.73 -0.419906L1221.73 90.0801L1222.27 90.9199L1362.27 0.419906L1361.73 -0.419906ZM1222 90L1208.5 90V91L1222 91V90ZM1208.87 90.1609L1203.87 84.7418L1203.13 85.4199L1208.13 90.8391L1208.87 90.1609ZM1203.5 84.5808H968V85.5808H1203.5V84.5808ZM732.5 85.5808H968V84.5808H732.5V85.5808ZM727.867 90.8391L732.867 85.4199L732.133 84.7418L727.133 90.1609L727.867 90.8391ZM714 91L727.5 91V90L714 90V91ZM573.729 0.419906L713.729 90.9199L714.271 90.0801L574.271 -0.419906L573.729 0.419906ZM608 -0.5H574V0.5H608V-0.5Z" fill="url(#paint1_linear_15_43)"/>
      <mask id="path-4-inside-1_15_43" fill="white">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1236 91L1335.7 26.2187L1336.18 26.252L1360.9 11H1924.18V91H1236Z"/>
      </mask>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M1236 91L1335.7 26.2187L1336.18 26.252L1360.9 11H1924.18V91H1236Z" fill="url(#paint2_linear_15_43)"/>
      <path d="M1335.7 26.2187L1335.77 25.2211L1335.43 25.1979L1335.15 25.3802L1335.7 26.2187ZM1236 91L1235.46 90.1615L1232.63 92H1236V91ZM1336.18 26.252L1336.11 27.2496L1336.43 27.2719L1336.7 27.1031L1336.18 26.252ZM1360.9 11V10H1360.62L1360.38 10.1489L1360.9 11ZM1924.18 11H1925.18V10H1924.18V11ZM1924.18 91V92H1925.18V91H1924.18ZM1335.15 25.3802L1235.46 90.1615L1236.54 91.8385L1336.24 27.0573L1335.15 25.3802ZM1336.25 25.2544L1335.77 25.2211L1335.63 27.2163L1336.11 27.2496L1336.25 25.2544ZM1360.38 10.1489L1335.65 25.4009L1336.7 27.1031L1361.43 11.8511L1360.38 10.1489ZM1924.18 10H1360.9V12H1924.18V10ZM1925.18 91V11H1923.18V91H1925.18ZM1236 92H1924.18V90H1236V92Z" fill="url(#paint3_linear_15_43)" mask="url(#path-4-inside-1_15_43)"/>
      <mask id="path-6-inside-2_15_43" fill="white">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M702.178 91L602.479 26.2187L602 26.252L577.277 11H14V91H702.178Z"/>
      </mask>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M702.178 91L602.479 26.2187L602 26.252L577.277 11H14V91H702.178Z" fill="url(#paint4_linear_15_43)"/>
      <path d="M602.479 26.2187L602.409 25.2211L602.743 25.1979L603.024 25.3802L602.479 26.2187ZM702.178 91L702.723 90.1615L705.552 92H702.178V91ZM602 26.252L602.069 27.2496L601.749 27.2719L601.475 27.1031L602 26.252ZM577.277 11V10H577.56L577.802 10.1489L577.277 11ZM14.0001 11H13.0001V10H14.0001V11ZM14.0001 91V92H13.0001V91H14.0001ZM603.024 25.3802L702.723 90.1615L701.633 91.8385L601.934 27.0573L603.024 25.3802ZM601.931 25.2544L602.409 25.2211L602.548 27.2163L602.069 27.2496L601.931 25.2544ZM577.802 10.1489L602.525 25.4009L601.475 27.1031L576.752 11.8511L577.802 10.1489ZM14.0001 10H577.277V12H14.0001V10ZM13.0001 91V11H15.0001V91H13.0001ZM702.178 92H14.0001V90H702.178V92Z" fill="url(#paint5_linear_15_43)" mask="url(#path-6-inside-2_15_43)"/>
      <path d="M1891 86L1874.85 64H1863.31L1879.46 86H1891Z" fill="url(#paint6_linear_15_43)"/>
      <path d="M1873.69 86L1857.54 64H1846L1862.15 86H1873.69Z" fill="url(#paint7_linear_15_43)"/>
      <path d="M1855.23 86L1839.08 64H1827.54L1843.69 86H1855.23Z" fill="url(#paint8_linear_15_43)"/>
      <path d="M1836.77 86L1820.62 64H1809.08L1825.23 86H1836.77Z" fill="url(#paint9_linear_15_43)"/>
      <path d="M1817.15 86L1801 64H1789.46L1805.62 86H1817.15Z" fill="url(#paint10_linear_15_43)"/>
      <path d="M1798.69 86L1782.54 64H1771L1787.15 86H1798.69Z" fill="url(#paint11_linear_15_43)"/>
      <path d="M31 86L47.1538 64H58.6923L42.5385 86H31Z" fill="url(#paint12_linear_15_43)"/>
      <path d="M48.3077 86L64.4615 64H76L59.8462 86H48.3077Z" fill="url(#paint13_linear_15_43)"/>
      <path d="M66.7692 86L82.9231 64H94.4615L78.3077 86H66.7692Z" fill="url(#paint14_linear_15_43)"/>
      <path d="M85.2308 86L101.385 64H112.923L96.7692 86H85.2308Z" fill="url(#paint15_linear_15_43)"/>
      <path d="M104.846 86L121 64H132.538L116.385 86H104.846Z" fill="url(#paint16_linear_15_43)"/>
      <path d="M123.308 86L139.462 64H151L134.846 86H123.308Z" fill="url(#paint17_linear_15_43)"/>
    <defs>
      <linearGradient id="paint0_linear_15_43" x1="933.75" y1="0" x2="933.75" y2="90.5" gradientUnits="userSpaceOnUse">
      <stop offset="0.276042" :stop-color="mainColor"/>
      <stop offset="1" :stop-color="secondaryColor"/>
      </linearGradient>
      <linearGradient id="paint1_linear_15_43" x1="932" y1="86" x2="932" y2="-2.87245e-06" gradientUnits="userSpaceOnUse">
      <stop stop-color="#3CFCDB"/>
      <stop offset="1" stop-color="#3BFBDB" stop-opacity="0"/>
      </linearGradient>
      <linearGradient id="paint2_linear_15_43" x1="1218.18" y1="11" x2="1751.18" y2="11" gradientUnits="userSpaceOnUse">
      <stop :stop-color="secondaryColor"/>
      <stop offset="1" stop-color="#002C99" stop-opacity="0"/>
      </linearGradient>
      <linearGradient id="paint3_linear_15_43" x1="1263" y1="86.5" x2="1591.5" y2="80.5" gradientUnits="userSpaceOnUse">
      <stop stop-color="#37F4D7"/>
      <stop offset="0.99987" stop-color="#37F4D7" stop-opacity="0"/>
      </linearGradient>
      <linearGradient id="paint4_linear_15_43" x1="720" y1="11" x2="187" y2="11" gradientUnits="userSpaceOnUse">
      <stop :stop-color="secondaryColor"/>
      <stop offset="1" stop-color="#002C99" stop-opacity="0"/>
      </linearGradient>
      <linearGradient id="paint5_linear_15_43" x1="675.178" y1="86.5" x2="346.678" y2="80.5" gradientUnits="userSpaceOnUse">
      <stop stop-color="#37F4D7"/>
      <stop offset="0.99987" stop-color="#37F4D7" stop-opacity="0"/>
      </linearGradient>
      <linearGradient id="paint6_linear_15_43" x1="1869.65" y1="59.5091" x2="1761.77" y2="59.5091" gradientUnits="userSpaceOnUse">
      <stop stop-color="#0089D6"/>
      <stop offset="1" stop-color="#83FFF8" stop-opacity="0"/>
      </linearGradient>
      <linearGradient id="paint7_linear_15_43" x1="1869.65" y1="59.5091" x2="1761.77" y2="59.5091" gradientUnits="userSpaceOnUse">
      <stop stop-color="#0089D6"/>
      <stop offset="1" stop-color="#83FFF8" stop-opacity="0"/>
      </linearGradient>
      <linearGradient id="paint8_linear_15_43" x1="1869.65" y1="59.5091" x2="1761.77" y2="59.5091" gradientUnits="userSpaceOnUse">
      <stop stop-color="#0089D6"/>
      <stop offset="1" stop-color="#83FFF8" stop-opacity="0"/>
      </linearGradient>
      <linearGradient id="paint9_linear_15_43" x1="1869.65" y1="59.5091" x2="1761.77" y2="59.5091" gradientUnits="userSpaceOnUse">
      <stop stop-color="#0089D6"/>
      <stop offset="1" stop-color="#83FFF8" stop-opacity="0"/>
      </linearGradient>
      <linearGradient id="paint10_linear_15_43" x1="1869.65" y1="59.5091" x2="1761.77" y2="59.5091" gradientUnits="userSpaceOnUse">
      <stop stop-color="#0089D6"/>
      <stop offset="1" stop-color="#83FFF8" stop-opacity="0"/>
      </linearGradient>
      <linearGradient id="paint11_linear_15_43" x1="1869.65" y1="59.5091" x2="1761.77" y2="59.5091" gradientUnits="userSpaceOnUse">
      <stop stop-color="#0089D6"/>
      <stop offset="1" stop-color="#83FFF8" stop-opacity="0"/>
      </linearGradient>
      <linearGradient id="paint12_linear_15_43" x1="52.3462" y1="62.5091" x2="160.231" y2="62.5091" gradientUnits="userSpaceOnUse">
      <stop stop-color="#0189D6"/>
      <stop offset="1" stop-color="#83FFF8" stop-opacity="0"/>
      </linearGradient>
      <linearGradient id="paint13_linear_15_43" x1="52.3462" y1="62.5091" x2="160.231" y2="62.5091" gradientUnits="userSpaceOnUse">
      <stop stop-color="#0189D6"/>
      <stop offset="1" stop-color="#83FFF8" stop-opacity="0"/>
      </linearGradient>
      <linearGradient id="paint14_linear_15_43" x1="52.3462" y1="62.5091" x2="160.231" y2="62.5091" gradientUnits="userSpaceOnUse">
      <stop stop-color="#0189D6"/>
      <stop offset="1" stop-color="#83FFF8" stop-opacity="0"/>
      </linearGradient>
      <linearGradient id="paint15_linear_15_43" x1="52.3462" y1="62.5091" x2="160.231" y2="62.5091" gradientUnits="userSpaceOnUse">
      <stop stop-color="#0189D6"/>
      <stop offset="1" stop-color="#83FFF8" stop-opacity="0"/>
      </linearGradient>
      <linearGradient id="paint16_linear_15_43" x1="52.3462" y1="62.5091" x2="160.231" y2="62.5091" gradientUnits="userSpaceOnUse">
      <stop stop-color="#0189D6"/>
      <stop offset="1" stop-color="#83FFF8" stop-opacity="0"/>
      </linearGradient>
      <linearGradient id="paint17_linear_15_43" x1="52.3462" y1="62.5091" x2="160.231" y2="62.5091" gradientUnits="userSpaceOnUse">
      <stop stop-color="#0189D6"/>
      <stop offset="1" stop-color="#83FFF8" stop-opacity="0"/>
      </linearGradient>
    </defs>
    </svg>
  </div>
</template>
<script>
import Resize  from '@jiaminghi/data-view/src/mixin/autoResize.js'
import Minin from './mixin'
export default {
  name: 'Decoration12',
  mixins: [Minin, Resize],
  data() {
    return {
      ref: 'decoration-13',
      w: 1920,
      h: 106,
      svgScale: [1, 1],
    }
  },
  computed: {
    mainColor() {
      return this.statusConfig.mainColor || '#001C68'
    },
    secondaryColor() {
      return this.statusConfig.secondaryColor || '#002AA5'
    }
  },
  methods: {
    afterAutoResizeMixinInit () {
      const { calcScale } = this
      calcScale()
    },
    calcScale () {
      const { width, height, w, h } = this
      this.svgScale = [width / w, height / h]
    },
    onResize () {
      const { calcScale } = this

      calcScale()
    },
  }
}
</script>
<style lang="less" scoped>
  .decoration-container{
    width: 100%;
    height: 100%;
  }
</style>