<!--
    @name: calcRules
    @description：calcRules
    @author: ZengWei
    @date: 2021-11-30 09:51
-->
<template>
  <div class="rule-container">
    <div class="rule-wrapper">
      <div class="wrap-l">
        <div class="el-menu-wrap">
          <div class="el-menu-inner">
            <el-menu :default-active="activeIndex" mode="horizontal" @select="handleSelect">
              <el-menu-item index="1">常用公式</el-menu-item>
              <el-menu-item index="2">表单字段</el-menu-item>
              <el-menu-item index="3">数据字段</el-menu-item>
            </el-menu>
          </div>
        </div>
        <div v-if="activeIndex === '1'" class="right-scrollbar">
          <el-collapse v-model="activeNames">
            <el-collapse-item
              v-for="(item,index) in categoryFuncList"
              :key="index"
              :title="item.category"
              :name="index"
            >
              <el-menu class="el-menu-demo" mode="horizontal">
                <el-menu-item
                  v-for="(ele,ind) in item.functions"
                  :key="ind"
                >
                  <div @mouseover="hoverEvent(ele)" @click="copyValue(ele,'func')">
                    <p class="fn-name">
                      {{ ele.displayName }}
                    </p>
                    <p class="fn-desc">
                      {{ ele.desc }}
                    </p>
                  </div>
                </el-menu-item>
              </el-menu>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div v-if="activeIndex === '2'" class="left-scrollbar">
          <div class="tree-data">
            <el-tree
              v-if="treeData"
              :data="treeData"
              node-key="id"
              default-expand-all
              :expand-on-click-node="false"
              empty-text="暂无表单字段"
            >
              <span class="custom-tree-node" slot-scope="{ node, data }">
                <span>{{ node.label }}</span>
                <span v-if="data.field">
                  <el-button
                    type="text"
                    size="mini"
                    @click="() => handleClick(node)">
                    字段
                  </el-button>
                  <el-button
                    type="text"
                    size="mini"
                    @click="() => handleClick(node,'calc')">
                    取值
                  </el-button>
                </span>
              </span>
            </el-tree>
          </div>
        </div>
        <div v-if="activeIndex === '3'" class="left-scrollbar">
          <div class="fields-data">
            <p style="margin-top: 0;font-size: 14px;color:#606266;margin-bottom: 10px">
              数据字段选择
            </p>
            <div
              v-for="(item,index) in dataFields"
              :key="index"
              style="position: relative;border: 1px dashed #ccc;padding: 15px 15px 0 15px;margin-bottom: 15px">
              <div
                class="close-btn select-line-icon"
                style="position: absolute;color: red;top: -15px; right:-8px;cursor: pointer"
                @click="dataFields.splice(index,1)">
                <i class="el-icon-remove-outline" />
              </div>
              <el-select
                v-model="item.objectUuid"
                style="width: 100%"
                :filterable="true"
                size="small"
                @change="dynamicSelect($event,index)">
                <el-option
                  v-for="item in objectList"
                  :key="item.uuid"
                  :label="item.name"
                  :value="item.uuid">
                  {{ item.name }}
                </el-option>
              </el-select>
              <div class="tree-data">
                <el-tree
                  v-if="item.viewList"
                  :data="item.viewList"
                  node-key="id"
                  default-expand-all
                  :expand-on-click-node="false"
                  empty-text="暂无数据视图"
                >
                  <span class="custom-tree-node" slot-scope="{ node, data }">
                    <span>{{ node.label }}</span>
                    <span v-if="data.value">
                      <el-button
                        type="text"
                        size="mini"
                        @click="() => handleClick(node,'view')">
                        视图
                      </el-button>
                    </span>
                  </span>
                </el-tree>
                <el-tree
                  v-if="item.treeData"
                  :data="item.treeData"
                  node-key="id"
                  default-expand-all
                  :expand-on-click-node="false"
                  empty-text="暂无数据字段"
                >
                  <span class="custom-tree-node" slot-scope="{ node, data }">
                    <span>{{ node.label }}</span>
                    <span v-if="data.value">
                      <el-button
                        type="text"
                        size="mini"
                        @click="() => handleClick(node)">
                        字段
                      </el-button>
                      <el-button
                        type="text"
                        size="mini"
                        @click="() => handleClick(node,'calc')">
                        取值
                      </el-button>
                    </span>
                  </span>
                </el-tree>
              </div>
            </div>
            <div style="margin-left: 20px;">
              <el-button
                style="padding-bottom: 0"
                icon="el-icon-circle-plus-outline"
                type="text"
                @click="addDataSource"
              >
                添加数据源
              </el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="wrap-r">
        <div class="rule-header">
          <span>{{ hoverRule.displayName }}</span>
          <p>{{ hoverRule.wiki }}</p>
        </div>
        <div class="rule-body">
          <codemirror
            ref="codeRule"
            v-model="curCode"
            class="code-rules"
            :options="cmOptions"
          />
        </div>
        <div class="rule-footer">
          <div
            v-for="(item,index) in expressBarList"
            :key="index"
            class="rule-op-item"
            @click="copyValue(item, 'op')"
          >
            {{ item.displayName }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import calcRules from '@/plugins/editorCalcRules'
import { codemirror } from 'vue-codemirror'
import "codemirror/theme/idea.css";
import { formatTreeData } from '@/plugins/util'
import {Menu,MenuItem,Collapse,CollapseItem,Tree} from "element-ui";
import {formRequest} from "@/apis/data/form";
require("codemirror/mode/javascript/javascript"); // 这里引入的模式的js，根据设置的mode引入，一定要引入！！

export default {
  name: 'CalcRules',
  components: {
    codemirror,
    'el-menu': Menu,
    'el-menu-item': MenuItem,
    'el-collapse': Collapse,
    'el-collapse-item': CollapseItem,
    'el-tree': Tree
  },
  props: {
    drawingList: {
      type: Array,
      default: () => []
    },
    codeRule: {
      type: [Array,Object],
      default: () => {}
    }
  },
  data() {
    return {
      activeIndex:'1',
      activeNames: 0,
      hoverRule: calcRules.categoryFuncList[0].functions[0],
      categoryFuncList: calcRules.categoryFuncList,
      expressBarList: calcRules.expressBarList,
      // advancedRuleList:calcRules.advancedRuleList,
      advancedRuleList: [],
      cursorIndex: 0,
      focus: false,

      // 公式编辑器
      curCode:'',
      cmOptions:{
        value:'',
        mode:"text/javascript",
        theme: "idea",
        readOnly: false,
      },
      treeData: null,
      objectList: [],
      dataFields: [
        {
          objectUuid: '',
          viewList: [],
          treeData: [],
        }
      ],
    }
  },
  created() {
    const data = formatTreeData(this.drawingList)
    this.treeData = data
  },
  mounted() {
    this.recoverCodeRule()
    this.getObjectList()
  },
  methods: {
    getObjectList(){
      const url = `/api/metadata/objects`
      const params = {
        size: 99999, page: 1
      }
      formRequest('GET',url,params).then(res => {
        this.objectList = res.data.data.data
      })
    },
    dynamicSelect(objectUuid,index){
      this.getViewList(objectUuid,index);
      this.getFieldList(objectUuid,index);
    },
    getViewList(objectUuid,index){
      const url = `/api/metadata/views`
      const params = {
        object_uuid: objectUuid
      }
      const data = this.objectList.filter(item => item.uuid === objectUuid)
      formRequest('GET',url,params).then(res => {
        const viewList = res.data.data.data
        const views = []
        for (let item of viewList){
          views.push({
            id: item.uuid,
            label: item.name,
            value: item.uuid,
            field: item.uuid,
          })
        }
        const newData = [
          {
            id: data[0].uuid,
            label: data[0].name,
            field: data[0].uuid,
            children: views
          }
        ]
        this.dataFields[index].viewList = newData
      })
    },
    getFieldList(objectUuid,index){
      const url = `/api/metadata/objectFields`
      const params = {
        object_uuid: objectUuid,
        size: 500, page: 1
      }
      const data = this.objectList.filter(item => item.uuid === objectUuid)
      formRequest('GET',url,params).then(res => {
        const fieldsList = res.data.data.data
        const fields = []
        for (let item of fieldsList){
          fields.push({
            id: item.uuid,
            label: item.name,
            value: item.uuid,
            field: item.uuid,
          })
        }
        const newData = [
          {
            id: data[0].uuid,
            label: data[0].name,
            field: data[0].uuid,
            children: fields
          }
        ]
        this.dataFields[index].treeData = newData
      })
    },
    addDataSource(){
      this.dataFields.push({objectUuid: '', viewList: [], treeData: []})
    },
    recoverCodeRule(){
      if(this.codeRule instanceof Array){
        // 兼容旧公式
        const calcRules = this.codeRule
        let express = '';
        for (let ele of calcRules) {
          if(ele.ruleType === 'FUNCTION'){
            express += ele.funcName
          } else if(ele.ruleType === 'COMPONENT_VALUE'){
            express += `calcField('`+ele.value+`')`
          } else if(ele.ruleType === 'STATIC'){
            if (parseInt(ele.value)){
              express += ele.value
            } else {
              express += `'`+ele.value+`'`
            }
          } else {
            express += ele.displayName
          }
        }
        this.$refs.codeRule.codemirror.setValue(express)
      } else {
        const editor = this.$refs.codeRule.codemirror;
        const value = this.codeRule?.originVal || ''
        editor.setValue(value)
        const markers = this.codeRule?.marker || []
        editor.eachLine((line) => {
          const lineNum = editor.lineInfo(line).line
          for (const marker of markers){
            const type = marker.type
            const label = marker.label
            let index = line.text.indexOf(label)
            while (index !== -1){
              const startCh = index
              const endCh = startCh + label.length
              const startPos = {line:lineNum, ch: startCh}
              const endPos = {line:lineNum, ch: endCh}

              const dom = document.createElement("span")
              dom.className = type
              dom.innerHTML = marker.label

              editor.replaceRange(marker.label,startPos,endPos)
              editor.markText(startPos, endPos, {
                replacedWith: dom,
                attributes: marker
              })
              index = line.text.indexOf(label, index + 1)
            }
          }
        })
      }
    },
    handleSelect(index){
      this.activeIndex = index
    },
    getRule(){
      const marker = this.$refs.codeRule.codemirror.getAllMarks()
      let dataStr = this.$refs.codeRule.codemirror.getValue()
      const newMarkers = [],dataSource = []
      for (const item of marker){
        const type = item.attributes.type
        let realField = item.attributes.field
        const text = item.attributes.label
        if(type === 'cm-field'){
          realField = `calcField('${realField}')`
          dataStr = dataStr.replace(text,realField)
        } else if(type === 'cm-field-flag') {
          realField = `'${realField}'`
          dataStr = dataStr.replace(text,realField)
        } else if(type === 'cm-view') {
          realField = `calcView('${realField}')`
          dataStr = dataStr.replace(text,realField)
        }
        newMarkers.push(item.attributes)
      }
      for (let item of this.dataFields){
        dataSource.push({
          objectUuid: item.objectUuid,
        })
      }
      const originVal = this.$refs.codeRule.codemirror.getValue()
      const codeRules = {
        originVal : originVal, express: dataStr,
        marker: newMarkers, dataSource: dataSource
      }
      console.log(JSON.stringify(codeRules))
      return codeRules
    },
    //插入信息
    copyValue(param,flag = 'func') {
      const label = param.displayName
      const curPos = this.$refs.codeRule.codemirror.getCursor()
      const startPos={
        line: curPos.line,  //行号
        ch: curPos.ch //光标位置
      }
      const type = 'cm-function'

      const dom = document.createElement("span")
      dom.className = type
      dom.innerHTML = label;

      let bookMark = label;
      const endPos = { line: startPos.line, ch: startPos.ch + bookMark.length };

      if(param.category === 'code'){
        bookMark = param.code
        this.$refs.codeRule.codemirror.replaceRange(bookMark,startPos)  //替换内容
      } else {
        if(flag === 'func'){
          bookMark = bookMark + '()'
        }
        this.$refs.codeRule.codemirror.replaceRange(bookMark,startPos)  //替换内容
        this.$refs.codeRule.codemirror.markText(startPos, endPos, {
          replacedWith: dom,
          attributes: { type, label }
        });
      }
    },
    onFocus() {
      this.focus = true
    },
    offFocus() {
      this.focus = false
    },
    hoverEvent(element) {
      this.hoverRule = element
    },
    getAdvancedRules() {
      return this.advancedRuleList
    },
    handleClick(node,flag='field'){
      const data = node.data
      const parentData = node.parent.data
      let label = '', field = ''
      if(parentData.field) {
        label = `[${data.label}]`
        field = `${parentData.field}.${data.field}`
      } else {
        label = `[${data.label}]`
        field = `${data.field}`
      }

      //获取codemirror光标位置
      const curPos = this.$refs.codeRule.codemirror.getCursor()
      const startPos = {};
      startPos.line = curPos.line;
      startPos.ch = curPos.ch;

      //制作标签dom(颜色样式自行设置)
      const typeArr = {
        'calc':'cm-field','field': 'cm-field-flag',
        'view':'cm-view','cond': 'cm-cond'
      }
      let type = typeArr[flag]
      const dom = document.createElement("span");
      dom.className = type
      dom.innerHTML = label;

      const bookMark = label;
      this.$refs.codeRule.codemirror.replaceRange(bookMark, startPos);//先插入字符串

      //再对插入字符串进行标签制定（其实就是在codemirror中动态添加标签）
      const endPos = { line: startPos.line, ch: startPos.ch + bookMark.length };
      this.$refs.codeRule.codemirror.markText(startPos, endPos, {
        replacedWith: dom,
        attributes: { type, field, label }
      });
    },
  }
}
</script>
<style lang="less" scoped>
:deep(.el-menu-wrap) {
  border-bottom: solid 1px #e6e6e6;

  .el-menu-inner{
    width: 300px;
    margin: auto;

    .el-menu.el-menu--horizontal{
      border-bottom: none;
    }
  }
}

.fields-data{
  padding: 15px;
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

.field-item{
  list-style: none;
  margin: 0;
  padding-left: 0;
  max-height: 400px;
  overflow-y: auto;

  li {
    cursor: pointer;
    line-height: 30px;
    padding: 0 10px;
    &:hover{
      background-color: #f6f6f6;
    }
  }

}

:deep(.code-rules) {
  height: 100%;
  .CodeMirror {
    height: 100%;
  }

  .cm-field {
    display: inline-block;
    height: 22px;
    padding: 0 5px;
    margin: 0 3px;
    border-radius: 3px;
    font-size: 13px;
    line-height: 22px;
    color: #fff;
    background-image: linear-gradient(-117deg,#90dcd4 0%,#7cdfc2 100%);
  }

  .cm-field-flag {
    display: inline-block;
    color: #f690a3;
    border: 1px solid #f690a3;
    border-radius: 3px;
    padding: 0 5px;
    margin: 0 3px;
    font-size: 13px;
    height: 20px;
    line-height: 20px;
  }

  .cm-view{
    display: inline-block;
    color: #7abafa;
    border: 1px solid #7abafa;
    border-radius: 3px;
    padding: 0 5px;
    margin: 0 3px;
    font-size: 13px;
    height: 20px;
    line-height: 20px;
  }

  .cm-cond{
    display: inline-block;
    color: #ee8f55;
    border: 1px solid #ee8f55;
    border-radius: 3px;
    padding: 0 5px;
    margin: 0 3px;
    font-size: 13px;
    height: 20px;
    line-height: 20px;
  }

  .cm-function {
    color: #aa04bf;
  }
}

.rule-container {
  height: 100%;

  .rule-wrapper {
    height: calc(100% - 2px);
    display: flex;
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
    .wrap-l {
      width: 350px;
      height: 100%;
      border-right: 1px solid #e6e6e6;

      .left-scrollbar{
        height: calc(100% - 62px);
        overflow-y: auto;
      }

      .right-scrollbar {
        height: calc(100% - 62px);
        overflow-y: auto;
        :deep(.el-collapse) {
          border: none;

          .el-collapse-item__header {
            border: none;
          }

          .el-collapse-item__wrap {
            border: none;

            .el-collapse-item__content {
              border: none;
              padding-bottom: 0;

              .el-menu.el-menu--horizontal {
                border: none;
              }

              .el-menu-item {
                width: 100%;
                line-height: 50px;
                height: 50px;

                .fn-name {
                  margin: 0;
                  font-size: 12px;
                  line-height: 20px;
                  color: #303133;
                  font-weight: 400 !important;
                }

                .fn-desc {
                  margin: 0;
                  line-height: 20px;
                  font-size: 12px;
                  color: #b2b2b2;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              ;
              }
            }
          }
        }
      }
    }

    .wrap-r {
      flex: 1;
      display: flex;
      flex-flow: column;
      overflow: auto;

      .rule-header {
        padding: 16px 32px;
        border-bottom: 1px solid #ebeef5;

        span {
          font-size: 12px;
          color: #303133;
        }

        p {
          margin: 4px 0;
          font-size: 12px;
          color: #b2b2b2;
        }
      }

      .rule-body {
        flex: 1;
        padding: 24px 32px 0 32px;
        overflow-y: auto;
        user-select: none;

        .expression-item {
          margin: 4px 0;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          height: 24px;
          float: left;
          font-size: 12px;

          .static-inp {
            width: 45px;
            border: 1px solid #b2b2b2;
            outline-style: none;
            border-radius: 3px;
            padding: 3px;
          }

          .tag {
            background-color: #f5f7fa;
            padding: 6px;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            height: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
          }

          .field-label {
            color: #fff;
            font-size: 12px;
            background-color: #027aff;
            padding: 6px 8px;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            height: 24px;
            line-height: 24px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            border-radius: 2px;
            max-width: 300px;
          }

          .focus {
            width: 2px;
            height: 16px;
            margin: 0 4px;
          }
        }
      }

      .rule-footer {
        padding: 20px 25px;
        display: flex;
        flex-wrap: wrap;

        .rule-op-item {
          cursor: pointer;
          min-width: 22px;
          height: 10px;
          line-height: 10px;
          text-align: center;
          border: 1px solid #ebeef5;
          padding: 10px 4px;
          border-radius: 2px;
          color: #b2b2b2;
          font-size: 12px;
          margin: 5px 5px;
        }
      }
    }
  }
}

:deep(.tree-data) {
  padding: 15px;

  .el-tree-node__content{
    line-height: 35px;
    height: 35px;
    padding-left: 0!important;
  }

  .el-tree-node__children {
    padding-left: 12px;
  }

  .el-tree-node__expand-icon.is-leaf {
    display: none;
  }

  .el-tree-node {
    position: relative;
    padding-left: 12px;

    &:before {
      content: "";
      left: -4px;
      position: absolute;
      right: auto;
      border-width: 1px;
      border-left: 1px dashed #4386c6;
      bottom: 0;
      height: 100%;
      top: -10px;
      width: 1px;
    }

    &:after {
      content: "";
      left: -4px;
      position: absolute;
      right: auto;
      border-width: 1px;
      border-top: 1px dashed #4386c6;
      height: 20px;
      top: 18px;
      width: 16px;
    }
  }
}

</style>
