<!--
 * @Description: 选择数据对象的UUID 通过远程搜索select
 * @Author: luocheng
 * @Date: 2021-09-29 18:44:14
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2022-12-28 14:11:35
-->
<template>
	<div class="select-obect-uuid">
		<el-select
			v-model="uuid"
			filterable
			remote
			:placeholder="placeholder"
			:remote-method="onRemoteObject"
			:loading="objLoading"
			@change="onEmit"
			clearable
		>
			<el-option
				v-for="obj in objectList"
				:key="obj.id"
				:label="obj.name"
				:value="obj.uuid"
			></el-option>
		</el-select>
	</div>
</template>

<script>
import { getObjects } from '@/apis/data/index';

export default {
	name: 'SelectObjectUUID',
	props: {
		value: {
			type: String,
			default: ''
		},
		// 说明
		placeholder: {
			type: String,
			required: false,
			default: '请选择'
		}
	},
	data() {
		return {
			uuid: '',
			objLoading: false,
			objectList: []
		};
	},
	created() {
		this.uuid = this.value;
		this.onRemoteObject('');
	},
	methods: {
		/**
		 * @desc: 远程搜索对象
		 * @param {String} name 关键字
		 */
		onRemoteObject(name) {
			this.objLoading = true;
			getObjects({ name, uuid: name ? '' : this.uuid, size: 50 })
				.then(res => {
					this.objectList = res?.data?.data?.data || [];
					this.objLoading = false;
				})
				.catch(err => {
					console.log(err);
					this.objectList = [];
					this.objLoading = false;
				});
		},
		/**
		 * @desc: 提交数据
		 */
		onEmit() {
			this.$emit('input', this.uuid);
			this.$emit('update', this.uuid);
		}
	}
};
</script>

<style></style>
