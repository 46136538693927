/*
 * @Description: 第二屏切换
 * @Author: luocheng
 * @Date: 2022-05-19 15:01:56
 * @LastEditors: luocheng
 * @LastEditTime: 2022-05-19 15:05:51
 */
export default {
  state: {
    // 当前选中应用
    screenApp: null,
    // 可选项目
    projectForView: [],
    // 可选公司
    companyForView: []
  },
  mutations: {
    // 设置当前应用
    setScreenApp(state, app) {
      state.screenApp = app;
    }
  }
}