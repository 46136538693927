/*
 * @Author: 吴绍鹏 542278473@qq.com
 * @Date: 2023-08-08 10:38:48
 * @LastEditors: 吴绍鹏 542278473@qq.com
 * @LastEditTime: 2023-08-21 17:07:58
 * @FilePath: \dataview-next\src\custom-component\simpleChart\simpleChartFormat.js
 * @Description: 格式化图表options
 */

/**
 * @description: 格式主函数
 * @param {*} data 请求数据
 * @param {*} config 配置
 * @return {*}
 */
export default function (data, config = { style: {}}) {
  const option = {};
  // 设置颜色盘 config.color暂时没有使用
  option.color = config.color || ['#80FFA5', '#00DDFF', '#37A2FF', '#FF0087', '#FFBF00'];
  // 禁用标题
  option.title = { show: false };
  // 预设 tooltip
  option.tooltip = {
    show: true,
    trigger: 'item'
  };
  // 设置网格 铺满 上方距离12
  option.grid = {
    left: '0%',
    right: '0%',
    top: 12,
    bottom: '0%',
    containLabel: false
  };
  // 处理series 和 X轴 Y轴 及相关的数据
  let formatData = {};
  switch (config.type) {
    case 'bar': {
      formatData = formatBarData(data, config);
      break;
    }
    case 'line': {
      formatData = formatLineData(data, config);
      break;
    }
    case 'pie': {
      formatData = formatPieData(data, config);
      break;
    }
    case 'schedule': {
      formatData = formatScheduleData(data, config);
      break;
    }
    case 'polarBar': {
      formatData = formatPolarBarData(data, config);
      break;
    }
  }
  // 混合option
  for (const key in formatData) {
    if (Object.hasOwnProperty.call(formatData, key)) {
      Reflect.set(option, key, Reflect.get(formatData, key));
    }
  }
  return option;
}
/**
 * @description: 格式化柱状数据
 * @param {*} data
 * @param {*} config
 * @return {*}
 */
function formatBarData (data, config) {
  // 格式化series data
  const formatData = data.data.map(el => {
    const v = Number(el.value);
    return isNaN(v) ? 0 : v;
  })
  // 标签数据
  const labelData = data.data.map(el => {
    const v = el.name;
    return v ? v : '-';
  })
  const tooltip = {
    trigger: 'axis'
  };
  const xAxis = [
    {
      type: 'category',
      show: false,
      data: labelData
    }
  ];
  const yAxis = [
    {
      type: 'value',
      show: false,
    }
  ];

  const { useGradient, barColor, tooltipText,  gradientColors, borderRadius, showBackground, backgroundColor, } = config.style;
  if(tooltipText) {
    tooltip.formatter = function(param) {
      return `${param[0].name}<br> ${param[0].marker} ${tooltipText} ${param[0].value}`
    }
  }
  const seriesItem = {
    type: 'bar',
    data: formatData,
    barMaxWidth: 12, // 最大的柱宽
    itemStyle: {},
    showBackground: !!showBackground, // 是否使用背景
    backgroundStyle: {},
  }
  // 设置柱颜色
  if(useGradient) {
    // 渐变
    if(Array.isArray(gradientColors) && gradientColors.length) {
      const len = gradientColors.length;
      seriesItem.itemStyle.color = {
        type: 'linear',
        x: 0.5,
        y: 0,
        x2: 0.5,
        y2: 1,
        colorStops: gradientColors.map((el, index) => ({ offset: index / len, color: el }))
      }
    }
  } else {
    // 单色
    if(barColor) {
      seriesItem.itemStyle.color = barColor;
    }
  }
  // 柱体圆角
  if(borderRadius) {
    seriesItem.itemStyle.borderRadius = Number(borderRadius);
  }
  // 设置背景背景色
  if(backgroundColor) {
    seriesItem.backgroundStyle.color = backgroundColor;
    if(borderRadius) {
      seriesItem.backgroundStyle.borderRadius = Number(borderRadius);
    }
  }
  const series = [
    seriesItem
  ];
  return {
    series,
    xAxis,
    yAxis,
    tooltip
  };
}
/**
 * @description: 格式化折线数据
 * @param {*} data
 * @param {*} config
 * @return {*}
 */
function formatLineData (data, config) {
  const formatData = data.data.map(el => {
    const v = Number(el.value);
    return isNaN(v) ? 0 : v;
  })
  const labelData = data.data.map(el => {
    const v = el.name;
    return v ? v : '-';
  })
  const xAxis = [
    {
      type: 'category',
      show: false,
      data: labelData
    }
  ];
  const tooltip = {
    trigger: 'axis',
  };
  const yAxis = [
    {
      type: 'value',
      show: false,
    }
  ];

  const seriesItem = {
    type: 'line',
    data: formatData,
    itemStyle: {},
  };
  const { smooth, lineColor, tooltipText, disableSymbol, useArea, useGradient, gradientColors, areaColor } = config.style;
  if(tooltipText) {
    tooltip.formatter = function(param) {
      return `${param[0].name}<br> ${param[0].marker} ${tooltipText} ${param[0].value}`;
    }
  }
  seriesItem.smooth = !!smooth;

  if(lineColor) {
    seriesItem.itemStyle.color = lineColor;
  }
  if(disableSymbol) {
    seriesItem.showSymbol = !disableSymbol;
  }
  if(useArea) {
    seriesItem.areaStyle = {
      origin: 'auto'
    };
    xAxis[0].boundaryGap = false;
    if(useGradient) {
      if(Array.isArray(gradientColors) && gradientColors.length) {
        const len = gradientColors.length;
        seriesItem.areaStyle.color = {
          type: 'linear',
          x: 0.5,
          y: 0,
          x2: 0.5,
          y2: 1,
          colorStops: gradientColors.map((el, index) => ({ offset: index / len, color: el }))
        }
      }
    } else {
      seriesItem.areaStyle = areaColor;
    }
  }

  const series = [seriesItem];
  return {
    series,
    xAxis,
    yAxis,
    tooltip
  };
}
/**
 * @description: 格式化饼状数据
 * @param {*} data
 * @param {*} config
 * @return {*}
 */
function formatPieData (data, config) {
  const formatData = data.data.map(el => {
    const v = Number(el.value);
    return {
      value: isNaN(v) ? 0 : v,
      name: el.name
    };
  })
  const { colors, radiusEnd = 75, radiusstart = 0, roseType } = config.style;
  const seriesItem = {
    type: 'pie',
    data: formatData,
    label: {
      show: false
    }
  };
  let radius = `${radiusEnd}%`;
  if(radiusstart) {
    radius  = [`${radiusstart}%`, `${radiusEnd}%`];
  }
  seriesItem.radius = radius;
  if(roseType) {
    seriesItem.roseType = 'radius';
  }
  const series = [
    seriesItem
  ];
  const tooltip = {
    formatter: '{b}&nbsp;&nbsp;{c}&nbsp;&nbsp;{d}%'
  }
  const options = {
    tooltip,
    series
  };
  if(Array.isArray(colors) && colors.length) {
    options.color = colors;
  }
  return options;
}
/**
 * @description: 格式化进度图数据
 * @param {*} data
 * @param {*} config
 * @return {*}
 */
function formatScheduleData(data, config) {
  const formatData = data.data.map(el => {
    const v = Number(el.value);
    return isNaN(v) ? 0 : v;
  });
  const maxData = data.total || Math.max(...formatData);
  const totalData = data.data.map(() => {
    const v = Number(maxData);
    return isNaN(v) ? 0 : v;
  });
  const labelData = data.data.map(el => {
    const v = el.name;
    return v ? v : '-';
  });
  const { totalColors, completedColors, fontColor, barWidth } = config.style;

  const xAxis = {
    show: false,
    max: maxData
  };

  const yAxis = {
    show: false,
    type: 'category',
    data: labelData,
  };
  const reBarWidth = barWidth || 10
  const seriesItem = {
    type: 'bar',
    data: formatData,
    barWidth: reBarWidth,
    itemStyle: {
      borderRadius: reBarWidth / 2,
    },
    barGap: '-100%',
    label: {
      formatter: '{b}',
      show: true,
      offset: [-4, -20],
      position: 'insideTopLeft',
      valueAnimation: true,
      align: 'left',
      color: fontColor|| '#707786',
    }
  };
  const seriesItemToTal = {
    type: 'bar',
    data: totalData,
    barWidth: reBarWidth,
    itemStyle: {
      borderRadius: reBarWidth / 2,
    },
    label: {
      formatter: function(params) {
        const numTemp =  Number(data.data?.[params.dataIndex].value);
        const number = ((numTemp / maxData) * 100);
        if(isNaN(number)) {
          return '0%'
        } else {
          const str = ((numTemp / maxData) * 100).toFixed(2);
          return str.replace(/(?:\.0*|(\.\d+?)0+)$/, '$1') + ' %';
        }
      },
      show: true,
      offset: [4, -20],
      position: 'insideTopRight',
      valueAnimation: true,
      align: 'right',
      color: fontColor|| '#707786',
    },
    emphasis: {
      disabled: true
    },
    tooltip: {
      formatter: `总数 &nbsp;&nbsp;&nbsp;{c}`
    }
  };

  if(Array.isArray(totalColors) && totalColors.length) {
    const len = totalColors.length;
    seriesItemToTal.itemStyle.color = {
      type: 'linear',
      x: 0,
      y: 0.5,
      x2: 1,
      y2: 0.5,
      colorStops: totalColors.map((el, index) => ({ offset: index / len, color: el }))
    };
  }
  if(Array.isArray(completedColors) && completedColors.length) {
    const len = completedColors.length;
    seriesItem.itemStyle.color = {
      type: 'linear',
      x: 0,
      y: 0.5,
      x2: 1,
      y2: 0.5,
      colorStops: completedColors.map((el, index) => ({ offset: index / len, color: el }))
    };
  }

  const tooltip = {
    show: true
  };
  const series = [
    seriesItemToTal,
    seriesItem
  ];
  return {
    series,
    xAxis,
    yAxis,
    tooltip,
  };
}
/**
 * @description: 格式化极坐标柱状图数据
 * @param {*} data
 * @param {*} config
 * @return {*}
 */
function formatPolarBarData(data, config) {
  const formatData = data.data.map(el => {
    const v = Number(el.value);
    return isNaN(v) ? 0 : v;
  });
  const labelData = data.data.map(el => {
    const v = el.name;
    return v ? v : '-';
  });
  const total = formatData.reduce((a, b) => a + b, 0)
  const tooltip = {
    trigger: 'axis',
    formatter(param) {
      return `${data.title}<br>${param.map(el => `${el.marker} ${labelData[el.componentIndex]} ${el.value}`).join('<br>')}`
    }
  };
  const radiusAxis = [
    {
      type: 'category',
      show: false,
      data: [data.title, ''],
      inverse: true
    }
  ];
  const angleAxis = [
    {
      type: 'value',
      show: false,
      max: total
    }
  ];
  const { useGradient, colors, gradientColorsOne, gradientColorsTow, gradientColorsThree, showBackground, backgroundColor, } = config.style;
  // 设置柱体
  const series = formatData.map((el, index) => {
    const seriesItem = {
      name: el.name,
      barCateGoryGap: 9,
      barMaxWidth: 8,
      showBackground: !!showBackground,
      type: 'bar',
      data: [el],
      backgroundStyle: {},
      itemStyle: {},
      coordinateSystem: 'polar',
      roundCap: true,
    }
    if(useGradient) {
      let gradientColors = null;
      switch(index) {
        case 0: {
          gradientColors = gradientColorsOne;
          break;
        }
        case 1: {
          gradientColors = gradientColorsTow;
          break;
        }
        case 2: {
          gradientColors = gradientColorsThree;
          break;
        }
      }
      if(Array.isArray(gradientColors) && gradientColors.length) {
        const len = gradientColors.length;
        seriesItem.itemStyle.color = {
          type: 'linear',
          x: 1,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: gradientColors.map((el, index) => ({ offset: index / len, color: el }))
        };
      }
    } else {
      if(Array.isArray(colors) && colors.length) {
        seriesItem.itemStyle.color = colors[index % colors.length];
      }
    }
    // 设置背景
    if(backgroundColor) {
      seriesItem.backgroundStyle.color = backgroundColor;
    }
    return seriesItem;
  })
  const polar = {
    radius: [5, '90%']
  };
  return {
    series,
    radiusAxis,
    angleAxis,
    tooltip,
    polar
  };
}