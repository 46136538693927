<!--
    @name: KnowDialog
    @description：KnowDialog
    @author: ZengWei
    @date: 2022-04-28 15:14
-->
<template>
  <div class="global-dialog">
    <el-dialog
      :title="title"
      custom-class="workorder-dialog-overscroll"
      top="3%"
      :visible.sync="showDialog"
      width="80%">
      <div class="dialog-main" v-if="data_id" v-loading="loading">
        <PcParser
          v-if="formDesignData"
          ref="dialogForm"
          :form-data="formDesignData"
          :displayData="displayData"
          :editFields="editFields"
          :disabled="formDisabled"
          :needFlow="!!needFlow"
          :moduleUuid="moduleUuid"
          :formMode="formMode"
          @submit="onFormSubmit"
        ></PcParser>

        <TaskPicker
          v-if="taskRecoverId"
          type="next"
          :taskEngineId="taskEngineId"
          :stepNode="stepNode"
          :taskRecoverId="taskRecoverId"
          @checked-users="dealWithPicker"
          @selected-step="getSelectStep">
        </TaskPicker>
      </div>
      <div slot="footer">
        <el-button @click="showDialog = false">取消</el-button>
        <el-button type="primary" @click="triggerSubmit">处理任务</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import PcParser from "@/custom-component/form/newParser/PcParser";
import TaskPicker from "@/views/workOrder/task-flow/components/TaskPicker";
import {formDesign} from "@/apis/data/workflow";
import {dataInterface, transFieldsUUid} from "@/apis/data";

export default {
  name: "KnowDialog",
  components: {PcParser,TaskPicker},
  props: {
    disabled: {
      type: Boolean,
      default: true,
    },
    data_id:{
      type:Number,
      default:0
    },
    title:{
      type:String,
      default:'提示'
    },
    tag:{
      type:String,
      default:''
    },
    taskConfig: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      showDialog: false,
      loading: true,
      detailData: null,

      formDesignData: null,
      formDisabled: false,
      displayData: {},
      editFields: {},
      needFlow: false,
      moduleUuid: '',
      formMode: 'add',
      selectedUsers: [],
      checkPickerNum: 0,

      taskRecoverId: 0,
      taskEngineId: 0,
      stepNode: '',
    }
  },
  created() {
    this.showDialog = true
    this.loading = false

    this.taskRecoverId = this.taskConfig?.taskRecoverId || 0
    this.taskEngineId = this.taskConfig?.taskEngineId || 0
    this.stepNode = this.taskConfig?.stepNode || 0

    this.initParser()
  },
  methods: {
    triggerSubmit(){
      this.$refs.dialogForm.handleSubmit()
    },
    getSelectStep(){},
    // 任务责任人
    dealWithPicker(selected, total) {
      this.selectedUsers = selected
      this.checkPickerNum = total
    },
    initParser(){
      this.formDesignData = null
      this.loading = true
      const formType = this.taskConfig?.form_type || 1
      if(formType === 1){

        console.log(this.taskConfig.func_form, 995555)

        formDesign.formShow(this.taskConfig.func_form).then(res=>{
          if(res.data.code === 200){
            const formData = res.data.data
            this.formDesignData = JSON.parse(formData.json)
            this.needFlow = !!formData.need_flow
            this.moduleUuid = formData.module_uuid
            this.loading = false
          }
        });
      } else if(formType === 2){
        const objectUuid = this.taskConfig?.object_uuid
        const formUuid = this.taskConfig?.func_form
        transFieldsUUid(objectUuid, formUuid).then((res) => {
          if (res.data.code == 200) {
            this.needFlow = !!res.data.data.need_flow;
            this.moduleUuid = formUuid || objectUuid
            this.needParentFlow = !!res.data.data.need_parent_flow;
            this.formDesignData = res.data.data;
            this.loading = false;
          }
        })
      }
    },
    onFormSubmit(formData){
      formData.task_log_id = this.taskConfig?.task_log_id || 0
      if (
        this.checkPickerNum !== 0 &&
        this.checkPickerNum !== this.selectedUsers.target_users.length
      ) {
        this.$message.error('下一任务节点责任人未选择！')
        return false
      }
      if (this.selectedUsers.target_users && this.selectedUsers.target_users.length > 0) {
        formData.target_users = this.selectedUsers.target_users
      }
      if(this.taskConfig.form_type === 1){
        formData.module_uuid = this.moduleUuid
        formDesign.formDataStore(formData).then((res) => {
          if (res.data.code === 200) {
            this.$message.success(res.data.msg);
          } else {
            this.$message.error(res.data.msg);
          }
          this.showDialog = false;
          this.loading = false;
          this.$emit('close')
        });
      } else if(this.taskConfig.form_type === 2){
        const objectUuid = this.taskConfig?.object_uuid
        const params = {
          __method_name__: 'createData',
          object_uuid: objectUuid,
          ...formData
        }
        dataInterface(params)
          .then((res) => {
            if (res.data.code=== 200) {
              this.showDialog = false;
              this.loading = false;
              this.$emit('close')
            }
          }).catch((err) => {
          console.log(err);
        }).finally(()=>{
          this.showDialog = false;
          this.loading = false;
          this.$emit('close')
        });
      }
    },
  },
}
</script>

<style lang="less" scoped>
:deep(.workorder-dialog-overscroll) {
  height: 90%;
  .el-dialog__body{
    padding: 0;
  }
}

.dialog-main{
  padding: 0 15px;
  height: calc(100vh - 210px);
  overflow-y: auto;
}
</style>
