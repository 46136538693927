<!--
 * @Description: 流程图
 * @Author: luocheng
 * @Date: 2021-12-09 11:24:58
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-05-04 15:34:05
-->
<template>
	<div class="common-flow">
		<FlowEngine
			v-if="configResult && isInit"
			:module="configResult.form_uuid || configResult.object_uuid"
			:obuuid="obuuid"
		></FlowEngine>
		<el-empty
			description="流程配置错误"
			v-else
		></el-empty>
		<!-- <section class="mask"></section> -->
	</div>
</template>

<script>
import FlowEngine from '@/custom-component/form/formParser/flowEngine/index';
import { Empty } from 'element-ui';
import { getComponentById, removeEventBus } from '@/utils/tools';
import { mapState } from 'vuex';
import eventBus from '@/plugins/eventBus';

export default {
	name: 'CommonFlow',
	components: {
		'el-empty': Empty,
		FlowEngine
	},
	props: {
		element: {
			type: Object,
			required: true,
			default: () => {}
		},
		componentList: {
      default: null
		},
		// 当前分组的组件数据
    groupComponents: {
      type: Array,
      default: () => []
    },
	},
	data() {
		return {
			isInit: false,
			configResult: null,
			obuuid: ''
		}
	},
	computed: {
		...mapState(['componentData']),
		// 流程图配置
		flowConfig() {
			return this.element && this.element.flowConfig;
		}
	},
	mounted() {
		this.$nextTick(() => {
			const databaseTrigger = {
				[this.element.id]: (data = {}) => {
					if (this.flowConfig.configType !== 'component') return;
					const { customConfig = {} } = this.flowConfig;
					if (customConfig.componentId !== data.componentId) return;
					this.configResult = null;
					this.isInit = false;
					// 刷新
					this.updateComponentSource(customConfig);
				}
			};
			eventBus.$on('databaseTrigger', databaseTrigger[this.element.id]);
		})
	},
	watch: {
		flowConfig: {
			handler() {
				const {
					configType = '',
					object_uuid = '',
					form_uuid = '',
					customConfig
				} = this.flowConfig;
				// let obuuid = '';
				this.configResult = null;
				this.isInit = false;
				if (configType === 'component') {
					this.updateComponentSource(customConfig);
					return;
				} else if (this.flowConfig?.object_uuid) {
					this.configResult = {
						form_uuid,
						object_uuid
					};
					this.obuuid = this.flowConfig.object_uuid;
				}
				// this.$router.replace({
				// 	query: { 
				// 		...this.$route.query,
				// 		obuuid
				// 	}
				// });
				setTimeout(() => {
					this.isInit = true;
				}, 100);
			},
			immediate: true,
			deep: true
		}
	},
	methods: {
		/**
		 * @desc: 更新组件来源配置的视图刷新
		 */
		updateComponentSource(customConfig) {
			// let obuuid = '';
			if (!customConfig || !customConfig?.object_uuid_field || !customConfig.componentId) {
				return;
			}
			// 普通从组件获取
			let sourceComponent = getComponentById(this.componentList || this.componentData, customConfig?.componentId);
			if (!sourceComponent && this.isGroup && this.groupComponents.length) {
				sourceComponent = getComponentById(this.groupComponents, customConfig?.componentId);
			}
			const resolveData = sourceComponent?.resolveData;
			if (!resolveData) return;
			this.configResult = {
				form_uuid: resolveData?.[customConfig?.form_uuid_field],
				object_uuid: resolveData?.[customConfig?.object_uuid_field]
			};
			this.obuuid = resolveData?.[this.flowConfig.object_uuid_field]; 
			// this.$router.replace({
			// 	query: { 
			// 		...this.$route.query,
			// 		obuuid
			// 	}
			// });
			setTimeout(() => {
				this.isInit = true;
			}, 100);
		}
		
	},
	beforeDestroy() {
		removeEventBus(['databaseTrigger'], this.element.id);
	}
};
</script>

<style lang="less" scoped>
.common-flow {
	height: 100%;
	width: 100%;
	position: relative;
	.mask {
		position: absolute;
		height: 100%;
		width: 100%;
		z-index: 10;
		background: rgba(0, 0, 0, 0.3);
		left: 0;
		top: 0;
	}
}
</style>