<!--
    @name: SendProps
    @description：SendProps
    @author: ZengWei
    @date: 2023-06-21 14:36
-->
<template>
  <div>
    <el-tabs type="border-card" v-model="activeTab">
      <el-tab-pane label="抄送配置" name="send">
        <div class="scrollbar">
          <el-form label-position="top" label-width="80px">
            <el-form-item label="抄送配置">
              <el-radio-group :disabled="disabled" class="user-type-radio" @change="onTypeChange" v-model="task.send_type" size="small">
                <!--与流程保持一致-->
                <el-radio :label="1">指定人员</el-radio>
                <el-radio :label="2">发起人分派</el-radio>
                <el-radio :label="6">指定岗位</el-radio>
                <el-radio :label="7">任务中分派</el-radio>
                <el-radio :label="8">发起人</el-radio>
              </el-radio-group>
              <el-radio-group
                v-if="[1, 2, 6, 7].includes(task.send_type)"
                :disabled="disabled"
                v-model="task.send_from"
                @change="changeUserScope">
                <el-radio-button :label="1">{{ task.send_type === 6 ? '项目岗位' : '项目人员' }}</el-radio-button>
                <!--<el-radio-button :label="2" disabled>全部人员</el-radio-button>-->
                <el-radio-button :label="3">{{ task.send_type === 6 ? '公司岗位' : '公司人员' }}</el-radio-button>
              </el-radio-group>
              <div v-if="task.send_type === 1" style="border: 1px dashed #eee;margin-top: 15px;padding: 5px 10px 0 0">
                <select-user
                  :key="'senduser'+task.send_arch"
                  :companyId="task.send_arch"
                  :disabled="disabled"
                  :show-list="task.send_users"
                  :show-choose-button="false"
                  position-type="left"
                  @on-select-change="onSelectUser($event,'send')"
                />
              </div>
              <div v-if="task.send_type === 6">
                <el-select
                  :disabled="disabled"
                  v-model="task.send_role"
                  multiple
                  placeholder="请选择岗位"
                  class="select-item"
                  @change="onChange">
                  <el-option
                    v-for="item in roleList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {RadioGroup, Radio, RadioButton, TabPane, Tabs} from "element-ui";
import selectUser from "@/custom-component/form/formParser/selectUser";
export default {
  name: 'SendProps',
  components: {
    selectUser,
    'el-tabs': Tabs,
    'el-tab-pane': TabPane,
    'el-radio-group': RadioGroup,
    'el-radio': Radio,
    'el-radio-button': RadioButton,
  },
  props: ['currentItem','currentNodeId','nodeType','formNode','disabled'],
  data() {
    return {
      activeTab: 'send',
      roleList: [],
      task: {
        send_type: 1,
        send_from: 1,
        send_arch: 0,
        send_users: []
      }
    }
  },
  methods: {
    onTypeChange(){},
    changeUserScope(){},
    onSelectUser(){},
    onChange(){},
  },
}
</script>

<style scoped>

</style>
