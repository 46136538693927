/*
 * @Description: 组织架构相关
 * @Author: luocheng
 * @Date: 2021-11-05 18:40:13
 * @LastEditors: luocheng
 * @LastEditTime: 2021-12-21 14:48:00
 */
export default {
  state: {
    // 当前选择的顶部TAB
    targetGlobalTab: ''
  },
  mutations: {
    /**
     * @desc: 设置当前选中顶部tab
     * @param {Object} targetTab
     */
    setTargetGlobalTab(state, targetTab = '') {
      state.targetGlobalTab = targetTab || null;
      sessionStorage.setItem('targetGlobalTab', targetTab);
    },
  },
  getter: {
    /**
     * @desc: 获取全局选中顶部tab
     */
    getTargetGlobalTab(state) {
      if (state.targetGlobalTab) {
        return state.targetGlobalTab;
      }
      return sessionStorage.getItem('targetGlobalTab');
    }
  }
}