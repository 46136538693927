<!--
 * @Author: your name
 * @Date: 2021-12-02 15:32:01
 * @LastEditTime: 2023-05-17 14:02:53
 * @LastEditors: Shiltin 18580045074@163.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \nanhu-micro\src\views\components\bindModel\index.vue
-->

<template>
	<!-- 绑定全景 -->
	<div>
		<slot name="showType"></slot>
		<el-drawer
			custom-class="no-padding"
			:visible.sync="show"
			size="100%"
			append-to-body
			:with-header="false"
		>
			<BindPano
				:model-data="modelData"
				@back="show = false"
				@set-data="setData"
				v-if="show"
				:modelData="modelData"
			/>
		</el-drawer>
	</div>
</template>

<script>
import BindPano from '@/custom-component/bindPano/index.vue';
import { Drawer } from 'element-ui';

export default {
	name: 'ButtonBindPano',
	components: {
		BindPano,
		'el-drawer': Drawer
	},
	props: {
		// 回显数据 {panoId:"de84892f-b8fe-4f38-b2b0-1ff7d69229af",position:{mx: 1074, my: 615, ath: "22.51", atv: "11.66",id: 10}}
		modelData: {
			type: Object,
			default: null
		},
	},
	data() {
		return {
			show: false
		};
	},
	methods: {
		setData(data) {
			this.$emit('set-data', data);
			this.show = false;
		}
	}
};
</script>
