<template>
  <div class="menus">
    <div style="text-indent: 10px;">
      <a :class="{disabled:!props}" @click="onTop()">节点置顶</a>
    </div>
    <div style="text-indent: 10px;">
      <a :class="{disabled:!props}" @click="onBottom()">节点置底</a>
    </div>
    <!--<div class="line"></div>
    <div v-if="props.nodes">
      <a @click="onCombine()">组合</a>
      <div>
        <a @click="onCombine(true)">包含</a>
      </div>
    </div>
    <div v-if="props.node && props.node.name === 'combine'">
      <a @click="onUncombine()">取消组合/包含</a>
    </div>
    <div>
      <a
        :class="{disabled:!props.node && !props.nodes}"
        @click="onLock()"
      >{{ props.locked ? '解锁' : '锁定' }}</a>
    </div>
    <div class="line"></div>
    <div>
      <a :class="{disabled:!props.node && !props.nodes && !props.line}" @click="onDel()">删除</a>
    </div>
    <div class="line"></div>
    <div>
      <a @click="canvas.undo()" class="flex">
        <span class="full">撤消</span>
        <span class="ml50">Ctrl + Z</span>
      </a>
    </div>
    <div>
      <a @click="canvas.redo()">
        恢复
        <span class="ml50">Ctrl + Shift+ Z</span>
      </a>
    </div>
    <div class="line"></div>
    <div>
      <a @click="canvas.cut()" class="flex">
        <span class="full">剪切</span>
        <span class="ml50">Ctrl + X</span>
      </a>
    </div>
    <div>
      <a @click="canvas.copy()" class="flex">
        <span class="full">复制</span>
        <span class="ml50">Ctrl + C</span>
      </a>
    </div>
    <div>
      <a @click="canvas.paste()" class="flex">
        <span class="full">粘贴</span>
        <span class="ml50">Ctrl + V</span>
      </a>
    </div>
    <div class="line"></div>
    <div>
      <a :class="{disabled:!props.node || !props.node.image}" @click="onCopyImage()" class="flex">
        <span class="full">复制节点图片地址</span>
      </a>
    </div>-->
  </div>
</template>

<script >
export default {
  data() {
    return {}
  },
  props: ['canvas','props'],
  methods: {
    onTop() {
      if(this.props){
        this.props.toFront()
      }
      this.$emit('cancel');
    },
    onBottom() {
      if(this.props){
        this.props.toBack()
      }
      this.$emit('cancel');
    },
    onCombine() {
    },
    onUncombine() {
    },
    onLock() {
    },
    onDel() {
    }
  }
}
</script>

<style lang="less" scoped>
.menus {
  color: #000;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  min-width: 120px;
  text-align: left;
  padding: 0.08rem 0;

  & > div {
    line-height: 2.2;
    a {
      color: #314659;
      display: block;
      padding: 0 0.2rem;
      text-decoration: none;
      cursor: pointer;

      &:hover {
        color: #1890ff;
      }

      &.flex {
        display: flex;
      }

      &.disabled {
        color: #ccc;
        cursor: default;
      }
    }
  }

  .line {
    background-color: transparent !important;
    padding: 0;
    margin: 0.05rem 0;
    border-top: 1px solid #eee;
  }
}
</style>
