<!--
    @name: TaskNode
    @description：TaskNode
    @author: ZengWei
    @date: 2022-05-13 16:35
-->
<template>
  <div class="task-node-block" :style="{'border-color': color}">
    <i class="iconfont iconlvyuepingjia" @click="showFormParser"></i>
    <p class="task-title" :style="{'background-color': color}">
      <i class="iconfont iconyuebao"></i> {{title}}
    </p>
    <div class="task-content">
      <div class="text-item" v-if="func_form">
        <div class="left">
          任务表单
        </div>
        <div class="center-line">
          <p class="line"></p>
        </div>
        <div class="right">
          <div>
            <el-link type="primary" :underline="false" @click.stop="showFormParser">
              <i class="el-icon-date"></i> 表单预览
            </el-link>
          </div>
        </div>
      </div>

      <div class="text-item" v-if="deadline_show">
        <div class="left">
          {{ deadline_name }}
        </div>
        <div class="center-line">
          <p class="line"></p>
        </div>
        <div class="right">
          {{ deadline }}
        </div>
      </div>

      <template v-for="(item,index) in content">
        <div v-if="index<5" :key="index" class="text-item">
          <div class="left">
            {{item.name}}
          </div>
          <div class="center-line">
            <p class="line"></p>
          </div>
          <div class="right">
            <div v-if="item.type !== 'upload'">
              {{item.value}}
            </div>
            <div v-else>
              <el-link type="primary" @click.stop="showAttach(item.attach)">
                <i class="el-icon-link"></i> 查看附件
              </el-link>
            </div>
          </div>
        </div>
      </template>
    </div>

    <el-dialog
      :title="modalTitle"
      :visible.sync="dialogVisible"
      top="5vh"
      width="60%"
      custom-class="common-dialog"
      append-to-body
    >
      <div class="dialog-main" v-loading="loading">
        <PcParser
          v-if="dialogVisible && formDesignData"
          ref="dialogForm"
          :form-data="formDesignData"
          :displayData="displayData"
          :editFields="editFields"
          :disabled="formDisabled"
          :needFlow="!!needFlow"
          :moduleUuid="moduleUuid"
        ></PcParser>
      </div>
      <div slot="footer" style="text-align: right">
        <el-button type="default" @click="dialogVisible = false" style="margin-right: 10px;">取消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {Link} from "element-ui";
import eventBus from "@/plugins/eventBus";
import {formDesign} from "@/apis/data/workflow";
import {transFieldsUUid} from "@/apis/data";

export default {
  name: "TaskNode",
  components: {
    'el-link': Link
  },
  inject: ["getGraph", "getNode"],
  data() {
    return {
      title: '任务标题',
      color: '#7E8595',
      form_type: '',
      func_form: '',
      deadline_show: '',
      deadline_name: '',
      deadline: '',
      content: [
        // {type:'input',name:'文本',value:'',show:true},
        // {type:'data',name:'日期',value:'',show:true},
        // {type:'data-range',name:'日期范围',value:[],show:true},
        // {type:'upload',name:'附件',value:[],show:true},
      ],

      modalTitle: '',
      dialogVisible: false,
      formDesignData: null,
      formDisabled: false,
      displayData: {},
      editFields: {},
      needFlow: false,
      moduleUuid: '',
      loading: true,
    }
  },
  mounted() {
    const self = this
    const node = this.getNode()
    const data = node.getData()
    if(data){
      self.title = data.name
      self.deadline = data?.deadline || ''
      self.deadline_name = data?.deadline_name || ''
      self.deadline_show = data?.deadline_show || ''
      self.form_type = data?.form_type || ''
      self.func_form = data?.func_form || ''
      self.content = data?.content || []
      self.color = data?.color || '#7E8595'
    }
    // 监听数据改变事件
    node.on("change:data", ({ current }) => {
      self.title = current.name
      self.deadline = current?.deadline || ''
      self.deadline_name = current?.deadline_name || ''
      self.deadline_show = current?.deadline_show || ''
      self.form_type = current?.form_type || ''
      self.func_form = current?.func_form || ''
      self.color = current?.color || '#7E8595'
    });
  },
  methods: {
    showFormParser(){
      const node = this.getNode()
      const data = node.getData()
      this.openFormParser(data)
    },
    showAttach(attach){
      eventBus.$emit('open-attach',attach)
    },
    openFormParser(node){
      this.dialogVisible = true
      this.loading = true
      this.modalTitle = node.name
      if(node.form_type === 1){
        formDesign.formShow(node.func_form).then(res=>{
          if(res.data.code === 200){
            const formData = res.data.data
            this.formDesignData = JSON.parse(formData.json)
            this.needFlow = false
            this.moduleUuid = formData.module_uuid
            this.loading = false
          }
        });
      } else if(node.form_type === 2) {
        transFieldsUUid(node.object_uuid, node.func_form).then((res) => {
          if (res.data.code == 200) {
            this.needFlow = false;
            this.moduleUuid = node.func_form || node.object_uuid
            this.formDesignData = res.data.data;
            this.loading = false;
          }
        })
      } else {
        this.loading = false
        this.$message.info('暂未支持系统表单预览')
      }
    },
  },
}
</script>

<style lang="less" scoped>
:deep(.common-dialog)  {
  height: auto;
  max-height: calc(100vh - 50px);

  .el-dialog__body{
    padding: 0;
  }
}

.dialog-main{
  padding: 0 15px;
  max-height: calc(100vh - 200px);
  min-height: 100px;
  overflow-y: auto;
}

.task-node-block{
  width: 100%;
  height: 100%;
  border: 2px solid #F2654F;
  border-radius: 8px;
  box-shadow: 0 4px 10px #0c1f5014;

  /*&:hover{
    box-shadow: 0 0 5px #F2654F,
    0 0 25px #F2654F,
    0 0 50px #F2654F,
    0 0 100px #F2654F;
  }*/

  .iconlvyuepingjia{
    position: absolute;
    top: 10px;
    right: 8px;
    font-size: 17px;
    color: #ffffff;
    cursor: pointer;
  }

  .task-title{
    padding: 8px 25px 8px 10px;
    background-color: #F2654F;
    color: #ffffff;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .task-content{
    margin-bottom: 2px;
    height: calc(100% - 43px);
    overflow: hidden;
    .text-item{
      display: flex;
      justify-content: space-between;
      line-height: 31px;
      cursor: pointer;

      &:hover{
        background-color: #C0C4CC;
      }

      .center-line{
        flex: 1;
        color: #C0C4CC;
        padding: 0 10px;
        .line {
          border-bottom: 1px dashed #C0C4CC;
          margin-top: 16px;
        }
      }

      .left{
        padding-left: 10px;
        width: 75px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .right{
        width: 85px;
        text-align: right;
        padding-right: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>
