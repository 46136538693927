<!--
 * @Author: wsp 542278473@qq.com
 * @Date: 2022-11-30 14:17:40
 * @LastEditors: wsp 542278473@qq.com
 * @LastEditTime: 2022-12-02 15:08:05
 * @FilePath: \dataview-next\src\custom-component\login\components\BindPassword.vue
 * @Description: 绑定密码，暂无接口，单纯的静态页面
-->
<template>
  <div class="bind-password-wrap">
    <h4 class="title">设置密码</h4>
    <div class="form-wrap">
      <div class="tips">
        <p class="tips-p">已为您注册账户，请设置密码</p>
        <p class="tips-p">设置密码后，下次即可使用手机+密码登录</p>
      </div>
      <el-form
        class="setpassword-form"
        ref="setwordForm"
        :model="passwordFormData"
        :rules="passwordFormRules"
        :hide-required-asterisk="true"
        :show-message="false"
        size="lager"
      >
        <el-form-item>
          <el-input placeholder="请输入密码" show-password>
            <i slot="prefix" style="fontSize: 16px" class="iconfont iconsuoding"></i>
          </el-input>
        </el-form-item>
      </el-form>
      <el-button style="width: 100%" size="lager" type="primary">完成</el-button>
    </div>
    <footer class="footer">
      <el-button type="text">点击跳过</el-button>
    </footer>
  </div>
</template>
<script>
export default {
  data () {
    return {
      passwordFormData: {},
      passwordFormRules: {}
    }
  }
}
</script>
<style scoped lang="less">
  .bind-password-wrap{
    width: 100%;
    height: 100%;
    padding: 0 24px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    text-align: left;
    .title{
      margin-top: 48px;
      margin-bottom: 30px;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      color: #3C3C3C;
    }
    .form-wrap{
      flex-grow: 1;
      height: 10px;
      .tips{
        padding: 12px;
        box-sizing: border-box;
        background: #EAF3FE;
        border-radius: 4px;
        .tips-p{
          font-size: 12px;
          line-height: 18px;
          color: #3F4C5C;
        }
      }
      .setpassword-form{
        margin: 16px 0;
      }
    }
    .footer{
      text-align: right;
      margin: 8px 0;
    }
  }
</style>