export default class ClusterEvents {
    constructor() {}

    static getEvents(viewer, comp) {
        return {
            'ViewerGIS.createPointCluster': async (eid, key, data) => {
                if (eid !== comp.element.id) return;

                viewer.Cluster.createPointCluster(key, data);
            },
            'ViewerGIS.removeCluster': (eid, key) => {
                if (eid !== comp.element.id) return;

                viewer.Cluster.removeCluster(key);
            },
            'ViewerGIS.locationCluster': (eid, key, angle) => {
                if (eid !== comp.element.id) return;

                viewer.Cluster.locationCluster(key, angle);
            },
            'ViewerGIS.locationClusterItem': (eid, key, itemKey, angle) => {
                if (eid !== comp.element.id) return;

                viewer.Cluster.locationClusterItem(key, itemKey, angle);
            },
        };
    }
}
