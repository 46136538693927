<!--
 * @Author: Shiltin
 * @Date: 2021-10-20 17:52:57
 * @LastEditors: zx
 * @LastEditTime: 2022-10-25 15:05:46
 * @Description:
-->
<template>
  <div
    style="height: 100%"
    :style="!notForm ? 'max-width:' + maxShowWidth + 'px' : 'width:100%'"
  >
    <cascader
      :data-info="data3"
      :default-expand="defaultExpand"
      :inpage="inpage"
      :is-check-type="isCheckType"
      :max-height="maxHeight"
      :position-type="positionType"
      :show-list="showList"
      :show-tree="showTree"
      :width="width"
      @on-select-change="onSelectChange"
    />
  </div>
</template>
<script>
import cascader from './components/cascader'
import {getValue} from '@/apis/data/workflow'

export default {
  name: '',
  components: {cascader},
  props: {
    /* 流程组件内（inpage）页面盒子内使用示例
   <select-user
      :inpage="true"
      :not-form="true"
      :is-itself="true" //当前架构人员  不选下级
      :depart-id="210" //指定部门id
      @on-select-change="onSelectChange"
    />
   */
    dataInfo: {
      type: Array,
      default: () => []
    },
    /* 回显传入的选中的值[
          { id: 1},
        ], */
    showList: {
      type: Array,
      default: () => []
    },
    /* 指定部门id */
    departId: {
      type: Number,
      default: 0
    },
    /* 指定项目id */
    projectId: {
      type: Number,
      default: 0
    },
    /* 只请求当前架构的人员 */
    isItself: {
      type: Boolean,
      default: false
    },
    /* 回显传入的选中的值 */
    inpage: {
      type: Boolean,
      default: false
    },
    // cascaderCheck 级联单选
    // cascaderCheckbox 级联多选
    isCheckType: {
      type: String,
      default: 'cascaderCheckbox'
    },
    /* 点击选择窗口位置 */
    positionType: {
      type: String,
      default: 'bottom-start'
    },
    /* 弹出框宽度 */
    width: {
      type: Number,
      default: 450
    },
    /* 弹出框最大高度 */
    maxHeight: {
      type: Number,
      default: 300
    },
    /* 弹出框宽度 */
    notForm: {
      type: Boolean,
      default: false
    },
    /* 最大的显示人的长度(form表单默认100%会撑开，不能超出隐藏，form表单下使用需要设置宽度数值) */
    maxShowWidth: {
      type: Number,
      default: 428
    },
    /* 默认展开 */
    defaultExpand: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      userInfo: null,
      archiInfo: null,
      data3: null,
      showTree: true
    }
  },
  created() {
    this.userInfo = this.$GetUserInfo()
    this.archiInfo = this.$GetTargetArchi()
  },
  methods: {
    userEdit(list) {
      const dataList = list
      dataList.forEach((element) => {
        /* 处理协作部门人员 */
        if (element.external_company && element.external_company.length) {
          const projectName = element.name
          const projectId = element.id
          element.external_company.forEach((item) => {
            const children = []
            const externalName = item.name
            const externalId = item.id
            if (item.sections && item.sections.length) {
              item.sections.forEach((itemx) => {
                itemx.projectName = projectName
                itemx.projectId = projectId
                itemx.externalName = externalName
                itemx.externalId = externalId
                children.push(itemx)
              })
            }
            item.children = children
          })
        }
        /* 处理项目子项目协作单位人员  将人员全部存到项目下的对象children里面去 */
        const reChildConcat = (list) => {
          list.forEach((element) => {
            if (element.external_company && element.external_company.length) {
              const projectName = element.name
              const projectId = element.id
              const children = []
              element.external_company.forEach((item) => {
                const externalName = item.name
                const externalId = item.id
                if (item.sections && item.sections.length) {
                  item.sections.forEach((itemx) => {
                    itemx.projectName = projectName
                    itemx.projectId = projectId
                    itemx.externalName = externalName
                    itemx.externalId = externalId
                    children.push(itemx)
                  })
                }
              })
              element.children = children
            }
            if (element.project && element.project.length > 0) {
              reChildConcat(element.project)
            }
          })
        }
        reChildConcat(element.project)

        const children = []
        if (element.external_company && element.external_company.length) {
          element.external_company.forEach((item) => {
            if (item.children && item.children.length) {
              children.push(item)
            }
          })
        }
        const projectList = []
        const childConcat = (list) => {
          list.forEach((item) => {
            if (item.children && item.children.length) {
              projectList.push(item)
            }
            if (item.project && item.project.length) {
              childConcat(item.project)
            }
          })
        }
        childConcat(element.project)
        element.children = children.concat(projectList)
      })
      let key = 1
      dataList.forEach((item) => {
        if (item?.company || item?.company?.length === 0) {
          delete item.company
        }
        if (item?.project || item?.project?.length === 0) {
          delete item.project
        }
        if (item?.sections || item?.sections?.length === 0) {
          delete item.sections
        }
        item.idKey = key
        key += 1
        item.children.forEach((element) => {
          if (element?.company || element?.company?.length === 0) {
            delete element.company
          }
          if (element?.project || element?.project?.length === 0) {
            delete element.project
          }
          if (element?.sections || element?.sections?.length === 0) {
            delete element.sections
          }
          element.idKey = key
          key += 1
          element.children.forEach((ite) => {
            if (ite?.company || ite?.company?.length === 0) {
              delete ite.company
            }
            if (ite?.project || ite?.project?.length === 0) {
              delete ite.project
            }
            if (ite?.sections || ite?.sections?.length === 0) {
              delete ite.sections
            }
            ite.idKey = key
            key += 1
            ite.label = ite.name // 部门名称缺失
          })
        })
      })
      this.data3 = JSON.parse(JSON.stringify(dataList[0].children))
      this.showTree = false
    },
    /* 公司请求人员处理 */
    companyUserEdit(list) {
      const arr = list
      let mainList = []
      arr.forEach((company) => {
        /* 公司级别部门 */
        if (company.sections && company.sections.length) {
          company.sections.forEach((section) => {
            section.companyName = company.name
            section.companyId = company.id
            company.children = []
            company.children.push(section)
          })
          company.children = company.sections
          if (company.children && company.children.length) {
            mainList.push(company)
          }
        }
        /* 公司级别项目处理 */
        if (company.project && company.project.length) {
          const arr = this.projectEdit(company.project)
          const dealProjectSection = (array) => {
            array.forEach((element) => {
              if (element.users && element.users.length >= 0) {
                element.companyName = company.name
                element.companyId = company.id
              } else if (element.children && element.children.length > 0) {
                dealProjectSection(element.children)
              }
            })
          }
          dealProjectSection(arr)
          mainList = mainList.concat(arr)
        }
        /* 公司级别 处理子公司及子公司下项目数据 */
        if (company.company && company.company.length) {
          const arr = this.companyEdit(company.company)
          mainList = mainList.concat(arr)
        }
      })
      let key = 1
      const nodeKey = (list) => {
        list.forEach((element) => {
          if (element?.company || element?.company?.length === 0) {
            delete element.company
          }
          if (element?.project || element?.project?.length === 0) {
            delete element.project
          }
          if (element?.sections || element?.sections?.length === 0) {
            delete element.sections
          }
          element.idKey = key
          key += 1
          if (element.children && element.children.length) {
            nodeKey(element.children)
          }
        })
      }
      nodeKey(mainList)
      console.log(mainList, 'mainList')
      this.data3 = JSON.parse(JSON.stringify(mainList))
      this.showTree = false
    },
    /* 处理公司数据 */
    companyEdit(list) {
      let newList = []
      const reChildConcat = (array) => {
        array.forEach((element) => {
          element.children = []
          /* 处理公司的部门 */
          if (element.sections && element.sections.length) {
            element.sections.forEach((section) => {
              section.companyName = element.name
              section.companyId = element.id
              element.children.push(section)
            })
          }
          /* 处理公司的项目 */
          if (element.project && element.project.length) {
            const arr = this.projectEdit(element.project)
            const companyEdit = (editList) => {
              editList.forEach((item) => {
                if (item.users && item.users.length >= 0) {
                  item.companyName = element.name
                  item.companyId = element.id
                } else if (item.children && item.children.length > 0) {
                  companyEdit(item.children)
                }
              })
            }
            companyEdit(arr)
            element.children = element.children.concat(arr)
          }
          /* 处理公司下面的公司 */
          if (element.company && element.company.length) {
            reChildConcat(element.company)
          }
          if (element.children.length > 0) {
            newList.push(element)
          }
        })
      }
      reChildConcat(list)
      newList = newList.filter((item) => item.children.length > 0)
      return newList
    },
    /* 处理公司级别下面项目 */
    projectEdit(dataList) {
      let projectList = []
      const projectEdit = (allList) => {
        allList.forEach((element) => {
          if (element.external_company && element.external_company.length) {
            element.external_company.forEach((item) => {
              if (item.sections && item.sections.length) {
                item.children = item.sections
              }
            })
            element.children = element.external_company.filter(
              (item) => item.sections && item.sections.length > 0
            )
            projectList.push(element)
          }
          if (element.project && element.project.length) {
            projectEdit(element.project)
          }
        })
      }
      projectEdit(dataList)
      projectList = projectList.filter((item) => item.children.length > 0)
      return projectList
    },
    onSelectChange(arr) {
      this.$emit('on-select-change', arr)
    }
  },
  mounted() {
    const isArchiCompany = this.archiInfo.archiType === 'company' // 当前登录是否是公司端
    const archiId = this.archiInfo.archiId
      ? this.archiInfo.archiId
      : this.archiInfo.id.split('-')[1] // 当前登录的架构id

    console.log(isArchiCompany, archiId, 555)

    if (this.dataInfo && this.dataInfo.length) {
      const list = JSON.parse(JSON.stringify(this.dataInfo))
      this.userEdit(list)
    } else if (!isArchiCompany) {
      const obj = {
        object_uuid: 'object616fe6fe8119f',
        view_uuid: 'view6177ce7668c82',
        __method_name__: 'dataList',
        transcode: 0,
        project_id: archiId
      }
      getValue(obj).then((res) => {
        if (res.data.code === 200) {
          const list = JSON.parse(JSON.stringify(res.data.data))
          this.userEdit(list)
        }
      })
    } else if (isArchiCompany) {
      const obj = {
        object_uuid: 'object616fd8131c8f1',
        view_uuid: 'view617911f24b73d',
        __method_name__: 'dataList',
        transcode: 0,
        company_id: archiId
      }
      getValue(obj).then((res) => {
        if (res.status === 200) {
          const list = JSON.parse(JSON.stringify(res.data.data))
          this.companyUserEdit(list)
        }
      })
    }
  },
}
</script>
<style lang="less" scoped></style>
