<!--
 * @Description: 统计表格-1
 * @Author: luocheng
 * @Date: 2022-04-07 16:25:29
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-01-12 10:18:09
-->
<template>
  <div class="common-stat-table-one">
    <div class="search-header">
      <DatePicker
        v-model="time"
        @change="handleTimeChange"
        type="monthrange"
        :clearable="false"
        range-separator="-"
        unlink-panels
        start-placeholder="开始时间"
        end-placeholder="结束时间"
        value-format="yyyy-MM">
      </DatePicker>
    </div>
    <article class="stat-content">
      <el-table
        width='100%'
        v-loading="tableLoading"
        :data="tableData"
        border
        :span-method="spanMethod"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" v-if="statusConfig.showSelect" width="55" :fixed="fixedColumn ? 'left' : false"/>
        <el-table-column prop="classification" label=""  :fixed="fixedColumn ? 'left' : false"/>
        <el-table-column prop="label" width="160" label=""   :fixed="fixedColumn ? 'left' : false"/>
        <el-table-column prop="" label="预付款" align="center">
          <el-table-column prop="advanceCharge" width="120" :label="prePayTime"  align="center"/>
        </el-table-column>
        <el-table-column prop="" label="进度款" align="center">
          <template v-for="item in yearMonthCol">
            <el-table-column :key="item.key" :prop="item.key" width="120" :label="item.label" align="center"/>
          </template>
        </el-table-column>
        <el-table-column prop="warrantydeposit" label="质保金" align="center" > <el-table-column prop="warrantydeposit" width="120" :label="warrantyDepositTime" align="center" />
        </el-table-column>
      </el-table>
    </article>
  </div>
</template>

<script>
import { DatePicker  } from 'element-ui';
import { dataInterface } from '@/apis/data';
import eventBus from '@/plugins/eventBus';
import { initParams } from '@/utils/tools';
import { mapState } from 'vuex';
import databaseTriggerMixin from '@/custom-component/databaseTriggerMixin';

export default {
  name: 'CommonStatTableTwo',
  mixins: [databaseTriggerMixin],
  components: {
    DatePicker,
  },
  props: {
		// 是否为分组
		isGroup: {
			type: Boolean,
		},
		// 当前分组的组件数据
		groupComponents: {
			type: Array,
			default: () => []
		},
    componentList: {
      default: null
    }
  },
  computed: {
    ...mapState(['componentData']),
    // 组件配置
    statusConfig() {
      return this.element.statusConfig || {}
    },
    // 固定头尾列
    fixedColumn() {
      return this.statusConfig?.fixedColumn;
    },
    // 数据仓库
    database() {
      return this.element?.database
    }
  },
  data() {
    return {
      time: [],
      tableData: [],
      tableLoading: false,
      archiType: '',
      yearMonthCol: [],
      prePayTime:'', // 预付款支付日期
      warrantyDepositTime:'' // 质保金计划支付日期
    }
  },
  created() {
    this.archiType = this.$GetTargetArchi('archiType');
    // 初始化时间为最近一年
    const date = new Date()
    const y = date.getFullYear()
    const m = date.getMonth()
    this.time = [`${y - 1}-${m > 9 ? m : '0' + m}`, `${y}-${m + 1 > 9 ? m + 1 : '0' + (m + 1)}`]
    this.handleTimeChange(this.time)
  },
  mounted () {
    // 监听请求
		// 配置关联参数的容器才需要监听
    const databaseTrigger = {
      [this.$elementId]: (data) => {
        if (data.parentId && data.parentId !== this.$elementId) return false;
        // 配置时候触发请求
        if (data.componentId === this.$elementId && data.isInit) {
          this.getData();
          return;
        }
        // 点击操作时候不更新数据
        if (data.noUpdate) return;
        const { paramsConfig } = this.element.database;
        if (!paramsConfig || !paramsConfig.length) return;
        let isTarget = false;
        paramsConfig.forEach((ele) => {
          if (ele.componentId === data.componentId) {
            isTarget = true;
          }
        });
        if (!isTarget && !data.isUpdate) return;
        this.getData();
      }
    }
		eventBus.$on('databaseTrigger', databaseTrigger[this.$elementId]);
  },
  methods: {
    // 事件change事件
    handleTimeChange(v) {
      if (Array.isArray(v) && v.length) {
        this.yearMonthCol = this.returnDateRange(v)
      }
      this.getData()
    },
    // 获取数据
    getData() {
      let params = {
        __method_name__: 'dataList',
        transcode: 0,
        object_uuid: this.database?.objectData?.uuid,
        view_uuid: this.database?.viewData?.uuid,
        search: []
      }
      // 如果存在时间检索条件
      if (this.time && this.time.length) {
        params.search.push({
          code: 'year_month_use',
          ruleType: 'between',
          value: this.time
        })
      }
      const { search = [], param = {}, canPost } = initParams(this.element?.database?.paramsConfig || [], this.isGroup, this.componentList || this.componentData, this.groupComponents);
      if (!canPost) return;
      params = {
        ...params,
        ...param,
        search: params.search.concat(search)
      }
      const params1 = {
        __method_name__: 'dataList',
        transcode: 0,
        object_uuid:'object622fff8593545',
        view_uuid:'view6250042310455',
        ...param,
      }
      this.tableLoading = true
      Promise.all([dataInterface(params), dataInterface(params1)]).then(res => {
        this.prePayTime = this.reDate(res[1].data.data[0].pre_pay_time)
        this.warrantyDepositTime = this.reDate(res[1].data.data[0].warranty_deposit_time)
        if (res[0].data.code === 200 && res[1].data.code === 200) {
          this.initTableData(res[0].data.data, res[1].data.data)
        }
      }).finally(() => {
        this.tableLoading = false
      })
    },
    
    //将yyyy-MM-dd变成年月日
    reDate(date){
      let newArr = date.split('-')
      switch(newArr.length){
        case 2: return newArr[0] + '年' + newArr[1] + '月'
        case 3: return newArr[0] + '年' + newArr[1] + '月' + newArr[2] + '日'
      }
     return ''
    },
    // 生成表格数据
    initTableData(data, data1) {
      // 使用哈希存储信息，便于查找
      const work = {
        classification: '施工总进度(工作量)',
        label: '计划完成率',
        colsapn: 2,
        advanceCharge: '',
        warrantydeposit: ''
      }
      if (Array.isArray(data1) && data1.length) {
        work.advanceCharge = data1[0].pre_pay
        work.warrantydeposit = data1[0].warranty_deposit
      }
      const work1 = {
        classification: '施工总进度(工作量)',
        label: '实际完成率',
        colsapn: 0,
      }
      const output = {
        classification: '本部产值',
        label: '计划完成额',
        colsapn: 2,
      }
      const output1 = {
        classification: '本部产值',
        label: '实际完成额',
        colsapn: 0,
      }
      const collection = {
        classification: '本部收款',
        label: '计划完成额',
        colsapn: 2,
      }
      const collection1 = {
        classification: '本部收款',
        label: '实际完成额',
        colsapn: 0,
      }
      const personnelPlan = {
        classification: '人员计划',
        label: ' ',
        colsapn: 1,
      }
      // const personnelPlan1 = {
      //   classification: '人员计划',
      //   label: ' ',
      //   colsapn: 0,
      // }
      // const personnelPlan2 = {
      //   classification: '人员计划',
      //   label: ' ',
      //   colsapn: 0,
      // }

      const sourceData = [work, work1, output, output1, collection, collection1, personnelPlan]
    
      sourceData.map(el => {
        this.yearMonthCol.map(ele => {
          el[ele.key] = 0
        })
      })
      const keys = {
        actual_work: work1, // 施工总进度 实际完成率
        current_month_plan: output, // 本部产值 计划完成额
        actual_output: output1, // 本部产值 实际完成额
        current_month_plan_collect: collection, // 本部收款  计划完成额
        actual_shou: collection1, // 本部收款  实际完成额
        plan_percent: work, // 施工总进度 计划完成率
        researchers_plan:personnelPlan // 人员计划
      }
      data.map(el => {
        for (const key in keys) {
          if (Object.hasOwnProperty.call(keys, key)) {
            const yearMonthKey = `${el.new_year}-${el.new_month}`
            keys[key][yearMonthKey] = (isNaN(el[key]) || !el[key]) ? 0 : el[key]
          }
        }
      })
      this.tableData = sourceData
    },
    returnDateRange (timeRanges) {
      const startDate = new Date(timeRanges[0] + '-01 00:00:00')
      const endDate = new Date(timeRanges[1] + '-01 00:00:00')
      const res = []
      let temp = startDate
      res.push(temp)
      while(endDate.getTime() > temp.getTime()) {
        temp = this.addMonth(temp)
        res.push(temp)
      }
      return (res.map(el => {
        return this.formateTime(el)
      }))
    },
    // 下一月
    addMonth(date) {
      const time = date.getTime()
      const temp = 1000 * 60 * 60 * 24 * 31
      const resDate = new Date()
      resDate.setTime(time + temp)
      return new Date(resDate.getFullYear(), resDate.getMonth(), 1)
    },
    // 格式化时间
    formateTime (time) {
      const date = new Date(time)
      return {
        key: `${date.getFullYear()}-${date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)}`,
        label: `${date.getFullYear()}年${date.getMonth() + 1}月`
      }
    },
    // 合并列
    spanMethod({ row, columnIndex, rowIndex }) {
      if (columnIndex === 0) {
        if (row.colsapn) {
          return [row.colsapn, 1]
        } else {
          return [0, 0]
        }
      }
      if (columnIndex === 2) {
        if (rowIndex === 0) {
          return [9, 1]
        } else {
          return [0, 0]
        }
      }
      if (this.yearMonthCol.length) {
        if (columnIndex === this.yearMonthCol.length + 3) {
          if (rowIndex === 0) {
            return [9, 1]
          } else {
            return [0, 0]
          }
        }
      } else {
        if (columnIndex === 4) {
          if (rowIndex === 0) {
            return [9, 1]
          } else {
            return [0, 0]
          }
        }
      }
    },
    getEndDate(v) {
      const date = new Date(v)
      // 传递的事件为当月1号，加32天是绝对能到下一个月的。
      date.setTime(date.getTime() + 60000 * 60 * 24 * 32)
      const year = date.getFullYear()
      const month = date.getMonth()
      const newDate = new Date(year, month, 0)
      const ys = newDate.getFullYear()
      const ms = newDate.getMonth() >= 9 ? newDate.getMonth() + 1 : '0' + (newDate.getMonth() + 1)
      const ds = newDate.getDate()
      return `${ys}-${ms}-${ds} 23:59:59`
    },
    /**
		 * @desc: 选择表格
		 * @param {Array} selectList
		 */
		handleSelectionChange(selectList) {
			this.element.resolveData = selectList || [];
		},
  }
}
</script>

<style lang="less" scoped>
@headerHeight: 45px;
@border: rgb(221, 222, 221);
.common-stat-table-one{
  .search-header{
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 5px;
    box-sizing: border-box;
    .el-input{
      width: 220px;
    }
  }
  .stat-content{
    height: calc(100% - 50px);
    padding: 0 5px;
    box-sizing: border-box;
  }
  .ellipsis{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
