/*
 * @Date: 2023-03-14 10:26:27
 * @LastEditors: asahi
 * @LastEditTime: 2023-03-14 10:33:45
 * @FilePath: \dataview-next\src\custom-component\component-config\ue.js
 */
const ue = {
    type: 'ue',
	title: 'UE相关',
	show: true,
	order: 8,
	componentList: [
        {
			component: 'UEClient',
			type: 'container',
			name: 'UE事件中心',
			icon: 'icon-moxing',
			isLock: false, // 是否锁定
			needCommonStyle: true,
			propValue: '',
			span: 24,
			statusConfig: {
				displayShow: true,
				isShow: true // 是否显示
			},
			style: {
				height: '100%',
				width: '',
				rotate: 0,
				paddingTop: 0,
				paddingBottom: 0,
				paddingLeft: 0,
				paddingRight: 0
			},
			behaviors: [
			],
			actionConfig:{
				needAction: true,
			},
		},
    ]
}

export default ue;
