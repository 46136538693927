<!--
 * @Author: 冉桂精 156189868@qq.com
 * @Date: 2022-12-01 11:37:16
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2022-12-22 16:26:43
 * @FilePath: /dataview-viewer-test/src/custom-component/currency/TimeCom.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div
    class="currency-component-container"
    :style="style"
  >
    {{ timeString }}
  </div>
</template>

<script>
import Minin from './mixin';
export default {
  name: 'TimeCom',
  mixins: [Minin],
  computed: {
    style() {
      return {
        fontSize: this.fontSize,
        textAlign: this.textAlign || '',
        lineHeight: this.lineHeight || '',
        fontWeight: this.bold,
        fontStyle: this.italic
        // fontFamily: this.fontFamily,
      };
    }
  },
  data() {
    return {
      date: new Date(),
      timeString: '',
      timer: null
    };
  },
  created() {
    this.timer = setInterval(() => {
      this.date = new Date();
      this.updateTimeString();
    }, 1000);
  },
  beforeDestroy() {
    // 销毁前清空计时器
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    // 更新时间
    updateTimeString() {
      let h = this.date.getHours();
      let m = this.date.getMinutes();
      let s = this.date.getSeconds();
      h = this.fillZero(h);
      m = this.fillZero(m);
      s = this.fillZero(s);
      this.timeString = `${h}:${m}:${s}`;
    },
    // 补零
    fillZero(val) {
      if (!isNaN(val) && val < 10 && val >= 0) {
        return `0${val}`;
      }
      return val;
    }
  }
};
</script>

<style lang="less" scoped>
.currency-component-container {
  width: 100%;
  height: 100%;
}
</style>
