/*
 * @Description: 自定义菜单
 * @Author: luocheng
 * @Date: 2022-06-17 11:27:46
 * @LastEditors: luocheng
 * @LastEditTime: 2022-06-17 14:55:19
 */
export default {
  state: {
    showCustomMenu: false,
    customMenuPosition: {
      left: 0,
      top: 0
    },
    // 当前操作的菜单
    customMenuData: null,
    // 当前APP
    currentMenuAppData: null
  },
  mutations: {
    /**
     * @desc: 显示自定义菜单
     */
    showCustomMenu(state, { position = {}, data }) {
      state.customMenuPosition = position;
      state.showCustomMenu = true;
      state.customMenuData = data;
    },
    /**
     * @desc: 隐藏编辑器菜单
     */
    hideCustomMenu(state) {
      state.showCustomMenu = false;
    },
    setMenuAppData(state, { app, data }) {
      state.currentMenuAppData = app;
      sessionStorage.setItem('currentMenuAppData', JSON.stringify(app));
      sessionStorage.setItem('customMenuData', JSON.stringify(data));
    }
  }
}