<!--
    @name: FormDesigner
    @description：FormDesigner
    @author: ZengWei
    @date: 2021-12-09 09:49
-->
<template>
	<div class="form-designer" v-loading="loading">
		<iframe
			ref="designForm"
			v-if="pathSrc"
			:src="pathSrc"
			style="width: 100%; height: 100%"
			frameborder="0"
		></iframe>
		<el-dialog
			title="表单设计预览"
			:visible.sync="dialogVisible"
			:fullscreen="true"
			custom-class="vertical-center"
      append-to-body
		>
			<div>
				<PcParser
          ref="formParser"
          v-if="previewData && dialogVisible"
          :form-data="previewData">
        </PcParser>
				<skeleton v-else></skeleton>
			</div>
		</el-dialog>
	</div>
</template>

<script>
  import PcParser from '@/custom-component/form/newParser/PcParser';
	import skeleton from "@/custom-component/form/newParser/skeleton";
	import { dataInterface } from '@/apis/data';
	import { Dialog } from 'element-ui'

	export default {
		name: "FormDesigner",
		components: { PcParser,skeleton,
			'el-dialog': Dialog,
		},
		props: {
			recoverDesignData: {
				type: Object,
				default: () => {},
			},
			formUuid: {
				type: String,
				default: '',
			},
		},
		data() {
			return {
				loading: true,
				iframeWindow: null,
				formDesignData: null,
				dialogVisible:false,
				previewData: null,
				pathSrc: '',
			}
		},
		created() {
			if(process.env.NODE_ENV === 'production'){
				this.pathSrc = "/base/designForm/#/design";
			} else {
				this.pathSrc = "/localForm/#/design";
			}
			window.saveFormDesign = this.saveFormDesign.bind(this);
			window.previewPC = this.previewPC.bind(this);
		},
		mounted() {
      this.$nextTick(()=>{
        this.initIframeWindow();
      })
		},
		methods: {
			initIframeWindow() {
				const _this = this;
        this.loading = true;
        this.$refs.designForm.onload = ()=>{
          const iframe = _this.$refs.designForm.contentWindow;
          if (typeof iframe.formDesignRecover === "function") {
            if (_this.formUuid) {
              dataInterface({},'api/form4/design/'+_this.formUuid,'GET')
                .then((res) => {
                  if (res && res.data && res.data.data) {
                    _this.formDesignData = JSON.parse(res.data.data.json);
                    if(_this.formDesignData){
                      iframe.formDesignRecover(JSON.stringify(_this.formDesignData));
                    }
                  }
                  _this.loading = false;
                })
                .catch((err) => {
                  console.log(err);
                  _this.loading = false;
                });
            } else if(_this.recoverDesignData?.fields){
              if(_this.recoverDesignData){
                iframe.formDesignRecover(JSON.stringify(_this.recoverDesignData));
              }
              _this.loading = false;
            } else {
              _this.loading = false;
            }
          }
        }
			},
			saveFormDesign(formData) {
				let data = {
					json: JSON.stringify(formData),
				};
				this.$confirm("请确认相关配置配置完成, 是否继续?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				})
					.then(() => {
						console.log('表单设计数据：',data)
						this.$emit('form-data',data)
					})
					.catch(() => {});
			},
			previewPC(formDesignData){
				this.previewData = formDesignData
				this.dialogVisible = true
			},
		},
	}
</script>

<style lang="less" scoped>
	.form-designer {
		height: 100%;
		background: #fff;
		text-align: left;
	}
</style>
