<!--
 * @Author: shiltin
 * @Date: 2022-07-15 09:37:07
 * @LastEditTime: 2023-01-03 11:11:07
 * @Description: 
 * @LastEditors: Shiltin 18580045074@163.com
-->
<template>
  <div v-if="showTree" class="tree" >
    <div v-if="treeData && treeData.length" class="tree-data">
			<div class="top-title">
				{{title}}
				<!-- <i @click="closePage" v-if="element && element.checkedParam !== null" class="close-icon iconfont iconc-close"	/> -->
			</div>
			<el-tree
				class="type-tree"
				:class="activeCol?'active-tree':''"
				:data="treeData"
				:props="treeProps"
				highlight-current
				node-key="id"
				default-expand-all
				@current-change="stateCheckClick"
				ref="treeRef">
			</el-tree>
    </div>
    <div v-if="(!treeData || treeData.length === 0)" class="tree-empty">
			<el-empty style="height: 100%"  description="暂无数据"></el-empty>
    </div>
    <div v-if="treeData && treeData.length" class="submit">
			<el-button :disabled="stateCheckObj === null" type="primary" @click="submitData()">确认</el-button>
    </div>
  </div>
</template>
<script>
  import { defineComponent, onMounted, reactive, toRefs } from 'vue'
  import { Empty, Tree } from 'element-ui'
  import { dataInterface } from '@/apis/data/index';
  // import eventBus from '@/plugins/eventBus';
  import useStore from '@/store'
  export default defineComponent({
  props: {
		element: {
			type: Object,
			default: () => {}
		}
  },
  components: {
    'el-empty': Empty,
		'el-tree': Tree
  },
  setup(props) {
    const store = useStore
    const state = reactive({
      treeData: [],
			treeRef:null,
      showTree: false,
      stateCheckObj: null,
      treeProps: null,
      view_uuid: '',
      object_uuid: '',
      getListLoading: false,
			title:'',
			activeCol:''
    })
    const getList = async () => {
      const configObj = {
        __method_name__: 'dataList',
        object_uuid: props.element.database.objectData.uuid,
        view_uuid: props.element.database.viewData.uuid,
        page: 1,
        size: 1000
      }
      const res = await dataInterface(configObj)
      if (res && res.status === 200) {
        /* 字段值 */
        state.metadata = res.data.metadata
        let idKey = ''
        for(let key in state.metadata) {
          if (state.metadata[key] === 'id') {
            idKey = key
          }
        }
        if (res.data.data) {
          state.treeData = props.element.database?.typeTreeInfo?.isPage ? res.data.data.data : res.data.data.data
					store.commit('modifyComponent', {
						component: {
							...props.element,
							metadata:res.data.metadata
						},
						containerId: null,
						isModify: true
					});
          if (state.treeData && state.treeData.length && state.treeProps !== null) {
            const loop = (array) => {
              array.forEach(element => {
                element.id = element[idKey]
                if (element[state.treeProps.children] && element[state.treeProps.children].length) {
                  loop(element[state.treeProps.children])
                }
              });
            }
            loop(state.treeData)
          }
        }
        state.showTree = true;
				setTimeout(() => {
					if(state.treeRef !== null && state.stateCheckObj !== null){
						state.treeRef.setCurrentKey(state.stateCheckObj.id);
					}
				},500)
      }
    }
    onMounted( async () => {
			if(props.element?.checkedParam !== null){
				stateCheckClick(props.element.checkedParam)
			}
			if (props.element?.database?.typeTreeInfo && props.element.database.typeTreeInfo?.labelKey && props.element.database.typeTreeInfo.childrenKey) {
				state.treeProps = {
					children: props.element.database.typeTreeInfo.childrenKey,
					label: props.element.database.typeTreeInfo.labelKey
				}
				state.activeCol = props.element.database.typeTreeInfo.activeCol || ''
				state.title = props.element.database.typeTreeInfo.title || '分类选择'
			} 
			if (props.element.database.viewData?.uuid && props.element.database.objectData?.uuid) {
				if (props.element.database.viewData.uuid === state.view_uuid && props.element.database.objectData.uuid === state.object_uuid) {
					return
				}
				state.view_uuid = props.element.database.viewData.uuid
				state.object_uuid = props.element.database.objectData.uuid
				getList()
			}
    })
    const submitData = () => {
      if (props.element?.actionConfig?.sureClick?.eventList?.length) {     
        const eventList = props.element.actionConfig.sureClick.eventList;
        if (eventList && eventList.length) {
					eventList.forEach((el) => {
						el.eventList.forEach(ele => {
							if (ele.key === 'click') {
								ele.effects.forEach((effect) => {
									store.commit('triggerEvents', {
										config: {
											...ele,
										...effect
										},
										element: props.element
									});
								});
							}
						})
					});
				}
      }
    }
    const stateCheckClick = (data) => {
      state.stateCheckObj = data
			store.commit('modifyComponent', {
				component: {
					...props.element,
					checkedParam: data,
					resolveData: data
				},
				containerId: null,
				isModify: true
			});
    }
		//关闭当前页
		const closePage = () => {
			let statusConfig = props.element.statusConfig
			statusConfig.isShow = false
			store.commit('modifyComponent', {
				component: {
					...props.element,
					statusConfig:statusConfig
				},
				containerId: null,
				isModify: true
			});
		}
    return {
      ...toRefs(state),
      submitData,
      stateCheckClick,
			closePage
    }
  }
 })
</script>
<style lang='less' scoped>
.tree {
	backdrop-filter: blur(4px);
  width: 100%;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
	color:inherit;
  display: flex;
  flex-direction: column;
  .tree-data {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
		overflow-y:auto;
    :deep(.el-tree){
			background:transparent!important;
			color:inherit !important;
		}
		:deep(.el-tree-node__content:hover){
			background:mix(#ffffff, @theme, 60%);
			opacity: 0.8;
		}
		:deep(.el-tree-node.is-current>.el-tree-node__content){
			background:mix(#ffffff, @theme, 40%);
		}
		:deep(.el-tree-node__content){
			height: 35px!important;
		}
  }
  .tree-empty {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .submit {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
		button{
			width: 100%;
		}
  }
	.top-title{
		height: 35px;
		text-align: left;
		font-size: 16px;
		line-height:30px;
		color:inherit !important;
		position:relative;
		i{
			position:absolute;
			right:0;
			top:0;
			font-size: 20px;
		}
	}
}
</style>
