<!--
 * @Author: 吴绍鹏 542278473@qq.com
 * @Date: 2022-12-05 09:11:47
 * @LastEditors: 吴绍鹏 542278473@qq.com
 * @LastEditTime: 2022-12-13 17:42:43
 * @FilePath: \dataview-next\src\custom-component\video\player\RtmpPlayer.vue
 * @Description: RTMP组件
-->
<template>
  <iframe class="rtmp-player-container" :src="src"></iframe>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      default () {
        return {}
      },
      require: true
    },
    config: {
      type: Object,
      default () {
        return {
          controls: true
        }
      }
    } 
  },
  computed: {
    /**
     * @description: iframe的路径
     * @return {String} iframe的路径
     */    
    src() {
      return this.data.monitor_path;
    }
  }
}
</script>
<style lang="less" scoped>
  .rtmp-player-container{
    width: 100%;
    height: 100%;
  }
</style>
