<!--
 * @Author: 吴绍鹏 542278473@qq.com
 * @Date: 2022-12-05 09:11:47
 * @LastEditors: 吴绍鹏 542278473@qq.com
 * @LastEditTime: 2022-12-19 14:37:18
 * @FilePath: \dataview-next\src\custom-component\video\player\EzuiPlayer.vue
 * @Description: 普通萤石云播放器
-->
<template>
  <video class="ezui-video-warp" autoplay :controls="config.showControl" playsInline v-ezuiDirectives="{
    src,
    player,
    setPlayer
  }">
  </video>
</template>

<script>
/**
 * @desc 萤石云播放器hls版本, 采用指令来进行播放，这样可以简化dom的操作，进行分别设置是为了上层适配，提高可阅读性
 */
 import { EZUIPlayer } from '@/libs/ezuikit.js'
 export default {
  props: {
    src: {
      type: String,
      default: ''
    },
    controls: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      player: null
    }
  },
  directives: {
    ezuiDirectives: {
      inserted: (el, binding) => {
        if(el.tagName.toLocaleLowerCase() !== 'video') {
          console.error('ezui directives must ues video tag');
          return;
        }
        if(!binding.value || !binding.value.src) {
          console.error('ezui directives src is undefine')
        }
        if (binding.oldValue) {
          const srcFlag = binding.oldValue.src === binding.value.src;
          // 如果值并没有变化则不进行处理，原因是因为当盒子被点击时compute会重新计算
          if (srcFlag) {
            return;
          }
        }
        if(EZUIPlayer) {
          const source = document.createElement('source')
          source.src = binding.value.src;
          source.setAttribute('type', "application/x-mpegURL");
          el.appendChild(source);
          const player = new EZUIPlayer(el);
          binding.value.setPlayer(player)
        }
      },
      update: (el, binding) => {
        if(el.tagName.toLocaleLowerCase() !== 'video') {
          console.error('hls directives must ues video tag');
          return;
        }
        // 如果值并没有变化则不进行处理，原因是因为当盒子被点击时compute会重新计算
        if (binding.oldValue && binding.oldValue.src === binding.value.src) {
          return ;
        }
         // 如果存在之前的实例，停掉它
         if (binding.value.player && binding.value.player.stop) {
          binding.value.player.stop();
        }
        if (binding.value.player && binding.value.player.destroy) {
          binding.value.player.destroy();
        }
        if(EZUIPlayer) {
          const source = document.createElement('source')
          source.src = binding.value.src;
          source.setAttribute('type', "application/x-mpegURL");
          el.appendChild(source);
          const player = new EZUIPlayer(el);
          binding.value.setPlayer(player)
        }
      },
      unbind: (el, binding) => {
        if (binding.value.player && binding.value.player.stop) {
          binding.value.player.stop();
        }
        if (binding.value.player && binding.value.player.destroy) {
          binding.value.player.destroy();
        }
      }
    }
  },
  methods: {
    setPlayer(player) {
      this.player = player
    }
  }
}
</script>
<style lang="less" scoped>
  .ezui-video-wrap{
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
</style>