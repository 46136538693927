<template>
  <div
    class="punch-in-container"
    v-loading="loading"
  >
    <div class="search-container">
      <!-- 筛选项 -->
      <el-date-picker
        v-if="statusConfig.showDateScreen"
        v-model="attendTimeSource"
        @change="handleAttendTimeSourceChange"
        value-format="yyyy-MM-dd"
        format="yyyy-MM-dd"
        type="date"
        placeholder="选择日期"
      >
      </el-date-picker>
    </div>
    <div
      :id="key"
      class="punch-in-inner"
    ></div>
  </div>
</template>
<script>
import { DatePicker } from 'element-ui';
import Viewer from '@/libs/viewer2DGIS.min.js';
import eventBus from '@/plugins/eventBus';
import CustomComponentMixin from '@/custom-component/mixins/CustomComponentMixin.js';
import { dataInterface } from '@/apis/data';

/**
 * @desc 人员打卡组件， 使用viewer2DGIS
 */
export default {
  name: 'PunchIn',
  mixins: [CustomComponentMixin],
  components: {
    'el-date-picker': DatePicker
  },
  props: {
    element: {
      type: Object,
      required: true,
      default: () => {}
    },
    // 是否在组合内
    isGroup: {
      type: Boolean
    },
    // 组合内组件列表
    groupComponents: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      map2d: null,
      key: this.getKey(),
      personInfo: [],
      personInfoOLD: [],
      WLInfo: [],
      WLInfoOLD: [],
      attendTimeSource: new Date(), // 查询时间
      loading: false
    };
  },
  computed: {
    statusConfig() {
      return this.element.statusConfig || {};
    },
    // 参数配置
    paramsConfig() {
			console.log(this.element.paramsConfig,'paramsConfig')
      return this.element?.paramsConfig || [];
    },
    // 文本颜色
    titleColor() {
      return this.statusConfig?.titleColor || '#FFFFFF'
    },
    // 图标颜色
    iconColor() {
      return this.statusConfig?.iconColor || '#FF0000'
    }
  },
  mounted() {
    const databaseTrigger = {
      [this.element.id]: config => {
        if (!config || !this.paramsConfig.length || !this.paramsConfig.find(ele => ele.componentId === config.componentId)) {
          return;
        }
        this.updateMarks();
      }
    }
    eventBus.$on('databaseTrigger', databaseTrigger[this.element.id]);
    // 初始化地图2D
    this.initMap2d();
  },
  methods: {
    handleAttendTimeSourceChange() {
      this.updateMarks();
    },
    initMap2d() {
      // 如果Viewer2DGIS存在
      if (Viewer.Viewer2DGIS) {
        this.map2d = new Viewer.Viewer2DGIS(this.key, '20191204141039-1089838992-5de74ddfe3a2c.gislayer2d');
        this.map2d.on('viewerPreLoad', () => {
          //加载完成
          // 使用地图
          this.useMap2d();
        });
      }
    },
    useMap2d() {
      // 初始化GIS模块
      this.map2d.initionMap({ isFence: false, isSearch: false, isMeasure: false, isLayer: false });
      // 设置深色模式
      this.map2d.setImagery('streets_geoq_midnight');
      this.map2d.on('move', () => {});
      this.map2d.emitMove();
      // 更新标记
      this.updateMarks();
    },
    updateMarks() {
      this.personInfo = [];
      this.WLInfo = [];
      if (this.map2d && this.map2d !== null) {
        const { search = [], param = {}, canPost } = this.initParams(this.paramsConfig || [], this.isGroup, this.componentData, this.groupComponents);
        if (!canPost) return false;
        const reqData = {
          object_uuid: 'object62a2b172b5d09',
          view_uuid: 'view62ba68eb9ddeb',
          __method_name__: 'dataList',
          transcode: 0,
          search,
          ...param
        };
        const date = this.formatTime(this.attendTimeSource);
        if (date && !('attend_time' in reqData)) { // 如果date存在 且 params 中不存在 attend_time时添加参数
          reqData.attend_time = date;
        }
        this.loading = true;
        dataInterface(reqData)
          .then(res => {
            if (res.data.code === 200) {
              if (Array.isArray(res.data.data)) {
                this.WLInfo = res.data.data;
                res.data.data.forEach(el => {
                  if(el && Array.isArray(el.gps_people_log) && el.gps_people_log.length) {
                    el.gps_people_log.forEach(ele => {
                      if (ele && Array.isArray(ele.gps_face_log) && ele.gps_face_log.length) {
                        ele.gps_face_log.forEach(elee => {
                          if(elee && Array.isArray(elee.gps_log_item) && elee.gps_log_item) {
                            elee.gps_log_item.forEach(eleee => {
                              this.personInfo.push({
                                ...elee,
                                GPSInfo: eleee
                              });
                            })
                          }
                        })
                      }
                    })
                  }
                });
                if (Array.isArray(this.WLInfo)) {
                  // 处理围栏
                  this.drawWL();
                }
                if (Array.isArray(this.personInfo)) {
                  // 处理人员
                  this.drawPerson();
                }
                this.personInfoOLD = this.personInfo.map(el => {
                  return {
                    id: el.id
                  };
                });
                this.WLInfoOLD = this.WLInfo.map(el => {
                  return {
                    id: el.id
                  };
                });
              }
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    // 围栏
    drawWL() {
      // 隐藏围栏 fix: 目前没有移除围栏的方法，且围栏数量通常较少，不易发生改变，故采用这种方法
      const needHiddenWL = this.WLInfoOLD.filter(el => this.WLInfo.findIndex(ele => el.id === ele.id) < 0);
      needHiddenWL.forEach(el => {
        this.map2d.showFenceByKey(el.id, false);
      });
      this.WLInfo.forEach(item => {
        // 已存在就不管了
        if (this.WLInfoOLD.findIndex(el => item.id === el.id) < 0) {
          switch (+item.type) {
            case 1: {
              this.map2d.addFenceByType(item.id, 'circle', item.calc_area, { color: item.color, radius: item.offset });
              break;
            }
            case 2: {
              this.map2d.addFenceByType(item.id, 'polygon', item.calc_area, { color: item.color });
              break;
            }
            case 3: {
              this.map2d.addFenceByType(item.id, 'polyline', item.calc_area, {
                color: item.color,
                buffer: item.offset
              });
              break;
            }
          }
        }
      });
    },
    // 人员
    drawPerson() {
      // 清空点 fix: 重要用于刷新
      this.map2d.removeAllPoints();
      // 重新绘制点
      this.$nextTick(() => {
        this.personInfo.forEach(el => {
          // this.map2d.addMarkerByWgs(el.id, el.GPSInfo.longitude,el.GPSInfo.latitude,{})//重新添加人员位置
          this.map2d.createPointByDivIcon(el.id, el.GPSInfo.longitude, el.GPSInfo.latitude, {
            title: `<div style="color:${this.titleColor}">${el.name}</div>`,
            titleDirection: 'right',
            titleOffset: [0, 0],
            background: false,
            icon: 'icongis_dingwei',
            color: this.iconColor
          });
        });
      });
    },
    getKey() {
      var d = new Date().getTime();
      if (window.performance && typeof window.performance.now === 'function') {
        d += performance.now(); //use high-precision timer if available
      }
      var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0; // d是随机种子
        d = Math.floor(d / 16);
        return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
      });
      return `viewew-${uuid}`;
    },
    formatTime(date) {
      const dateObj = new Date(date);
      if (!isNaN(dateObj.getTime())) {
        const y = dateObj.getFullYear();
        const m = dateObj.getMonth();
        const d = dateObj.getDate();
        const target = `${y}-${m >= 9 ? m + 1 : '0' + (m + 1)}-${d >= 9 ? d : '0' + d}`;
        return [`${target} 00:00:00`, `${target} 23:59:59`];
      } else {
        return false;
      }
    }
  }
};
</script>
<style lang="less" scoped>
.punch-in-container {
  width: 100%;
  height: 100%;
  position: relative;
  .search-container {
    position: absolute;
    left: 5px;
    top: 5px;
    padding: 5px;
    box-sizing: border-box;
  }
  .punch-in-inner {
    width: 100%;
    height: 100%;
  }
}
</style>
